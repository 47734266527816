// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '@/services/axiosConfig'

// Fetch intake type list
export const gutClinicList = createAsyncThunk('gut-clinic/list', async () => {
  const response = await axiosInstance.get(`appointment/gut-clinic/list`)
  return response.data
})

const gutClinicSlice = createSlice({
  name: 'gutClinic',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    gutClinics: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      // gutClinicList list
      .addCase(gutClinicList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(gutClinicList.fulfilled, (state, { payload } = action) => {
        state.gutClinics = payload.gutClinics
        state.isLoading = false
      })
      .addCase(gutClinicList.rejected, (state, { payload }) => {
        state.isLoading = false
      })
  },
})

export default gutClinicSlice.reducer
