// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../../../services/axiosConfig'

// Fetch patient FollowUp
export const fetchFollowUp = createAsyncThunk(
  'patient/follow-up/patient-follow-up',
  async (patientId) => {
    const response = await axiosInstance.get(
      `patient/follow-up/patient-follow-up/${patientId}`
    )
    return response.data
  }
)
// Follow Up list
export const followUpList = createAsyncThunk(
  'patient/follow-up/list',
  async (filterData) => {
    const response = await axiosInstance.get(`patient/follow-up/list`, {
      params: filterData,
    })
    return response.data
  }
)

//Patient follow up create
export const create = createAsyncThunk(
  'patient/follow-up/create',
  async ({ data, patientId }) => {
    const response = await axiosInstance.post(
      `patient/follow-up/create/${patientId}`,
      data
    )
    return response.data
  }
)

//Patient follow up update
export const update = createAsyncThunk(
  'patient/follow-up/update',
  async ({ data, followUpId }) => {
    const response = await axiosInstance.post(
      `patient/follow-up/update/${followUpId}`,
      data
    )
    return response.data
  }
)

//Patient follow up create
export const fetchFollowUpEdit = createAsyncThunk(
  'patient/follow-up/edit',
  async ({ followUpId }) => {
    const response = await axiosInstance.get(
      `patient/follow-up/edit/${followUpId}`
    )
    return response.data
  }
)

const FollowUpSlice = createSlice({
  name: 'followUp',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    error: '',
    patientFollowUps: [],
    followUps: [],
    followUpDetails: '',
    staff: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch  patient based followup list
      .addCase(fetchFollowUp.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchFollowUp.fulfilled, (state, { payload } = action) => {
        state.patientFollowUps = payload.followUps
        state.staff = payload.staff
        state.isLoading = false
      })
      .addCase(fetchFollowUp.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch followup list
      .addCase(followUpList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(followUpList.fulfilled, (state, { payload } = action) => {
        state.followUps = payload.followUps
        state.staff = payload.staff
        state.isLoading = false
      })
      .addCase(followUpList.rejected, (state) => {
        state.isLoading = false
      })

      // Follow Up create
      .addCase(create.pending, (state) => {
        state.isLoading = true
      })
      .addCase(create.fulfilled, (state, { payload } = action) => {
        state.errors = payload.errors
        state.message = payload.message
        state.isLoading = false
      })
      .addCase(create.rejected, (state) => {
        state.isLoading = false
      })

      // Follow Up create
      .addCase(update.pending, (state) => {
        state.isLoading = true
      })
      .addCase(update.fulfilled, (state, { payload } = action) => {
        state.errors = payload.errors
        state.message = payload.message
        state.isLoading = false
      })
      .addCase(update.rejected, (state) => {
        state.isLoading = false
      })

      // Patient Follow up edit data
      .addCase(fetchFollowUpEdit.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchFollowUpEdit.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.followUpDetails = payload.followUp
        state.error = payload.error
      })
      .addCase(fetchFollowUpEdit.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default FollowUpSlice.reducer
