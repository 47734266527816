// Home.jsx

import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useDispatch } from 'react-redux'

import { setBreadcrumb } from '@/redux/Slice/breadcrumbSlice'
import { Helmet } from 'react-helmet-async'
import TopSection from './Partials/TopSection'
import FaxInbox from './Partials/FaxInbox'
import PatientFollowUp from './Partials/PatientFollowUp'
import { currentUser } from '@/utils/cutils'
import PatientDashboard from '../Dashboard/PatientDashboard'
import Doctor from './Doctor'

const Home = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(setBreadcrumb([{ path: '/', label: 'Dashboard', isLast: true }]))
  }, [dispatch])

  return (
    <>
      <Helmet>
        <title>Bioaro | Dashboard</title>
      </Helmet>
      {[1].includes(currentUser.role_id) && (
        <>
          <TopSection />
          <FaxInbox />
          <PatientFollowUp />
        </>
      )}
      {currentUser.role_id == 5 && <Doctor />}
      {currentUser.role_id == 2 && <PatientDashboard />}
    </>
  )
}

export default Home
