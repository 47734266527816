// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '@/services/axiosConfig'

// Fetch blog list
export const list = createAsyncThunk('website/blog/list', async () => {
  const response = await axiosInstance.get('website/blog/list')
  return response.data
})

// Fetch blog info
export const blogInfo = createAsyncThunk(
  'website/blog/info',
  async (blogId) => {
    const response = await axiosInstance.get(`website/blog/info/${blogId}`)
    console.log(response.data)
    return response.data
  }
)

// Fetch blog list
export const create = createAsyncThunk('website/blog/create', async (data) => {
  const response = await axiosInstance.post('website/blog/create', data)
  return response.data
})

// Fetch blog list
export const update = createAsyncThunk('website/blog/update', async (data) => {
  const response = await axiosInstance.post('website/blog/update', data)
  return response.data
})


// Fetch blog list
export const gptContent = createAsyncThunk('website/blog/gpt-command', async (data) => {
    const response = await axiosInstance.post('website/blog/gpt-command', data)
    return response.data
  })

const blogSlice = createSlice({
  name: 'websiteBlog',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    blogs: [],
    info: [],
    content:'',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      //blog List
      .addCase(list.pending, (state) => {
        state.isLoading = true
      })
      .addCase(list.fulfilled, (state, { payload } = action) => {
        state.blogs = payload.blogs
        state.isLoading = false
      })

      .addCase(list.rejected, (state) => {
        state.isLoading = false
      })

            //gpt content
            .addCase(gptContent.pending, (state) => {
                state.isLoading = true
              })
              .addCase(gptContent.fulfilled, (state, { payload } = action) => {
                state.content = payload.content
                state.isLoading = false
              })
        
              .addCase(gptContent.rejected, (state) => {
                state.isLoading = false
              })

      //blog info
      .addCase(blogInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(blogInfo.fulfilled, (state, { payload } = action) => {
        state.info = payload.info
        state.isLoading = false
      })

      .addCase(blogInfo.rejected, (state) => {
        state.isLoading = false
      })

      //blog create
      .addCase(create.pending, (state) => {
        state.isLoading = true
      })
      .addCase(create.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(create.rejected, (state) => {
        state.isLoading = false
      })

      //blog update
      .addCase(update.pending, (state) => {
        state.isLoading = true
      })
      .addCase(update.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(update.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default blogSlice.reducer
