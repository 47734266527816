import React, { useState } from 'react'
import TaskList from '../../InventoryManagement/Task/partial/TaskList'
import { Card, Typography } from '@mui/material'
import CSelect from '@/components/CSelect'
import { list } from '../../InventoryManagement/Task/taskSlice'
import { useDispatch } from 'react-redux'
import { Stack } from 'rsuite'

const TodoList = () => {
  const dispatch = useDispatch()
  const [status, setStatus] = useState(0)

  return (
    <Card sx={{p:2}}>
      <Stack direction='row' justifyContent='space-between'>
        <Typography variant="h6">To-Do List</Typography>
        <CSelect
          value={status}
          options={statusOptions}
          onChange={(e) => {
            setStatus(e.target.value)
            dispatch(list({ status: e.target.value }))
          }}
        />
      </Stack>
      <TaskList />
    </Card>
  )
}

export default TodoList

const statusOptions = [
  {
    label: 'Pending',
    value: '0',
  },
  {
    label: 'Review',
    value: '1',
  },
  {
    label: 'In Progress',
    value: '2',
  },
  {
    label: 'Success',
    value: '3',
  },
]
