// // UserList.jsx
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import { formatDate, getDateRange } from '@/utils/cdayjs'

import ButtonGroup from '@mui/material/ButtonGroup'

import { Helmet } from 'react-helmet-async'

import { setBreadcrumb } from '@/redux/Slice/breadcrumbSlice'
import { Card, Grid, Stack } from '@mui/material'
import CButton from '@/components/CButton'
import { useDispatch, useSelector } from 'react-redux'
import CDataTable from '@/components/CDatatable'

import dayjs from 'dayjs'
import { leadsDelete, list } from './leadsSlice'

import NoteCreate from './Partials/NoteCreate'
import { showError, showSuccess } from '@/redux/Slice/alertSlice'

import StatusChange from './Partials/StatusChange'
import UserDetails from './Partials/UserDetails'
import RebookAppointment from './Partials/RebookAppointment'
import CDateRangeShortcut from '@/components/CDateRangeShortcut'
import { renderCell } from '@/utils/ctableTool'

const LeadList = () => {
  const dispatch = useDispatch()
  const { leadId } = useParams()

  const { leads, isLoading } = useSelector((state) => state.lead)

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        {
          path: '/leads/list',
          label: 'Leads',
          isLast: false,
        },
        { path: '', label: 'All', isLast: true },
      ])
    )
  }, [dispatch])

  const [quickFilterValue, setQuickfilterValue] = useState(null)
  // add this when you use server side filter
  const handleQuickFilterValue = (filterModel) => {
    const quickFilterValue = filterModel.quickFilterValues.join(' ')
    dispatch(list(quickFilterValue))
    setQuickfilterValue(quickFilterValue)
  }

  const [filterData, setFilterData] = useState({
    date: null,
    start: null,
    end: null,
    status: 'all',
  })
  
  // handle leads delete
  const handleLeadsDelete = async (leadsId) => {
    try {
      const res = await dispatch(leadsDelete(leadsId))

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
      dispatch(list(leadId))
    } catch (error) {
      console.error('An error occurred!', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred!',
        })
      )
    }
  }


  const columns = [
    {
      field: 'full_name',
      headerName: 'Name',
      minWidth: 200,
      flex: 1,
      renderCell: (params) => (
        <>
          <UserDetails
            first_name={params.row.first_name}
            last_name={params.row.last_name}
            email={params.row.email}
            phone={params.row.phone}
          />
        </>
      ),
    },
    {
      field: 'consultation_services_name',
      headerName: 'Service Name',
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      field: 'date',
      headerName: 'FollowUp Date Time',
      minWidth: 200,
      flex: 1,
      renderCell: (params) =>
        renderCell(
          <>
            <RebookAppointment
              status={params.row.date}
              id={params.row.id}
              lead={params.row.lead}
            />
          </>
        ),
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 200,
      flex: 1,

      renderCell: (params) =>
        renderCell(
          <StatusChange
            status={params.row.status}
            id={params.row.id}
            lead={params.row.lead}
          />
        ),
    },
    {
      field: 'refUser',
      headerName: 'Reference',
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },

    {
      headerName: 'Log',
      minWidth: 400,
      flex: 1,
      renderCell: (params) =>
        renderCell(
          <>
            <ButtonGroup variant="contained" aria-label="Basic button group">
              <NoteCreate
                note_to_booking={params.row.id}
                patient_id={params.row.patient_id}
                type={2}
              />
              <CButton
                component={Link}
                to={`/leads/view-log/${params.row.id}`}
                size="small"
                label="View Log"
              />
              <CButton
                component={Link}
                to={params.row.baseUrl}
                label="New Order"
              />
              <CButton
                size="small"
                label="Delete Apt"
                color="error"
                onClick={() => handleLeadsDelete(params.row.id)}
              />
            </ButtonGroup>
          </>
        ),
    },
  ]

  useEffect(() => {
    dispatch(list(filterData))
  }, [leadId])

  const handleFilterData = async (filterData) => {
    try {
      dispatch(list(filterData))
    } catch (error) {
      console.error('An error occurred!', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred!',
        })
      )
    }
  }

  return (
    <>
      <Helmet>
        <title>Bioaro | Leads - List</title>
      </Helmet>
      <Stack direction="row" spacing={0.4} mb={2}>
        <CButton
          size="small"
          label="All"
          onClick={(value) => {
            const modifiedFilterData = {
              date: null,
              start: null,
              end: null,
              status: 'all',
            }

            dispatch(list(modifiedFilterData))

            setFilterData(modifiedFilterData)
          }}
        />
        <CButton
          size="small"
          label="Leads"
          onClick={(value) => {
            const modifiedFilterData = {
              date: null,
              start: null,
              end: null,
              status: 11,
            }

            dispatch(list(modifiedFilterData))

            setFilterData(modifiedFilterData)
          }}
        />
        <CButton
          size="small"
          label="Closed - Success"
          onClick={(value) => {
            const modifiedFilterData = {
              date: null,
              start: null,
              end: null,
              status: 12,
            }

            dispatch(list(modifiedFilterData))

            setFilterData(modifiedFilterData)
          }}
        />
        <CButton
          size="small"
          label="Not Interested"
          onClick={(value) => {
            const modifiedFilterData = {
              date: null,
              start: null,
              end: null,
              status: 13,
            }

            dispatch(list(modifiedFilterData))

            setFilterData(modifiedFilterData)
          }}
        />
      </Stack>
      <Card>
        <Grid container mb={1} spacing={2} justifyContent="end">
          <Grid item>
            <ButtonGroup variant="contained" aria-label="Basic button group">
              <CButton
                label="Today"
                onClick={(value) => {
                  const modifiedFilterData = { ...filterData }
                  modifiedFilterData['date'] = formatDate(dayjs())
                  dispatch(list(modifiedFilterData))

                  setFilterData(modifiedFilterData)
                }}
              />
              <CButton
                label="Tomorrow"
                onClick={(value) => {
                  const modifiedFilterData = { ...filterData }
                  modifiedFilterData['date'] = formatDate(dayjs().add(1, 'day'))
                  dispatch(list(modifiedFilterData))

                  setFilterData(modifiedFilterData)
                }}
              />
              <CButton
                label="All"
                onClick={(value) => {
                  const modifiedFilterData = { ...filterData }
                  modifiedFilterData['date'] = 'all'
                  dispatch(list(modifiedFilterData))

                  setFilterData(modifiedFilterData)
                }}
              />
            </ButtonGroup>
          </Grid>
          <Grid item>
            <CDateRangeShortcut
              onChange={(value) => {
                const modifiedFilterData = { ...filterData }
                const dateRange = getDateRange(value)
                modifiedFilterData['start'] = dateRange.startDate
                modifiedFilterData['end'] = dateRange.endDate
                dispatch(list(modifiedFilterData))
                setFilterData(modifiedFilterData)
              }}
              onClean={(e) => {
                const modifiedFilterData = { ...filterData }
                modifiedFilterData['start'] = null
                modifiedFilterData['end'] = null
                dispatch(list(modifiedFilterData))
                setFilterData(modifiedFilterData)
              }}
            />
          </Grid>
          <Grid item>
            <CButton label="New Lead" />
          </Grid>
        </Grid>

        <CDataTable
          rows={leads}
          columns={columns}
          isLoading={isLoading}
          filterMode="server"
          handleQuickFilterValue={handleQuickFilterValue}
        />
      </Card>
    </>
  )
}

export default LeadList
