import { FormControlLabel, Switch } from '@mui/material'
import React from 'react'

export default function CSwitch({ onChange, label, defaultChecked }) {
  return (
    <FormControlLabel
      control={
        <Switch
          color="primary"
          defaultChecked={defaultChecked}
          onChange={onChange}
        />
      }
      label={label}
    />
  )
}
