import React from 'react'
import { Route, Routes } from 'react-router-dom'
import IntakeTypeCreate from './partial/IntakeTypeCreate'
import IntakeType from './IntakeType'
import IntakeTypeUpdate from './partial/IntakeTypeUpdate'

const intakeTypeRoutes = [
  { path: 'list', element: <IntakeType /> },
  { path: 'create', element: <IntakeTypeCreate /> },
  { path: 'update/:intakeTypeId', element: <IntakeTypeUpdate /> },
]

export default intakeTypeRoutes
