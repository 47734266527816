// // UserList.jsx
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Card, Chip, Grid, Stack, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { Helmet } from 'react-helmet-async'
import CDataTable from '../../../components/CDatatable'
import { setBreadcrumb } from '../../../redux/Slice/breadcrumbSlice'
import { fetchSellerSiteList } from './sellerSiteSlice'
import CButton from '@/components/CButton'
import { renderCell } from '@/utils/ctableTool'
import { deleteRecord } from '@/redux/Slice/commonSlice'
import { showError, showSuccess } from '@/redux/Slice/alertSlice'

// import CButton from '../../../components/CButton'

const SellerSiteList = () => {
  const dispatch = useDispatch()
  const { sellerSites, isLoading } = useSelector((state) => state.sellerSite)

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        {
          path: '/seller-site/list',
          label: 'Seller site',
          isLast: false,
        },
        { path: '', label: 'List', isLast: true },
      ])
    )
  }, [dispatch])

  const [quickFilterValue, setQuickfilterValue] = useState(null)
  // add this when you use server side filter
  const handleQuickFilterValue = (filterModel) => {
    const quickFilterValue = filterModel.quickFilterValues.join(' ')
    dispatch(fetchSellerSiteList(quickFilterValue))
    setQuickfilterValue(quickFilterValue)
  }

  const columns = [
    {
      field: 'title',
      headerName: 'Website',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      field: 'service',
      headerName: 'Service',
      minWidth: 300,
      flex: 1,
      renderCell: (params) =>
        renderCell(
          <>
            <Grid container spacing={0.5} m={0.5}>
              {params.row.service == null
                ? ''
                : params.row.service.map((item, index) => (
                    <Grid item key={index} mr={0.5}>
                      <Chip color="success" size="small" label={item.name} />
                    </Grid>
                  ))}
            </Grid>
          </>
        ),
    },
    {
      field: 'total_order',
      headerName: 'Total Order',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      field: 'url',
      headerName: 'Link',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      headerName: 'Action',
      minWidth: 300,
      flex: 1,
      // renderCell: (params) => formatDateTime(params.value),
      renderCell: (params) =>
        renderCell(
          <Grid container spacing={0.2}>
            <Grid item>
              <CButton
                label="Orders"
                component={Link}
                to={`/seller-site/order-list/${params.row.id}`}
              />
            </Grid>

            <Grid item>
              <CButton
                label="Update"
                component={Link}
                to={`/seller-site/update/${params.row.id}`}
              />
            </Grid>

            <Grid item>
              <CButton
                label="Delete"
                color="error"
                yesNo={true}
                onClick={() => handleDelete(params.row.id)}
              />
            </Grid>
          </Grid>
        ),
    },
  ]

  useEffect(() => {
    dispatch(fetchSellerSiteList())
  }, [])

  const handleDelete = async (id) => {
    try {
      const res = await dispatch(
        deleteRecord({
          id: id,
          model: 'SellerSite',
        })
      )

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        dispatch(fetchSellerSiteList())
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Server error:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred during updating user.',
        })
      )
    }
  }

  return (
    <>
      <Helmet>
        <title>Bioaro | Seller Site - List</title>
      </Helmet>
      <Card sx={{ p: 3 }}>
        <Stack direction="row" justifyContent="end" sx={{ mb: 2 }}>
          <CButton
            label="Create"
            icon={<AddIcon />}
            component={Link}
            to={`/seller-site/create`}
          />
          
        </Stack>

        <CDataTable
          rows={sellerSites}
          columns={columns}
          isLoading={isLoading}
          filterMode="server"
          getRowHeight="auto"
        />
      </Card>
    </>
  )
}

export default SellerSiteList
