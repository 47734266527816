import * as React from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress'

function FacebookCircularProgress(props) {
  return (
    <Box sx={{ position: 'relative' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        }}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) =>
            theme.palette.mode === 'light' ? theme.palette.primary : '#308fe8',
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </Box>
  )
}

export default function CCircularProgress() {
  return (
    <Box
      sx={{
        display: 'grid',
        placeItems: 'center',
        justifyContent: 'center',
        my: '25%',
      }}
    >
      <FacebookCircularProgress />
      {/* <CircularProgress /> */}
    </Box>
  )
}
