// components/FormPreview.js
import React, { useState } from 'react'
import { Box, Button, Typography, Divider, Stack, Card } from '@mui/material'
import CTextField from '@/components/CTextField'
import CSelect from '@/components/CSelect'
import CRadioGroup from '@/components/CRadioGroup'
import CCheckbox from '@/components/CCheckbox'
import CAutocomplete from '@/components/CAutocomplete' // Adjust the import path as necessary
import CFileField from '@/components/CFileField'
import { useSelector } from 'react-redux'
import CDatePicker from '@/components/CDatePicker'
import { formatDate } from '@/utils/cdayjs'
import CNumberField from '@/components/CNumberField'

const FormPreview = () => {
  const elements = useSelector((state) => state.formBuilder.elements)
  const filteredElements = elements.filter(
    (item) => Object.keys(item).length !== 0
  )
  const [formElements, setFormElements] = useState(filteredElements)

  const handleChange = (index, target) => {
    const { type, value } = target

    let fieldValue
    if (type === 'checkbox') {
      fieldValue = { checked: target.checked }
    } else if (type === 'file') {
      fieldValue = { files: value } // Store the FileList object
    } else {
      fieldValue = { value: value }
    }

    let updatedElements = [...formElements]
    updatedElements[index] = { ...updatedElements[index], ...fieldValue }
    setFormElements(updatedElements)
  }

  const renderElement = (element, index) => {
    switch (element.key) {
      case 'text':
      case 'textarea':
        return (
          <CTextField
            key={index}
            {...element}
            onChange={(e) =>
              handleChange(index, { type: 'text', value: e.target.value })
            }
          />
        )
      case 'number':
        return (
          <CNumberField
            key={index}
            {...element}
            onChange={(e) =>
              handleChange(index, { type: 'text', value: e.target.value })
            }
          />
        )
      case 'date':
        return (
          <CDatePicker
            key={index}
            {...element}
            onChange={(value) =>
              handleChange(index, { type: 'text', value: formatDate(value) })
            }
          />
        )
      case 'select':
        return (
          <CSelect
            key={index}
            {...element}
            onChange={(e) =>
              handleChange(index, { type: 'text', value: e.target.value })
            }
          />
        )
      // case 'autocomplete':
      //   return <CAutocomplete key={index} {...element} />
      case 'button':
        return (
          <Button key={index} {...element}>
            {element.label}
          </Button>
        )
      case 'checkbox':
        return (
          <CCheckbox
            key={index}
            label={element.label}
            checked={element?.checked}
            onChange={(e) =>
              handleChange(index, { type: 'checkbox', value: e.target.checked })
            }
          />
        )
      case 'radio':
        return (
          <CRadioGroup
            key={index}
            {...element}
            onChange={(e) =>
              handleChange(index, { type: 'text', value: e.target.value })
            }
          />
        )
      case 'file':
        return (
          <CFileField
            key={index}
            onChange={(e) =>
              handleChange(index, {
                type: 'file',
                value: Array.from(e.target.files),
              })
            }
            {...element}
          />
        )
      case 'header':
        return (
          <Typography key={index} {...element}>
            {element.value}
          </Typography>
        )
      case 'paragraph':
        return (
          <Typography key={index} variant="body1" component="p">
            {element.value}
          </Typography>
        )
      case 'divider':
        return <Divider key={index} sx={{ margin: '20px 0' }} />
      default:
        return null
    }
  }

  return (
    <Card sx={{ minWidth: '50rem', p: 5 }} justifyContent="center">
      {formElements.map((element, index) => (
        <Box sx={{ mb: 2 }} key={index}>
          {renderElement(element, index)}
        </Box>
      ))}
    </Card>
  )
}

export default FormPreview
