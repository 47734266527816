// // UserList.jsx
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Icon
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import AddIcon from '@mui/icons-material/Add'
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
// Components
import AdminLayout from '@/layouts/AdminLayout'

// Slice
import { setBreadcrumb } from '@/redux/Slice/breadcrumbSlice'
import { Link, useNavigate } from 'react-router-dom'
import CButton from '@/components/CButton'
import CDatatable from '@/components/CDatatable'
import { changeStatus, deleteRecord } from '@/redux/Slice/commonSlice'
import { showError, showSuccess } from '@/redux/Slice/alertSlice'
import CTextField from '@/components/CTextField'
import SaveIcon from '@mui/icons-material/Save'
import { Helmet } from 'react-helmet-async'
import { list } from '../acknowledgeSlice'
import AcknowledgeCreate from './AcknowledgeCreate'

const AcknowledgeList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { acknowledges, errors, isLoading } = useSelector(
    (state) => state.acknowledge
  )

  const handleQuickFilterValue = (filterModel) => {
    const quickFilterValue = filterModel.quickFilterValues.join(' ')
    console.log(quickFilterValue)
  }

    // Handle form submission
    const handleStatusChange = async (categoryId, status, model, column) => {
      try {
        const res = await dispatch(
          changeStatus({
            id: categoryId,
            status: status,
            model: model,
            column: column,
          })
        )
  
        if (res.payload && !res.payload.error) {
          dispatch(
            showSuccess({
              success: true,
              message: res.payload.message,
            })
          )
          dispatch(list())
        } else {
          dispatch(
            showError({
              error: true,
              message: res.payload.message,
            })
          )
        }
      } catch (error) {
        console.error('Error while creating File:', error)
        dispatch(
          showError({
            error: true,
            message: 'An error occurred during File creation.',
          })
        )
      }
    }

  const handleDelete = async (categoryId, model) => {
    try {
      const res = await dispatch(
        deleteRecord({
          id: categoryId,
          model: model,
        })
      )

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        dispatch(list())
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Error while creating File:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred during File creation.',
        })
      )
    }
  }

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        {
          path: 'inventory/acknowledge/list',
          label: 'Acknowledge',
          isLast: false,
        },
        { path: '', label: 'List', isLast: true },
      ])
    )
  }, [dispatch])

  const columns = [
    { field: 'note', headerName: 'Note', flex: 1 },
    { field: 'expiry_date', headerName: 'Expiry Date', flex: 1 },
    { field: 'full_name', headerName: 'Patient', flex: 1 },
    { field: 'by', headerName: 'By', flex: 1 },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => (
        <>
          <CButton
            component={Link}
            //   to={`/leads/view-log/${params.row.id}`}
            label={params.row.status == 1 ? 'On' : 'Off'}
            sx={{
              background: params.row.status == 1 ? '#0a8154' : '#cf2323',
            }}
            onClick={() =>
              handleStatusChange(
                params.row.id,
                params.row.status == 0 ? 1 : 0,
                'Acknowledge',
                'status'
              )
            }
          />
        </>
      ),
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 0.7,
      renderCell: (params) => (
        <ButtonGroup variant="contained" aria-label="Basic button group">
          <CButton
            component={Link}
            to={`/inventory/acknowledge/update/${params.row.id}`}
            size="small"
            label="Edit"
          />
          <CButton
            label="Delete"
            onClick={() => handleDelete(params.row.id, 'Acknowledge')}
            sx={{ background: '#eb3333', color: 'white' }}
          />
        </ButtonGroup>
      ),
    },
  ]

  useEffect(() => {
    dispatch(list())
  }, [])

  return (
    <>
      <Helmet>
        <title>Bioaro | Acknowledge - List</title>
      </Helmet>

      <Card sx={{ p: 2 }}>
        <Stack direction="row" justifyContent="flex-end" mb={1}>
          <CButton
            label="Create"
            icon={<AddIcon />}
            component={Link}
            to={`/inventory/acknowledge/create`}
          />
        </Stack>
        <CDatatable
          rows={acknowledges}
          columns={columns}
          isLoading={isLoading}
          handleQuickFilterValue={handleQuickFilterValue}
        />
      </Card>
    </>
  )
}

export default AcknowledgeList
