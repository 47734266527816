// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../../services/axiosConfig'

// Fetch intake type list
export const list = createAsyncThunk('support/list', async () => {
  const response = await axiosInstance.get(`support/list`)
  return response.data
})
//intak type create
export const create = createAsyncThunk('support/create', async (data) => {
  const response = await axiosInstance.post(`support/create`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data
})

//intak type create
export const update = createAsyncThunk('support/update', async (data) => {
  const response = await axiosInstance.post(`support/update`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data
})

//intak type create
export const updateInfo = createAsyncThunk(
  'support/info',
  async (supportId) => {
    const response = await axiosInstance.get(`support/info/${supportId}`)
    return response.data
  }
)

const supportSlice = createSlice({
  name: 'support',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    support: null,
    supports: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      // support list List
      .addCase(list.pending, (state) => {
        state.isLoading = true
      })
      .addCase(list.fulfilled, (state, { payload } = action) => {
        state.supports = payload.supports
        state.isLoading = false
      })
      .addCase(list.rejected, (state, { payload }) => {
        state.isLoading = false
      })

      // support list List
      .addCase(updateInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateInfo.fulfilled, (state, { payload } = action) => {
        state.support = payload.support
        state.isLoading = false
      })
      .addCase(updateInfo.rejected, (state, { payload }) => {
        state.isLoading = false
      })

      // support create
      .addCase(create.pending, (state) => {
        state.isLoading = true
      })
      .addCase(create.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(create.rejected, (state, { payload }) => {
        state.isLoading = false
      })

      // support update
      .addCase(update.pending, (state) => {
        state.isLoading = true
      })
      .addCase(update.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(update.rejected, (state, { payload }) => {
        state.isLoading = false
      })
  },
})

export default supportSlice.reducer
