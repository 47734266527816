import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { setBreadcrumb } from '@/redux/Slice/breadcrumbSlice'
import CAccordion from '@/components/CAccordion'
import { Box, Card, CardHeader, Grid, Stack, Typography } from '@mui/material'
import {
  fetchFollowUp,
  followUpList,
} from '../../Patient/FollowUp/followUpSlice'
import { FollowUpUpdate } from '../../Patient/FollowUp/Partials/FollowUpUpdate'
import CSelect from '@/components/CSelect'
import CDataTable from '@/components/CDatatable'
import CModal from '@/components/CModal'
import { renderCell } from '@/utils/ctableTool'
import { changeStatus } from '@/redux/Slice/commonSlice'
import { showError, showSuccess } from '@/redux/Slice/alertSlice'
import CButton from '@/components/CButton'

export default function PatientFollowUp() {
  const dispatch = useDispatch()

  const { followUps, staff, isLoading } = useSelector((state) => state.followUp)

  const [status, setStatus] = useState(0)
  const [doctor, setDoctor] = useState(null)

  const columns = [
    {
      field: 'patient',
      headerName: 'Patient',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      field: 'assign_date',
      headerName: 'Assign Date',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    { field: 'assign_time', headerName: 'Deadline', minWidth: 150, flex: 1 },

    {
      headerName: 'Action',
      minWidth: 270,
      flex: 1,
      renderCell: ({ row }) =>
        renderCell(
          <Grid container spacing={0.5}>
            <Grid item>
              <CModal
                btnTitle={getStatusLabel(row.status)}
                callbackFunc={handleChangeStatus}
              >
                <CSelect
                  label="Change Status"
                  options={statusOptions}
                  onChange={(e) => handleChangeStatus(e, row.id)}
                />
              </CModal>
            </Grid>
            <Grid item>
              <FollowUpUpdate followUpId={row.id} />
            </Grid>
            {/* <Grid item>
              <TaskUpdate taskId={row.id} />
            </Grid> */}
          </Grid>
        ),
    },
  ]

  useEffect(() => {
    dispatch(followUpList())
  }, [])

  const handleChangeStatus = async (e, patientFollowUpId) => {
    const data = {
      id: patientFollowUpId,
      status: parseInt(e.target.value),
      name: 'FollowUp',
      model: 'FollowUp',
      column: 'status',
    }

    try {
      const res = await dispatch(changeStatus(data))

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        dispatch(followUpList())
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Error while changing status:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred during changing status.',
        })
      )
    }
  }

  return (
    <>
      <Card sx={{ p: 2 }}>
        <Grid container spacing={1} justifyContent="end" mb={1}>
          <Grid item>
            <CSelect
              value={doctor}
              options={staff}
              placeholder="Select Staff"
              onChange={(e) => {
                console.log(e.target)
                setDoctor(e.target.value)
                dispatch(followUpList({ staff: e.target.value }))
              }}
            />
          </Grid>
          <Grid item>
            <CSelect
              value={status}
              options={statusOptions}
              onChange={(e) => {
                console.log(e.target)

                setStatus(e.target.value)
                dispatch(followUpList({ status: e.target.value }))
              }}
            />
          </Grid>
        </Grid>

        <CDataTable
          rows={followUps ?? []}
          columns={columns}
          // isLoading={isLoading}
          getRowHeight="auto"
        />
      </Card>
    </>
  )
}

const statusOptions = [
  {
    label: 'Pending',
    value: 0,
  },
  {
    label: 'Success',
    value: 1,
  },
  {
    label: 'Cancelled',
    value: 2,
  },
]

function getStatusLabel(value) {
  const status = statusOptions.find((option) => option.value === value)
  return status ? status.label : 'Unknown Status'
}
