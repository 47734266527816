import React, { useState } from 'react'
import { Grid, Paper, Button, Stack } from '@mui/material'
import Sidebar from './Partials/Sidebar'
import FormCanvas from './Partials/FormCanvas'
import FormPreview from './Partials/FormPreview'
import { useDispatch, useSelector } from 'react-redux'
import { formBuilderCreate, setElements } from './formBuilderSlice'
import { showError, showSuccess } from '@/redux/Slice/alertSlice'
import { useNavigate } from 'react-router-dom'
import CTextField from '@/components/CTextField'

export default function FormBuilderCreate() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const elements = useSelector((state) => state.formBuilder.elements)
  const errors = useSelector((state) => state.formBuilder.errors)
  const [formName, setFormName] = useState('')
  const [isPreviewMode, setIsPreviewMode] = useState(false)

  const togglePreviewMode = () => {
    setIsPreviewMode((prevMode) => !prevMode)
  }

  // Handle form submission
  const handleSave = async (event) => {
    event.preventDefault()

    try {
      const res = await dispatch(
        formBuilderCreate({
          data: { name: formName, form: elements },
        })
      )

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        dispatch(setElements([]))
        navigate(`/form-builder/list`)
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Error while creating:', error)
      dispatch(
        showError({
          error: true,
          message: 'An Error while creating',
        })
      )
    }
  }

  return (
    <>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <CTextField
            label="Name"
            onChange={(e) => setFormName(e.target.value)}
            required
            error={!(errors && errors.name)}
            helperText={errors && errors.name}
          />
        </Grid>
        <Grid item md={9}>
          {!isPreviewMode && <FormCanvas />}
        </Grid>
        <Grid item md={3}>
          {!isPreviewMode ? <Sidebar /> : null}
        </Grid>
      </Grid>
      <Stack direction="row" justifyContent="end" spacing={1} sx={{ mt: 0.5 }}>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={togglePreviewMode}
        >
          {isPreviewMode ? 'Back to Builder' : 'Preview'}
        </Button>

        {!isPreviewMode && (
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={handleSave}
          >
            Save
          </Button>
        )}
      </Stack>
      {isPreviewMode && (
        <Stack direction="row" justifyContent="center">
          <FormPreview />
        </Stack>
      )}
    </>
  )
}
