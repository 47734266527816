// // UserList.jsx
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { formatDateTime } from '../../../utils/cdayjs'

// Components
import { Helmet } from 'react-helmet-async'
import CDatatable from '../../../components/CDatatable'
import AddIcon from '@mui/icons-material/Add'
// Slice
import { setBreadcrumb } from '../../../redux/Slice/breadcrumbSlice'
import {
  Box,
  Button,
  Card,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from '@mui/material'
import CButton from '../../../components/CButton'
import { useDispatch, useSelector } from 'react-redux'
import CDataTable from '../../../components/CDatatable'
import { Edit } from '@mui/icons-material'
import { list } from './supportSlice'
import dayjs from 'dayjs'
import { renderCell } from '@/utils/ctableTool'

const SupportList = () => {
  const dispatch = useDispatch()
  const { supports, isLoading } = useSelector((state) => state.support)
  console.log(supports)
  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        {
          path: '/support/list',
          label: 'Intake Type',
          isLast: false,
        },
        { path: '', label: 'List', isLast: true },
      ])
    )
  }, [dispatch])

  const [quickFilterValue, setQuickfilterValue] = useState(null)
  // add this when you use server side filter
  const handleQuickFilterValue = (filterModel) => {
    const quickFilterValue = filterModel.quickFilterValues.join(' ')
    dispatch(list(quickFilterValue))
    setQuickfilterValue(quickFilterValue)
  }

  const columns = [
    {
      field: 'subject',
      headerName: 'Subject',
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      field: 'date',
      headerName: 'Date',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => <>{dayjs(params.date).format('DD MMM YYYY')}</>,
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 50,
      flex: 1,
      renderCell: (params) => (
        <>
          {params.row.status == 0
            ? 'Pending'
            : params.row.status == 1
            ? 'Review'
            : params.row.status == 2
            ? 'In Progress'
            : 'Success'}
        </>
      ),
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      minWidth: 200,
      flex: 1,
      renderCell: (params) => (
        <>{dayjs(params.created_at).format('DD MMM YYYY h:mm A')}</>
      ),
    },
    {
      field: 'assign_by',
      headerName: 'Assign By',
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: 'Action',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <>
          <CButton
            label="Update"
            size="small"
            icon={<Edit />}
            component={Link}
            to={`/support/update/${params.row.id}`}
          />
        </>
      ),
    },
  ]

  useEffect(() => {
    dispatch(list())
  }, [])

  return (
    <>
      <Helmet>
        <title>Bioaro | Support - List</title>
      </Helmet>
      <Card sx={{ p: 3 }}>
        <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
          <Typography variant="h6">Support List</Typography>
          <CButton
            label="Create Support"
            icon={<AddIcon />}
            component={Link}
            to={`/support/create`}
          />
        </Stack>
        <CDataTable
          rows={supports}
          columns={columns}
          isLoading={isLoading}
          filterMode="server"
          handleQuickFilterValue={handleQuickFilterValue}
          getRowHeight="auto"
        />
      </Card>
    </>
  )
}

export default SupportList
