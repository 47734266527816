import React, { lazy } from 'react'

const IntakeList = lazy(() => import('./IntakeList'))
const IntakeUpdate = lazy(() => import('./IntakeUpdate'))

const intakeRoutes = [
  { path: 'list', element: <IntakeList /> },
  { path: 'update/:patientId', element: <IntakeUpdate /> },
]

export default intakeRoutes
