// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '@/services/axiosConfig'

// This function is called to set the auth header

// create user
export const create = createAsyncThunk('user/create', async (data) => {
  const response = await axiosInstance.post('user/create', data)
  return response.data
})

// Update user
export const update = createAsyncThunk(
  'user/update',
  async ({ userId, data }) => {
    const response = await axiosInstance.post(`user/update/${userId}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  }
)

// Fetch all user's roles
export const fetchRoles = createAsyncThunk('user/roles', async () => {
  const response = await axiosInstance.get('user/roles')
  return response.data
})

// Fetch user detail by user id
export const fetchUserDetail = createAsyncThunk('user/detail', async (id) => {
  const response = await axiosInstance.get(`user/detail/${id}`)
  return response.data
})

// Fetch role based users
export const fetchRoleBasedUserList = createAsyncThunk(
  'user/role-based-user-list/',
  async (roleId) => {
    const response = await axiosInstance.post(
      `user/role-based-user-list/${roleId}`
    )
    return response.data
  }
)

const userSlice = createSlice({
  name: 'user',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    roles: [],
    roleBasedUsers: [],
    user: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Create user
      .addCase(create.pending, (state) => {
        state.isLoading = true
      })
      .addCase(create.fulfilled, (state, action) => {
        if (action.payload.error) {
          state.message = action.payload.message
          state.errors = action.payload.errors
        } else {
          state.message = action.payload.message
          state.errors = null
        }
        state.isLoading = false
      })
      .addCase(create.rejected, (state) => {
        state.isLoading = false
      })

      // Update user
      .addCase(update.pending, (state) => {
        state.isLoading = true
      })
      .addCase(update.fulfilled, (state, action) => {
        if (action.payload.error) {
          state.message = action.payload.message
          state.errors = action.payload.errors
        } else {
          state.message = action.payload.message
          state.errors = null
        }
        state.isLoading = false
      })
      .addCase(update.rejected, (state) => {
        state.isLoading = false
      })

      // Role List
      .addCase(fetchRoles.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchRoles.fulfilled, (state, { payload } = action) => {
        state.roles = payload.roles
        state.isLoading = false
      })
      .addCase(fetchRoles.rejected, (state) => {
        state.isLoading = false
      })
      // User detail by user id
      .addCase(fetchUserDetail.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchUserDetail.fulfilled, (state, { payload } = action) => {
        state.user = payload.user
        state.isLoading = false
      })
      .addCase(fetchUserDetail.rejected, (state) => {
        state.isLoading = false
      })

      // Role based user list
      .addCase(fetchRoleBasedUserList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        fetchRoleBasedUserList.fulfilled,
        (state, { payload } = action) => {
          state.roleBasedUsers = payload.users
          state.isLoading = false
        }
      )
      .addCase(fetchRoleBasedUserList.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default userSlice.reducer
