import React from 'react'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import { Divider, Grid } from '@mui/material'

export default function CImageList({ attachments }) {
  return (
    <Grid container>
      {attachments.map((item, index) => (
        <Grid item xs={6} key={index}>
          <img src={item} loading="lazy" width={150} />
        </Grid>
      ))}
    </Grid>
  )
}
