// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '@/services/axiosConfig'

setAuthToken() // This function is called to set the auth header

// Fetch category list
export const list = createAsyncThunk('tag/list', async () => {
  const response = await axiosInstance.get('tag/list')
  return response.data
})

// Fetch category info
export const tagInfo = createAsyncThunk('tag/info', async (categoryId) => {
  const response = await axiosInstance.get(`tag/info/${categoryId}`)
  console.log(response.data)
  return response.data
})

// Fetch tag create
export const create = createAsyncThunk('tag/create', async (data) => {
    const response = await axiosInstance.post('tag/create',data)
    return response.data
  })

  
// Fetch tag list
export const update = createAsyncThunk('tag/update', async (data) => {
  const response = await axiosInstance.post('tag/update',data)
  return response.data
})

const tagSlice = createSlice({
  name: 'tag',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    tags: [],
    info: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      //Category List
      .addCase(list.pending, (state) => {
        state.isLoading = true
      })
      .addCase(list.fulfilled, (state, { payload } = action) => {
        state.tags = payload.tags
        state.isLoading = false
      })

      .addCase(list.rejected, (state) => {
        state.isLoading = false
      })

      //Category info
      .addCase(tagInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(tagInfo.fulfilled, (state, { payload } = action) => {
        state.info = payload.info
        state.isLoading = false
      })

      .addCase(tagInfo.rejected, (state) => {
        state.isLoading = false
      })

       //Category create
       .addCase(create.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create.fulfilled, (state, { payload } = action) => {
        state.message = payload.message;
        state.errors = payload.errors;
        state.isLoading = false;
      })
      .addCase(create.rejected, (state) => {
        state.isLoading = false;
      })


       //Category update
       .addCase(update.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update.fulfilled, (state, { payload } = action) => {
        state.message = payload.message;
        state.errors = payload.errors;
        state.isLoading = false;
      })
      .addCase(update.rejected, (state) => {
        state.isLoading = false;
      })
  },
})

export default tagSlice.reducer
