import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../../services/axiosConfig'

// Fetch Gut Communication Schedule List
export const fetchGutComScheduleList = createAsyncThunk('gutComSchedule/list', async () => {
  const response = await axiosInstance.get('gut-com-schedule/list')
  return response.data
})

// Fetch Gut Communication Schedule detail
export const fetchGutComScheduleDetail = createAsyncThunk('gutComSchedule/detail/', async (gutComScheduleId) => {
  const response = await axiosInstance.get(`gut-com-schedule/detail/${gutComScheduleId}`)
  return response.data
})

// Gut Communication Schedule Create
export const create = createAsyncThunk('gutComSchedule/create', async ({ data }) => {
  console.log(data)
  const response = await axiosInstance.post(`gut-com-schedule/create`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data
})

// Gut Communication Schedule Update
export const update = createAsyncThunk(
  'gutComSchedule/update',
  async ({ gutComScheduleId, data }) => {
    const response = await axiosInstance.post(
      `gut-com-schedule/update/${gutComScheduleId}`,
      data
    )
    return response.data
  }
)


// Fetch Gut Communication Schedule info
export const fetchGutComScheduleInfo = createAsyncThunk('gutComSchedule/info', async () => {
  const response = await axiosInstance.get('gut-com-schedule/info')
  return response.data
})

const gutComScheduleSlice = createSlice({
  name: 'gutComSchedule',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    gutComSchedules: [],
    gutComScheduleInfo: null,
    gutComSchedule: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Gut Communication Schedule create
      .addCase(create.pending, (state) => {
        state.isLoading = true
      })
      .addCase(create.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(create.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch Gut Communication Schedule list
      .addCase(fetchGutComScheduleList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchGutComScheduleList.fulfilled, (state, { payload }) => {
        state.gutComSchedules = payload.gutComSchedules
        state.isLoading = false
      })
      .addCase(fetchGutComScheduleList.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch Gut Communication Schedule detail
      .addCase(fetchGutComScheduleDetail.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchGutComScheduleDetail.fulfilled, (state, { payload }) => {
        state.gutComSchedule = payload.gutComSchedule
        state.isLoading = false
      })
      .addCase(fetchGutComScheduleDetail.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch Gut Communication Schedule info
      .addCase(fetchGutComScheduleInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchGutComScheduleInfo.fulfilled, (state, { payload }) => {
        state.gutComScheduleInfo = payload
        state.isLoading = false
      })
      .addCase(fetchGutComScheduleInfo.rejected, (state) => {
        state.isLoading = false
      })

      // Gut Communication Schedule update
      .addCase(update.pending, (state) => {
        state.isLoading = true
      })
      .addCase(update.fulfilled, (state, { payload }) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(update.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default gutComScheduleSlice.reducer
