import React, { useState, useEffect, useRef } from 'react'
import PopupDialog from './PopupDialog'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { showSuccess } from '@/redux/Slice/alertSlice'
import { logout, refreshToken } from '@/pages/Auth/authSlice'
import { removeData } from '@/services/localStorage'

const MainComponent = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [remainingTime, setRemainingTime] = useState(60)
  const countdownRef = useRef(null)
  const intervalRef = useRef(null)
  const activityTimeoutRef = useRef(null)

  // Read interval and duration from environment variables
  const popupInterval = parseInt(import.meta.env.VITE_POPUP_INTERVAL, 10)
  const popupDuration = parseInt(import.meta.env.VITE_POPUP_DURATION, 10)

  const handleClose = (response) => {
    setOpen(false)
    clearTimeout(countdownRef.current)
    clearTimeout(activityTimeoutRef.current)

    if (response === 'yes') {
      dispatch(refreshToken()).then((res) => {})
      resetActivityTimeout()
    } else {
      handleLogout()
    }
  }

  const handleLogout = () => {
    dispatch(logout())
    removeData('accessToken')
    dispatch(
      showSuccess({
        success: true,
        message: 'Your session has been expired!',
      })
    )
    navigate('/')
  }

  const resetActivityTimeout = () => {
    clearTimeout(activityTimeoutRef.current)
    activityTimeoutRef.current = setTimeout(() => {
      setOpen(true)
      setRemainingTime(popupDuration / 1000)

      countdownRef.current = setTimeout(handleLogout, popupDuration)

      const countdownInterval = setInterval(() => {
        setRemainingTime((prev) => {
          if (prev <= 1) {
            clearInterval(countdownInterval)
            return 0
          }
          return prev - 1
        })
      }, 1000)
    }, popupInterval)
  }

  useEffect(() => {
    resetActivityTimeout()

    const handleActivity = () => {
      resetActivityTimeout()
    }

    // Add event listeners for user activity
    window.addEventListener('mousemove', handleActivity)
    window.addEventListener('keydown', handleActivity)
    window.addEventListener('click', handleActivity)

    return () => {
      clearTimeout(intervalRef.current)
      clearTimeout(countdownRef.current)
      clearTimeout(activityTimeoutRef.current)
      window.removeEventListener('mousemove', handleActivity)
      window.removeEventListener('keydown', handleActivity)
      window.removeEventListener('click', handleActivity)
    }
  }, [])

  return (
    <div>
      <PopupDialog
        open={open}
        onClose={handleClose}
        remainingTime={remainingTime}
      />
    </div>
  )
}

export default MainComponent
