// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../../../services/axiosConfig'

// Fetch Patient Based File List
export const fetchTalkToMeList = createAsyncThunk(
  'patient/talk-to-me/list',
  async (patientId) => {
    const response = await axiosInstance.get(
      `patient/talk-to-me/list/${patientId}`
    )
    return response.data
  }
)

// Patient Based talkToMeQuery
export const talkToMeQuery = createAsyncThunk(
  'patient/talk-to-me/talk2me-query',
  async ({ patientId, data }) => {
    const response = await axiosInstance.post(
      `patient/talk-to-me/talk2me-query/${patientId}`,
      data
    )
    return response.data
  }
)

const talkToMeSlice = createSlice({
  name: 'talkToMe',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    talks: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch  patient based dictation list
      .addCase(fetchTalkToMeList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchTalkToMeList.fulfilled, (state, { payload }) => {
        state.prescriptions = payload.talkToMe
        state.talks = payload.talks
        state.isLoading = false
      })
      .addCase(fetchTalkToMeList.rejected, (state) => {
        state.isLoading = false
      })

      // Patient based talkToMeQuery
      .addCase(talkToMeQuery.pending, (state) => {
        state.isLoading = true
      })
      .addCase(talkToMeQuery.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(talkToMeQuery.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default talkToMeSlice.reducer
