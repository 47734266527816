import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'

// Components
import { Helmet } from 'react-helmet-async'
import CButton from '@/components/CButton'
import CDataTable from '@/components/CDatatable'
import FinancialButton from './FinancialButton'

// Icons

// MUI Components
import {
  Box,
  Button,
  Card,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from '@mui/material'

// Slice
import { setBreadcrumb } from '@/redux/Slice/breadcrumbSlice'
import { fetchSubscriptionList } from '../financialSlice'

// Utility
import { renderCell } from '@/utils/ctableTool'

const SubscriptionList = () => {
  const dispatch = useDispatch()
  const { subscriptions, isLoading } = useSelector((state) => state.financial)

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          path: '/financial/invoice-list',
          label: 'Financial',
          isLast: false,
        },
        { path: '', label: 'Subscription List', isLast: true },
      ])
    )
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchSubscriptionList())
  }, [dispatch])

  const columns = [
    {
      field: 'biller_name',
      headerName: 'Biller Name',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      field: 'service_name',
      headerName: 'Service Name',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      field: 'panel_name',
      headerName: 'Panel Name',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      field: 'purchase_date',
      headerName: 'Purchase Date',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) =>
        renderCell(dayjs(value).format('DD MMM YYYY h:mm A')),
    },
    {
      field: 'amount',
      headerName: 'Amount',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      headerName: 'With Order',
      minWidth: 300,
      flex: 1,
      renderCell: (params) => renderCell(params.value),
    },
  ]

  return (
    <>
      <Helmet>
        <title>Bioaro | Financial - Subscription List</title>
      </Helmet>
      <Card sx={{ p: 3 }}>
        <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
          <Typography variant="h6">Subscription List</Typography>
        </Stack>
        <CDataTable
          rows={subscriptions || []}
          columns={columns}
          isLoading={isLoading}
          filterMode="server"
        />
      </Card>
    </>
  )
}

export default SubscriptionList
