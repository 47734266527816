import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Card, Grid, Stack } from '@mui/material'
import CTextField from '../../../components/CTextField'
import CAutocomplete from '../../../components/CAutocomplete'
import CButton from '../../../components/CButton'
import SaveIcon from '@mui/icons-material/Save'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { setBreadcrumb } from '../../../redux/Slice/breadcrumbSlice'
import {
  update,
  fetchSellerSiteInfo,
  fetchSellerSiteList,
  fetchSellerSiteDetail,
} from './sellerSiteSlice'
import { showError, showSuccess } from '@/redux/Slice/alertSlice'

const SellerSiteUpdate = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { sellerSiteId } = useParams()
  const { sellerSiteInfo, errors } = useSelector((state) => state.sellerSite)
  const sellerSiteFromRedux = useSelector(
    (state) => state.sellerSite.sellerSite
  )

  const [sellerSite, setSellerSite] = useState(sellerSiteFromRedux)

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        { path: '/seller-site/list', label: 'Seller Site', isLast: false },
        { path: '', label: 'Update', isLast: true },
      ])
    )

    dispatch(fetchSellerSiteInfo())
    dispatch(fetchSellerSiteDetail(sellerSiteId))
  }, [])

  useEffect(() => {
    setSellerSite(sellerSiteFromRedux)
  }, [sellerSiteFromRedux, sellerSiteInfo])

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      const res = await dispatch(
        update({ sellerSiteId: sellerSiteId, data: sellerSite })
      )

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        dispatch(fetchSellerSiteList())
        navigate('/seller-site/list')
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Server error:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred during updating user.',
        })
      )
    }
  }

  return (
    <>
      <Helmet>
        <title>Bioaro | Seller Site - Update</title>
      </Helmet>
      {sellerSite ? (
        <Stack direction="row" justifyContent="center">
          <Card sx={{ p: 3, maxWidth: '50rem' }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                  <CTextField
                    label="Title"
                    required
                    autoFocus
                    error={!!(errors && errors.title)}
                    helperText={errors && errors.title}
                    value={sellerSite.title}
                    onChange={(e) =>
                      setSellerSite({
                        ...sellerSite,
                        title: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <CAutocomplete
                    label="Select Service"
                    options={sellerSiteInfo?.services ?? []}
                    value={sellerSite.services}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.id == value.id
                    }
                    multiple={true}
                    required
                    error={!!(errors && errors.service_id)}
                    helperText={errors && errors.service_id}
                    onChange={(_, value) =>
                      setSellerSite({
                        ...sellerSite,
                        service_id: value.map((item) => item.id),
                        services: value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <CTextField
                    label="Link"
                    value={sellerSite.link}
                    error={!!(errors && errors.link)}
                    helperText={errors && errors.link}
                    onChange={(e) =>
                      setSellerSite({
                        ...sellerSite,
                        link: e.target.value,
                      })
                    }
                  />
                </Grid>
              </Grid>
              <Stack direction="row" justifyContent="flex-end" mb={2}>
                <CButton label="Save" icon={<SaveIcon />} type="submit" />
              </Stack>
            </form>
          </Card>
        </Stack>
      ) : null}
    </>
  )
}

export default SellerSiteUpdate
