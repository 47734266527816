// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '@/services/axiosConfig'

// Fetch province list
export const list = createAsyncThunk('website/province/list', async () => {
  const response = await axiosInstance.get('website/province/list')
  return response.data
})

// Fetch province info
export const provinceInfo = createAsyncThunk(
  'website/province/info',
  async (provinceId) => {
    const response = await axiosInstance.get(`website/province/info/${provinceId}`)
    console.log(response.data)
    return response.data
  }
)

// Fetch province list
export const create = createAsyncThunk('website/province/create', async (data) => {
  const response = await axiosInstance.post('website/province/create', data)
  return response.data
})

// Fetch province list
export const update = createAsyncThunk('website/province/update', async (data) => {
  const response = await axiosInstance.post('website/province/update', data)
  return response.data
})

const provinceSlice = createSlice({
  name: 'province',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    provinces: [],
    info: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      //Category List
      .addCase(list.pending, (state) => {
        state.isLoading = true
      })
      .addCase(list.fulfilled, (state, { payload } = action) => {
        state.provinces = payload.provinces
        state.isLoading = false
      })

      .addCase(list.rejected, (state) => {
        state.isLoading = false
      })

      //Category info
      .addCase(provinceInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(provinceInfo.fulfilled, (state, { payload } = action) => {
        state.info = payload.info
        state.isLoading = false
      })

      .addCase(provinceInfo.rejected, (state) => {
        state.isLoading = false
      })

      //Category create
      .addCase(create.pending, (state) => {
        state.isLoading = true
      })
      .addCase(create.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(create.rejected, (state) => {
        state.isLoading = false
      })

      //Category update
      .addCase(update.pending, (state) => {
        state.isLoading = true
      })
      .addCase(update.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(update.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default provinceSlice.reducer
