import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../../../services/axiosConfig'

// Create
export const dentalChartCreate = createAsyncThunk(
  'patient/dental-chart/create',
  async (data) => {
    const response = await axiosInstance.post(
      'patient/dental-chart/create',
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  }
)

// Fetch by patient id
export const dentalChartDetail = createAsyncThunk(
  'patient/dental-chat/detail',
  async (patientId) => {
    const response = await axiosInstance.get(
      `patient/dental-chart/detail/${patientId}`
    )
    return response.data
  }
)

const dentalChartSlice = createSlice({
  name: 'dentalChart',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    dentalChart: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Create Note Template
      .addCase(dentalChartCreate.pending, (state) => {
        state.isLoading = true
      })
      .addCase(dentalChartCreate.fulfilled, (state, action) => {
        if (action.payload.error) {
          state.message = action.payload.message
          state.errors = action.payload.errors
        } else {
          state.message = action.payload.message
          state.errors = null
        }
        state.isLoading = false
      })
      .addCase(dentalChartCreate.rejected, (state) => {
        state.isLoading = false
      })

      // Chart list
      .addCase(dentalChartDetail.pending, (state) => {
        state.isLoading = true
      })
      .addCase(dentalChartDetail.fulfilled, (state, { payload } = action) => {
        state.dentalChart = payload.dentalChart
        state.isLoading = false
      })
      .addCase(dentalChartDetail.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default dentalChartSlice.reducer
