import React from 'react'
import DictationTemplateList from './partial/DictationTemplateList';
import DictationTemplateCreate from './partial/DictationTemplateCreate';
import DictationTemplateUpdate from './partial/DictationTemplateUpdate';
const DictationTemplateRoutes = [
  { path: "list/", element: <DictationTemplateList /> },
  { path: "create/", element: <DictationTemplateCreate /> },
  { path: "update/:dictationTemplateId", element: <DictationTemplateUpdate /> },
];

export default DictationTemplateRoutes;

