import React from 'react'

const PhysicianList = React.lazy(() => import('./PhysicianList'))
const PhysicianUpdate = React.lazy(() => import('./PhysicianUpdate'))
const PhysicianCreate = React.lazy(() => import('./PhysicianCreate'))
const PhysicianSchedule = React.lazy(() => import('./PhysicianSchedule'))
const PhysicianLeave = React.lazy(() => import('./PhysicianLeave'))
const ScheduleList = React.lazy(() => import('./ScheduleList'))
const ScheduleUpdate = React.lazy(() => import('./ScheduleUpdate'))

const physicianRoutes = [
  { path: 'list', element: <PhysicianList /> },
  { path: 'create', element: <PhysicianCreate /> },
  { path: 'schedule/:physicianId', element: <PhysicianSchedule /> },
  { path: 'leave/:physicianId', element: <PhysicianLeave /> },
  { path: 'update/:userId', element: <PhysicianUpdate /> },
  { path: 'schedule-list', element: <ScheduleList /> },
  { path: 'schedule-update/:scheduleId', element: <ScheduleUpdate /> },
]

export default physicianRoutes
