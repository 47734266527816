import React, { useCallback, useState } from 'react'
import { useDrop } from 'react-dnd'
import FormElement from './FormElement'
import UpdateFieldAttrs from './UpdateFieldAttrs'
import { Grid, Paper } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
  addElement,
  removeElement,
  updateElement,
  moveElement,
  setElements,
  removeEmptyObject,
} from '../formBuilderSlice'

const FormCanvas = () => {
  const dispatch = useDispatch()
  const elements = useSelector((state) => state.formBuilder.elements)
  const formElements = elements.filter((item) => Object.keys(item).length !== 0)

  const [editingElement, setEditingElement] = useState(null)
  const [editValues, setEditValues] = useState(null)

  const [{ isOver }, drop] = useDrop({
    accept: 'FORM_ELEMENT',
    drop: (item) => addElementToForm({ ...item.element }),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  })

  const moveElementCallback = useCallback(
    (dragIndex, hoverIndex) => {
      dispatch(moveElement({ dragIndex, hoverIndex }))
    },
    [dispatch]
  )

  const addElementToForm = (element) => {
    dispatch(addElement(element))
  }

  const removeElementFromForm = (indexToRemove) => {
    dispatch(removeElement(indexToRemove))
  }

  const handleEdit = (index) => {
    setEditingElement(index)
    const element = formElements[index]
    setEditValues(element)
  }

  const saveEdit = () => {
    dispatch(updateElement({ index: editingElement, element: editValues }))
    setEditingElement(null)
    setEditValues(null)
  }

  const addOption = () => {
    setEditValues((prevValues) => ({
      ...prevValues,
      options: [...prevValues.options, { label: '', value: '' }],
    }))
  }

  const removeOption = (indexToRemove) => {
    setEditValues((prevValues) => ({
      ...prevValues,
      options: prevValues.options.filter((_, index) => index !== indexToRemove),
    }))
  }

  const handleOptionChange = (index, field, value) => {
    setEditValues((prevValues) => {
      const updatedOptions = [...prevValues.options]
      updatedOptions[index][field] = value
      return { ...prevValues, options: updatedOptions }
    })
  }

  const handleDefaultOptionChange = (value) => {
    setEditValues((prevValues) => ({ ...prevValues, value }))
  }

  return (
    <Paper ref={drop} sx={{ p: 2, height: '65vh', overflow: 'auto' }}>
      {formElements.map((element, index) => (
        <FormElement
          key={index}
          element={element}
          index={index}
          onDelete={removeElementFromForm}
          onEdit={handleEdit}
          moveElement={moveElementCallback}
        />
      ))}
      <UpdateFieldAttrs
        open={editingElement !== null}
        onClose={() => setEditingElement(null)}
        editValues={editValues}
        setEditValues={setEditValues}
        saveEdit={saveEdit}
        formElements={formElements}
        editingElement={editingElement}
        addOption={addOption}
        removeOption={removeOption}
        handleOptionChange={handleOptionChange}
        handleDefaultOptionChange={handleDefaultOptionChange}
      />
    </Paper>
  )
}

export default FormCanvas
