import React from 'react'
import ConsentTemplateList from './partial/ConsentTemplateList'
import ConsentTemplateCreate from './partial/ConsentTemplateCreate'
import ConsentTemplateUpdate from './partial/ConsentTemplateUpdate'
const ConsentTemplateRoutes = [
  { path: 'list/', element: <ConsentTemplateList /> },
  { path: 'create/', element: <ConsentTemplateCreate /> },
  { path: 'update/:consentTemplateId', element: <ConsentTemplateUpdate /> },
]

export default ConsentTemplateRoutes
