// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '@/services/axiosConfig'

// Fetch category list
export const list = createAsyncThunk('inventory/order-invoice/list', async () => {
  const response = await axiosInstance.get('inventory/order-invoice/list')
  return response.data
})

const orderInvoiceSlice = createSlice({
  name: 'orderInvoice',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    orderInvoices: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      //Category List
      .addCase(list.pending, (state) => {
        state.isLoading = true
      })
      .addCase(list.fulfilled, (state, { payload } = action) => {
        state.orderInvoices = payload.orderInvoices
        state.isLoading = false
      })

      .addCase(list.rejected, (state) => {
        state.isLoading = false
      })

  },
})

export default orderInvoiceSlice.reducer
