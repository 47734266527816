import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, Chip, Grid, Stack, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { Helmet } from 'react-helmet-async'
import CDataTable from '../../../components/CDatatable'
import { setBreadcrumb } from '../../../redux/Slice/breadcrumbSlice'
import {
  fetchSellerSiteList,
  fetchSellerSiteOrderList,
} from './sellerSiteSlice'
import CButton from '@/components/CButton'

const SellerSiteOrderList = () => {
  const dispatch = useDispatch()
  const { sellerSiteId } = useParams()
  const { orders, isLoading } = useSelector((state) => state.sellerSite)

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          path: '/seller-site/list',
          label: 'Seller site',
          isLast: false,
        },
        { path: '', label: 'Order List', isLast: true },
      ])
    )
  }, [])

  const columns = [
    {
      field: 'patient',
      headerName: 'Patient',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'service',
      headerName: 'Service',
      minWidth: 250,
      flex: 1,
      renderCell: (params) => (
        <Grid container spacing={0.5} m={0.5}>
          {params.row.service == null
            ? ''
            : params.row.service.map((item, index) => (
                <Grid item key={index} mr={0.5}>
                  <Chip color="success" size="small" label={item.name} />
                </Grid>
              ))}
        </Grid>
      ),
    },
    {
      field: 'payment_status',
      headerName: 'Payment Status',
      minWidth: 150,
      flex: 1,
    },
  ]

  useEffect(() => {
    dispatch(fetchSellerSiteOrderList(sellerSiteId))
  }, [])

  return (
    <>
      <Helmet>
        <title>Bioaro | Seller Site - Order List</title>
      </Helmet>
      <Card sx={{ p: 3 }}>
        <CDataTable
          rows={orders}
          columns={columns}
          isLoading={isLoading}
          filterMode="server"
        />
      </Card>
    </>
  )
}

export default SellerSiteOrderList
