// // UserList.jsx
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Icon
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import AddIcon from '@mui/icons-material/Add'
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Stack,
  Typography,
} from '@mui/material'
// Components
import AdminLayout from '@/layouts/AdminLayout'

// Slice
import { setBreadcrumb } from '@/redux/Slice/breadcrumbSlice'
import { Link } from 'react-router-dom'
import CButton from '@/components/CButton'
import CDatatable from '@/components/CDatatable'
import { changeStatus, deleteRecord } from '@/redux/Slice/commonSlice'
import { showError, showSuccess } from '@/redux/Slice/alertSlice'
import { list } from '../longevityCategorySlice'
import LongevityCategoryCreate from './LongevityCategoryCreate'
import LongevityCategoryUpdate from './LongevityCategoryUpdate'
import DeleteRow from '../../Common/DeleteRow'
import { Helmet } from 'react-helmet-async'
import { renderCell } from '@/utils/ctableTool'

const LongevityCategoryList = () => {
  const dispatch = useDispatch()
  const { categories, isLoading } = useSelector(
    (state) => state.longevityCategory
  )
  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        {
          path: '/longevity-category/',
          label: 'Longevity Category',
          isLast: false,
        },
        { path: '', label: 'List', isLast: true },
      ])
    )
  }, [dispatch])

  const handleQuickFilterValue = (filterModel) => {
    const quickFilterValue = filterModel.quickFilterValues.join(' ')
    console.log(quickFilterValue)
  }

  const columns = [
    {
      field: 'name',
      headerName: 'name',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      field: 'input_files',
      headerName: 'Input',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      field: 'position',
      headerName: 'Position',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      field: 'description',
      headerName: 'Description',
      minWidth: 200,
      flex: 1,
      renderCell: (params) =>
        renderCell(
          <Box
            component="div"
            dangerouslySetInnerHTML={{
              __html: params.row.description,
            }}
          />
        ),
    },
    {
      field: 'apt_type',
      headerName: 'Sub Category',
      minWidth: 200,
      flex: 1,
      renderCell: (params) =>
        renderCell(
          <>
            <Typography>In Complete</Typography>
          </>
        ),
    },
    {
      field: 'action',
      headerName: 'Action',
      minWidth: 300,
      flex: 1,
      renderCell: (params) =>
        renderCell(
          <ButtonGroup variant="contained" aria-label="Basic button group">
            <LongevityCategoryUpdate
              id={params.row.id}
              name={params.row.name}
              input_files={params.row.input_files}
              position={params.row.position}
              description={params.row.description}
            />
            <CButton
              label="Delete"
              color="error"
              yesNo
              onClick={() => handleDelete(params.row.id, 'longevityCategory')}
            />
          </ButtonGroup>
        ),
    },
  ]

  useEffect(() => {
    dispatch(list())
  }, [])

  // Handle form submission
  const handleDelete = async (categoryId, model) => {
    try {
      const res = await dispatch(
        deleteRecord({
          id: categoryId,
          model: model,
        })
      )

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        dispatch(list())
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Error while creating File:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred during File creation.',
        })
      )
    }
  }

  // Handle form submission
  const handleStatusChange = async (categoryId, status, model, column) => {
    try {
      const res = await dispatch(
        changeStatus({
          id: categoryId,
          status: status,
          model: model,
          column: column,
        })
      )

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        dispatch(list())
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Error while creating File:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred during File creation.',
        })
      )
    }
  }

  return (
    <>
      <Helmet>
        <title>Bioaro | Longevity Category - list</title>
      </Helmet>
      <Card sx={{ p: 2 }}>
        <Stack direction="row" justifyContent="flex-end" mb={1}>
          <LongevityCategoryCreate />
        </Stack>

        <CDatatable
          rows={categories}
          columns={columns}
          isLoading={isLoading}
          getRowHeight="auto"
        />
      </Card>
    </>
  )
}

export default LongevityCategoryList
