import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'

import SendOutlinedIcon from '@mui/icons-material/SendOutlined'
import { Box, Grid, InputLabel, Stack, Typography } from '@mui/material'
import CDialog from '../../../../components/CDialog'
import CButton from '../../../../components/CButton'
import CTextField from '../../../../components/CTextField'
import CEditor from '../../../../components/CEditor'
import { noteCreate } from '../../../../redux/Slice/commonSlice'
import { showError, showSuccess } from '../../../../redux/Slice/alertSlice'
import { list, update } from '../clamServiceSlice'
import SaveIcon from '@mui/icons-material/Save'

const ClamServiceUpdate = ({ id, cpt_code, short_description }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const handleCDialogOpen = () => {
    setOpen(true)
  }
  const handleCDialogClose = () => {
    setOpen(false)
  }
  const { errors, message, payload } = useSelector((state) => state.clamService)

  //   initial create value
  const [clam, setClam] = useState({
    id: id,
    cpt_code: cpt_code,
    short_description: short_description,
  })
  const handleInputChange = (event) => {
    const { name, value } = event.target
    // Update the local state with the changed value
    setClam((clam) => ({
      ...clam,
      [name]: value,
    }))
  }

  // submit form
  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const res = await dispatch(update(clam))

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        dispatch(list())
        setOpen(false)
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Server error:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred while updating the Category.',
        })
      )
    }
  }
  return (
    <CDialog
      btnTitle="Edit"
      headerTitle="Clam Service Create"
      handleCDialogOpen={handleCDialogOpen}
      handleCDialogClose={handleCDialogClose}
      open={open}
    >
      <Box maxWidth="50rem">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="center">
            <Grid xs={12} m={1}>
              <CTextField
                label="ICD 10"
                name="cpt_code"
                value={clam?.cpt_code}
                error={errors && errors.cpt_code}
                helperText={errors && errors.cpt_code}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid xs={12} m={1}>
              <CTextField
                label="Short description"
                name="short_description"
                value={clam?.short_description}
                error={errors && errors.short_description}
                helperText={errors && errors.short_description}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Box>
            {' '}
            <Stack sx={{ float: 'left' }}>
              {' '}
              <Typography variant="h5"></Typography>{' '}
            </Stack>
            <Stack
              direction="row"
              justifyContent="flex-end"
              mb={2}
              mt={2}
              mr={1.3}
            >
              <CButton label="Update" icon={<SaveIcon />} type="submit" />
            </Stack>
          </Box>
        </form>
      </Box>
    </CDialog>
  )
}

export default ClamServiceUpdate
