import PendingPaymentList from './Partials/PendingPaymentList'
import ReportList from './Partials/ReportList'

const PatientDashboard = () => {
  return (
    <>
      <ReportList />
      <PendingPaymentList />
    </>
  )
}

export default PatientDashboard
