import React, { useEffect } from 'react'

import Weight from '@/assets/images/icons/weight.png'
import CHorizontalWidget from '@/components/CHorizontalWidget'
import { IconChartPie3, IconPackage } from '@tabler/icons-react'
import { Card, Grid } from '@mui/material'
import { IconMoodSmile } from '@tabler/icons-react'
import { IconShoppingBag } from '@tabler/icons-react'
import CBarChart from '@/components/CBarChart'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDashboardInfo } from '../dashboardSlice'

const TopSection = () => {
  const dispatch = useDispatch()
  const dashboardInfo = useSelector((state) => state.dashboard.dashboardInfo)

  useEffect(() => {
    dispatch(fetchDashboardInfo())
  }, [])

  useEffect(() => {}, [dashboardInfo])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} spacing={2}>
        <Grid container spacing={2}>
          {/* Total Orders */}
          <Grid item xs={12} md={6}>
            <CHorizontalWidget
              subTitle="Total Orders"
              title={dashboardInfo?.total_orders}
              icon={<IconChartPie3 size={38} stroke={1} color="#9f9f9f" />}
              titleColor="#0545a5"
              subTitleColor="black"
            />
          </Grid>

          {/* Paid Payment */}
          <Grid item xs={12} md={6}>
            <CHorizontalWidget
              subTitle="Paid Payment"
              title={dashboardInfo?.paid_payment}
              icon={<IconMoodSmile size={38} stroke={1} color="#9f9f9f" />}
              titleColor="#0545a5"
              subTitleColor="black"
            />
          </Grid>

          {/* Pending Payment */}
          <Grid item xs={12} md={6}>
            <CHorizontalWidget
              subTitle="Pending Payment"
              title={dashboardInfo?.pending_payment}
              icon={<IconPackage size={38} stroke={1} color="#9f9f9f" />}
              titleColor="#0545a5"
              subTitleColor="black"
            />
          </Grid>

          {/* Total Users */}
          <Grid item xs={12} md={6}>
            <CHorizontalWidget
              subTitle="Total Users"
              title={dashboardInfo?.total_users}
              icon={<IconShoppingBag size={38} stroke={1} color="#9f9f9f" />}
              titleColor="#0545a5"
              subTitleColor="black"
            />
          </Grid>
        </Grid>
      </Grid>

      {/*  */}
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          {/* Total Orders */}
          <Grid item xs={12} md={12}>
            <CBarChart
              data={dashboardInfo?.payments}
              labelKey="date"
              dataKey="count"
              height={300}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TopSection
