import React from 'react'

import CDialog from '@/components/CDialog'
import { Box, Stack, Typography } from '@mui/material'
import CButton from '@/components/CButton'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const AckAlert = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // CDialog start
  const [open, setOpen] = React.useState(props.ack.open)

  const handleCDialogOpen = () => {
    setOpen(true)
  }

  const handleCDialogClose = () => {
    setOpen(false)
    props.sendDataToParent({ open: false, patientId: null })
  }

  //   ----------------

  const ack = useSelector((state) => state.patient.ack)

  const handleCancel = () => {
    setOpen(false)
    props.sendDataToParent({ open: false, patientId: null })
  }

  const handleAcknowledge = () => {
    navigate(`/patient/detail/${props.ack.patientId}/profile`)
    props.sendDataToParent({ open: false, patientId: null })
  }
  return (
    <CDialog
      headerTitle="Medical Alert"
      handleCDialogOpen={handleCDialogOpen}
      handleCDialogClose={handleCDialogClose}
      open={open}
    >
      <Box minWidth="50rem">
        <Typography
          component="div"
          dangerouslySetInnerHTML={{ __html: ack?.note }}
        />
        <Stack
          direction="row"
          justifyContent="flex-end"
          sx={{ mt: 2 }}
          spacing={2}
        >
          <CButton label="Acknowledge" onClick={handleAcknowledge} />
          <CButton label="Cancel" color="error" onClick={handleCancel} />
        </Stack>
      </Box>
    </CDialog>
  )
}

export default AckAlert
