import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Button from '@mui/material/Button'
import TaskIcon from '@mui/icons-material/Task'
import {
  Box,
  Card,
  FormControl,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import SaveIcon from '@mui/icons-material/Save'

// Local Components
import CEditor from '../../../../../components/CEditor'
import CDialog from '../../../../../components/CDialog'
import CAutocomplete from '../../../../../components/CAutocomplete'
import CCheckbox from '../../../../../components/CCheckbox'

// Redux Actions
import { showSuccess, showError } from '../../../../../redux/Slice/alertSlice'
import CTextField from '../../../../../components/CTextField'
import CDatePicker from '../../../../../components/CDatePicker'
import CSelect from '../../../../../components/CSelect'
import { formatDate } from '../../../../../utils/cdayjs'
import CButton from '../../../../../components/CButton'
import { Helmet } from 'react-helmet-async'
import { create, list } from '../taskSlice'

const TaskCreate = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { patientId } = useParams()

  const [open, setOpen] = React.useState(false)

  const handleCDialogOpen = () => {
    setOpen(true)
  }

  const handleCDialogClose = () => {
    setOpen(false)
  }

  const { errors, doctors, patients, users } = useSelector(
    (state) => state.inventoryTask
  )

  const [tasks, setTasks] = useState({
    priority: '',
    deadline: '',
    to: '',
    task: '',
    status: '',
    description: '',
    attachment: '',
    job_class: '',
    assign_to: [],
  })

  useEffect(() => {
    dispatch(list())
  }, [])
  // onChange handler to update the local state
  // onChange handler to update the local state
  const handleInputChange = (event) => {
    const { name, value } = event.target
    // Update the local state with the changed value
    setTasks((tasks) => ({
      ...tasks,
      [name]: value,
    }))
  }

  // // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      const res = await dispatch(create(tasks))

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        // Close CDialog after success request
        setOpen(false)
        navigate(-1)
        // dispatch(fetchTaskInfo(patientId))
        // navigate(`/patient/detail/${patientId}`);
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Error while creating task:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred during task creation.',
        })
      )
    }
  }

  return (
    <>
      <Helmet>
        <title>Bioaro | Task Create</title>
      </Helmet>
      <Stack direction="row" justifyContent="center">
        <Card sx={{ p: 3, maxWidth: '90rem' }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={12}>
                <CTextField
                  name="task"
                  label="Task"
                  value={tasks?.task}
                  onChange={handleInputChange}
                  error={!!(errors && errors.task)}
                  helperText={errors && errors.task}
                />
              </Grid>

              <Grid item xs={12}>
                <InputLabel>Description</InputLabel>
                <CEditor
                  name="description"
                  value={tasks.description}
                  handleChange={(value) =>
                    setTasks({
                      ...tasks,
                      description: value,
                    })
                  }
                  helperText={errors && errors.description}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CTextField
                  name="job_class"
                  label="Job Class"
                  value={tasks?.job_class}
                  onChange={handleInputChange}
                  error={!!(errors && errors.job_class)}
                  helperText={errors && errors.job_class}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <CSelect
                  label="Priority"
                  name="priority"
                  value={tasks?.priority}
                  options={[
                    { label: 'Normal', value: 'Normal' },
                    { label: 'High', value: 'High' },
                    { label: 'Low', value: 'Low' },
                  ]}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <CDatePicker
                  label="Return To"
                  name="to"
                  onChange={(value) =>
                    setTasks({ ...tasks, to: formatDate(value) })
                  }
                  error={!!(errors && errors.to)}
                  helperText={errors && errors.to}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <CDatePicker
                  label="Deadline"
                  name="deadline"
                  onChange={(value) =>
                    setTasks({ ...tasks, deadline: formatDate(value) })
                  }
                  error={!!(errors && errors.deadline)}
                  helperText={errors && errors.deadline}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputLabel>Attachment</InputLabel>
                <TextField
                  name="attachment"
                  type="file"
                  fullWidth
                  variant="outlined"
                  onChange={(e) =>
                    setTasks({ ...tasks, attachment: e.target.files[0] })
                  }
                  error={!!(errors && errors.attachment)}
                  helperText={errors && errors.attachment}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CSelect
                  label="Status"
                  name="status"
                  value={tasks?.status}
                  options={[
                    { label: 'Pending', value: 0 },
                    { label: 'Review', value: 1 },
                    { label: 'In Progress', value: 2 },
                    { label: 'Success', value: 3 },
                  ]}
                  onChange={handleInputChange}
                  error={!!(errors && errors.status)}
                  helperText={errors && errors.status}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CCheckbox label="Confidential" name="confidential" />
              </Grid>

              <Grid item xs={12} md={6}>
                <CAutocomplete
                  options={users}
                  getOptionLabel={(option) => option.full_name}
                  onChange={(e, value) =>
                    // console.log(value.map((item)=>item.id))
                    setTasks({
                      ...tasks,
                      assign_to: value,
                      //   assign_to: value.map((item) => item.id),
                    })
                  }
                  label="Assign to"
                  name="assign_to"
                  multiple
                  required
                  error={!!(errors && errors.assign_to)}
                  helperText={errors && errors.assign_to}
                />
              </Grid>
            </Grid>
            <Stack
              direction="row"
              justifyContent="flex-end"
              mb={2}
              mt={2}
              mr={1.3}
            >
              <CButton label="Save" icon={<SaveIcon />} type="submit" />
            </Stack>
          </form>
        </Card>
      </Stack>
    </>
  )
}

export default TaskCreate
