import React from 'react'
import BannerList from './partial/BannerList';
import BannerCreate from './partial/BannerCreate';
import BannerUpdate from './partial/BannerUpdate';
const bannerRoutes = [
  { path: "list/", element: <BannerList /> },
  { path: "create/", element: <BannerCreate /> },
  { path: "update/:bannerId", element: <BannerUpdate /> },
];

export default bannerRoutes;

