// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '@/services/axiosConfig'

// Fetch department list
export const list = createAsyncThunk('hospital/department/list', async () => {
  const response = await axiosInstance.get('hospital/department/list')
  return response.data
})

// Fetch department info
export const departmentInfo = createAsyncThunk(
  'hospital/department/info',
  async (departmentId) => {
    const response = await axiosInstance.get(`hospital/department/info/${departmentId}`)
    console.log(response.data)
    return response.data
  }
)

// Fetch department list
export const create = createAsyncThunk('hospital/department/create', async (data) => {
  const response = await axiosInstance.post('hospital/department/create', data)
  return response.data
})

// Fetch department list
export const update = createAsyncThunk('hospital/department/update', async (data) => {
  const response = await axiosInstance.post('hospital/department/update', data)
  return response.data
})

const departmentSlice = createSlice({
  name: 'department',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    departments: [],
    info: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      //Category List
      .addCase(list.pending, (state) => {
        state.isLoading = true
      })
      .addCase(list.fulfilled, (state, { payload } = action) => {
        state.departments = payload.departments
        state.isLoading = false
      })

      .addCase(list.rejected, (state) => {
        state.isLoading = false
      })

      //Category info
      .addCase(departmentInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(departmentInfo.fulfilled, (state, { payload } = action) => {
        state.info = payload.info
        state.isLoading = false
      })

      .addCase(departmentInfo.rejected, (state) => {
        state.isLoading = false
      })

      //Category create
      .addCase(create.pending, (state) => {
        state.isLoading = true
      })
      .addCase(create.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(create.rejected, (state) => {
        state.isLoading = false
      })

      //Category update
      .addCase(update.pending, (state) => {
        state.isLoading = true
      })
      .addCase(update.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(update.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default departmentSlice.reducer
