// // UserList.jsx

// Icon
import AddIcon from '@mui/icons-material/Add'
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Grid,
  InputLabel,
  Stack,
  Typography,
} from '@mui/material'
// Components
import AdminLayout from '@/layouts/AdminLayout'

// Slice
import { setBreadcrumb } from '@/redux/Slice/breadcrumbSlice'
import { Link, useNavigate } from 'react-router-dom'
import CButton from '@/components/CButton'
import CDatatable from '@/components/CDatatable'
import { changeStatus, deleteRecord } from '@/redux/Slice/commonSlice'
import { showError, showSuccess } from '@/redux/Slice/alertSlice'
import CTextField from '@/components/CTextField'
import SaveIcon from '@mui/icons-material/Save'
import { Helmet } from 'react-helmet-async'
import { useEffect, useState } from 'react'
import { create, list } from '../seoSlice'
import { useDispatch, useSelector } from 'react-redux'
import CAutocomplete from '@/components/CAutocomplete'
import CEditor from '@/components/CEditor'

export default function SeoCreate() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { errors, error, isLoading } = useSelector((state) => state.websiteSeo)

  //   initial create value
  const [seo, setSeo] = useState({
    link: '',
    social_image: '',
    page_title: '',
    keyword: '',
    seo_description: '',
    social_title: '',
    social_description: '',
  })

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        {
          path: '/website/seo/list',
          label: 'Website Seo ',
          isLast: false,
        },
        { path: '', label: 'Create', isLast: true },
      ])
    )
  }, [dispatch])

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const res = await dispatch(create(seo))

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )

        navigate('/website/seo/list')
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Server error:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred while Creating Website Seo / Social.',
        })
      )
    }
  }

  // onChange handler to update the local state
  const handleInputChange = (event) => {
    const { name, value } = event.target
    setSeo((seo) => ({
      ...seo,
      [name]: value,
    }))
  }

  return (
    <>
      <Helmet>
        <title>Website Seo Create</title>
      </Helmet>
      <Stack direction="row" sx={{ pr: 4, width: '79rem', pb: 2 }}>
        <Card sx={{ p: 3, width: '90rem' }}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              width="60rem"
              sx={{ alignContent: 'center', margin: 'auto' }}
            >
              <Stack sx={{ float: 'left' }} mb={3}>
                {' '}
                <Typography variant="h5">Website Seo Create</Typography>{' '}
              </Stack>

              <Grid xs={12} ml={1}>
                <CTextField
                  label="Link"
                  name="link"
                  value={seo?.link}
                  error={errors && errors.link}
                  helperText={errors && errors.link}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <InputLabel>Web Images</InputLabel>
                <CTextField
                  name="social_image"
                  type="file"
                  fullWidth
                  variant="outlined"
                  onChange={(e) =>
                    setSeo({ ...seo, social_image: e.target.files })
                  }
                  error={!!(errors && errors.social_image)}
                  helperText={errors && errors.social_image}
                />
              </Grid>

              <Grid xs={12} m={1}>
                <CTextField
                  label="Page title"
                  name="page_title"
                  value={seo?.page_title}
                  error={errors && errors.page_title}
                  helperText={errors && errors.page_title}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid xs={12} m={1}>
                <CTextField
                  label="Keyword"
                  name="keyword"
                  value={seo?.keyword}
                  error={errors && errors.keyword}
                  helperText={errors && errors.keyword}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} ml={-1} mb={6}>
                <InputLabel>Meta Description</InputLabel>
                <CEditor
                  value={seo?.seo_description}
                  handleChange={(value) =>
                    setSeo({
                      ...seo,
                      seo_description: value,
                    })
                  }
                />
              </Grid>

              <Grid xs={12} m={1}>
                <CTextField
                  label="Social Title"
                  name="social_title"
                  value={seo?.social_title}
                  error={errors && errors.social_title}
                  helperText={errors && errors.social_title}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} ml={-1} mb={6}>
                <InputLabel>Social Description</InputLabel>
                <CEditor
                  value={seo?.social_description}
                  handleChange={(value) =>
                    setSeo({
                      ...seo,
                      social_description: value,
                    })
                  }
                />
              </Grid>

              <Grid mb={4} md={12}>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  mb={2}
                  mt={2}
                  mr={1.3}
                >
                  <CButton label="Save" icon={<SaveIcon />} type="submit" />
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Stack>
    </>
  )
}
