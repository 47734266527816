import { Route, Routes } from 'react-router-dom'

// local
import NoteCreate from './NoteCreate'
import NoteList from './NoteList'
import NoteUpdate from './NoteUpdate'

const noteRoutes = [
  { path: 'create', element: <NoteCreate /> },
  { path: 'list', element: <NoteList /> },
  { path: 'update/:id', element: <NoteUpdate /> },
]

export default noteRoutes
