// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../../services/axiosConfig'

// Fetch patient ack alert by patient id
export const fetchAckAlert = createAsyncThunk(
  'patient/ack/alert',
  async (patientId) => {
    const response = await axiosInstance.get(`patient/ack/alert/${patientId}`)
    return response.data
  }
)

// Fetch Patient info by patient id
export const fetchPatientInfo = createAsyncThunk(
  'patient/patientInfo',
  async (patientId) => {
    const response = await axiosInstance.get(`patient/info/${patientId}`)
    return response.data
  }
)

// Fetch Patient info by patient id
export const fetchGuardianInfo = createAsyncThunk(
  'patient/guardian',
  async () => {
    const response = await axiosInstance.get(`patient/guardians`)
    return response.data
  }
)

// Change Volunteer status
export const changeVolunteerStatus = createAsyncThunk(
  'patient/change-volunteer-status',
  async ({ patientId, data }) => {
    const response = await axiosInstance.post(
      `patient/change-volunteer-status/${patientId}`,
      data
    )
    return response.data
  }
)

// Change notification status
export const changeNotificationStatus = createAsyncThunk(
  'patient/change-notification-status',
  async ({ patientId, data }) => {
    const response = await axiosInstance.post(
      `patient/change-notification-status/${patientId}`,
      data
    )
    return response.data
  }
)

// Patient Create
export const create = createAsyncThunk('patient/create', async ({ data }) => {
  const response = await axiosInstance.post(`patient/create`, data)
  return response.data
})

// Fetch Patient Create
export const updateLastSeenPatient = createAsyncThunk(
  'patient/update-last-seen-patient',
  async (patientId) => {
    const response = await axiosInstance.post(
      `patient/update-last-seen-patient/${patientId}`
    )
    return response.data
  }
)

// Fetch all recent Patient list
export const fetchRecentPatientList = createAsyncThunk(
  'patient/recent-patient-list',
  async () => {
    let url = 'patient/recent-patient-list' // Initialize the URL

    const response = await axiosInstance.get(url)

    // console.log(response.data);
    return response.data
  }
)

// Fetch all searched Patient list
export const fetchSearchedPatientList = createAsyncThunk(
  'patient/searched-patient-list',
  async (searchTerm = null) => {
    let url = 'patient/searched-patient-list' // Initialize the URL
    if (searchTerm) {
      url += `?searchTerm=${searchTerm}` // Append query parameter if searchTerm is provided
    }
    const response = await axiosInstance.get(url)

    // console.log(response.data);
    return response.data
  }
)

// sendMail
export const sendMail = createAsyncThunk(
  'patient/send-mail',
  async ({ patientId, data }) => {
    const response = await axiosInstance.post(
      `patient/send-mail/${patientId}`,
      data
    )
    return response.data
  }
)

export const currencyChange = createAsyncThunk(
  'patient/currency-change',
  async ({ data }) => {
    const response = await axiosInstance.post(`patient/currency-change`, data)
    return response.data
  }
)
export const patientFollow = createAsyncThunk(
  'patient/patient-follow',
  async ({ data }) => {
    const response = await axiosInstance.post(`patient/patient-follow`, data)
    return response.data
  }
)

const patientSlice = createSlice({
  name: 'patient',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    patientInfo: '',
    guardians: '',
    recentPatients: [],
    searchedPatients: [],
    ack: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Ack Alert
      .addCase(fetchAckAlert.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchAckAlert.fulfilled, (state, { payload } = action) => {
        state.ack = payload.ack
        state.isLoading = false
      })
      .addCase(fetchAckAlert.rejected, (state, { payload }) => {
        state.patientInfo = []
        state.isLoading = false
      })

      // Patient List
      .addCase(fetchPatientInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchPatientInfo.fulfilled, (state, { payload } = action) => {
        state.patientInfo = payload.patientInfo
        state.isLoading = false
      })
      .addCase(fetchPatientInfo.rejected, (state, { payload }) => {
        state.patientInfo = []
        state.isLoading = false
      })

      // Patient create
      .addCase(create.pending, (state) => {
        state.isLoading = true
      })
      .addCase(create.fulfilled, (state, { payload } = action) => {
        state.isLoading = false
        state.errors = payload.errors
      })
      .addCase(create.rejected, (state, { payload }) => {
        state.isLoading = false
      })
      // Patient Guardian
      .addCase(fetchGuardianInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchGuardianInfo.fulfilled, (state, { payload } = action) => {
        state.guardians = payload.guardians
        state.isLoading = false
      })
      .addCase(fetchGuardianInfo.rejected, (state, { payload }) => {
        state.guardians = []
        state.isLoading = false
      })

      // recent Patient List
      .addCase(fetchRecentPatientList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        fetchRecentPatientList.fulfilled,
        (state, { payload } = action) => {
          state.recentPatients = payload.recentPatientList
          state.isLoading = false
        }
      )
      .addCase(fetchRecentPatientList.rejected, (state, { payload }) => {
        state.isLoading = false
      })

      // Searched Patient List
      .addCase(fetchSearchedPatientList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        fetchSearchedPatientList.fulfilled,
        (state, { payload } = action) => {
          state.searchedPatients = payload.searchedPatientList
          state.isLoading = false
        }
      )
      .addCase(fetchSearchedPatientList.rejected, (state, { payload }) => {
        state.isLoading = false
      })

      // Send Email
      .addCase(sendMail.pending, (state) => {
        state.isLoading = true
      })
      .addCase(sendMail.fulfilled, (state, action) => {
        if (action.payload.error) {
          state.message = action.payload.message
          state.errors = action.payload.errors
        } else {
          state.message = action.payload.message
          state.errors = ''
        }
        state.isLoading = false
      })
      .addCase(sendMail.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export const allRoles = (state) => state.user.roles

export default patientSlice.reducer
