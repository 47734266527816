// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance } from '@/services/axiosConfig'

// Fetch billing invoice list
export const fetchPaidInvoiceList = createAsyncThunk(
  'patient/billing/paid-invoice-list',
  async (patientId) => {
    const response = await axiosInstance.get(
      `patient/billing/paid-invoice-list/?patientId=${patientId}`
    )
    return response.data
  }
)

// Fetch billing invoice list
export const trackOrder = createAsyncThunk(
  'patient/billing/track-order',
  async (paymentId) => {
    const response = await axiosInstance.get(
      `patient/billing/track-order/${paymentId}`
    )
    return response.data
  }
)

export const refundMoney = createAsyncThunk(
  'patient/billing/refund-money',
  async ({ data }) => {
    const response = await axiosInstance.post(
      'patient/billing/refund-money',
      data
    )
    return response.data
  }
)

const billingSlice = createSlice({
  name: 'billing',
  initialState: {
    isLoading: false,
    errors: '',
    paidInvoices: [],
    data: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch  billing invoice
      .addCase(fetchPaidInvoiceList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchPaidInvoiceList.fulfilled, (state, { payload }) => {
        state.paidInvoices = payload.paidInvoices
        state.isLoading = false
      })
      .addCase(fetchPaidInvoiceList.rejected, (state) => {
        state.isLoading = false
      })

      // Refund Money
      .addCase(refundMoney.pending, (state) => {
        state.isLoading = true
      })
      .addCase(refundMoney.fulfilled, (state, { payload } = action) => {
        state.isLoading = false
        state.errors = payload.errors
      })
      .addCase(refundMoney.rejected, (state, { payload }) => {
        state.isLoading = false
      })

      // Track order
      .addCase(trackOrder.pending, (state) => {
        state.isLoading = true
      })
      .addCase(trackOrder.fulfilled, (state, { payload }) => {
        state.data = payload.data
        state.isLoading = false
      })
      .addCase(trackOrder.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default billingSlice.reducer
