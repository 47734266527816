import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Card, Grid, Stack } from '@mui/material'
import CTextField from '../../../../components/CTextField'
import CAutocomplete from '../../../../components/CAutocomplete'
import CButton from '../../../../components/CButton'
import SaveIcon from '@mui/icons-material/Save'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { create, fetchDocumentResendList, info } from '../documentResendSlice'
import { setBreadcrumb } from '../../../../redux/Slice/breadcrumbSlice'
import { showError, showSuccess } from '../../../../redux/Slice/alertSlice'

export default function DocumentResendCreate() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { users, errors, isLoading } = useSelector((state) => state.document)
  const [document, setDocument] = useState({
    patient_id: '',
    template: [],
  })
  useEffect(() => {
    dispatch(info())
  }, [])
  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        {
          path: '/document-resend/list',
          label: 'Document Resend',
          isLast: false,
        },
        { path: '', label: 'Create', isLast: true },
      ])
    )
  }, [dispatch])
  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const res = await dispatch(
        // Wait for the pharmacy create action to complete.
        create({
          patient_id: document?.patient_id,
          template: document?.template,
        })
      )

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        navigate('/document-resend/list')
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      // Handle any errors that occur during the login action dispatch
      console.error('Server error:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred during creating user.',
        })
      )
    }
  }
  const template = ['template1', 'template2', 'template3', 'template4']

  return (
    <>
      <>
        <Helmet>
          <title>Bioaro | Document Resend - Create</title>
        </Helmet>
        <Card sx={{ p: 3, maxWidth: '100rem' }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={5}>
                <CAutocomplete
                  options={users}
                  getOptionLabel={(option) => option.full_name}
                  onChange={(e, value) =>
                    setDocument({ ...document, patient_id: value.id })
                  }
                  label="Patient"
                  error={!!(errors && errors.patient_id)}
                  helperText={errors && errors.patient_id}
                />
              </Grid>
              <Grid item xs={12} md={7}>
                <CAutocomplete
                  options={template}
                  getOptionLabel={(option) => option}
                  value={document.template}
                  onChange={(e, value) =>
                    // console.log(value)
                    setDocument({
                      ...document,
                      template: value,
                    })
                  }
                  label="Forms"
                  multiple
                  error={!!(errors && errors.template)}
                  helperText={errors && errors.template}
                />
              </Grid>
            </Grid>
            <Stack direction="row" justifyContent="flex-end" mt={2}>
              <CButton label="Save" icon={<SaveIcon />} type="submit" />
            </Stack>
          </form>
        </Card>
      </>
    </>
  )
}
