import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { dictationTemplateInfo, update } from '../dictationTemplateSlice'
import { setBreadcrumb } from '@/redux/Slice/breadcrumbSlice'
import { showError, showSuccess } from '@/redux/Slice/alertSlice'
import CButton from '@/components/CButton'
import CEditor from '@/components/CEditor'
import CAutocomplete from '@/components/CAutocomplete'
import CTextField from '@/components/CTextField'
import SaveIcon from '@mui/icons-material/Save'
import { Box, Card, Grid, InputLabel, Stack, Typography } from '@mui/material'
import { Helmet } from 'react-helmet-async'

export default function DictationTemplateUpdate() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { dictationTemplateId } = useParams()
  const { doctors, info, errors } = useSelector(
    (state) => state.dictationTemplate
  )
  const dictationTemplateFromRedux = useSelector(
    (state) => state.dictationTemplate.info
  )
  const [template, setTemplate] = useState(dictationTemplateFromRedux)

  useEffect(() => {
    dispatch(dictationTemplateInfo(dictationTemplateId))
  }, [])
  useEffect(() => {
    setTemplate(dictationTemplateFromRedux)
  }, [dictationTemplateFromRedux])

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        {
          path: 'inventory/dictation-template/list',
          label: 'Dictation Template',
          isLast: false,
        },
        { path: '', label: 'Update', isLast: true },
      ])
    )
  }, [dispatch])

  // Update the local state with the changed value
  const handleInputChange = (event) => {
    const { name, value } = event.target
    setTemplate((prevTemplate) => ({
      ...prevTemplate,
      [name]: value,
    }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const res = await dispatch(update(template))

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )

        navigate('/inventory/dictation-template/list')
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Server error:', error)
      dispatch(
        showError({
          error: true,
          message:
            'An error occurred while Creating Dictation Template service.',
        })
      )
    }
  }

  return (
    <>
      <Helmet>
        <title>Bioaro | Dictation Template Create</title>
      </Helmet>
      <Stack direction="row" sx={{ pr: 4, width: '79rem', pb: 2 }}>
        <Card sx={{ p: 3, width: '90rem' }}>
          <form onSubmit={handleSubmit}>
            <Box borderBottom={2} mb={4}>
              {' '}
              <Stack sx={{ float: 'left' }}>
                {' '}
                <Typography variant="h5" sx={{ fontWeight: 'bolder' }}>
                  Dictation Template Create
                </Typography>{' '}
              </Stack>
              <Stack
                direction="row"
                justifyContent="flex-end"
                mb={2}
                mt={2}
                mr={1.3}
              >
                <CButton label="Update" icon={<SaveIcon />} type="submit" />
              </Stack>
            </Box>

            <Grid
              container
              spacing={2}
              alignItems="center"
              width="60rem"
              sx={{ alignContent: 'center', margin: 'auto' }}
            >
              <Grid xs={12} m={1}>
                <CTextField
                  label="Title"
                  name="title"
                  value={template?.title}
                  error={errors && errors.title}
                  helperText={errors && errors.title}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid xs={12} m={1}>
                <CTextField
                  label="Subject"
                  name="subject"
                  value={template?.subject}
                  error={errors && errors.subject}
                  helperText={errors && errors.subject}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} ml={0}>
                <InputLabel>Body</InputLabel>
                <CEditor
                  value={template?.body}
                  handleChange={(value) =>
                    setTemplate((prevTemplate) => ({
                      ...prevTemplate,
                      body: value,
                    }))
                  }
                />
              </Grid>

              <Grid xs={12} mt={6}>
                <CAutocomplete
                  options={doctors}
                  getOptionLabel={(option) => option.full_name}
                  value={template?.doctor}
                  onChange={(e, value) =>
                    setTemplate((prevTemplate) => ({
                      ...prevTemplate,
                      doctor: value,
                    }))
                  }
                  label="Doctors"
                  multiple
                  error={!!(errors && errors.doctor)}
                  helperText={errors && errors.doctor}
                />
              </Grid>
            </Grid>
          </form>
        </Card>
      </Stack>
    </>
  )
}
