import { IconShoppingCart } from '@tabler/icons-react'
import { IconAddressBook } from '@tabler/icons-react'
import { IconCopyPlus } from '@tabler/icons-react'
import { IconFile } from '@tabler/icons-react'
import {
  IconCalendarCheck,
  IconHome,
  IconList,
  IconMessageDots,
  IconUserSquare,
} from '@tabler/icons-react'

const portal = 'https://portal.bioaro.com'

const patientMenu = [
  {
    menuLabel: false,
    label: 'Dashboard',
    icon: <IconHome />,
    path: '/dashboard',
  },
  {
    menuLabel: false,
    label: 'Chat',
    icon: <IconMessageDots />,
    path: '/chat/',
  },
  {
    menuLabel: false,
    label: 'Order Manage',
    icon: <IconUserSquare />,
    path: '/order/order-manage-list',
  },

  {
    menuLabel: false,
    label: 'Documents',
    icon: <IconFile />,
    path: '/document/list',
  },
  {
    menuLabel: false,
    label: 'Intake',
    icon: <IconList />,
    path: '/intake/list',
  },
  {
    menuLabel: false,
    label: 'Appointment',
    icon: <IconCalendarCheck />,
    path: '/appointment/list',
  },
  {
    menuLabel: false,
    label: 'Completed Purchase',
    icon: <IconShoppingCart />,
    path: '/purchase/list',
  },
  {
    menuLabel: false,
    label: 'Book Your Lab Test',
    icon: <IconCopyPlus />,
    path: `${portal}/booking?gut=0`,
  },
  {
    menuLabel: false,
    label: 'Book your BioGut Journey',
    icon: <IconAddressBook />,
    path: `${portal}/front/booking`,
  },
]

export default patientMenu
