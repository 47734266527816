// // UserList.jsx

// Icon
import AddIcon from '@mui/icons-material/Add'
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Grid,
  InputLabel,
  Stack,
  Typography,
} from '@mui/material'
// Components
import AdminLayout from '@/layouts/AdminLayout'

// Slice
import { setBreadcrumb } from '@/redux/Slice/breadcrumbSlice'
import { Link, useNavigate } from 'react-router-dom'
import CButton from '@/components/CButton'
import CDatatable from '@/components/CDatatable'
import { changeStatus, deleteRecord } from '@/redux/Slice/commonSlice'
import { showError, showSuccess } from '@/redux/Slice/alertSlice'
import CTextField from '@/components/CTextField'
import SaveIcon from '@mui/icons-material/Save'
import { Helmet } from 'react-helmet-async'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CAutocomplete from '@/components/CAutocomplete'
import { create, list } from '../consentTemplateSlice'
import CEditor from '@/components/CEditor'

export default function ConsentTemplateCreate() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { errors, error, isLoading } = useSelector(
    (state) => state.inventoryConsentTemplate
  )
  useEffect(() => {
    dispatch(list())
  }, [])

  //   initial create value
  const [template, setTemplate] = useState({
    subject: '',
    message: '',
  })

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        {
          path: 'inventory/consent-template/list',
          label: 'Consent Template',
          isLast: false,
        },
        { path: '', label: 'Create', isLast: true },
      ])
    )
  }, [dispatch])

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const res = await dispatch(create(template))

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )

        navigate('/inventory/consent-template/list')
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Server error:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred while Creating Consent Template service.',
        })
      )
    }
  }

  // onChange handler to update the local state
  const handleInputChange = (event) => {
    const { name, value } = event.target
    // Update the local state with the changed value
    setTemplate((template) => ({
      ...template,
      [name]: value,
    }))
  }

  return (
    <>
      <Helmet>
        <title>Bioaro | Consent Template Create</title>
      </Helmet>
      <Stack direction="row" sx={{ pr: 4, width: '79rem', pb: 2 }}>
        <Card sx={{ p: 3, width: '90rem' }}>
          <form onSubmit={handleSubmit}>
            <Box borderBottom={2} mb={4}>
              {' '}
              <Stack sx={{ float: 'left' }}>
                {' '}
                <Typography variant="h5" sx={{ fontWeight: 'bolder' }}>
                  Consent Template Create
                </Typography>{' '}
              </Stack>
              <Stack
                direction="row"
                justifyContent="flex-end"
                mb={2}
                mt={2}
                mr={1.3}
              >
                <CButton label="Save" icon={<SaveIcon />} type="submit" />
              </Stack>
            </Box>

            <Grid
              container
              spacing={2}
              alignItems="center"
              width="60rem"
              sx={{ alignContent: 'center', margin: 'auto' }}
            >
              <Grid xs={12} m={1}>
                <CTextField
                  label="Subject"
                  name="subject"
                  value={template?.subject}
                  error={errors && errors.subject}
                  helperText={errors && errors.subject}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} ml={0} mb={6}>
                <InputLabel>Description</InputLabel>
                <CEditor
                  value={template?.message}
                  handleChange={(value) =>
                    setTemplate({
                      ...template,
                      message: value,
                    })
                  }
                />
              </Grid>
            </Grid>
          </form>
        </Card>
      </Stack>
    </>
  )
}
