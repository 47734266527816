import React, { useState, useRef, useEffect } from 'react'
import {
  Box,
  InputAdornment,
  Stack,
  Typography,
  IconButton,
  ClickAwayListener,
  Fab,
  Grid,
} from '@mui/material'
import {
  Add as AddIcon,
  Send as SendIcon,
  AddReaction as AddReactionIcon,
  Image,
  AttachFile,
  Close as CloseIcon,
  Send,
} from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import CTextField from '@/components/CTextField'
import CIconButton from '@/components/CIconButton'
import EmojiPicker from 'emoji-picker-react'
import { useDispatch, useSelector } from 'react-redux'
import {
  messageUpdate,
  messageList,
  setMessages,
  messageForward,
} from '../chatSlice'
import CDialog from '@/components/CDialog'
import CAutocomplete from '@/components/CAutocomplete'

const Forward = ({ handleForwardDialog, socket }) => {
  const dispatch = useDispatch()
  const { messageId, receiver, isOnline, chatList } = useSelector(
    (state) => state.chat
  )
  const [forward, setForward] = useState({ messageId: messageId, users: [] })

  const handleSubmit = async (e) => {
    e.preventDefault()

    await dispatch(messageForward({ data: forward })).then(() => {
      dispatch(messageList(receiver.user_id))
      setForward({ messageId: messageId, users: [] })
      handleForwardDialog()
    })
  }

  return (
    <CDialog
      headerTitle="Forward Message"
      handleCDialogClose={handleForwardDialog}
      open={true}
    >
      <Box minWidth="50rem" minHeight="5rem">
        <form onSubmit={handleSubmit}>
          <Grid container alignItems="center">
            <Grid item xs={11}>
              {' '}
              <CAutocomplete
                options={chatList}
                getOptionLabel={(option) => option.full_name}
                value={forward.users}
                onChange={(e, value) =>
                  setForward({
                    ...forward,
                    users: value,
                    receiverIds: value.map((item) => item.user_id),
                  })
                }
                label="Select Users"
                multiple
              />
            </Grid>
            <Grid item mt={3}>
              <CIconButton icon={<Send />} type="submit" />
            </Grid>
          </Grid>
        </form>
      </Box>
    </CDialog>
  )
}

export default Forward
