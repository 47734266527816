// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../../services/axiosConfig'

// Doctor Create
export const search = createAsyncThunk(
  'patient/data-analysis',
  async (data) => {
    const response = await axiosInstance.post(`patient/data-analysis`, data)
    return response.data
  }
)

const dataAnalysisSlice = createSlice({
  name: 'dataAnalysis',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    users: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Fetch  doctor Create
      .addCase(search.pending, (state) => {
        state.isLoading = true
      })
      .addCase(search.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.users = payload.users
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(search.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default dataAnalysisSlice.reducer
