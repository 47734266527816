// // UserList.jsx
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { formatDateTime } from '../../../utils/cdayjs'

// Components
import { Helmet } from 'react-helmet-async'
import CDatatable from '../../../components/CDatatable'
import AddIcon from '@mui/icons-material/Add'
// Slice
import { setBreadcrumb } from '../../../redux/Slice/breadcrumbSlice'
import {
  Box,
  Button,
  Card,
  Chip,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Typography,
} from '@mui/material'
import CButton from '../../../components/CButton'
import { useDispatch, useSelector } from 'react-redux'
import CDataTable from '../../../components/CDatatable'
import { Edit } from '@mui/icons-material'
import { fetchDocumentResendList } from './documentResendSlice'

const DocumentResend = () => {
  const dispatch = useDispatch()
  const { documents, isLoading } = useSelector((state) => state.document)

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        {
          path: '/document-resend/list',
          label: 'Document Resend',
          isLast: false,
        },
        { path: '', label: 'List', isLast: true },
      ])
    )
  }, [dispatch])

  const [quickFilterValue, setQuickfilterValue] = useState(null)
  // add this when you use server side filter
  const handleQuickFilterValue = (filterModel) => {
    const quickFilterValue = filterModel.quickFilterValues.join(' ')
    dispatch(fetchDocumentResendList(quickFilterValue))
    setQuickfilterValue(quickFilterValue)
  }

  const columns = [
    {
      field: 'full_name',
      headerName: 'Patient',
      flex: 0.5,
    },
    {
      field: 'title',
      headerName: 'Template',
      flex: 0.5,
      renderCell: (params) => (
        <>
          <Grid container spacing={1} p={1}>
            {params.row.template?.map((item, index) => (
              <Grid item>
                <Chip color="success" size="small" label={item} />
              </Grid>
            ))}
          </Grid>
        </>
      ),
    },

    {
      field: 'status',
      headerName: 'Submitted',
      flex: 0.5,
      renderCell: (params) => (
        <>
          <Typography>
            <Stack>{params.row.status == 0 ? 'Submitted' : 'Not Submit'}</Stack>
          </Typography>
        </>
      ),
    },
    {
      headerName: 'Action',
      flex: 0.2,
      renderCell: (params) => (
        <>
          <CButton
            label="Edit"
            icon={<Edit />}
            component={Link}
            to={`/document-resend/update/${params.row.id}`}
          />
        </>
      ),
    },
  ]

  useEffect(() => {
    dispatch(fetchDocumentResendList())
  }, [])

  return (
    <>
      <Helmet>
        <title>Bioaro | Document Resend - List</title>
      </Helmet>
      <Card sx={{ p: 3 }}>
        <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
          <Typography variant="h6">
            {quickFilterValue ? 'Pharmacy List' : 'Pharmacy List'}
          </Typography>
          <CButton
            label="New document"
            icon={<AddIcon />}
            component={Link}
            to={`/document-resend/create`}
          />
        </Stack>
        <CDataTable
          rows={documents}
          columns={columns}
          isLoading={isLoading}
          filterMode="server"
          handleQuickFilterValue={handleQuickFilterValue}
        />
      </Card>
    </>
  )
}

export default DocumentResend
