import { Link, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import Button from '@mui/material/Button'
import SaveIcon from '@mui/icons-material/Save'
import {
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material'
import CTextField from '@/components/CTextField'
import CAutocomplete from '@/components/CAutocomplete'
import CSelect from '@/components/CSelect'
import CButton from '@/components/CButton'
import CNumberField from '@/components/CNumberField'
import CFileField from '@/components/CFileField'
import CRadioGroup from '@/components/CRadioGroup'
import CEditor from '@/components/CEditor'
import { slugify } from '@/utils/cutils'
import { showSuccess, showError } from '@/redux/Slice/alertSlice'
import { setBreadcrumb } from '@/redux/Slice/breadcrumbSlice'
import { fetchGutComScheduleInfo, create } from './gutComScheduleSlice'
import CTimePicker from '@/components/CTimePicker'
import { formatDateTimeToTime } from '@/utils/cdayjs'
import dayjs from 'dayjs'

const GutComScheduleCreate = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const gutComScheduleInfoFromRedux = useSelector(
    (state) => state.gutComSchedule?.gutComScheduleInfo
  )
  const errors = useSelector((state) => state.gutComSchedule.errors)

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          path: '/gut-com-schedule/list',
          label: 'Gut Communication Schedule',
          isLast: false,
        },
        { path: '', label: 'Create', isLast: true },
      ])
    )
  }, [dispatch])

  const [gutComSchedule, setGutComSchedule] = useState({
    days: '',
    msg_time: null,
    subject: '',
    email_message: '',
    chat_message: '',
    category_id: null,
    email_files: null,
    chat_filese: null,
  })

  useEffect(() => {
    dispatch(fetchGutComScheduleInfo())
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const res = await dispatch(create({ data: gutComSchedule }))

      if (res.payload && !res.payload.error) {
        dispatch(showSuccess({ success: true, message: res.payload.message }))
        navigate('/gut-com-schedule/list')
      } else {
        dispatch(showError({ error: true, message: res.payload.message }))
      }
    } catch (error) {
      console.error('Server error:', error)
      dispatch(showError({ error: true, message: 'An error occurred!' }))
    }
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setGutComSchedule((prevGutComSchedule) => ({
      ...prevGutComSchedule,
      [name]: value,
    }))
    if (name === 'name') {
      setGutComSchedule((prevGutComSchedule) => ({
        ...prevGutComSchedule,
        slug: slugify(value),
      }))
    }
  }

  const handleAutocompleteChange = (data) => {
    const { name, value, id_column } = data
    const ids = value.map((item) => item.id)
    setGutComSchedule((prevGutComSchedule) => ({
      ...prevGutComSchedule,
      [name]: value,
      [id_column]: ids,
    }))
  }

  let objects = generateObjects()

  console.log(gutComSchedule)

  return (
    <>
      <Helmet>
        <title>Bioaro | Gut Communication Schedule Create</title>
      </Helmet>
      <Card sx={{ p: 2 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="center">
            {/* Days */}
            <Grid item xs={12} md={6}>
              <CAutocomplete
                label="Days"
                options={objects}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id == value.id}
                error={!!errors && errors.days}
                helperText={errors && errors.days}
                onChange={(e, value) =>
                  setGutComSchedule({ ...gutComSchedule, days: value.value })
                }
              />
            </Grid>

            {/* Message Time  */}
            <Grid item xs={12} md={6}>
              <CTimePicker
                label="Message Time"
                mobileTimePicker={false}
                value={
                  gutComSchedule.msg_time && dayjs(gutComSchedule.msg_time)
                }
                error={!!errors && errors.msg_time}
                helperText={errors && errors.msg_time}
                onChange={(value) =>
                  setGutComSchedule({
                    ...gutComSchedule,
                    msg_time: formatDateTimeToTime(value),
                  })
                }
              />
            </Grid>

            {/* Subject  */}
            <Grid item xs={12}>
              <CTextField
                label="Subject"
                name="name"
                value={gutComSchedule?.name}
                required={true}
                error={!!errors && errors.name}
                helperText={errors && errors.name}
                onChange={handleInputChange}
              />
            </Grid>

            {/* Email Message */}
            <Grid item xs={12} md={6}>
              <CEditor
                label="Email Message"
                value={gutComSchedule.email_message}
                error={!!errors && errors.email_message}
                helperText={errors && errors.email_message}
                handleChange={(value) =>
                  setGutComSchedule({
                    ...gutComSchedule,
                    email_message: value,
                  })
                }
              />
            </Grid>

            {/* Chat Message */}
            <Grid item xs={12} md={6}>
              <CTextField
                label="Chat Message"
                name="chat_message"
                value={gutComSchedule?.chat_message}
                multiline={true}
                rows={15}
                error={!!errors && errors.chat_message}
                helperText={errors && errors.chat_message}
                onChange={handleInputChange}
              />
            </Grid>

            {/* Email File  */}
            <Grid item xs={12} md={6}>
              <CFileField
                label="Email File"
                multiple={true}
                onChange={(e) =>
                  setGutComSchedule({
                    ...gutComSchedule,
                    email_files: e.target.files,
                  })
                }
              />
            </Grid>

            {/* Chat File  */}
            <Grid item xs={12} md={6}>
              <CFileField
                label="Chat File"
                multiple={true}
                onChange={(e) =>
                  setGutComSchedule({
                    ...gutComSchedule,
                    chat_files: e.target.files,
                  })
                }
              />
            </Grid>

            {/* Category */}
            <Grid item xs={12} md={6}>
              <CAutocomplete
                label="Category"
                options={gutComScheduleInfoFromRedux?.categories || []}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id == value.id}
                onChange={(e, value) =>
                  setGutComSchedule({
                    ...gutComSchedule,
                    category_id: value.id,
                  })
                }
              />
            </Grid>
          </Grid>
          <Stack direction="row" justifyContent="flex-end" mt={2}>
            <CButton label="Save" icon={<SaveIcon />} type="submit" />
          </Stack>
        </form>
      </Card>
    </>
  )
}

export default GutComScheduleCreate

function generateObjects(limit = 90) {
  let objectsList = []
  for (let i = 1; i <= limit; i++) {
    let obj = { name: i.toString(), value: i.toString() }
    objectsList.push(obj)
  }
  return objectsList
}
