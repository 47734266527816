import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'

import SendOutlinedIcon from '@mui/icons-material/SendOutlined'
import { Box, Grid, InputLabel, Stack, Typography } from '@mui/material'
import CDialog from '../../../../../components/CDialog'
import CButton from '../../../../../components/CButton'
import CTextField from '../../../../../components/CTextField'
import CEditor from '../../../../../components/CEditor'
import { showError, showSuccess } from '../../../../../redux/Slice/alertSlice'
import SaveIcon from '@mui/icons-material/Save'
import { list, update } from '../inventorySubCategorySlice'
import CAutocomplete from '@/components/CAutocomplete'

const InventorySubCategoryUpdate = ({ id, name, categoryInfo }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const handleCDialogOpen = () => {
    setOpen(true)
  }
  const handleCDialogClose = () => {
    setOpen(false)
  }
  const { subCategories, categories, errors, message, payload } = useSelector(
    (state) => state.inventorySubCategory
  )

  //   initial create value
  const [category, setCategory] = useState({
    id: id,
    name: name,
    category: categoryInfo,
    category_id: categoryInfo.id,
  })

  console.log(category)

  // submit form
  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const res = await dispatch(update(category))

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        dispatch(list())
        setOpen(false)
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Server error:', error)
      dispatch(
        showError({
          error: true,
          message:
            'An error occurred while updating the Inventory Sub Category.',
        })
      )
    }
  }

  // onChange handler to update the local state
  const handleInputChange = (event) => {
    const { name, value } = event.target
    // Update the local state with the changed value
    setCategory((category) => ({
      ...category,
      [name]: value,
    }))
  }
  const listOfObjects = [
    {
      id: 1,
      student: 'John Doe',
      roll: 101,
    },
    {
      id: 2,
      student: 'Jane Smith',
      roll: 102,
    },
    {
      id: 3,
      student: 'Alice Johnson',
      roll: 103,
    },
    {
      id: 4,
      student: 'Bob Brown',
      roll: 104,
    },
    {
      id: 5,
      student: 'Emily Davis',
      roll: 105,
    },
  ]
  const object = {
    id: 5,
    student: 'Emily Davis',
    roll: 105,
  }
  return (
    <CDialog
      btnTitle="Edit"
      headerTitle="Inventory Sub Category Update"
      handleCDialogOpen={handleCDialogOpen}
      handleCDialogClose={handleCDialogClose}
      open={open}
    >
      <Box maxWidth="50rem">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="center">
            <CAutocomplete
              options={listOfObjects}
              getOptionLabel={(option) => option.student}
              value={object}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(e, value) =>
                // console.log(value)
                setCategory({
                  ...category,
                  category: value,
                  category_id: value.id,
                })
              }
              label="Category"
              error={!!(errors && errors.category)}
              helperText={errors && errors.category}
            />
            {/* <CAutocomplete
              options={categories}
              getOptionLabel={(option) => option.name}
              value={category?.category}
              onChange={(e, value) =>
                // console.log(value)
                setCategory({
                  ...category,
                  category: value,
                  category_id: value.id,
                })
              }
              label="Category"
              error={!!(errors && errors.category)}
              helperText={errors && errors.category}
            /> */}
            <Grid xs={12} m={1}>
              <CTextField
                label="Name"
                name="name"
                value={category?.name}
                error={errors && errors.name}
                helperText={errors && errors.name}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Box>
            {' '}
            <Stack sx={{ float: 'left' }}>
              {' '}
              <Typography variant="h5"></Typography>{' '}
            </Stack>
            <Stack
              direction="row"
              justifyContent="flex-end"
              mb={2}
              mt={2}
              mr={1.3}
            >
              <CButton label="Update" icon={<SaveIcon />} type="submit" />
            </Stack>
          </Box>
        </form>
      </Box>
    </CDialog>
  )
}

export default InventorySubCategoryUpdate
