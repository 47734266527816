import { useSelector } from 'react-redux'
import { ThemeProvider, CssBaseline, LinearProgress } from '@mui/material'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import { RouterProvider } from 'react-router-dom'

import lightTheme from './themes/light/lightTheme'
import darkTheme from './themes/dark/darkTheme'
import ErrorBoundary from './components/ErrorBoundary'
import adminRoutes from './routes/adminRoutes'

const App = () => {
  const themeMode = useSelector((state) => state.theme.mode)
  const selectedTheme = themeMode === 'dark' ? darkTheme : lightTheme

  return (
    <HelmetProvider>
      <ThemeProvider theme={selectedTheme}>
        <DndProvider backend={HTML5Backend}>
          <CssBaseline />
          <ErrorBoundary>
            <RouterProvider router={adminRoutes} />
          </ErrorBoundary>
        </DndProvider>
      </ThemeProvider>
    </HelmetProvider>
  )
}

export default App
