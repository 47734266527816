import React from 'react'

const SecurityAnswerCreate = React.lazy(() => import('./SecurityAnswerCreate'))
const SecurityAnswerVerify = React.lazy(() => import('./SecurityAnswerVerify'))
const AnswerReset = React.lazy(() => import('./AnswerReset'))
const Login = React.lazy(() => import('./Login'))
const PasswordForgot = React.lazy(() => import('./PasswordForgot'))
const PasswordReset = React.lazy(() => import('./PasswordReset'))

const authRoutes = [
  { path: '', element: <Login /> },
  { path: '/password-forgot', element: <PasswordForgot /> },
  { path: '/password-reset/:token', element: <PasswordReset /> },
  { path: '/security-answer-create', element: <SecurityAnswerCreate /> },
  { path: '/security-answer-verify', element: <SecurityAnswerVerify /> },
  { path: '/security-answer-reset', element: <AnswerReset /> },
  
]

export default authRoutes
