// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '@/services/axiosConfig'

// Fetch warehouse list
export const list = createAsyncThunk('inventory/warehouse/list', async () => {
  const response = await axiosInstance.get('inventory/warehouse/list')
  return response.data
})

// Fetch warehouse info
export const warehouseInfo = createAsyncThunk(
  'inventory/warehouse/info',
  async (warehouseId) => {
    const response = await axiosInstance.get(`inventory/warehouse/info/${warehouseId}`)
    console.log(response.data)
    return response.data
  }
)

// Fetch warehouse list
export const create = createAsyncThunk('inventory/warehouse/create', async (data) => {
  const response = await axiosInstance.post('inventory/warehouse/create', data)
  return response.data
})

// Fetch warehouse list
export const update = createAsyncThunk('inventory/warehouse/update', async (data) => {
  const response = await axiosInstance.post('inventory/warehouse/update', data)
  return response.data
})

const warehouseSlice = createSlice({
  name: 'warehouse',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    warehouses: [],
    users:[],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      //Category List
      .addCase(list.pending, (state) => {
        state.isLoading = true
      })
      .addCase(list.fulfilled, (state, { payload } = action) => {
        state.warehouses = payload.warehouses
        state.users = payload.users
        state.isLoading = false
      })

      .addCase(list.rejected, (state) => {
        state.isLoading = false
      })

      //Category info
      .addCase(warehouseInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(warehouseInfo.fulfilled, (state, { payload } = action) => {
        state.info = payload.info
        state.isLoading = false
      })

      .addCase(warehouseInfo.rejected, (state) => {
        state.isLoading = false
      })

      //Category create
      .addCase(create.pending, (state) => {
        state.isLoading = true
      })
      .addCase(create.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(create.rejected, (state) => {
        state.isLoading = false
      })

      //Category update
      .addCase(update.pending, (state) => {
        state.isLoading = true
      })
      .addCase(update.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(update.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default warehouseSlice.reducer
