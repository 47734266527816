// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '@/services/axiosConfig'

// Fetch patient medicine active inactive list
export const fetchAckList = createAsyncThunk(
  'patient/ack/list',
  async (patientId) => {
    const response = await axiosInstance.get(`patient/ack/list/${patientId}`)
    return response.data
  }
)

//ack create slice

export const create = createAsyncThunk(
  'patient/ack/create',
  async ({ data, patientId }) => {
    const response = await axiosInstance.post(
      `patient/ack/create/${patientId}`,
      data
    )
    return response.data
  }
)

//change ack status
export const changeAckStatus = createAsyncThunk(
  'patient/ack/change-status',
  async ({ ackId, data }) => {
    const response = await axiosInstance.post(
      `/patient/ack/change-status/${ackId}`,
      data
    )
    return response.data
  }
)

const ackSlice = createSlice({
  name: 'ack',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    acknowledge: [],
    activity: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch  ack , activity list
      .addCase(fetchAckList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchAckList.fulfilled, (state, { payload } = action) => {
        state.acknowledge = payload.acknowledge
        state.activity = payload.activity
        state.isLoading = false
      })
      .addCase(fetchAckList.rejected, (state) => {
        state.isLoading = false
      })

      //change ack create
      .addCase(create.pending, (state) => {
        state.isLoading = true
      })
      .addCase(create.fulfilled, (state, { payload }) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(create.rejected, (state) => {
        state.isLoading = false
      })

      //change ack status
      .addCase(changeAckStatus.pending, (state) => {
        state.isLoading = true
      })
      .addCase(changeAckStatus.fulfilled, (state, { payload }) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(changeAckStatus.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default ackSlice.reducer
