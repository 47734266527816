import React from 'react'
import { Helmet } from 'react-helmet-async'

const AppCalendar = React.lazy(() =>
  import('../Patient/Appointment/AppCalendar')
)

const Schedule = () => {
  return (
    <>
      <Helmet>
        <title>Bioaro | Schedule - Calendar</title>
      </Helmet>
      <AppCalendar />
    </>
  )
}

export default Schedule
