// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '@/services/axiosConfig'

setAuthToken() // This function is called to set the auth header

// Fetch category list
export const list = createAsyncThunk('clam-service/list', async () => {
  const response = await axiosInstance.get('clam-service/list')
  return response.data
})



// Fetch clam service create
export const create = createAsyncThunk('clam-service/create', async (data) => {
    const response = await axiosInstance.post('clam-service/create',data)
    return response.data
  })

  
// Fetch clam service update
export const update = createAsyncThunk('clam-service/update', async (data) => {
  const response = await axiosInstance.post('clam-service/update',data)
  return response.data
})

const clamServiceSlice = createSlice({
  name: 'clamService',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    clams: [],
    info: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      //Category List
      .addCase(list.pending, (state) => {
        state.isLoading = true
      })
      .addCase(list.fulfilled, (state, { payload } = action) => {
        state.clams = payload.clams
        state.isLoading = false
      })

      .addCase(list.rejected, (state) => {
        state.isLoading = false
      })

      

       //Category create
       .addCase(create.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create.fulfilled, (state, { payload } = action) => {
        state.message = payload.message;
        state.errors = payload.errors;
        state.isLoading = false;
      })
      .addCase(create.rejected, (state) => {
        state.isLoading = false;
      })


       //Category update
       .addCase(update.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update.fulfilled, (state, { payload } = action) => {
        state.message = payload.message;
        state.errors = payload.errors;
        state.isLoading = false;
      })
      .addCase(update.rejected, (state) => {
        state.isLoading = false;
      })
  },
})

export default clamServiceSlice.reducer
