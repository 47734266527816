// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../../../services/axiosConfig'

// Fetch all chart notes by patient id
export const fetchIntakeInfoByPatientId = createAsyncThunk(
  'patient/intake/info',
  async (patientId) => {
    const response = await axiosInstance.get(`patient/intake/info/${patientId}`)
    return response.data
  }
)

// Update Intake info
export const update = createAsyncThunk(
  'patient/intake/update',
  async ({ patientId, data }) => {
    const response = await axiosInstance.post(
      `patient/intake/update/${patientId}`,
      data
    )
    return response.data
  }
)

// Print Intake
export const intakePrint = createAsyncThunk(
  'patient/intake/print',
  async (patientId) => {
    const response = await axiosInstance.get(
      `patient/intake/print/${patientId}`,
      {
        responseType: 'blob',
      }
    )
    return response.data
  }
)

// send Email
export const sendAuthMail = createAsyncThunk(
  'patient/intake/send-auth-mail',
  async (patientId) => {
    const response = await axiosInstance.post(
      `patient/intake/send-auth-mail/${patientId}`
    )
    return response.data
  }
)

// Fetch all chart notes by patient id
export const intakeList = createAsyncThunk('patient/intake/list', async () => {
  const response = await axiosInstance.get(`patient/intake/list`)
  return response.data
})

const intakeSlice = createSlice({
  name: 'intake',
  initialState: {
    isLoading: false,
    selectedForms: [],
    data: null,
    activities: [],
    errors: '',
    intakes: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchIntakeInfoByPatientId.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchIntakeInfoByPatientId.fulfilled, (state, { payload }) => {
        state.selectedForms = payload.selectedForms
        state.data = payload
        state.activities = payload.activities
        state.isLoading = false
      })
      .addCase(fetchIntakeInfoByPatientId.rejected, (state) => {
        state.isLoading = false
      })

      // Update Intake
      .addCase(update.pending, (state) => {
        state.isLoading = true
      })
      .addCase(update.fulfilled, (state, action) => {
        if (action.payload.error) {
          state.message = action.payload.message
          state.errors = action.payload.errors
        } else {
          state.message = action.payload.message
          state.errors = null
        }
        state.isLoading = false
      })
      .addCase(update.rejected, (state) => {
        state.isLoading = false
      })

    // Intake Print
    // .addCase(intakePrint.pending, (state) => {
    //   state.isLoading = true;
    // })
    // .addCase(intakePrint.fulfilled, (state, { payload } = action) => {
    //   state.isLoading = false;
    // })
    // .addCase(intakePrint.rejected, (state) => {
    //   state.isLoading = false;
    // });

    .addCase(intakeList.pending, (state) => {
      state.isLoading = true
    })
    .addCase(intakeList.fulfilled, (state, { payload }) => {
      state.intakes = payload.intakes
      state.isLoading = false
    })
    .addCase(intakeList.rejected, (state) => {
      state.isLoading = false
    })
  },
})

export default intakeSlice.reducer
