// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '@/services/axiosConfig'

// Fetch acknowledge list
export const list = createAsyncThunk(
  'inventory/acknowledge/list',
  async () => {
    const response = await axiosInstance.get(
      'inventory/acknowledge/list'
    )
    return response.data
  }
)

// Fetch acknowledge info
export const acknowledgeInfo = createAsyncThunk(
  'inventory/acknowledge/info',
  async (templateId) => {
    const response = await axiosInstance.get(
      `inventory/acknowledge/info/${templateId}`
    )
    console.log(response.data)
    return response.data
  }
)

// Fetch acknowledge list
export const create = createAsyncThunk(
  'inventory/acknowledge/create',
  async (data) => {
    const response = await axiosInstance.post(
      'inventory/acknowledge/create',
      data
    )
    return response.data
  }
)

// Fetch acknowledge list
export const update = createAsyncThunk(
  'inventory/acknowledge/update',
  async (data) => {
    const response = await axiosInstance.post(
      'inventory/acknowledge/update',
      data
    )
    return response.data
  }
)

const acknowledgeSlice = createSlice({
  name: 'acknowledge',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    acknowledges: [],
    info: '',
    patients:[]
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      //acknowledge List
      .addCase(list.pending, (state) => {
        state.isLoading = true
      })
      .addCase(list.fulfilled, (state, { payload } = action) => {
        state.acknowledges = payload.acknowledges
        state.patients = payload.patients
        state.isLoading = false
      })

      .addCase(list.rejected, (state) => {
        state.isLoading = false
      })

      //acknowledge info
      .addCase(acknowledgeInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        acknowledgeInfo.fulfilled,
        (state, { payload } = action) => {
          state.info = payload.info
          state.isLoading = false
        }
      )

      .addCase(acknowledgeInfo.rejected, (state) => {
        state.isLoading = false
      })

      //acknowledge create
      .addCase(create.pending, (state) => {
        state.isLoading = true
      })
      .addCase(create.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(create.rejected, (state) => {
        state.isLoading = false
      })

      //acknowledge update
      .addCase(update.pending, (state) => {
        state.isLoading = true
      })
      .addCase(update.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(update.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default acknowledgeSlice.reducer
