// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '@/services/axiosConfig'

// Fetch mailTemplate list
export const list = createAsyncThunk(
  'inventory/mail-template/list',
  async () => {
    const response = await axiosInstance.get(
      'inventory/mail-template/list'
    )
    return response.data
  }
)

// Fetch mailTemplate info
export const mailTemplateInfo = createAsyncThunk(
  'inventory/mail-template/info',
  async (templateId) => {
    const response = await axiosInstance.get(
      `inventory/mail-template/info/${templateId}`
    )
    console.log(response.data)
    return response.data
  }
)

// Fetch mailTemplate list
export const create = createAsyncThunk(
  'inventory/mail-template/create',
  async (data) => {
    const response = await axiosInstance.post(
      'inventory/mail-template/create',
      data
    )
    return response.data
  }
)

// Fetch mailTemplate list
export const update = createAsyncThunk(
  'inventory/mail-template/update',
  async (data) => {
    const response = await axiosInstance.post(
      'inventory/mail-template/update',
      data
    )
    return response.data
  }
)

const mailTemplateSlice = createSlice({
  name: 'inventoryMailTemplate',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    mailTemplates: [],
    info: '',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      //mailTemplate List
      .addCase(list.pending, (state) => {
        state.isLoading = true
      })
      .addCase(list.fulfilled, (state, { payload } = action) => {
        state.mailTemplates = payload.mailTemplates
        state.isLoading = false
      })

      .addCase(list.rejected, (state) => {
        state.isLoading = false
      })

      //mailTemplate info
      .addCase(mailTemplateInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        mailTemplateInfo.fulfilled,
        (state, { payload } = action) => {
          state.info = payload.info
          state.isLoading = false
        }
      )

      .addCase(mailTemplateInfo.rejected, (state) => {
        state.isLoading = false
      })

      //mailTemplate create
      .addCase(create.pending, (state) => {
        state.isLoading = true
      })
      .addCase(create.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(create.rejected, (state) => {
        state.isLoading = false
      })

      //mailTemplate update
      .addCase(update.pending, (state) => {
        state.isLoading = true
      })
      .addCase(update.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(update.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default mailTemplateSlice.reducer
