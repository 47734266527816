// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../../../services/axiosConfig'

// Fetch Patient Based Lab List
export const fetchLabList = createAsyncThunk(
  'patient/lab/list',
  async (patientId) => {
    const response = await axiosInstance.get(`patient/lab/list/${patientId}`)
    return response.data
  }
)

// Patient Based Lab Create
export const create = createAsyncThunk(
  'patient/lab/create',
  async ({ patientId, data }) => {
    const response = await axiosInstance.post(
      `patient/lab/create/${patientId}`,
      data
    )
    return response.data
  }
)

export const fetchLab = createAsyncThunk('patient/lab/view', async (labId) => {
  const response = await axiosInstance.get(`patient/lab/view/${labId}`)
  return response.data
})

// Patient Based Lab Create
export const updatePatientAccess = createAsyncThunk(
  'patient/lab/update-patient-access',
  async ({ labId, data }) => {
    const response = await axiosInstance.post(
      `patient/lab/update-patient-access/${labId}`,
      data
    )
    return response.data
  }
)

// sendFax
export const sendFax = createAsyncThunk(
  'patient/lab/send-fax',
  async ({ labId, data }) => {
    const response = await axiosInstance.post(
      `patient/lab/send-fax/${labId}`,
      data
    )
    return response.data
  }
)
// send Email
export const sendEmail = createAsyncThunk(
  'patient/lab/send-email',
  async ({ labId, data }) => {
    const response = await axiosInstance.post(
      `patient/lab/send-email/${labId}`,
      data
    )
    return response.data
  }
)

// Fetch Fax and Email log list by lab id
export const fetchFaxAndEmailLogList = createAsyncThunk(
  'patient/lab/fax-and-email-log-list',
  async (labId) => {
    const response = await axiosInstance.get(
      `patient/lab/fax-and-email-log-list/${labId}`
    )
    return response.data
  }
)

// Print trash (temporary delete it can be restored)
export const labTrash = createAsyncThunk('patient/lab/trash', async (labId) => {
  const response = await axiosInstance.get(`patient/lab/trash/${labId}`)
  return response.data
})

const labSlice = createSlice({
  name: 'lab',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    labs: [],
    labCategories: [],
    lab: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch  patient based lab list
      .addCase(fetchLabList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchLabList.fulfilled, (state, { payload } = action) => {
        state.labs = payload.labs
        state.labCategories = payload.labCategories
        state.isLoading = false
      })
      .addCase(fetchLabList.rejected, (state) => {
        state.isLoading = false
      })
      // Patient based lab  Create
      .addCase(create.pending, (state) => {
        state.isLoading = true
      })
      .addCase(create.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(create.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch  lab detail
      .addCase(fetchLab.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchLab.fulfilled, (state, { payload } = action) => {
        if (payload.error) {
          state.lab = null
        } else {
          state.lab = payload.lab
          state.isLoading = false
        }
      })
      .addCase(fetchLab.rejected, (state) => {
        state.isLoading = false
      })

      // fetch Fax and Email log list
      .addCase(fetchFaxAndEmailLogList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchFaxAndEmailLogList.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.faxLogs = payload.faxLogs
        state.emailLogs = payload.emailLogs
      })
      .addCase(fetchFaxAndEmailLogList.rejected, (state) => {
        state.isLoading = false
      })
      // Lab Trash
      .addCase(labTrash.pending, (state) => {
        state.isLoading = true
      })
      .addCase(labTrash.fulfilled, (state, action) => {
        state.message = action.payload.message
        state.isLoading = false
      })
      .addCase(labTrash.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default labSlice.reducer
