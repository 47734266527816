import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { FormLabel, Typography } from '@mui/material';

export default function CAutocomplete({
  label,
  name,
  required,
  multiple,
  value,
  options,
  getOptionLabel,
  onChange,
  isOptionEqualToValue,
  error,
  helperText,
  autoFocus,
  className,
  autoComplete,
  placeholder,
  variant,
  size,
  disabled,
  ...props
}) {
  return (
    <>
      <FormLabel htmlFor={`${label}${name}`}>
        {label}
        {required && (
          <Typography sx={{ display: 'inline', color: 'red' }}>*</Typography>
        )}
      </FormLabel>
      <Autocomplete
        id={`${label}${name}`}
        multiple={multiple}
        value={value}
        options={options}
        getOptionLabel={getOptionLabel}
        onChange={onChange}
        isOptionEqualToValue={isOptionEqualToValue}
        fullWidth
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            error={error}
            helperText={helperText}
            autoFocus={autoFocus}
            className={className}
            autoComplete={autoComplete}
            placeholder={placeholder}
            variant={variant}
            size={size || 'small'}
          />
        )}
        {...props}
      />
    </>
  );
}
