import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function UserDetails({ first_name, last_name, email,phone }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Typography sx={{color:"#8181eb",cursor:"pointer"}} onClick={handleOpen}>{first_name} {last_name}</Typography>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2" borderBottom={1}>
            User Info
          </Typography>
          <Stack borderBottom={0.2} p={1}>First Name: {first_name}</Stack>
          <Stack borderBottom={0.5} p={1}>Last Name: {last_name}</Stack>
          <Stack borderBottom={1} p={1}>Email: {email}</Stack>
          <Stack borderBottom={1} p={1}>Phone: {phone}</Stack>
        </Box>
      </Modal>
    </div>
  );
}
