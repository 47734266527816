// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '@/services/axiosConfig'

// Fetch Patient Based OrderingPhysician List
export const fetchFamilyTreeList = createAsyncThunk(
  'patient/family-tree/list',
  async (patientId) => {
    const response = await axiosInstance.get(
      `patient/family-tree/list/${patientId}`
    )
    return response.data
  }
)

export const create = createAsyncThunk(
  'patient/family-tree/create',
  async ({ patientId, data }) => {
    const response = await axiosInstance.post(
      `patient/family-tree/create/${patientId}`,
      data
    )
    return response.data
  }
)

export const changeFamilyTreeStatus = createAsyncThunk(
  'patient/family-tree/change-family-tree-status',
  async ({ familyTreeId, data }) => {
    const response = await axiosInstance.post(
      `patient/family-tree/change-family-tree-status/${familyTreeId}`,
      data
    )
    return response.data
  }
)

export const deleteFamilyTree = createAsyncThunk(
  'patient/family-tree/delete',
  async (familyTreeId) => {
    const response = await axiosInstance.post(
      `patient/family-tree/delete/${familyTreeId}`,
    )
    return response.data
  }
)

const familyTreeSlice = createSlice({
  name: 'family_tree',
  initialState: {
    isLoading: false,
    errors: '',
    message: '',
    userInfos: [],
    familyTrees: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Create Ordering Physician
      .addCase(create.pending, (state) => {
        state.isLoading = true
      })
      .addCase(create.fulfilled, (state, { payload }) => {
        if (payload.error) {
          state.message = payload.message
          state.errors = payload.errors
        } else {
          console.log(payload)
          state.message = payload.message
          state.errors = null
        }
        state.isLoading = false
      })
      .addCase(create.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch  Family tree list
      .addCase(fetchFamilyTreeList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchFamilyTreeList.fulfilled, (state, { payload }) => {
        state.familyTrees = payload.familyTrees
        state.userInfos = payload.userInfos
        state.isLoading = false
      })
      .addCase(fetchFamilyTreeList.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default familyTreeSlice.reducer
