import React, { useEffect } from 'react'
import { setBreadcrumb } from '@/redux/Slice/breadcrumbSlice'
import { Card, Box, Typography } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { useDispatch } from 'react-redux'

const DocumentList = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        { path: '/document/list', label: 'Document', isLast: true },
      ])
    )
  }, [dispatch])
  return (
    <>
      <Helmet>
        <title>Bioaro | Document - List</title>
      </Helmet>

      <Card sx={{ height: '80vh' }}>
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="h3" sx={{textAlign:'center'}}>
            Unfortunately, there are no document reviews available for you at
            this time.
          </Typography>
        </Box>
      </Card>
    </>
  )
}

export default DocumentList
