import React, { lazy, Suspense } from 'react'

const UserCreate = lazy(() => import('./UserCreate'))
const UserUpdate = lazy(() => import('./UserUpdate'))
const RoleBasedUserList = lazy(() => import('./RoleBasedUserList'))
const UserRoles = lazy(() => import('./UserRoles'))
const UserProfileUpdate = lazy(() => import('./UserProfileUpdate'))

const userRoutes = [
  { path: 'create', element: <UserCreate /> }, // Relative path
  { path: 'role-based-user-list/:roleId', element: <RoleBasedUserList /> },
  { path: 'update/:roleId/:userId', element: <UserUpdate /> },
  { path: 'profile-update/:userId', element: <UserProfileUpdate /> },
  { path: 'roles', element: <UserRoles /> },
]

export default userRoutes
