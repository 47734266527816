import {
  IconFileCertificate,
  IconReload,
  IconUserCheck,
  IconShoppingBagCheck,
  IconBox,
  IconCircleCheckFilled,
  IconShoppingBag,
} from '@tabler/icons-react'
import CStepper from '@/components/CStepper'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { trackOrder } from '../../Patient/Billing/billingSlice'
import CSkeleton from '@/components/CSkeleton'
import { Box, Card, Grid, Typography } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { setBreadcrumb } from '@/redux/Slice/breadcrumbSlice'

const icons = {
  0: <IconReload />,
  1: <IconUserCheck />,
  2: <IconShoppingBagCheck />,
  3: <IconBox />,
  4: <IconFileCertificate />,
  5: <IconCircleCheckFilled />,
  6: <IconShoppingBag />,
}

export default function TrackOrder() {
  const dispatch = useDispatch()
  const { paymentId } = useParams()
  const data = useSelector((state) => state.billing.data)
  const order = data?.order
  const steps = data?.steps

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        {
          path: '/financial/invoice-list',
          label: 'Financial',
          isLast: false,
        },
        { path: '', label: 'Track Order', isLast: true },
      ])
    )
  }, [dispatch])

  useEffect(() => {
    dispatch(trackOrder(paymentId))
  }, [])

  return (
    <>
      <Helmet>
        <title>Bioaro | Financial - Invoice list</title>
      </Helmet>
      <Box>
        {order ? (
          <>
            <Card>
              <Grid container justifyContent="space-between" sx={{ p: [0, 4] }}>
                <Grid item>
                  <Typography variant="h6" fontWeight="bold">
                    Order ID:
                  </Typography>
                  <Typography>{order.tracking_number}</Typography>
                </Grid>

                <Grid item>
                  <Typography variant="h6" fontWeight="bold">
                    Status:
                  </Typography>
                  <Typography>{order.status_name}</Typography>
                </Grid>

                <Grid item>
                  <Typography variant="h6" fontWeight="bold">
                    Address:
                  </Typography>
                  <Typography>{order.address}</Typography>
                </Grid>

                <Grid item>
                  <Typography variant="h6" fontWeight="bold">
                    Phone:
                  </Typography>
                  <Typography>{order.phone1}</Typography>
                </Grid>
              </Grid>
            </Card>

            <Card sx={{ display: 'flex', overflow: 'auto', mt: 4 }}>
              <CStepper
                icons={icons}
                steps={steps}
                activeStep={order.active_step || -1}
              />
            </Card>
          </>
        ) : (
          <>
            <CSkeleton variant="rectangular" height={236} />
          </>
        )}
      </Box>
    </>
  )
}
