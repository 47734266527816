// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../../../services/axiosConfig'

// Profile info
export const fetchProfileInfo = createAsyncThunk(
  'patient/profile/info',
  async (patientId) => {
    const response = await axiosInstance.get(
      `patient/profile/info/${patientId}`
    )
    return response.data
  }
)

// Update Profile
export const update = createAsyncThunk(
  'patient/profile/update',
  async ({ patientId, data }) => {
    const response = await axiosInstance.post(
      `patient/profile/update/${patientId}`,
      data
    )
    return response.data
  }
)

// Generate Exercise Plan
export const generateExercisePlan = createAsyncThunk(
  'patient/profile/generate-exercise-plan',
  async (patientId) => {
    const response = await axiosInstance.post(
      `patient/profile/generate-exercise-plan/${patientId}`
    )
    return response.data
  }
)

// Favorite Pharmacy list
export const fetchFavPharmacyList = createAsyncThunk(
  'patient/profile/favorite-pharmacy-list',
  async (patientId) => {
    const response = await axiosInstance.get(
      `patient/profile/favorite-pharmacy-list/${patientId}`
    )
    return response.data
  }
)

// Favorite Pharmacy create
export const createFavPharmacy = createAsyncThunk(
  'patient/profile/create-fav-pharmacy',
  async ({ patientId, data }) => {
    const response = await axiosInstance.post(
      `patient/profile/create-fav-pharmacy/${patientId}`,
      data
    )
    return response.data
  }
)

// Favorite Pharmacy status update
export const updateFavPharmacyStatus = createAsyncThunk(
  'patient/profile/update-fav-pharmacy-status',
  async ({ favPharmacyId, data }) => {
    const response = await axiosInstance.post(
      `patient/profile/update-fav-pharmacy-status/${favPharmacyId}`,
      data
    )
    return response.data
  }
)

export const setUnsetPackage = createAsyncThunk(
  'patient/profile/set-unset-package',
  async (data) => {
    const response = await axiosInstance.post(
      'patient/profile/set-unset-package',
      data
    )
    return response.data
  }
)

const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    isLoading: false,
    isGeneratedPlan: false,
    errors: '',
    data: null,
    pharmacyFavorites: [],
    pharmacies: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfileInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchProfileInfo.fulfilled, (state, { payload }) => {
        state.data = payload
        state.isLoading = false
      })
      .addCase(fetchProfileInfo.rejected, (state) => {
        state.isLoading = false
      })

      // Update Profile
      .addCase(update.pending, (state) => {
        state.isLoading = true
      })
      .addCase(update.fulfilled, (state, action) => {
        if (action.payload.error) {
          state.message = action.payload.message
          state.errors = action.payload.errors
        } else {
          state.message = action.payload.message
          state.errors = null
        }
        state.isLoading = false
      })
      .addCase(update.rejected, (state) => {
        state.isLoading = false
      })

      // Generate Exercise Plan
      .addCase(generateExercisePlan.pending, (state) => {
        state.isGeneratedPlan = true
      })
      .addCase(generateExercisePlan.fulfilled, (state, { payload }) => {
        state.data = payload
        state.isGeneratedPlan = false
      })
      .addCase(generateExercisePlan.rejected, (state) => {
        state.isGeneratedPlan = false
      })

      // Favorite Pharmacy list
      .addCase(fetchFavPharmacyList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchFavPharmacyList.fulfilled, (state, { payload }) => {
        state.pharmacyFavorites = payload.pharmacyFavorites
        state.pharmacies = payload.pharmacies
        state.isLoading = false
      })
      .addCase(fetchFavPharmacyList.rejected, (state) => {
        state.isLoading = false
      })

      // Create Fav Pharmacy
      .addCase(createFavPharmacy.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createFavPharmacy.fulfilled, (state, action) => {
        if (action.payload.error) {
          state.message = action.payload.message
          state.errors = action.payload.errors
        } else {
          state.message = action.payload.message
          state.errors = null
        }
        state.isLoading = false
      })
      .addCase(createFavPharmacy.rejected, (state) => {
        state.isLoading = false
      })

      // set unset package
      .addCase(setUnsetPackage.pending, (state) => {
        state.isLoading = true
      })
      .addCase(setUnsetPackage.fulfilled, (state, action) => {
        if (action.payload.error) {
          state.message = action.payload.message
          state.errors = action.payload.errors
        } else {
          state.message = action.payload.message
          state.errors = null
        }
        state.isLoading = false
      })
      .addCase(setUnsetPackage.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default profileSlice.reducer
