// // UserList.jsx

// Icon
import AddIcon from '@mui/icons-material/Add'
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
// Components
import AdminLayout from '@/layouts/AdminLayout'

// Slice
import { setBreadcrumb } from '@/redux/Slice/breadcrumbSlice'
import { Link, useNavigate } from 'react-router-dom'
import CButton from '@/components/CButton'
import CDatatable from '@/components/CDatatable'
import { changeStatus, deleteRecord } from '@/redux/Slice/commonSlice'
import { showError, showSuccess } from '@/redux/Slice/alertSlice'
import CTextField from '@/components/CTextField'
import SaveIcon from '@mui/icons-material/Save'
import { Helmet } from 'react-helmet-async'
import { useEffect, useState } from 'react'
import { create, list } from '../paidConsultationSlice'
import { useDispatch, useSelector } from 'react-redux'
import CAutocomplete from '@/components/CAutocomplete'

export default function PaidConsultationCreate() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { doctors, categories, errors, error, isLoading } = useSelector(
    (state) => state.paidConsultation
  )

  useEffect(() => {
    dispatch(list())
  }, [])

  //   initial create value
  const [consultation, setConsultation] = useState({
    name: '',
    price: '',
    duration: '',
    advance_payment: '',
    category: '',
    doctor: [],
  })

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const res = await dispatch(create(consultation))

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )

        navigate('/paid-consultation/list')
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Server error:', error)
      dispatch(
        showError({
          error: true,
          message:
            'An error occurred while Creating paid consultation service.',
        })
      )
    }
  }

  // onChange handler to update the local state
  const handleInputChange = (event) => {
    const { name, value } = event.target
    // Update the local state with the changed value
    setConsultation((consultation) => ({
      ...consultation,
      [name]: value,
    }))
  }

  return (
    <>
      <Helmet>
        <title>Paid Consultation Create</title>
      </Helmet>
      <Stack alignItems="center">
        <Card sx={{ p: 3, width: '50rem' }}>
          <form onSubmit={handleSubmit}>
            <Stack direction="row" justifyContent="space-between" mb={2}>
              <Typography variant="h5">Paid Consultation Create</Typography>{' '}
              <CButton label="Save" icon={<SaveIcon />} type="submit" />
            </Stack>

            <Grid container spacing={2} alignItems="center">
              <Grid xs={12} m={1}>
                <CTextField
                  label="Name"
                  name="name"
                  value={consultation?.name}
                  error={errors && errors.name}
                  helperText={errors && errors.name}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid xs={12} m={1}>
                <CTextField
                  label="Price"
                  name="price"
                  value={consultation?.price}
                  error={errors && errors.price}
                  helperText={errors && errors.price}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid xs={12} m={1}>
                <CTextField
                  label="Advance Price"
                  name="advance_payment"
                  value={consultation?.advance_payment}
                  error={errors && errors.advance_payment}
                  helperText={errors && errors.advance_payment}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid xs={12} m={1}>
                <CTextField
                  label="Duration"
                  name="duration"
                  value={consultation?.duration}
                  error={errors && errors.duration}
                  helperText={errors && errors.duration}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid xs={12} m={1}>
                <CAutocomplete
                  options={doctors}
                  getOptionLabel={(option) => option.full_name}
                  value={consultation?.doctor}
                  onChange={(e, value) =>
                    setConsultation({
                      ...consultation,
                      doctor: value,
                    })
                  }
                  label="Doctors"
                  multiple
                  error={!!(errors && errors.doctor)}
                  helperText={errors && errors.doctor}
                />
              </Grid>

              <Grid xs={12} m={1}>
                <CAutocomplete
                  options={categories || []}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, value) =>
                    // console.log(value)
                    setConsultation({
                      ...consultation,
                      category: value,
                    })
                  }
                  label="Category"
                  error={!!(errors && errors.category)}
                  helperText={errors && errors.category}
                />
              </Grid>
            </Grid>
          </form>
        </Card>
      </Stack>
    </>
  )
}
