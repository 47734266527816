import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

export default function CHorizontalWidget({
  subTitle,
  title,
  icon,
  titleColor,
  subTitleColor = 'text.disabled',
  sx,
  subTitleSx,
  ...other
}) {
  return (
    <Card
      component={Stack}
      spacing={.2}
      direction="row"
      alignItems="center"
      sx={{
        px: 2,
        py: 2,
        borderRadius: 2,
        ...sx,
      }}
      {...other}
    >
      {icon && <Box sx={{ width: 64, height: 44 }}>{icon}</Box>}

      <Stack spacing={0.5}>
        <Typography variant="h4" fontWeight="bold" color={titleColor}>
          {title}
        </Typography>

        <Typography variant="subtitle2" color={subTitleColor} sx={subTitleSx}>
          {subTitle}
        </Typography>
      </Stack>
    </Card>
  )
}
