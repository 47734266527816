// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '@/services/axiosConfig'

// Fetch Patient Based OrderingPhysician List
export const fetchOPList = createAsyncThunk(
  'patient/ordering-physician/list',
  async (patientId) => {
    const response = await axiosInstance.get(
      `patient/ordering-physician/list/${patientId}`
    )
    return response.data
  }
)

export const create = createAsyncThunk(
  'patient/ordering-physician/create',
  async ({ patientId, data }) => {
    const response = await axiosInstance.post(
      `patient/ordering-physician/create/${patientId}`,
      data
    )
    return response.data
  }
)

// export const fetchMessageDetail = createAsyncThunk(
//   "patient/message/detail",
//   async (messageId) => {
//     const response = await axiosInstance.get(`patient/message/detail/${messageId}`);
//     console.log(response.data)
//     return response.data;
//   }
// );

const opSlice = createSlice({
  name: 'ordering_physician',
  initialState: {
    isLoading: false,
    errors: '',
    message: '',
    orderingPhysicians: [],
    referralDoctors: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Create Ordering Physician
      .addCase(create.pending, (state) => {
        state.isLoading = true
      })
      .addCase(create.fulfilled, (state, { payload }) => {
        if (payload.error) {
          state.message = payload.message
          state.errors = payload.errors
        } else {
          console.log(payload)
          state.message = payload.message
          state.errors = null
        }
        state.isLoading = false
      })
      .addCase(create.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch  patient based ordering physician list
      .addCase(fetchOPList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchOPList.fulfilled, (state, { payload }) => {
        state.orderingPhysicians = payload.orderingPhysicians
        state.referralDoctors = payload.referralDoctors
        state.isLoading = false
      })
      .addCase(fetchOPList.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default opSlice.reducer
