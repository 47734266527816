import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'

import {
  Box,
  Button,
  Card,
  Chip,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material'

import blueLogo from '@/assets/images/logos/blueLogo.png'
import PrintIcon from '@mui/icons-material/Print'

import { cdownloadHtml2Pdf } from '@/utils/cfile'

const BillingInvoice = () => {
  const contentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
  })
  // const contentRef = useRef()
  const handleDownloadPDF = () => {
    cdownloadHtml2Pdf(contentRef)
  }

  return (
    <Box
      sx={{
        width: '21cm',
        minHeight: '29.7cm',
        mx: 'auto',
        mt: 5,
        backgroundColor: 'white',
        boxShadow: '0 0 10px rgba(0,0,0,.1)',
      }}
    >
      <Box sx={{ p: 3 }} ref={contentRef}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <img src={blueLogo} alt="Logo" />
          <Typography>Invoice</Typography>
        </Stack>
        <Divider sx={{ mt: 2 }} />

        <Box sx={{ p: 2, my: 4, backgroundColor: 'grey.100' }}>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item sx={{ width: '6cm' }}>
              <Typography variant="h6" fontWeight="bold">
                From : BioAro inc.
              </Typography>
              <Typography>
                Suite 1020 Calgary Place Tower 1, 330 – 5th Avenue SW Calgary,
                AB, T2P 0C3
              </Typography>
              <Typography>330 – 5th Avenue SW</Typography>
              <Typography>Calgary, AB, T2P 0C3 </Typography>
            </Grid>

            <Grid item sx={{ width: '6cm' }}>
              <Typography variant="h6" fontWeight="bold">
                To : Raman Kapoor
              </Typography>
              <Typography>Phone : </Typography>
              <Typography>Email : kapoor_raman@yopmail.com </Typography>
            </Grid>

            <Grid item sx={{ width: '6cm', justifyContent: 'flex-end' }}>
              <Typography variant="h6" fontWeight="bold">
                Invoice : #293016
              </Typography>
              <Typography>Issue Date : 01 Feb, 2024</Typography>
              <Typography>GST : 796630945RT000 </Typography>
              <Typography>
                Status :{' '}
                <Typography
                  variant="caption"
                  sx={{
                    display: 'inline',
                    backgroundColor: 'warning.main',
                    px: 0.4,
                  }}
                >
                  Paid
                </Typography>{' '}
                (stripe)
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ backgroundColor: 'primary.main', color: 'text.white', p: 2 }}
        >
          <Typography>Description</Typography>
          <Typography>Amount</Typography>
        </Stack>

        <Box sx={{ p: 2 }}>
          <Typography variant="h6" fontWeight="bold">
            Raman Kapoor
          </Typography>

          <Stack direction="row" justifyContent="space-between">
            <Typography color="primary">
              Large Intestine (Gut) Microbiome
            </Typography>
            <Typography fontWeight="bold">450.00 USD</Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Typography color="primary">
              Large Intestine (Gut) Microbiome
            </Typography>
            <Typography fontWeight="bold">0.00 USD</Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Typography fontWeight="bold">Package Price:</Typography>
            <Typography fontWeight="bold">450.00 USD</Typography>
          </Stack>

          <Divider sx={{ my: 1 }} />
          <Stack direction="row" justifyContent="space-between">
            <Typography fontWeight="bold">SubTotal:</Typography>
            <Typography fontWeight="bold">450.00 USD</Typography>
          </Stack>

          <Divider sx={{ my: 1 }} />
          <Stack direction="row" justifyContent="space-between">
            <Typography fontWeight="bold">Total:</Typography>
            <Typography fontWeight="bold">450.00 USD</Typography>
          </Stack>

          <Divider sx={{ my: 1 }} />
          <Stack direction="row" justifyContent="space-between">
            <Typography fontWeight="bold">GST (5%):</Typography>
            <Typography fontWeight="bold">450.00 USD</Typography>
          </Stack>
        </Box>
      </Box>

      {/* Action Button  */}
      <Stack direction="row" spacing={1} justifyContent="end" mt={1} p={3}>
        <Button variant="outlined" onClick={handleDownloadPDF}>
          Download PDF
        </Button>
        <Button
          variant="outlined"
          startIcon={<PrintIcon />}
          onClick={handlePrint}
        >
          Print
        </Button>
      </Stack>
    </Box>
  )
}

export default BillingInvoice
