import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Divider, IconButton, Stack, Typography } from '@mui/material'
import CButton from './CButton'

export default function CDialog({
  children,
  btnTitle,
  headerTitle,
  fullWidth,
  iconButton = false,
  icon,
  size,
  handleCDialogOpen,
  handleCDialogClose,
  open,
  color,
  variant,
  sx,
}) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <>
      {iconButton ? (
        <IconButton color="primary" size={size} onClick={handleCDialogOpen}>
          {icon}
        </IconButton>
      ) : (
        btnTitle && (
          <CButton
            fullWidth={fullWidth}
            size={size}
            onClick={handleCDialogOpen}
            icon={icon}
            label={btnTitle}
            color={color}
            variant={variant}
            sx={sx}
          />
        )
      )}

      <Dialog
        fullScreen={fullScreen}
        maxWidth="lg"
        open={open}
        onClose={handleCDialogClose}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: 1,
            // bgcolor: 'primary.main',
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            {headerTitle}
          </Typography>
          <IconButton size="large" onClick={handleCDialogClose}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </Box>
        <Divider />
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </>
  )
}
