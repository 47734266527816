import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Divider } from '@mui/material'
import ChatHeader from './Partials/ChatHeader'
import MessageInput from './Partials/MessageInput'
import MessageList from './Partials/MessageList'
import Sidebar from './Partials/Sidebar'
import ReplyInput from './Partials/ReplyInput'
import { io } from 'socket.io-client'

import { setOnlineUsers } from './chatSlice'
import { setBreadcrumb } from '@/redux/Slice/breadcrumbSlice'
import { Helmet } from 'react-helmet-async'
const socketURL = import.meta.env.VITE_SOCKET_URL
const socket = io(socketURL)

// const socket = io(socketURL, {
//   transports: ['websocket'],
//   path: '/socket.io',
// })

const Chat = () => {
  const dispatch = useDispatch()
  const [tabValue, setTabValue] = useState(0)
  const user = useSelector((state) => state.auth.user)

  const { receiver, isReply } = useSelector((state) => state.chat)

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(setBreadcrumb([{ path: '/chat', label: 'Chat', isLast: true }]))
  }, [dispatch])

  useEffect(() => {
    function handleAddUser() {
      if (user) {
        socket.emit('addUser', user)
      }
    }

    socket.on('getOnlineUsers', (onlineUsers) => {
      dispatch(setOnlineUsers(onlineUsers))
    })

    handleAddUser()

    return () => {
      socket.off('getOnlineUsers')
    }
  }, [user]) // Include dependencies

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  useEffect(() => {}, [receiver])

  return (
    <>
      <Helmet>
        <title>Bioaro | Chat</title>
      </Helmet>

      <Box sx={{ display: 'flex', height: '81vh', backgroundColor: '#fff' }}>
        <Sidebar tabValue={tabValue} handleTabChange={handleTabChange} />

        {receiver && (
          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            <ChatHeader />
            <MessageList socket={socket} />
            <Divider />

            {isReply ? (
              <ReplyInput socket={socket} />
            ) : (
              <MessageInput socket={socket} />
            )}
          </Box>
        )}
      </Box>
    </>
  )
}

export default Chat
