import React from 'react'
import { Route, Routes } from 'react-router-dom';
import CategoryList from './partial/CategoryList';
import CategoryCreate from './partial/CategoryCreate';
import CategoryUpdate from './partial/CategoryUpdate';

const categoryRoutes = [
  { path: "", element: <CategoryList /> },
  { path: "create/", element: <CategoryCreate /> },
  { path: "update/:categoryId", element: <CategoryUpdate /> }
];

export default categoryRoutes;

