import React, { useEffect, useState } from 'react'
import {
  Box,
  Grid,
  Typography,
  Checkbox,
  TextField,
  Button,
  FormControlLabel,
  FormGroup,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material'

import t18 from '@/assets/images/dental/teeth/18.png'
import t17 from '@/assets/images/dental/teeth/17.png'
import t16 from '@/assets/images/dental/teeth/16.png'
import t15 from '@/assets/images/dental/teeth/15.png'
import t14 from '@/assets/images/dental/teeth/14.png'
import t13 from '@/assets/images/dental/teeth/13.png'
import t12 from '@/assets/images/dental/teeth/12.png'
import t11 from '@/assets/images/dental/teeth/11.png'
import t21 from '@/assets/images/dental/teeth/21.png'
import t22 from '@/assets/images/dental/teeth/22.png'
import t23 from '@/assets/images/dental/teeth/23.png'
import t24 from '@/assets/images/dental/teeth/24.png'
import t25 from '@/assets/images/dental/teeth/25.png'
import t26 from '@/assets/images/dental/teeth/26.png'
import t27 from '@/assets/images/dental/teeth/27.png'
import t28 from '@/assets/images/dental/teeth/28.png'

import t48 from '@/assets/images/dental/teeth/48.png'
import t47 from '@/assets/images/dental/teeth/47.png'
import t46 from '@/assets/images/dental/teeth/46.png'
import t45 from '@/assets/images/dental/teeth/45.png'
import t44 from '@/assets/images/dental/teeth/44.png'
import t43 from '@/assets/images/dental/teeth/43.png'
import t42 from '@/assets/images/dental/teeth/42.png'
import t41 from '@/assets/images/dental/teeth/41.png'
import t31 from '@/assets/images/dental/teeth/31.png'
import t32 from '@/assets/images/dental/teeth/32.png'
import t33 from '@/assets/images/dental/teeth/33.png'
import t34 from '@/assets/images/dental/teeth/34.png'
import t35 from '@/assets/images/dental/teeth/35.png'
import t36 from '@/assets/images/dental/teeth/36.png'
import t37 from '@/assets/images/dental/teeth/37.png'
import t38 from '@/assets/images/dental/teeth/38.png'

import crown from '@/assets/images/dental/crown/crown.png'

import TreatmentDetails from './Partials/TreatmentDetails'
import Teeth from './Partials/Teeth'
import { useDispatch, useSelector } from 'react-redux'
import { dentalChartDetail } from './dentalChartSlice'
import { useParams } from 'react-router-dom'

const upperTeeth = [
  {
    toothType: 'upperTeeth',
    note: '',
    toothNumber: 18,
    image: t18,
    crown: crown,
    details: {
      buccal: false,
      lingual: false,
      occlusal: false,
      mesial: false,
      distal: false,

      missing: false,
      implant: false,

      abutment: false,
      pontic: false,

      rct: false,
      postCore: false,

      veneers: false,
      laminates: false,
      fullCrowns: false,

      note: '',
    },
  },
  {
    toothType: 'upperTeeth',
    note: '',
    toothNumber: 17,
    image: t17,
    crown: crown,
    details: {
      buccal: false,
      lingual: false,
      occlusal: false,
      mesial: false,
      distal: false,

      missing: false,
      implant: false,

      abutment: false,
      pontic: false,

      rct: false,
      postCore: false,

      veneers: false,
      laminates: false,
      fullCrowns: false,

      note: '',
    },
  },
  {
    toothType: 'upperTeeth',
    note: '',
    toothNumber: 16,
    image: t16,
    crown: crown,
    details: {
      buccal: false,
      lingual: false,
      occlusal: false,
      mesial: false,
      distal: false,

      missing: false,
      implant: false,

      abutment: false,
      pontic: false,

      rct: false,
      postCore: false,

      veneers: false,
      laminates: false,
      fullCrowns: false,

      note: '',
    },
  },
  {
    toothType: 'upperTeeth',
    note: '',
    toothNumber: 15,
    image: t15,
    crown: crown,
    details: {
      buccal: false,
      lingual: false,
      occlusal: false,
      mesial: false,
      distal: false,

      missing: false,
      implant: false,

      abutment: false,
      pontic: false,

      rct: false,
      postCore: false,

      veneers: false,
      laminates: false,
      fullCrowns: false,

      note: '',
    },
  },
  {
    toothType: 'upperTeeth',
    note: '',
    toothNumber: 14,
    image: t14,
    crown: crown,
    details: {
      buccal: false,
      lingual: false,
      occlusal: false,
      mesial: false,
      distal: false,

      missing: false,
      implant: false,

      abutment: false,
      pontic: false,

      rct: false,
      postCore: false,

      veneers: false,
      laminates: false,
      fullCrowns: false,

      note: '',
    },
  },
  {
    toothType: 'upperTeeth',
    note: '',
    toothNumber: 13,
    image: t13,
    crown: crown,
    details: {
      buccal: false,
      lingual: false,
      occlusal: false,
      mesial: false,
      distal: false,

      missing: false,
      implant: false,

      abutment: false,
      pontic: false,

      rct: false,
      postCore: false,

      veneers: false,
      laminates: false,
      fullCrowns: false,

      note: '',
    },
  },
  {
    toothType: 'upperTeeth',
    note: '',
    toothNumber: 12,
    image: t12,
    crown: crown,
    details: {
      buccal: false,
      lingual: false,
      occlusal: false,
      mesial: false,
      distal: false,

      missing: false,
      implant: false,

      abutment: false,
      pontic: false,

      rct: false,
      postCore: false,

      veneers: false,
      laminates: false,
      fullCrowns: false,

      note: '',
    },
  },
  {
    toothType: 'upperTeeth',
    note: '',
    toothNumber: 11,
    image: t11,
    crown: crown,
    details: {
      buccal: false,
      lingual: false,
      occlusal: false,
      mesial: false,
      distal: false,

      missing: false,
      implant: false,

      abutment: false,
      pontic: false,

      rct: false,
      postCore: false,

      veneers: false,
      laminates: false,
      fullCrowns: false,

      note: '',
    },
  },
  {
    toothType: 'upperTeeth',
    note: '',
    toothNumber: 21,
    image: t21,
    crown: crown,
    details: {
      buccal: false,
      lingual: false,
      occlusal: false,
      mesial: false,
      distal: false,

      missing: false,
      implant: false,

      abutment: false,
      pontic: false,

      rct: false,
      postCore: false,

      veneers: false,
      laminates: false,
      fullCrowns: false,

      note: '',
    },
  },
  {
    toothType: 'upperTeeth',
    note: '',
    toothNumber: 22,
    image: t22,
    crown: crown,
    details: {
      buccal: false,
      lingual: false,
      occlusal: false,
      mesial: false,
      distal: false,

      missing: false,
      implant: false,

      abutment: false,
      pontic: false,

      rct: false,
      postCore: false,

      veneers: false,
      laminates: false,
      fullCrowns: false,

      note: '',
    },
  },
  {
    toothType: 'upperTeeth',
    note: '',
    toothNumber: 23,
    image: t23,
    crown: crown,
    details: {
      buccal: false,
      lingual: false,
      occlusal: false,
      mesial: false,
      distal: false,

      missing: false,
      implant: false,

      abutment: false,
      pontic: false,

      rct: false,
      postCore: false,

      veneers: false,
      laminates: false,
      fullCrowns: false,

      note: '',
    },
  },
  {
    toothType: 'upperTeeth',
    note: '',
    toothNumber: 24,
    image: t24,
    crown: crown,
    details: {
      buccal: false,
      lingual: false,
      occlusal: false,
      mesial: false,
      distal: false,

      missing: false,
      implant: false,

      abutment: false,
      pontic: false,

      rct: false,
      postCore: false,

      veneers: false,
      laminates: false,
      fullCrowns: false,

      note: '',
    },
  },
  {
    toothType: 'upperTeeth',
    note: '',
    toothNumber: 25,
    image: t25,
    crown: crown,
    details: {
      buccal: false,
      lingual: false,
      occlusal: false,
      mesial: false,
      distal: false,

      missing: false,
      implant: false,

      abutment: false,
      pontic: false,

      rct: false,
      postCore: false,

      veneers: false,
      laminates: false,
      fullCrowns: false,

      note: '',
    },
  },
  {
    toothType: 'upperTeeth',
    note: '',
    toothNumber: 26,
    image: t26,
    crown: crown,
    details: {
      buccal: false,
      lingual: false,
      occlusal: false,
      mesial: false,
      distal: false,

      missing: false,
      implant: false,

      abutment: false,
      pontic: false,

      rct: false,
      postCore: false,

      veneers: false,
      laminates: false,
      fullCrowns: false,

      note: '',
    },
  },
  {
    toothType: 'upperTeeth',
    note: '',
    toothNumber: 27,
    image: t27,
    crown: crown,
    details: {
      buccal: false,
      lingual: false,
      occlusal: false,
      mesial: false,
      distal: false,

      missing: false,
      implant: false,

      abutment: false,
      pontic: false,

      rct: false,
      postCore: false,

      veneers: false,
      laminates: false,
      fullCrowns: false,

      note: '',
    },
  },
  {
    toothType: 'upperTeeth',
    note: '',
    toothNumber: 28,
    image: t28,
    crown: crown,
    details: {
      buccal: false,
      lingual: false,
      occlusal: false,
      mesial: false,
      distal: false,

      missing: false,
      implant: false,

      abutment: false,
      pontic: false,

      rct: false,
      postCore: false,

      veneers: false,
      laminates: false,
      fullCrowns: false,

      note: '',
    },
  },
]

const lowerTeeth = [
  {
    toothType: 'lowerTeeth',
    note: '',
    toothNumber: 48,
    image: t48,
    crown: crown,
    details: {
      buccal: false,
      lingual: false,
      occlusal: false,
      mesial: false,
      distal: false,

      missing: false,
      implant: false,

      abutment: false,
      pontic: false,

      rct: false,
      postCore: false,

      veneers: false,
      laminates: false,
      fullCrowns: false,

      note: '',
    },
  },
  {
    toothType: 'lowerTeeth',
    note: '',
    toothNumber: 47,
    image: t47,
    crown: crown,
    details: {
      buccal: false,
      lingual: false,
      occlusal: false,
      mesial: false,
      distal: false,

      missing: false,
      implant: false,

      abutment: false,
      pontic: false,

      rct: false,
      postCore: false,

      veneers: false,
      laminates: false,
      fullCrowns: false,

      note: '',
    },
  },
  {
    toothType: 'lowerTeeth',
    note: '',
    toothNumber: 46,
    image: t46,
    crown: crown,
    details: {
      buccal: false,
      lingual: false,
      occlusal: false,
      mesial: false,
      distal: false,

      missing: false,
      implant: false,

      abutment: false,
      pontic: false,

      rct: false,
      postCore: false,

      veneers: false,
      laminates: false,
      fullCrowns: false,

      note: '',
    },
  },
  {
    toothType: 'lowerTeeth',
    note: '',
    toothNumber: 45,
    image: t45,
    crown: crown,
    details: {
      buccal: false,
      lingual: false,
      occlusal: false,
      mesial: false,
      distal: false,

      missing: false,
      implant: false,

      abutment: false,
      pontic: false,

      rct: false,
      postCore: false,

      veneers: false,
      laminates: false,
      fullCrowns: false,

      note: '',
    },
  },
  {
    toothType: 'lowerTeeth',
    note: '',
    toothNumber: 44,
    image: t44,
    crown: crown,
    details: {
      buccal: false,
      lingual: false,
      occlusal: false,
      mesial: false,
      distal: false,

      missing: false,
      implant: false,

      abutment: false,
      pontic: false,

      rct: false,
      postCore: false,

      veneers: false,
      laminates: false,
      fullCrowns: false,

      note: '',
    },
  },
  {
    toothType: 'lowerTeeth',
    note: '',
    toothNumber: 43,
    image: t43,
    crown: crown,
    details: {
      buccal: false,
      lingual: false,
      occlusal: false,
      mesial: false,
      distal: false,

      missing: false,
      implant: false,

      abutment: false,
      pontic: false,

      rct: false,
      postCore: false,

      veneers: false,
      laminates: false,
      fullCrowns: false,

      note: '',
    },
  },
  {
    toothType: 'lowerTeeth',
    note: '',
    toothNumber: 42,
    image: t42,
    crown: crown,
    details: {
      buccal: false,
      lingual: false,
      occlusal: false,
      mesial: false,
      distal: false,

      missing: false,
      implant: false,

      abutment: false,
      pontic: false,

      rct: false,
      postCore: false,

      veneers: false,
      laminates: false,
      fullCrowns: false,

      note: '',
    },
  },
  {
    toothType: 'lowerTeeth',
    note: '',
    toothNumber: 41,
    image: t41,
    crown: crown,
    details: {
      buccal: false,
      lingual: false,
      occlusal: false,
      mesial: false,
      distal: false,

      missing: false,
      implant: false,

      abutment: false,
      pontic: false,

      rct: false,
      postCore: false,

      veneers: false,
      laminates: false,
      fullCrowns: false,

      note: '',
    },
  },
  {
    toothType: 'lowerTeeth',
    note: '',
    toothNumber: 31,
    image: t31,
    crown: crown,
    details: {
      buccal: false,
      lingual: false,
      occlusal: false,
      mesial: false,
      distal: false,

      missing: false,
      implant: false,

      abutment: false,
      pontic: false,

      rct: false,
      postCore: false,

      veneers: false,
      laminates: false,
      fullCrowns: false,

      note: '',
    },
  },
  {
    toothType: 'lowerTeeth',
    note: '',
    toothNumber: 32,
    image: t32,
    crown: crown,
    details: {
      buccal: false,
      lingual: false,
      occlusal: false,
      mesial: false,
      distal: false,

      missing: false,
      implant: false,

      abutment: false,
      pontic: false,

      rct: false,
      postCore: false,

      veneers: false,
      laminates: false,
      fullCrowns: false,

      note: '',
    },
  },
  {
    toothType: 'lowerTeeth',
    note: '',
    toothNumber: 33,
    image: t33,
    crown: crown,
    details: {
      buccal: false,
      lingual: false,
      occlusal: false,
      mesial: false,
      distal: false,

      missing: false,
      implant: false,

      abutment: false,
      pontic: false,

      rct: false,
      postCore: false,

      veneers: false,
      laminates: false,
      fullCrowns: false,

      note: '',
    },
  },
  {
    toothType: 'lowerTeeth',
    note: '',
    toothNumber: 34,
    image: t34,
    crown: crown,
    details: {
      buccal: false,
      lingual: false,
      occlusal: false,
      mesial: false,
      distal: false,

      missing: false,
      implant: false,

      abutment: false,
      pontic: false,

      rct: false,
      postCore: false,

      veneers: false,
      laminates: false,
      fullCrowns: false,

      note: '',
    },
  },
  {
    toothType: 'lowerTeeth',
    note: '',
    toothNumber: 35,
    image: t35,
    crown: crown,
    details: {
      buccal: false,
      lingual: false,
      occlusal: false,
      mesial: false,
      distal: false,

      missing: false,
      implant: false,

      abutment: false,
      pontic: false,

      rct: false,
      postCore: false,

      veneers: false,
      laminates: false,
      fullCrowns: false,

      note: '',
    },
  },
  {
    toothType: 'lowerTeeth',
    note: '',
    toothNumber: 36,
    image: t36,
    crown: crown,
    details: {
      buccal: false,
      lingual: false,
      occlusal: false,
      mesial: false,
      distal: false,

      missing: false,
      implant: false,

      abutment: false,
      pontic: false,

      rct: false,
      postCore: false,

      veneers: false,
      laminates: false,
      fullCrowns: false,

      note: '',
    },
  },
  {
    toothType: 'lowerTeeth',
    note: '',
    toothNumber: 37,
    image: t37,
    crown: crown,
    details: {
      buccal: false,
      lingual: false,
      occlusal: false,
      mesial: false,
      distal: false,

      missing: false,
      implant: false,

      abutment: false,
      pontic: false,

      rct: false,
      postCore: false,

      veneers: false,
      laminates: false,
      fullCrowns: false,

      note: '',
    },
  },
  {
    toothType: 'lowerTeeth',
    note: '',
    toothNumber: 38,
    image: t38,
    crown: crown,
    details: {
      buccal: false,
      lingual: false,
      occlusal: false,
      mesial: false,
      distal: false,

      missing: false,
      implant: false,

      abutment: false,
      pontic: false,

      rct: false,
      postCore: false,

      veneers: false,
      laminates: false,
      fullCrowns: false,

      note: '',
    },
  },
]

const DentalChart = () => {
  const dispatch = useDispatch()
  const { patientId } = useParams()
  
  const [selectedTooth, setSelectedTooth] = useState(null)
  const [dentalChart, setDentalChart] = useState({
    upperTeeth: upperTeeth,
    lowerTeeth: lowerTeeth,
  })

  useEffect(() => {
    dispatch(dentalChartDetail(patientId)).then((response) =>
      response.payload.dentalChart
        ? setDentalChart(response.payload.dentalChart)
        : setDentalChart(dentalChart)
    )
  }, [])

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Teeth
          dentalChart={dentalChart}
          selectedTooth={selectedTooth}
          setSelectedTooth={setSelectedTooth}
        />
      </Grid>
      <Grid item md={5} xs={12}>
        <TreatmentDetails
          selectedTooth={selectedTooth}
          setDentalChart={setDentalChart}
          setSelectedTooth={setSelectedTooth}
          dentalChart={dentalChart}
        />
      </Grid>
    </Grid>
  )
}

export default DentalChart