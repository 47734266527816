// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '@/services/axiosConfig'

// Fetch seo list
export const list = createAsyncThunk('website/seo/list', async () => {
  const response = await axiosInstance.get('website/seo/list')
  return response.data
})

// Fetch seo info
export const seoInfo = createAsyncThunk(
  'website/seo/info',
  async (seoId) => {
    const response = await axiosInstance.get(`website/seo/info/${seoId}`)
    console.log(response.data)
    return response.data
  }
)

// Fetch seo list
export const create = createAsyncThunk('website/seo/create', async (data) => {
  const response = await axiosInstance.post('website/seo/create', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data
})

// Fetch seo list
export const update = createAsyncThunk('website/seo/update', async (data) => {
  const response = await axiosInstance.post('website/seo/update', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data
})



const seoSlice = createSlice({
  name: 'websiteSeo',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    seos: [],
    info: [],
    content:'',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      //seo List
      .addCase(list.pending, (state) => {
        state.isLoading = true
      })
      .addCase(list.fulfilled, (state, { payload } = action) => {
        state.seos = payload.seos
        state.isLoading = false
      })

      .addCase(list.rejected, (state) => {
        state.isLoading = false
      })

      //seo info
      .addCase(seoInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(seoInfo.fulfilled, (state, { payload } = action) => {
        state.info = payload.info
        state.isLoading = false
      })

      .addCase(seoInfo.rejected, (state) => {
        state.isLoading = false
      })

      //seo create
      .addCase(create.pending, (state) => {
        state.isLoading = true
      })
      .addCase(create.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(create.rejected, (state) => {
        state.isLoading = false
      })

      //seo update
      .addCase(update.pending, (state) => {
        state.isLoading = true
      })
      .addCase(update.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(update.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default seoSlice.reducer
