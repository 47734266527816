import React from 'react'
import { Route, Routes } from 'react-router-dom'
import DocumentResend from './DocumentResend'
import DocumentResendCreate from './partial/DocumentResendCreate'
import DocumentResendUpdate from './partial/DocumentResendUpdate'

const documentResendRoutes = [
  { path: "list", element: <DocumentResend /> },
  { path: "create", element: <DocumentResendCreate /> },
  { path: "update/:documentId", element: <DocumentResendUpdate /> }
];

export default documentResendRoutes;
