import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import AdminTopbar from '../parts/admin/topbar/AdminTopbar'
import AdminSidebar from '../parts/admin/sidebar/AdminSidebar'
import CCircularProgress from '@/components/CCircularProgress'
import authCheck from '@/middlewares/authCheck'
import MainComponent from './UserSession/MainComponent'
import permissionCheck from '@/middlewares/permissionCheck'
import securityAnswerCheck from '@/middlewares/securityAnswerCheck'

export default function AdminLayout() {
  const isAuthenticated = authCheck()
  const location = useLocation()

  securityAnswerCheck()
  permissionCheck()

  return isAuthenticated ? (
    <Box sx={{ display: 'flex' }}>
      <AdminSidebar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          mt: 7,
          overflow: 'auto',
        }}
      >
        <AdminTopbar />
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
          <MainComponent />
          <React.Suspense fallback={<CCircularProgress color="inherit" />}>
            <Outlet />
          </React.Suspense>
        </Container>
      </Box>
    </Box>
  ) : (
    <Navigate to="/" state={{ next: location.pathname }} />
  )
}
