import React, { useEffect, useState } from 'react'
import { Card, Grid, InputLabel, Stack } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import CTextField from '../../../../components/CTextField'
import CButton from '../../../../components/CButton'
import { setBreadcrumb } from '../../../../redux/Slice/breadcrumbSlice'
import { first } from 'lodash'
import { showError, showSuccess } from '../../../../redux/Slice/alertSlice'
import { create } from '../supportSlice'
import CEditor from '../../../../components/CEditor'
import CDatePicker from '../../../../components/CDatePicker'
import CAutocomplete from '../../../../components/CAutocomplete'
import { formatDate } from '../../../../utils/cdayjs'
import dayjs from 'dayjs'

const SupportCreate = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { errors, isLoading } = useSelector((state) => state.support)
  const [support, setSupport] = useState({
    subject: '',
    date: '',
    details: '',
    attachment: [],
    status: '',
  })
  console.log(support)
  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        { path: '/support/list', label: 'support', isLast: false },
        { path: '', label: 'Create', isLast: true },
      ])
    )
  }, [dispatch])

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      const res = await dispatch(
        // Wait for the support create action to complete.
        create({
          subject: support?.subject,
          date: support?.date,
          details: support?.details,
          attachment: support?.attachment,
          status: support?.status,
        })
      )
      console.log(res)
      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        navigate('/support/list')
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      // Handle any errors that occur during the login action dispatch
      console.error('Server error:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred during creating support.',
        })
      )
    }
  }
  const status = [
    {
      id: 0,
      name: 'Pending',
    },
    {
      id: 1,
      name: 'Review',
    },
    {
      id: 2,
      name: 'In Progress',
    },
    {
      id: 3,
      name: 'Success',
    },
  ]

  return (
    <>
      <Helmet>
        <title>Bioaro | Support - Create</title>
      </Helmet>
      <Stack direction="row" justifyContent="center">
        <Card sx={{ p: 3, maxWidth: '70rem' }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={6}>
                <CTextField
                  label="Subject"
                  name="subject"
                  value={support?.subject}
                  error={!!(errors && errors.subject)}
                  helperText={errors && errors.subject}
                  onChange={(e) =>
                    setSupport({ ...support, subject: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CDatePicker
                  label="Date"
                  name="date"
                  value={support?.date ? dayjs(support?.date) : null}
                  onChange={(value) =>
                    setSupport({
                      ...support,
                      date: formatDate(value),
                    })
                  }
                  error={!!(errors && errors.date)}
                  helperText={errors && errors.date}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <CEditor
                  label="details"
                  value={support?.details}
                  handleChange={(value) =>
                    setSupport({
                      ...support,
                      details: value,
                    })
                  }
                  helperText={errors && errors.instructions}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputLabel>Attachment</InputLabel>
                <CTextField
                  name="attachment"
                  type="file"
                  fullWidth
                  variant="outlined"
                  onChange={(e) =>
                    setSupport({ ...support, attachment: e.target.files[0] })
                  }
                  error={!!(errors && errors.attachment)}
                  helperText={errors && errors.attachment}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CAutocomplete
                  options={status}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, value) =>
                    setSupport({ ...support, status: value.id })
                  }
                  label="Status"
                  error={!!(errors && errors.status)}
                  helperText={errors && errors.status}
                />
              </Grid>
            </Grid>
            <Stack direction="row" justifyContent="flex-end" mb={2} mt={2}>
              <CButton label="Save" icon={<SaveIcon />} type="submit" />
            </Stack>
          </form>
        </Card>
      </Stack>
    </>
  )
}

export default SupportCreate
