import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Icon
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import AddIcon from '@mui/icons-material/Add'
import ContactsIcon from '@mui/icons-material/Contacts'
import { Button, Card, FormControlLabel, Stack, Switch } from '@mui/material'
// Components
import { Helmet } from 'react-helmet-async'
import CDatatable from '../../../components/CDatatable'
import CSwitch from '../../../components/CSwitch'

// Slice
import { fetchReferralList } from './referralSlice'
import { setBreadcrumb } from '../../../redux/Slice/breadcrumbSlice'
import { changeRefStatus } from './referralSlice'
import { showError, showSuccess } from '../../../redux/Slice/alertSlice'
import CButton from '../../../components/CButton'

const ReferralList = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        { path: '/referral/list', label: 'Referral', isLast: false },
        { path: '', label: 'List', isLast: true },
      ])
    )
  }, [dispatch])

  const { referrals, isLoading } = useSelector((state) => state.referral)

  const handleQuickFilterValue = (filterModel) => {
    const quickFilterValue = filterModel.quickFilterValues.join(' ')
    console.log(quickFilterValue)
  }

  const handleReferralStatus = async (e, referralId) => {
    try {
      const res = await dispatch(
        changeRefStatus({
          data: { status: e.target.checked },
          referralId: referralId,
        })
      )

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        dispatch(fetchReferralList())
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error(
        'Error while changing status referral doctor status:',
        error
      )
      dispatch(
        showError({
          error: true,
          message: 'An error occurred during referral doctor changing status.',
        })
      )
    }
  }

  const columns = [
    { field: 'first_name', headerName: 'First Name', flex: 1 },
    { field: 'last_name', headerName: 'Last Name', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'phone', headerName: 'Phone', flex: 1 },
    { field: 'type', headerName: 'User Type', flex: 1 },
    { field: 'client_type', headerName: 'Client Type', flex: 1 },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.5,
      renderCell: (params) => (
        <CSwitch
          onChange={(e) => handleReferralStatus(e, params.row.id)}
          defaultChecked={params.value ? true : false}
        />
      ),
    },
    {
      headerName: 'Action',
      flex: 0.6,
      renderCell: (params) => (
        <div className="d-flex justify-content-around w-100">
          <Link to={`/referral/${params.row.id}/address`} className="mr-1">
            <ContactsIcon />
          </Link>
          <Link to={`/referral/update/${params.row.id}`} className="mr-1">
            <ModeEditIcon />
          </Link>
        </div>
      ),
    },
  ]

  useEffect(() => {
    dispatch(fetchReferralList())
  }, [])

  return (
    <>
      <Helmet>
        <title>Bioaro | Referral - List</title>
      </Helmet>
      <Card sx={{ p: 3 }}>
        <Stack direction="row" justifyContent="flex-end" sx={{ mb: 2 }}>
          <CButton
            label="Create"
            icon={<AddIcon />}
            component={Link}
            to={`/referral/create`}
          />
        </Stack>

        <CDatatable
          rows={referrals}
          columns={columns}
          isLoading={isLoading}
          handleQuickFilterValue={handleQuickFilterValue}
        />
      </Card>
    </>
  )
}

export default ReferralList
