import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'

// Components
import { Helmet } from 'react-helmet-async'
import CButton from '@/components/CButton'
import CDataTable from '@/components/CDatatable'
import COptionButton from '@/components/COptionButton'

// MUI Components
import {
  Box,
  Button,
  Card,
  FormControlLabel,
  Grid,
  ListItem,
  Stack,
  Switch,
  Typography,
} from '@mui/material'

// Slice
import { setBreadcrumb } from '@/redux/Slice/breadcrumbSlice'

// Utility
import { renderCell } from '@/utils/ctableTool'
import {
  formBuilderDelete,
  formBuilderList,
  setElements,
} from './formBuilderSlice'
import { Add } from '@mui/icons-material'
import { showError, showSuccess } from '@/redux/Slice/alertSlice'

const FormBuilderList = () => {
  const dispatch = useDispatch()

  const { formBuilders, isLoading } = useSelector((state) => state.formBuilder)

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        {
          path: '/form-builder/list',
          label: 'Form Builder',
          isLast: false,
        },
        { path: '', label: 'List', isLast: true },
      ])
    )
  }, [])

  useEffect(() => {
    dispatch(formBuilderList())
  }, [])

  const columns = [
    {
      field: 'name',
      headerName: 'Form Name',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },

    {
      headerName: 'Action',
      minWidth: 300,
      flex: 1,
      renderCell: ({ row }) =>
        renderCell(
          <Grid container spacing={0.2}>
            <Grid item>
              <CButton
                label="Update"
                component={Link}
                to={`/form-builder/update/${row.id}`}
              />
            </Grid>
            <Grid item>
              <CButton
                label="Delete"
                yesNo
                sx={{ backgroundColor: 'red' }}
                onClick={() =>
                  dispatch(formBuilderDelete(row.id)).then((res) => {
                    if (res.payload && !res.payload.error) {
                      dispatch(
                        showSuccess({
                          success: true,
                          message: res.payload.message,
                        })
                      )
                      dispatch(formBuilderList())
                    } else {
                      dispatch(
                        showError({
                          error: true,
                          message: res.payload.message,
                        })
                      )
                    }
                  })
                }
              />
            </Grid>
          </Grid>
        ),
    },
  ]

  return (
    <>
      <Helmet>
        <title>Bioaro | Form Builder - list</title>
      </Helmet>

      <Stack direction="row" justifyContent="flex-end" mb={1}>
        <CButton
          label="Create"
          icon={<Add />}
          component={Link}
          to={`/form-builder`}
        />
      </Stack>
      <Card sx={{ p: 3 }}>
        <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
          <Typography variant="h6">Form Builder List</Typography>
        </Stack>
        <CDataTable
          rows={formBuilders || []}
          columns={columns}
          isLoading={isLoading}
          filterMode="server"
        />
      </Card>
    </>
  )
}

export default FormBuilderList
