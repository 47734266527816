// // UserList.jsx
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Icon
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import AddIcon from '@mui/icons-material/Add'
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
// Components
import AdminLayout from '@/layouts/AdminLayout'

// Slice
import { create, list } from '../clamServiceSlice'
import { setBreadcrumb } from '@/redux/Slice/breadcrumbSlice'
import { Link, useNavigate } from 'react-router-dom'
import CButton from '@/components/CButton'
import CDatatable from '@/components/CDatatable'
import { changeStatus, deleteRecord } from '@/redux/Slice/commonSlice'
import { showError, showSuccess } from '@/redux/Slice/alertSlice'
import CTextField from '@/components/CTextField'
import SaveIcon from '@mui/icons-material/Save'
import ClamServiceUpdate from './ClamServiceUpdate'
import { Helmet } from 'react-helmet-async'

const ClamServiceList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { errors, clams, isLoading } = useSelector((state) => state.clamService)

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        { path: '/clam-service/list', label: 'Clam Service', isLast: false },
        { path: '', label: 'List', isLast: true },
      ])
    )
  }, [dispatch])

  const handleQuickFilterValue = (filterModel) => {
    const quickFilterValue = filterModel.quickFilterValues.join(' ')
    console.log(quickFilterValue)
  }

  const columns = [
    { field: 'cpt_code', headerName: 'ICD 10', flex: 1 },
    {
      field: 'short_description',
      headerName: 'Short Description',
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 0.5,
      renderCell: (params) => (
        <ButtonGroup variant="contained" aria-label="Basic button group">
          <ClamServiceUpdate
            id={params.row.id}
            cpt_code={params.row.cpt_code}
            short_description={params.row.short_description}
          />
        </ButtonGroup>
      ),
    },
  ]

  useEffect(() => {
    // page title
    document.title = 'BioAro | Clam Service - List'
    dispatch(list())
  }, [])

  //   initial create value
  const [clam, setClam] = useState({
    cpt_code: '',
    short_description: '',
  })

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const res = await dispatch(create(clam))

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        setClam({
          cpt_code: '',
          short_description: '',
        })
        dispatch(list())
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Server error:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred while Creating Clam service.',
        })
      )
    }
  }

  // onChange handler to update the local state
  const handleInputChange = (event) => {
    const { name, value } = event.target
    // Update the local state with the changed value
    setClam((clam) => ({
      ...clam,
      [name]: value,
    }))
  }

  return (
    <>
      <Helmet>
        <title>Bioaro | Clam Service - List</title>
      </Helmet>
      <Stack direction="row" justifyContent='center'>
        <Card sx={{ p: 3, width: '90rem' }}>
          <form onSubmit={handleSubmit}>
            <Box>
              {' '}
              <Stack sx={{ float: 'left' }}>
                {' '}
                <Typography variant="h5">Clam Service Create</Typography>{' '}
              </Stack>
              <Stack
                direction="row"
                justifyContent="flex-end"
                mb={2}
                mt={2}
                mr={1.3}
              >
                <CButton label="Save" icon={<SaveIcon />} type="submit" />
              </Stack>
            </Box>

            <Grid container spacing={2} alignItems="center">
              <Grid xs={12} m={1}>
                <CTextField
                  label="ICD 10"
                  name="cpt_code"
                  value={clam?.cpt_code}
                  error={errors && errors.cpt_code}
                  helperText={errors && errors.cpt_code}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid xs={12} m={1}>
                <CTextField
                  label="Short description"
                  name="short_description"
                  value={clam?.short_description}
                  error={errors && errors.short_description}
                  helperText={errors && errors.short_description}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
          </form>
        </Card>
      </Stack>
      <Card sx={{ p: 2 }}>
        <CDatatable
          rows={clams}
          columns={columns}
          isLoading={isLoading}
          handleQuickFilterValue={handleQuickFilterValue}
        />
      </Card>
    </>
  )
}

export default ClamServiceList
