import React from 'react'
import { Box, Avatar, Typography, IconButton } from '@mui/material'
import {
  Call as CallIcon,
  Videocam as VideocamIcon,
  MoreHoriz as MoreHorizIcon,
} from '@mui/icons-material'
import { useSelector } from 'react-redux'

const ChatHeader = () => {
  const { receiver } = useSelector((state) => state.chat)

  return (
    <Box
      sx={{
        padding: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #ddd',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar>{'O'.charAt(0)}</Avatar>
        <Typography variant="h6" sx={{ marginLeft: '16px' }}>
          {receiver.full_name}
        </Typography>
      </Box>
      <Box>
        <IconButton>
          <MoreHorizIcon />
        </IconButton>
      </Box>
    </Box>
  )
}

export default React.memo(ChatHeader)
