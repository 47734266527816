// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance } from '@/services/axiosConfig'
import { removeData, retrieveData, storeData } from '@/services/localStorage'

// Login user
export const login = createAsyncThunk('auth/login', async (credentials) => {
  const response = await axiosInstance.post('login', credentials)
  return response.data
})

// Check user is logged in or not
export const checkLoggedIn = createAsyncThunk('check-logged-in', async () => {
  const response = await axiosInstance.get('check-logged-in')
  return response.data
})

// Logout user
export const logout = createAsyncThunk('auth/logout', async () => {
  const accessToken = retrieveData('accessToken')
  axiosInstance.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${accessToken}`
  const response = await axiosInstance.post('logout')
  return response.data
})

export const refreshToken = createAsyncThunk('refresh-token', async () => {
  const accessToken = getToken('accessToken')
  axiosInstance.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${accessToken}`
  const response = await axiosInstance.post('refresh-token')
  return response.data
})

export const passwordForgot = createAsyncThunk(
  'password-forgot',
  async (credentials) => {
    const response = await axiosInstance.post('password-forgot', credentials)
    return response.data
  }
)
export const passwordReset = createAsyncThunk(
  'password-reset',
  async (credentials) => {
    const response = await axiosInstance.post('password-reset', credentials)
    return response.data
  }
)

export const securityAnswerInfo = createAsyncThunk(
  'security-answer/info',
  async () => {
    const response = await axiosInstance.get('security-answer/info')
    return response.data
  }
)

export const securityAnswerCreate = createAsyncThunk(
  'security-answer/create',
  async (data) => {
    const response = await axiosInstance.post('security-answer/create', data)
    return response.data
  }
)

export const securityAnswerUpdate = createAsyncThunk(
  'security-answer/update',
  async (data) => {
    const response = await axiosInstance.post('security-answer/update', data)
    return response.data
  }
)

export const getUserQuestion = createAsyncThunk(
  'security-answer/user-question',
  async (questionNum) => {
    const response = await axiosInstance.get(
      `security-answer/user-question?question_num=${questionNum}`
    )
    return response.data
  }
)

export const getUserAnswer = createAsyncThunk(
  'security-answer/user-answers',
  async () => {
    const response = await axiosInstance.get(`security-answer/user-answers`)
    return response.data
  }
)

export const securityAnswerVerify = createAsyncThunk(
  'security-answer/verify',
  async (data) => {
    const response = await axiosInstance.post('security-answer/verify', data)
    return response.data
  }
)

export const otpSend = createAsyncThunk(
  'security-answer/otp-send',
  async () => {
    const response = await axiosInstance.post('security-answer/otp-send')
    return response.data
  }
)

export const securityAnswerReset = createAsyncThunk(
  'security-answer/reset',
  async (data) => {
    const response = await axiosInstance.post('security-answer/reset', data)
    return response.data
  }
)

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoading: false,
    errors: null,
    error: null,
    message: '',
    accessToken: null,
    user: null,
    questions: [],
    userQuestion: null,
    answers: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true
      })
      .addCase(login.fulfilled, (state, action) => {
        if (action.payload.error) {
          state.message = action.payload.message
        } else {
          const accessToken = action.payload.access_token
          storeData('accessToken', accessToken)
          state.message = action.payload.message
          state.user = action.payload.user
          storeData('user', action.payload.user)
        }
        state.isLoading = false
      })

      .addCase(refreshToken.pending, (state) => {
        state.isLoading = true
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        if (action.payload.error) {
          state.message = action.payload.message
        } else {
          const accessToken = action.payload.access_token
          storeData('accessToken', accessToken)
        }
        state.isLoading = false
      })

      // logout
      .addCase(logout.pending, (state) => {
        state.isLoading = true
      })
      .addCase(logout.fulfilled, (state, action) => {
        removeData('accessToken')
        state.accessToken = null
        state.message = action.payload.message
      })
      .addCase(logout.rejected, (state) => {
        state.isLoading = false
      })

      // password forgot
      .addCase(passwordForgot.pending, (state) => {
        state.isLoading = true
      })
      .addCase(passwordForgot.fulfilled, (state, action) => {
        state.isLoading = false
        // handle success case
      })
      .addCase(passwordForgot.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload
      })

      // Security Answer info
      .addCase(securityAnswerInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(securityAnswerInfo.fulfilled, (state, { payload } = action) => {
        state.questions = payload.questions
        state.isLoading = false
      })
      .addCase(securityAnswerInfo.rejected, (state) => {
        state.isLoading = false
      })

      // Security Answer create
      .addCase(securityAnswerCreate.pending, (state) => {
        state.isLoading = true
      })
      .addCase(securityAnswerCreate.fulfilled, (state, action) => {
        if (action.payload.error) {
          state.message = action.payload.message
          state.errors = action.payload.errors
        } else {
          state.message = action.payload.message
          state.errors = null
        }
        state.isLoading = false
      })
      .addCase(securityAnswerCreate.rejected, (state) => {
        state.isLoading = false
      })

      // Security Answer update
      .addCase(securityAnswerUpdate.pending, (state) => {
        state.isLoading = true
      })
      .addCase(securityAnswerUpdate.fulfilled, (state, action) => {
        if (action.payload.error) {
          state.message = action.payload.message
          state.errors = action.payload.errors
        } else {
          state.message = action.payload.message
          state.errors = null
        }
        state.isLoading = false
      })
      .addCase(securityAnswerUpdate.rejected, (state) => {
        state.isLoading = false
      })

      // User Security Question
      .addCase(getUserQuestion.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getUserQuestion.fulfilled, (state, { payload } = action) => {
        state.userQuestion = payload.question
        state.isLoading = false
      })
      .addCase(getUserQuestion.rejected, (state) => {
        state.isLoading = false
      })

      // User Security Answer
      .addCase(getUserAnswer.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getUserAnswer.fulfilled, (state, { payload } = action) => {
        state.answers = payload.answers
        state.isLoading = false
      })
      .addCase(getUserAnswer.rejected, (state) => {
        state.isLoading = false
      })

      // Security Answer Reset
      .addCase(securityAnswerReset.pending, (state) => {
        state.isLoading = true
      })
      .addCase(securityAnswerReset.fulfilled, (state, action) => {
        if (action.payload.error) {
          state.message = action.payload.message
          state.errors = action.payload.errors
        } else {
          state.message = action.payload.message
          state.errors = null
        }
        state.isLoading = false
      })
      .addCase(securityAnswerReset.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default authSlice.reducer
