// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../../../services/axiosConfig'

// Fetch Patient Based Activity List
export const fetchActivityList = createAsyncThunk(
  'patient/activity/list',
  async ({ patientId, data }) => {
    const response = await axiosInstance.post(
      `patient/activity/list/${patientId}`,
      data
    )
    return response.data
  }
)

// Fetch Activity detail by user id
export const fetchActivityDetail = createAsyncThunk(
  'patient/activity/detail',
  async (id) => {
    const response = await axiosInstance.get(`patient/activity/detail/${id}`)
    return response.data
  }
)

const activitySlice = createSlice({
  name: 'activity',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    activities: [],
    activity: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch  Activity list
      .addCase(fetchActivityList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchActivityList.fulfilled, (state, { payload }) => {
        state.activities = payload.activities
        state.isLoading = false
      })
      .addCase(fetchActivityList.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch  Activity detail
      .addCase(fetchActivityDetail.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchActivityDetail.fulfilled, (state, { payload }) => {
        state.activity = payload.activity
        state.isLoading = false
      })
      .addCase(fetchActivityDetail.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default activitySlice.reducer
