import React from 'react'
import { useDrag } from 'react-dnd'
import { Typography } from '@mui/material'

const DraggableElement = ({ element }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'FORM_ELEMENT',
    item: { element },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }))

  return (
    <Typography ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
      {element.label}
    </Typography>
  )
}

export default DraggableElement
