
import { DateRangePicker } from "rsuite"; 
import {addDays} from 'date-fns' ; 

import "rsuite/dist/rsuite.min.css"; 

const predefinedRanges = [
  {
    label: 'Today',
    value: [new Date(), new Date()],
    placement: 'left',
  },
  {
    label: 'Yesterday',
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: 'left',
  },
  {
    label: 'Last 7 Days',
    value: [addDays(new Date(), -7), new Date()],
    placement: 'left',
  },
  {
    label: 'Last 30 Days',
    value: [addDays(new Date(), -30), new Date()],
    placement: 'left',
  },
]

const CDateRangeShortcut = (props) => (
  <>
    <DateRangePicker showOneCalendar ranges={predefinedRanges} placement="auto" {...props}/>
  </>
)

export default CDateRangeShortcut