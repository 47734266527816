import FormBuilderCreate from './FormBuilderCreate'
import FormBuilderList from './FormBuilderList'
import FormBuilderUpdate from './FormBuilderUpdate'

const formBuilderRoutes = [
  { path: '', element: <FormBuilderCreate /> },
  { path: 'list', element: <FormBuilderList /> },
  { path: 'update/:formBuilderId', element: <FormBuilderUpdate /> },
]

export default formBuilderRoutes
