import React from 'react'
import LeadList from './LeadList'
import ViewLog from './Partials/ViewLog'

const leadRoutes = [
  { path: "list", element: <LeadList /> },
  { path: "view-log/:leadId", element: <ViewLog /> }
];

export default leadRoutes;

