import { retrieveData } from '@/services/localStorage'

export const currentUser = retrieveData('user')

// Function to check if the user has a specific permission
export const hasPerm = (permission) => {
  if (!currentUser || !currentUser.permissions) {
    return false
  }

  const permissions = new Set(currentUser.permissions)
  return permissions.has(permission)
}

export const hasRole = (role_id) => {
  const roles = [1, 2, 3, 4, 5, 6, 7, 8, 9]

  return roles.includes(role_id)
}

export const hasAnyRole = (checkRoles) => {
  const roles = [1, 2, 3, 4, 5, 6, 7, 8, 9]
  return checkRoles.every((role) => roles.includes(role))
}

export const slugify = (str) => {
  return str
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^a-z0-9-]/g, '')
}

export function toBoolean(value) {
  if (value === 'true') return true
  if (value === 'false') return false
  return Boolean(value)
}

export function capEachWord(sentence) {
  return sentence.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
}
// example:
// let sentence = "this is another sample sentence.";
// let capitalizedSentence = capEachWord(sentence);
// console.log(capitalizedSentence); // Output: This Is Another Sample Sentence.

export function beautifyString(text) {
  // 1. Replace dots and hyphens with spaces
  let cleanedText = text.replace(/\.|-/g, ' ')

  // 2. Capitalize each word
  let capitalizedText = cleanedText
    .toLowerCase() // Ensure all words are lowercase first
    .split(' ') // Split into an array of words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
    .join(' ') // Join the words back into a sentence

  return capitalizedText
}
// example:
// let inputText = "Basic.delete-record";
// let outputText = beautifyString(inputText);

// console.log(outputText); // Output: Basic Delete Record
