import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { axiosInstance } from '@/services/axiosConfig'

const permissionCheck = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const interceptor = axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response && error.response.status === 403) {
          navigate('/403')
        }
        return Promise.reject(error)
      }
    )

    // Cleanup interceptor on component unmount
    return () => {
      axiosInstance.interceptors.response.eject(interceptor)
    }
  }, [navigate])
}

export default permissionCheck
