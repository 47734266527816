import { Link, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Card, Button, Stack, Typography, Container } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'

// Local
import { Helmet } from 'react-helmet-async'
import CTextField from '../../../../components/CTextField'
import CEditor from '../../../../components/CEditor'

// Slice
import { create } from './noteSlice'
import { showSuccess, showError } from '../../../../redux/Slice/alertSlice'
import { setBreadcrumb } from '../../../../redux/Slice/breadcrumbSlice'
import CButton from '../../../../components/CButton'

const NoteCreate = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        { path: '/template/note/list', label: 'Note', isLast: false },
        { path: '', label: 'Create', isLast: true },
      ])
    )
  }, [dispatch])

  const navigate = useNavigate()

  const [description, setDescription] = useState()
  const { errors } = useSelector((state) => state.noteTemplate)

  const handleSubmit = async (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)

    try {
      const res = await dispatch(
        // Wait for the note template create action to complete.
        create({
          title: data.get('title'),
          description: description,
        })
      )

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        navigate('/template/note/list')
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      // Handle any errors that occur during the login action dispatch
      console.error('Server error:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred during creating note template.',
        })
      )
    }
  }

  return (
    <>
      <Helmet>
        <title>Bioaro | Note - Create</title>
      </Helmet>
      <Stack direction="row" justifyContent="center">
        <Card sx={{ p: 3, maxWidth: '50rem' }}>
          <form onSubmit={handleSubmit}>
            <Stack>
              <CTextField
                label="Title"
                name="title"
                autoFocus
                error={!!(errors && errors.title)}
                helperText={errors && errors.title}
              />

              <CEditor
                value={description}
                handleChange={(value) => setDescription(value)}
                helperText={errors && errors.description}
              />
            </Stack>

            <Stack direction="row" justifyContent="flex-end" mb={2}>
              <CButton label="Save" icon={<SaveIcon />} type="submit" />
            </Stack>
          </form>
        </Card>
      </Stack>
    </>
  )
}

export default NoteCreate
