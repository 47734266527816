// // UserList.jsx
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { formatDate, formatDateTime } from '../../../utils/cdayjs'

// Components
import { Helmet } from 'react-helmet-async'
import CDatatable from '../../../components/CDatatable'

// Slice

import { setBreadcrumb } from '../../../redux/Slice/breadcrumbSlice'
import { Box, Button, Card, Grid, Stack, Typography } from '@mui/material'
import CButton from '../../../components/CButton'
import CTextField from '../../../components/CTextField'
import { search } from './dataAnalysisSlice'
import CSelect from '@/components/CSelect'
import dayjs from 'dayjs'
import { fetchAckAlert } from '../Patient/patientSlice'
import AckAlert from '../Patient/Partials/AckAlert'

const DataAnalysis = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { users, isLoading } = useSelector((state) => state.dataAnalysis)

  const [ack, setAck] = useState({ open: false, patientId: null })

  const [dataAnalysis, setData] = useState({
    gender: '',
    minAge: '',
    maxAge: '',
    search_one: '',
    search_three: '',
  })

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        {
          path: '/patient/list',
          label: 'Patient',
          isLast: false,
        },
        { path: '', label: 'List', isLast: true },
      ])
    )
  }, [dispatch])

  const handleAck = (data) => {
    setAck(data)
  }

  const columns = [
    {
      field: 'full_name',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => (
        <div>
          <CButton
            variant="text"
            label={params?.value}
            onClick={async () => {
              try {
                const res = await dispatch(
                  fetchAckAlert(params.row.id)
                )
                if (res.payload) {
                  handleAck({
                    open: true,
                    patientId: params.row.id,
                  })
                } else {
                  navigate(`/patient/detail/${params.row.id}/profile`)
                }
              } catch (error) {
                console.error('An error occurred ', error)
                dispatch(
                  showError({
                    error: true,
                    message: 'An error occurred',
                  })
                )
              }
            }}
          />
          {/* <div>
            <Link
              to={`/patient/detail/${params.row.user_info_id}/profile`}
              style={{ textDecoration: 'none' }}
            >
              {params.value}
            </Link>
          </div> */}
          <div>
            {params.row.chart_number && `(${params.row.chart_number})`}
            {params.row.date_of_birth &&
              `(${formatDate(params.row.date_of_birth)})`}
          </div>
        </div>
      ),
    },
    {
      field: 'phn',
      headerName: 'PHN',
      flex: 0.5,
    },
    {
      field: 'phone1',
      headerName: 'Phone',
      flex: 0.5,
    },
    {
      field: 'ex_email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'created_at',
      headerName: '	Date/Time',
      flex: 1,
      renderCell: (params) =>
        dayjs(params.row.created_at).format('DD MMM YYYY h:mm A'),
    },
  ]

  //   useEffect(()=> {location.reload()},[])
  useEffect(() => {
    dispatch(search(dataAnalysis))
  }, [dataAnalysis])

  // onChange handler to update the local state
  const handleInputChange = (event) => {
    const { name, value } = event.target
    // Update the local state with the changed value
    setData((dataAnalysis) => ({
      ...dataAnalysis,
      [name]: value,
    }))
  }

  return (
    <>
      <Helmet>
        <title>Bioaro | Patient - List</title>
      </Helmet>
      <Card sx={{ p: 3 }}>
        <Box sx={{ my: 2 }}>
          <Stack direction="row" justifyContent="center">
            <Card sx={{ p: 4, maxWidth: '100%' }} spacing={2}>
              <Grid xs={12} bgcolor={'#417795'} p={1.4} color={'#fff'} mb={2}>
                Search Physician
              </Grid>

              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={2}>
                  <CSelect
                    label="Gender"
                    name="gender"
                    value={dataAnalysis?.gender}
                    options={[
                      { label: 'Male', value: 'Male' },
                      { label: 'Female', value: 'Female' },
                      { label: 'Other', value: 'Other' },
                    ]}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid xs={1.5} item>
                  <CTextField
                    label="Age From"
                    name="minAge"
                    value={dataAnalysis?.minAge}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid xs={1.5} item>
                  <CTextField
                    label="Age To"
                    name="maxAge"
                    value={dataAnalysis?.maxAge}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid xs={3} item>
                  <CTextField
                    name="search_one"
                    label="Search Medications/Supplements"
                    value={dataAnalysis?.search_one}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid xs={4} item>
                  <CTextField
                    name="search_three"
                    label="Search History"
                    value={dataAnalysis?.search_three}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
              <Box mt={4}>
                <Typography mb={1}>Patient List</Typography>
                <CDatatable
                  rows={users ?? []}
                  columns={columns}
                  isLoading={isLoading}
                />
              </Box>
            </Card>
          </Stack>
        </Box>
      </Card>
      {ack.open ? <AckAlert ack={ack} sendDataToParent={handleAck} /> : null}
    </>
  )
}

export default DataAnalysis
