import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link, useParams } from 'react-router-dom'

import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import SaveIcon from '@mui/icons-material/Save'

// Local
import { Helmet } from 'react-helmet-async'

// Slice
import { showSuccess, showError } from '../../../redux/Slice/alertSlice'
import { setBreadcrumb } from '../../../redux/Slice/breadcrumbSlice'
import {
  update,
  fetchPermissions,
  fetchRoleDetail,
} from '../../../redux/Slice/roleSlice'
import LoadingIndicator from '../../../components/LoadingIndicator'
import { Button, Card, Stack, capitalize } from '@mui/material'
import CTextField from '../../../components/CTextField'
import CButton from '../../../components/CButton'
import { beautifyString } from '@/utils/cutils'

function RoleUpdate() {
  const { roleId } = useParams() // Extract roleId from URL
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        { path: '/role/list', label: 'Role', isLast: false },
        { path: '/role/update', label: 'Update', isLast: true },
      ])
    )
  }, [dispatch])

  const role = useSelector((state) => state.role)
  const { isLoading, errors, rolePermissions, roleDetail } = role
  const permissionData = role.permissions

  const [permissions, setPermissions] = useState({})

  // Function to toggle all permissions for a specific group
  const handleToggleAllGroupPermissions = (group, isChecked) => {
    setPermissions((prevPermissions) => {
      const updatedPermissions = { ...prevPermissions }
      for (const permission in updatedPermissions[group]) {
        updatedPermissions[group][permission] = isChecked
      }
      return updatedPermissions
    })
  }

  // Function to toggle all groups
  const handleToggleAllGroups = (isChecked) => {
    setPermissions((prevPermissions) => {
      const updatedPermissions = {}

      for (const group in prevPermissions) {
        updatedPermissions[group] = {}
        for (const permission in prevPermissions[group]) {
          updatedPermissions[group][permission] = isChecked
        }
      }

      return updatedPermissions
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    // Remove group key
    const withOutGroupKey = [].concat(...Object.values(permissions))

    // Merge all objects into one object
    const mergedPermissions = withOutGroupKey.reduce((acc, permission) => {
      return { ...acc, ...permission }
    }, {})

    try {
      const res = await dispatch(
        update({
          id: roleId,
          data: {
            name: data.get('name'),
            permissions: mergedPermissions,
          },
        })
      )

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        // navigate(`/role/${roleId}`); // Redirect to the role details page
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      // Handle any errors that occur during the update role action dispatch
      console.error('Server error:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred during updating role.',
        })
      )
    }
  }

  useEffect(() => {
    dispatch(fetchRoleDetail(roleId))
    dispatch(fetchPermissions())
  }, [])

  useEffect(() => {
    const rolePermissionsSet = new Set(rolePermissions)
    const initialPermissions = {}

    for (const group in permissionData) {
      initialPermissions[group] = {}
      permissionData[group].forEach((permission) => {
        // Compare with assigned permissions
        initialPermissions[group][permission.name] = rolePermissionsSet.has(
          permission.name
        )
      })
    }

    // Set permissions after permissionData is available
    setPermissions(initialPermissions)
  }, [permissionData])

  return (
    <>
      <Helmet>
        <title>Bioaro | User - Update</title>
      </Helmet>
      {!isLoading && permissions ? (
        <Stack direction="row" justifyContent="center">
          <Card sx={{ p: 3 }}>
            <form onSubmit={handleSubmit}>
              <Stack direction="row" justifyContent="flex-end" sx={{ mb: 2 }}>
                <CButton label="Save" icon={<SaveIcon />} type="submit" />
              </Stack>

              <CTextField
                autoFocus
                name="name"
                label="Role Name"
                value={roleDetail.name}
                error={!!(errors && errors.name)}
                helperText={errors && errors.name}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={Object.values(permissions).every((group) =>
                      Object.values(group).every((permission) => permission)
                    )}
                    onChange={() =>
                      handleToggleAllGroups(
                        !Object.values(permissions).every((group) =>
                          Object.values(group).every((permission) => permission)
                        )
                      )
                    }
                    indeterminate={Object.values(permissions).every((group) =>
                      Object.values(group).every((permission) => permission)
                    )}
                  />
                }
                label={
                  <strong className="text-primary">
                    Select All Module Permissions
                  </strong>
                }
              />

              {Object.keys(permissions).map((group) => (
                <div key={group}>
                  <FormControlLabel
                    label={
                      <strong className="text-primary">
                        Select All {group} Permissions
                      </strong>
                    }
                    control={
                      <Checkbox
                        checked={Object.values(permissions[group]).every(
                          (isChecked) => isChecked
                        )}
                        onChange={() =>
                          handleToggleAllGroupPermissions(
                            group,
                            !Object.values(permissions[group]).every(
                              (isChecked) => isChecked
                            )
                          )
                        }
                        indeterminate={Object.values(permissions[group]).every(
                          (isChecked) => isChecked
                        )}
                      />
                    }
                  />
                  <br />

                  {Object.keys(permissions[group]).map((permission) => (
                    <FormControlLabel
                      key={permission}
                      control={
                        <Checkbox
                          checked={permissions[group][permission]}
                          onChange={() => {
                            const updatedPermissions = { ...permissions }
                            updatedPermissions[group][permission] =
                              !permissions[group][permission]
                            setPermissions(updatedPermissions)
                          }}
                        />
                      }
                      label={beautifyString(permission)}
                    />
                  ))}

                  <hr />
                </div>
              ))}

              <Stack direction="row" justifyContent="flex-end" sx={{ mb: 2 }}>
                <CButton label="Save" icon={<SaveIcon />} type="submit" />
              </Stack>
            </form>
          </Card>
        </Stack>
      ) : null}
    </>
  )
}

export default RoleUpdate
