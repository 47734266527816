import * as React from 'react'
import Button from '@mui/material/Button'
import { OtherHouses } from '@mui/icons-material'

export default function CButton({
  label,
  fullWidth,
  icon,
  size = 'medium',
  variant,
  type,
  color = 'primary',
  component,
  to,
  onClick,
  endIcon,
  yesNo = false,
  yesNoText = '',
  sx,
  ...other
}) {
  const handleClick = () => {
    if (yesNo) {
      if (
        confirm(
          yesNoText
            ? yesNoText
            : 'Are you sure you want to perform this action?'
        )
      ) {
        onClick && onClick()
      }
    } else {
      onClick && onClick()
    }
  }
  return (
    <Button
      fullWidth={fullWidth}
      variant={variant || 'contained'}
      startIcon={icon}
      endIcon={endIcon}
      size={size}
      color={color}
      type={type}
      component={component}
      to={to}
      sx={{
        ...sx,
        textTransform: 'capitalize',
      }}
      onClick={handleClick}
      {...other}
    >
      {label}
    </Button>
  )
}
