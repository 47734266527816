import { Skeleton } from '@mui/material'
import React from 'react'

const CSkeleton = ({ animation, variant, ...other }) => {
  return (
    <Skeleton
      animation={animation || 'wave'}
      variant={variant || 'rectangular'}
      {...other}
    />
  )
}

export default CSkeleton
