import React from 'react'

const TenantList = React.lazy(() => import('./TenantList'))
const TenantCreate = React.lazy(() => import('./TenantCreate'))
// const TenantUpdate = React.lazy(() => import('./TenantUpdate'))

const tenantRoutes = [
  { path: 'list/', element: <TenantList /> },
  { path: 'create/', element: <TenantCreate /> },
  // { path: 'update/:tenantId', element: <TenantUpdate /> },
]

export default tenantRoutes
