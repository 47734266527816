import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined'
import { Box, Grid, InputLabel, Stack } from '@mui/material'
import CDialog from '../../../../components/CDialog'
import CButton from '../../../../components/CButton'
import CTextField from '../../../../components/CTextField'
import CEditor from '../../../../components/CEditor'
import { noteCreate } from '../leadsSlice'
import { showError, showSuccess } from '../../../../redux/Slice/alertSlice'

// Local Components

const NoteCreate = ({ note_to_booking, patient_id, type }) => {
  const dispatch = useDispatch()
  const emailReceivers = useSelector((state) => state.common.emailReceivers)

  const [open, setOpen] = useState(false)
  const handleCDialogOpen = () => {
    setOpen(true)
  }
  const handleCDialogClose = () => {
    setOpen(false)
  }
  if (open == true) {
    console.log(note_to_booking)
    console.log(patient_id)
    console.log(type)
  }
  const [noteData, setNote] = useState({
    note_to_booking: note_to_booking,
    patient_id: patient_id,
    type: type,
    note: '',
    file_image: [],
  })

  useEffect(() => {}, [])

  // modal
  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const res = await dispatch(noteCreate(noteData))

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        setOpen(false)
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('An error occurred!', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred!',
        })
      )
    }
  }

  return (
    <CDialog
      btnTitle="Add Note"
      headerTitle="Note"
      handleCDialogOpen={handleCDialogOpen}
      handleCDialogClose={handleCDialogClose}
      open={open}
    >
      <Box maxWidth="50rem">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {/* Other Email Receivers */}
            <Grid item xs={12}>
              <Grid item xs={12}>
                <InputLabel>Note</InputLabel>
                <CEditor
                  value={noteData.note}
                  handleChange={(value) =>
                    setNote({
                      ...noteData,
                      note: value,
                    })
                  }
                />
              </Grid>
            </Grid>

            {/* Email Message */}
            <Grid item xs={12}>
              <InputLabel>Choose File</InputLabel>
              <CTextField
                name="file_image"
                type="file"
                fullWidth
                variant="outlined"
                onChange={(e) =>
                  setNote({ ...noteData, file_image: e.target.files[0] })
                }
                // error={!!(errors && errors.file_image)}
                // helperText={errors && errors.file_image}
              />
            </Grid>
            {/* Add any error handling for email message if needed */}
          </Grid>

          <Stack
            direction="row"
            justifyContent="flex-end"
            sx={{ mb: 2 }}
            mt={2}
          >
            <CButton label="Send" icon={<SendOutlinedIcon />} type="submit" />
          </Stack>
        </form>
      </Box>
    </CDialog>
  )
}

export default NoteCreate
