// // UserList.jsx
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { formatDateTime } from '../../../utils/cdayjs'

// Components
import { Helmet } from 'react-helmet-async'
import CDatatable from '../../../components/CDatatable'
import AddIcon from '@mui/icons-material/Add'
// Slice
import { setBreadcrumb } from '../../../redux/Slice/breadcrumbSlice'
import { Button, Card, Stack, Typography } from '@mui/material'
import CButton from '../../../components/CButton'
import { fetchPharmacyList } from './pharmacySlice'
import { useDispatch, useSelector } from 'react-redux'
import CDataTable from '../../../components/CDatatable'
import { Edit } from '@mui/icons-material'
import { renderCell } from '@/utils/ctableTool'

const Pharmacy = () => {
  const dispatch = useDispatch()
  const { pharmacies, isLoading } = useSelector((state) => state.pharmacy)

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        {
          path: '/pharmacy/list',
          label: 'pharmacy',
          isLast: false,
        },
        { path: '', label: 'List', isLast: true },
      ])
    )
  }, [dispatch])

  const [quickFilterValue, setQuickfilterValue] = useState(null)
  // add this when you use server side filter
  const handleQuickFilterValue = (filterModel) => {
    const quickFilterValue = filterModel.quickFilterValues.join(' ')
    dispatch(fetchPharmacyList(quickFilterValue))
    setQuickfilterValue(quickFilterValue)
  }

  const columns = [
    {
      field: 'full_name',
      headerName: 'Name',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      field: 'title',
      headerName: 'Title',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      field: 'fax_number',
      headerName: 'Fax',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      field: 'address',
      headerName: 'Address',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      headerName: 'Action',
      minWidth: 200,
      flex: 1,
      renderCell: (params) =>
        renderCell(
          <>
            <CButton
              label="Edit"
              icon={<Edit />}
              component={Link}
              to={`/pharmacy/update/${params.row.id}`}
            />
          </>
        ),
    },
  ]

  useEffect(() => {
    dispatch(fetchPharmacyList())
  }, [])

  return (
    <>
      <Helmet>
        <title>Bioaro | Pharmacy - List</title>
      </Helmet>
      <Card sx={{ p: 3 }}>
        <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
          <Typography variant="h6">
            {quickFilterValue ? 'Patient List' : 'Pharmacy List'}
          </Typography>
          <CButton
            label="Create"
            icon={<AddIcon />}
            component={Link}
            to={`/pharmacy/create`}
          />
        </Stack>
        <CDataTable
          rows={pharmacies}
          columns={columns}
          isLoading={isLoading}
          filterMode="server"
          handleQuickFilterValue={handleQuickFilterValue}
        />
      </Card>
    </>
  )
}

export default Pharmacy
