import React from 'react'
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material'

import buccal from '@/assets/images/dental/crown/buccal.png'
import lingual from '@/assets/images/dental/crown/lingual.png'
import mesial from '@/assets/images/dental/crown/mesial.png'
import occlusal from '@/assets/images/dental/crown/occlusal.png'
import distal from '@/assets/images/dental/crown/distal.png'

import missing from '@/assets/images/dental/missing.png'
import implant from '@/assets/images/dental/implant.png'
import abutment from '@/assets/images/dental/abutment.png'
import rct from '@/assets/images/dental/rct.png'
import post from '@/assets/images/dental/post.png'
import core from '@/assets/images/dental/core.png'
import veneers from '@/assets/images/dental/veneers.png'
import downVeneers from '@/assets/images/dental/downVeneers.png'

const Teeth = ({ selectedTooth, dentalChart, setSelectedTooth }) => {
  return (
    <Box>
      <Typography variant="h6" align="center">
        Dental Chart
      </Typography>

      <TableContainer>
        <Table>
          <TableBody>
            {/* Upper Teeth  */}
            <TableRow>
              {dentalChart.upperTeeth.map((tooth, index) => (
                <TableCell
                  key={index}
                  sx={{
                    padding: 0,
                    border: 'none',
                  }}
                >
                  <Box
                    onClick={() =>
                      setSelectedTooth(
                        dentalChart.upperTeeth.find(
                          (item) => item.toothNumber === tooth.toothNumber
                        )
                      )
                    }
                    sx={{
                      ...(selectedTooth?.toothNumber === tooth.toothNumber && {
                        border: '2px solid blue',
                        borderBottom: 'none',
                      }),
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      position: 'relative',
                    }}
                  >
                    <img
                      src={tooth.image}
                      alt={`Tooth ${tooth.id}`}
                      width={30}
                    />
                    {/* -----------partials------------- */}
                    {toBoolean(tooth.details.missing) && (
                      <img
                        src={missing}
                        alt={`Tooth ${tooth.id}`}
                        width={30}
                        style={{ position: 'absolute' }}
                      />
                    )}

                    {toBoolean(tooth.details.implant) && (
                      <img
                        src={implant}
                        alt={`Tooth ${tooth.id}`}
                        width={30}
                        style={{ position: 'absolute' }}
                      />
                    )}

                    {toBoolean(tooth.details.abutment) && (
                      <img
                        src={abutment}
                        alt={`Tooth ${tooth.id}`}
                        width={30}
                        style={{ position: 'absolute' }}
                      />
                    )}
                    {toBoolean(tooth.details.rct) && (
                      <img
                        src={rct}
                        alt={`Tooth ${tooth.id}`}
                        width={30}
                        style={{ position: 'absolute' }}
                      />
                    )}
                    {toBoolean(tooth.details.postCore) && (
                      <>
                        <img
                          src={core}
                          alt={`Tooth ${tooth.id}`}
                          width={30}
                          style={{
                            position: 'absolute',
                            transform: 'rotate(180deg)',
                            top: -8,
                            left: 6,
                          }}
                        />

                        <img
                          src={post}
                          alt={`Tooth ${tooth.id}`}
                          width={30}
                          style={{ position: 'absolute' }}
                        />
                      </>
                    )}
                    {toBoolean(tooth.details.veneers) && (
                      <img
                        src={veneers}
                        alt={`Tooth ${tooth.id}`}
                        width={30}
                        style={{ position: 'absolute' }}
                      />
                    )}
                  </Box>
                </TableCell>
              ))}
            </TableRow>

            {/* Up Crown  */}
            <TableRow>
              {dentalChart.upperTeeth.map((tooth, index) => (
                <TableCell
                  key={index}
                  sx={{
                    padding: 0,
                    border: 'none',
                  }}
                >
                  <Box
                    sx={{
                      ...(selectedTooth?.toothNumber === tooth.toothNumber && {
                        border: '2px solid blue',
                        borderTop: 'none',
                      }),
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      position: 'relative',
                    }}
                  >
                    <img
                      src={tooth.crown}
                      alt={`Tooth ${tooth.toothNumber}`}
                      width={30}
                    />
                    {/* -------- partials -------- */}

                    {toBoolean(tooth.details.missing) && (
                      <img
                        src={missing}
                        alt={`Tooth ${tooth.id}`}
                        width={30}
                        style={{ position: 'absolute' }}
                      />
                    )}

                    {toBoolean(tooth.details.buccal) && (
                      <img
                        src={buccal}
                        alt={`Tooth ${tooth.toothNumber}`}
                        width={30}
                        style={{ position: 'absolute' }}
                      />
                    )}

                    {toBoolean(tooth.details.lingual) && (
                      <img
                        src={lingual}
                        alt={`Tooth ${tooth.toothNumber}`}
                        width={30}
                        style={{ position: 'absolute' }}
                      />
                    )}

                    {toBoolean(tooth.details.mesial) && (
                      <img
                        src={mesial}
                        alt={`Tooth ${tooth.toothNumber}`}
                        width={30}
                        style={{ position: 'absolute' }}
                      />
                    )}

                    {toBoolean(tooth.details.occlusal) && (
                      <img
                        src={occlusal}
                        alt={`Tooth ${tooth.toothNumber}`}
                        width={30}
                        style={{ position: 'absolute' }}
                      />
                    )}

                    {toBoolean(tooth.details.distal) && (
                      <img
                        src={distal}
                        alt={`Tooth ${tooth.id}`}
                        width={30}
                        style={{ position: 'absolute' }}
                      />
                    )}
                  </Box>
                </TableCell>
              ))}
            </TableRow>

            {/* Upper Teeth Number  */}
            <TableRow>
              {dentalChart.upperTeeth.map((tooth, index) => (
                <TableCell
                  key={index}
                  sx={{
                    padding: 0,
                    border: 'none',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      position: 'relative',
                    }}
                  >
                    {tooth.toothNumber}
                  </Box>
                </TableCell>
              ))}
            </TableRow>

            {/* Lower Teeth Number  */}
            <TableRow>
              {dentalChart.lowerTeeth.map((tooth, index) => (
                <TableCell
                  key={index}
                  sx={{
                    padding: 0,
                    border: 'none',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      position: 'relative',
                    }}
                  >
                    {tooth.toothNumber}
                  </Box>
                </TableCell>
              ))}
            </TableRow>

            {/* Down Crown  */}
            <TableRow>
              {dentalChart.lowerTeeth.map((tooth, index) => (
                <TableCell
                  key={index}
                  sx={{
                    padding: 0,
                    border: 'none',
                  }}
                >
                  <Box
                    sx={{
                      ...(selectedTooth?.toothNumber === tooth.toothNumber && {
                        border: '2px solid blue',
                        borderBottom: 'none',
                      }),
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      position: 'relative',
                    }}
                  >
                    <img
                      src={tooth.crown}
                      alt={`Tooth ${tooth.toothNumber}`}
                      width={30}
                    />
                    {/* -------- partials -------- */}
                    {toBoolean(tooth.details.missing) && (
                      <img
                        src={missing}
                        alt={`Tooth ${tooth.id}`}
                        width={30}
                        style={{ position: 'absolute' }}
                      />
                    )}

                    {toBoolean(tooth.details.buccal) && (
                      <img
                        src={buccal}
                        alt={`Tooth ${tooth.toothNumber}`}
                        width={30}
                        style={{ position: 'absolute' }}
                      />
                    )}

                    {toBoolean(tooth.details.lingual) && (
                      <img
                        src={lingual}
                        alt={`Tooth ${tooth.toothNumber}`}
                        width={30}
                        style={{ position: 'absolute' }}
                      />
                    )}

                    {toBoolean(tooth.details.mesial) && (
                      <img
                        src={mesial}
                        alt={`Tooth ${tooth.toothNumber}`}
                        width={30}
                        style={{ position: 'absolute' }}
                      />
                    )}

                    {toBoolean(tooth.details.occlusal) && (
                      <img
                        src={occlusal}
                        alt={`Tooth ${tooth.toothNumber}`}
                        width={30}
                        style={{ position: 'absolute' }}
                      />
                    )}

                    {toBoolean(tooth.details.distal) && (
                      <img
                        src={distal}
                        alt={`Tooth ${tooth.id}`}
                        width={30}
                        style={{ position: 'absolute' }}
                      />
                    )}
                  </Box>
                </TableCell>
              ))}
            </TableRow>

            {/* Down Teeth  */}
            <TableRow>
              {dentalChart.lowerTeeth.map((tooth, index) => (
                <TableCell
                  key={index}
                  sx={{
                    padding: 0,
                    border: 'none',
                  }}
                >
                  <Box
                    onClick={() =>
                      setSelectedTooth(
                        dentalChart.lowerTeeth.find(
                          (item) => item.toothNumber === tooth.toothNumber
                        )
                      )
                    }
                    sx={{
                      ...(selectedTooth?.toothNumber === tooth.toothNumber && {
                        border: '2px solid blue',
                        borderTop: 'none',
                      }),
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      position: 'relative',
                    }}
                  >
                    <img
                      src={tooth.image}
                      alt={`Tooth ${tooth.id}`}
                      width={30}
                    />

                    {/* -----------partials------------- */}
                    {toBoolean(tooth.details.missing) && (
                      <img
                        src={missing}
                        alt={`Tooth ${tooth.id}`}
                        width={30}
                        style={{
                          position: 'absolute',
                          transform: 'rotate(180deg)',
                        }}
                      />
                    )}

                    {toBoolean(tooth.details.implant) && (
                      <img
                        src={implant}
                        alt={`Tooth ${tooth.id}`}
                        width={30}
                        style={{
                          position: 'absolute',
                          transform: 'rotate(180deg)',
                        }}
                      />
                    )}

                    {toBoolean(tooth.details.abutment) && (
                      <img
                        src={abutment}
                        alt={`Tooth ${tooth.id}`}
                        width={30}
                        style={{
                          position: 'absolute',
                          transform: 'rotate(180deg)',
                        }}
                      />
                    )}
                    {toBoolean(tooth.details.rct) && (
                      <img
                        src={rct}
                        alt={`Tooth ${tooth.id}`}
                        width={30}
                        style={{
                          position: 'absolute',
                          transform: 'rotate(180deg)',
                        }}
                      />
                    )}
                    {toBoolean(tooth.details.postCore) && (
                      <>
                        <img
                          src={core}
                          alt={`Tooth ${tooth.id}`}
                          width={30}
                          style={{
                            position: 'absolute',
                            top: 5,
                            bottom: 10,
                          }}
                        />

                        <img
                          src={post}
                          alt={`Tooth ${tooth.id}`}
                          width={30}
                          style={{ position: 'absolute' }}
                        />
                      </>
                    )}
                    {toBoolean(tooth.details.veneers) && (
                      <img
                        src={downVeneers}
                        alt={`Tooth ${tooth.id}`}
                        width={30}
                        style={{ position: 'absolute' }}
                      />
                    )}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default Teeth

function toBoolean(value) {
  if (value === 'true') return true
  if (value === 'false') return false
  return Boolean(value)
}
