import React from 'react'
import FinancialButton from './Partials/FinancialButton'
import { useOutlet } from 'react-router-dom'

const Financial = () => {
  const outlet = useOutlet()
  return (
    <>
      <FinancialButton />
      {outlet}
    </>
  )
}

export default Financial
