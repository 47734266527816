// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../../../services/axiosConfig'

// This function is called to set the auth header

// Create Note Template
export const create = createAsyncThunk('template/note/create', async (data) => {
  const response = await axiosInstance.post('template/note/create', data)
  console.log(response)
  return response.data
})

// Update Note Template
export const update = createAsyncThunk(
  'template/note/update',
  async ({ id, data }) => {
    const response = await axiosInstance.post(
      `template/note/update/${id}`,
      data
    )
    console.log(response)
    return response.data
  }
)

// Fetch all note templates
export const fetchNoteList = createAsyncThunk(
  'template/note/list',
  async () => {
    const response = await axiosInstance.get('template/note/list')
    return response.data
  }
)

// Fetch note template detail by note template id
export const fetchNoteDetail = createAsyncThunk(
  'template/note/detail',
  async (id) => {
    const response = await axiosInstance.get(`template/note/detail/${id}`)
    return response.data
  }
)

const noteSlice = createSlice({
  name: 'noteTemplate',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    note: null,
    notes: [], // all note templates
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Create Note Template
      .addCase(create.pending, (state) => {
        state.isLoading = true
      })
      .addCase(create.fulfilled, (state, action) => {
        if (action.payload.error) {
          state.message = action.payload.message
          state.errors = action.payload.errors
        } else {
          state.message = action.payload.message
          state.errors = null
        }
        state.isLoading = false
      })
      .addCase(create.rejected, (state) => {
        state.isLoading = false
      })

      // Note Template List
      .addCase(fetchNoteList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchNoteList.fulfilled, (state, { payload } = action) => {
        state.notes = payload.notes
        state.isLoading = false
      })
      .addCase(fetchNoteList.rejected, (state) => {
        state.notes = []
        state.isLoading = false
      })

      // Update Note Template
      .addCase(update.pending, (state) => {
        state.isLoading = true
      })
      .addCase(update.fulfilled, (state, action) => {
        if (action.payload.error) {
          state.message = action.payload.message
          state.errors = action.payload.errors
        } else {
          state.message = action.payload.message
          state.errors = null
        }
        state.isLoading = false
      })
      .addCase(update.rejected, (state) => {
        state.isLoading = false
      })

      // NoteTemplate detail by its id
      .addCase(fetchNoteDetail.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchNoteDetail.fulfilled, (state, { payload } = action) => {
        state.note = payload.note
        state.isLoading = false
      })
      .addCase(fetchNoteDetail.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default noteSlice.reducer
