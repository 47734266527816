// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../../services/axiosConfig'

// Fetch intake type list
export const consultationPaymentList = createAsyncThunk(
  'consultation/payment/list',
  async () => {
    const response = await axiosInstance.get(`consultation/payment/list`)
    return response.data
  }
)

//new booking list
export const newBookingList = createAsyncThunk(
  'consultation/new-booking/list',
  async () => {
    const response = await axiosInstance.get(`consultation/new-booking/list`)
    return response.data
  }
)

const consultationSlice = createSlice({
  name: 'consultation',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    payments: [],
    newBooking: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      // consultationPaymentList list
      .addCase(consultationPaymentList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        consultationPaymentList.fulfilled,
        (state, { payload } = action) => {
          state.payments = payload.payments
          state.isLoading = false
        }
      )
      .addCase(consultationPaymentList.rejected, (state, { payload }) => {
        state.isLoading = false
      })

      // new booking list
      .addCase(newBookingList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(newBookingList.fulfilled, (state, { payload } = action) => {
        state.newBooking = payload.newBooking
        state.isLoading = false
      })
      .addCase(newBookingList.rejected, (state, { payload }) => {
        state.isLoading = false
      })
  },
})

export default consultationSlice.reducer
