import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../services/axiosConfig'


// Fetch all fax receivers by patient id
export const fetchFaxReceiverList = createAsyncThunk(
  'fax-receiver-list',
  async (patientId) => {
    const response = await axiosInstance.get(`fax-receiver-list/${patientId}`)
    return response.data
  }
)

// Fetch all email receivers by patient id
export const fetchEmailReceiverList = createAsyncThunk(
  'email-receiver-list',
  async (patientId) => {
    const response = await axiosInstance.get(`email-receiver-list/${patientId}`)
    return response.data
  }
)

export const changeStatus = createAsyncThunk('change-status', async (data) => {
  const response = await axiosInstance.post(`change-status`, data)
  return response.data
})

export const deleteRecord = createAsyncThunk('delete-record', async (data) => {
  const response = await axiosInstance.post(`delete-record`, data)
  return response.data
})

export const noteCreate = createAsyncThunk('note-create', async (data) => {
  const response = await axiosInstance.post(`note-create`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data
})

export const commonSlice = createSlice({
  name: 'common',
  initialState: {
    faxReceivers: [],
    emailReceivers: [],
    message: '',
    errors: '',
  },

  extraReducers: (builder) => {
    builder
      // Fax Receiver list
      .addCase(fetchFaxReceiverList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchFaxReceiverList.fulfilled, (state, { payload }) => {
        state.faxReceivers = payload.faxReceivers
        state.isLoading = false
      })
      .addCase(fetchFaxReceiverList.rejected, (state) => {
        state.isLoading = false
      })

      //  change status
      .addCase(changeStatus.pending, (state) => {
        state.isLoading = true
      })
      .addCase(changeStatus.fulfilled, (state, { payload }) => {
        state.errors = payload.errors
        state.message = payload.message
        state.isLoading = false
      })
      .addCase(changeStatus.rejected, (state) => {
        state.isLoading = false
      })

      //  delete record
      .addCase(deleteRecord.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteRecord.fulfilled, (state, { payload }) => {
        state.errors = payload.errors
        state.message = payload.message
        state.isLoading = false
      })
      .addCase(deleteRecord.rejected, (state) => {
        state.isLoading = false
      })

      //  note create
      .addCase(noteCreate.pending, (state) => {
        state.isLoading = true
      })
      .addCase(noteCreate.fulfilled, (state, { payload }) => {
        state.errors = payload.errors
        state.message = payload.message
        state.isLoading = false
      })
      .addCase(noteCreate.rejected, (state) => {
        state.isLoading = false
      })

      // Email Receiver list
      .addCase(fetchEmailReceiverList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchEmailReceiverList.fulfilled, (state, { payload }) => {
        state.emailReceivers = payload.emailReceivers
        state.isLoading = false
      })
      .addCase(fetchEmailReceiverList.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default commonSlice.reducer
