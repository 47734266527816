// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '@/services/axiosConfig'

// Fetch task list
export const list = createAsyncThunk(
  'inventory/task/list',
  async (filterData) => {
    const response = await axiosInstance.get('inventory/task/list', {
      params: filterData,
    })
    return response.data
  }
)

// Fetch task info
export const taskInfo = createAsyncThunk(
  'inventory/task/info',
  async (taskId) => {
    const response = await axiosInstance.get(`inventory/task/info/${taskId}`)
    console.log(response.data)
    return response.data
  }
)

// Fetch task create
export const create = createAsyncThunk(
  'inventory/task/create',
  async (data) => {
    const response = await axiosInstance.post('inventory/task/create', data)
    return response.data
  }
)

// Fetch task update
export const update = createAsyncThunk(
  'inventory/task/update',
  async (data) => {
    const response = await axiosInstance.post('inventory/task/update', data)
    return response.data
  }
)

const inventoryTaskSlice = createSlice({
  name: 'inventoryTask',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    tasks: [],
    patients: [],
    users: [],
    doctors: [],
    info: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      //Task List
      .addCase(list.pending, (state) => {
        state.isLoading = true
      })
      .addCase(list.fulfilled, (state, { payload } = action) => {
        state.tasks = payload.tasks
        state.doctors = payload.doctors
        state.users = payload.users
        state.patients = payload.patients
        state.isLoading = false
      })

      .addCase(list.rejected, (state) => {
        state.isLoading = false
      })

      //Task info
      .addCase(taskInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(taskInfo.fulfilled, (state, { payload } = action) => {
        state.info = payload.info
        state.isLoading = false
      })

      .addCase(taskInfo.rejected, (state) => {
        state.isLoading = false
      })

      //Task create
      .addCase(create.pending, (state) => {
        state.isLoading = true
      })
      .addCase(create.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(create.rejected, (state) => {
        state.isLoading = false
      })

      //Task update
      .addCase(update.pending, (state) => {
        state.isLoading = true
      })
      .addCase(update.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(update.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default inventoryTaskSlice.reducer
