// // UserList.jsx
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Icon
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import AddIcon from '@mui/icons-material/Add'
import { Button, Card, Grid, Stack } from '@mui/material'
// Components
import { Helmet } from 'react-helmet-async'
import CDatatable from '../../../../components/CDatatable'

// Slice
import { fetchClinicList } from './clinicSlice'
import { setBreadcrumb } from '../../../../redux/Slice/breadcrumbSlice'
import { Link } from 'react-router-dom'
import CButton from '../../../../components/CButton'
import { renderCell } from '@/utils/ctableTool'
import { showError, showSuccess } from '@/redux/Slice/alertSlice'
import { changeStatus, deleteRecord } from '@/redux/Slice/commonSlice'

const ClinicList = () => {
  const dispatch = useDispatch()
  const { clinics, isLoading } = useSelector((state) => state.clinic)
  console.log(clinics)

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        { path: '/clinic/list', label: 'Clinic', isLast: false },
        { path: '', label: 'List', isLast: true },
      ])
    )
  }, [dispatch])

  const handleQuickFilterValue = (filterModel) => {
    const quickFilterValue = filterModel.quickFilterValues.join(' ')
    console.log(quickFilterValue)
  }

  const columns = [
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    {
      field: 'status',
      headerName: 'Appointment type',
      flex: 1,
      renderCell: (params) => (
        <>
          <CButton
            component={Link}
            //   to={`/leads/view-log/${params.row.id}`}
            label={params.row.status == 1 ? 'YES' : 'NO'}
            sx={{
              background: params.row.status == 1 ? '#0a8154' : '#cf2323',
            }}
            onClick={() =>
              handleStatusChange(
                params.row.userId,
                params.row.status == 0 ? 1 : 0,
                'UserInfo',
                'status'
              )
            }
          />
        </>
      ),
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      renderCell: (params) =>
        renderCell(
          <Grid container spacing={0.2}>
            <Grid item>
              <CButton
                label="Edit"
                component={Link}
                to={`/clinic/update/${params.row.id}`}
              />
            </Grid>

            <Grid item>
              <CButton
                label="Delete"
                sx={{ background: '#cf2323', color: '#ffffff' }}
                onClick={() => handleDelete(params.row.id, 'User')}
              />
            </Grid>
          </Grid>
        ),
    },
  ]

  useEffect(() => {
    dispatch(fetchClinicList())
  }, [])

  // Handle form submission
  const handleDelete = async (id, model) => {
    try {
      const res = await dispatch(
        deleteRecord({
          id: id,
          model: model,
        })
      )

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        dispatch(fetchClinicList())
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Error while Delete File:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred during File Delete.',
        })
      )
    }
  }

  // Handle form submission
  const handleStatusChange = async (categoryId, status, model, column) => {
    try {
      const res = await dispatch(
        changeStatus({
          id: categoryId,
          status: status,
          model: model,
          column: column,
        })
      )

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        dispatch(fetchClinicList())
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Error while Change status:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred during Change status.',
        })
      )
    }
  }

  return (
    <>
      <Helmet>
        <title>Bioaro | Clinic List</title>
      </Helmet>
      <Card sx={{ p: 2 }}>
        <Stack direction="row" justifyContent="flex-end" mb={1}>
          <CButton
            label="Create"
            icon={<AddIcon />}
            component={Link}
            to={`/clinic/create`}
          />
        </Stack>

        <CDatatable
          rows={clinics}
          columns={columns}
          isLoading={isLoading}
          getRowHeight="auto"
        />
      </Card>
    </>
  )
}

export default ClinicList
