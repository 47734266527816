import { Link, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Button from '@mui/material/Button'
import { Helmet } from 'react-helmet-async'
import CTextField from '../../../../components/CTextField'
import CAutocomplete from '../../../../components/CAutocomplete'
import SaveIcon from '@mui/icons-material/Save'

import { fetchClinicDetail, update } from './clinicSlice'
import { showSuccess, showError } from '../../../../redux/Slice/alertSlice'
import { setBreadcrumb } from '../../../../redux/Slice/breadcrumbSlice'
import {
  Box,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material'
import CSelect from '../../../../components/CSelect'
import CButton from '../../../../components/CButton'
import CCircularProgress from '../../../../components/CCircularProgress'
import UserUpdate from './ClinicUpdate'

const ClinicUpdate = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const clinicInfoFromRedux = useSelector((state) => state.clinic.clinic)
  const errors = useSelector((state) => state.clinic.errors)
  const { clinicId } = useParams()
  console.log(clinicId)
  useEffect(() => {
    dispatch(fetchClinicDetail(clinicId))
  }, [clinicId])
  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        { path: '/clinic/list', label: 'Clinic', isLast: false },
        { path: '', label: 'Update', isLast: true },
      ])
    )
  }, [dispatch])

  console.log(clinicInfoFromRedux)

  // Initialize local state with the data from Redux
  const [clinic, setClinic] = useState(clinicInfoFromRedux)

  useEffect(() => {
    document.title = 'BioAro | Clinic - Create'
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const res = await dispatch(update({ clinicId: clinicId, data: clinic }))

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        navigate('/clinic/list')
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Server error:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred while updating the clinic.',
        })
      )
    }
  }

  // onChange handler to update the local state
  const handleInputChange = (event) => {
    const { name, value } = event.target
    // Update the local state with the changed value
    setClinic((clinic) => ({
      ...clinic,
      [name]: value,
    }))
  }

  return (
    <>
      <Helmet>
        <title>Bioaro | Clinic - Update</title>
      </Helmet>
      <Stack direction="row" justifyContent="center">
        <Card sx={{ p: 4, maxWidth: '100%' }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} alignItems="center">
              <Grid xs={12} md={6} item>
                <CTextField
                  label="First Name"
                  name="first_name"
                  value={clinic?.first_name}
                  error={!!(errors && errors.first_name)}
                  helperText={errors && errors.first_name}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid xs={12} md={6} item>
                <CTextField
                  label="Last Name"
                  name="last_name"
                  value={clinic?.last_name}
                  error={!!(errors && errors.last_name)}
                  helperText={errors && errors.last_name}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid xs={12} md={6} item>
                <CTextField
                  label="Email"
                  name="email"
                  value={clinic?.email}
                  error={!!(errors && errors.email)}
                  helperText={errors && errors.email}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel>Attachment</InputLabel>
                <TextField
                  name="image"
                  type="file"
                  fullWidth
                  variant="outlined"
                  onChange={(e) =>
                    setClinic({ ...clinic, image: e.target.files[0] })
                  }
                  // onChange={handleInputChange}
                  error={!!(errors && errors.image)}
                  helperText={errors && errors.image}
                />
              </Grid>
              <Grid xs={12} md={6} item>
                <CTextField
                  name="password"
                  label="Password"
                  type="password"
                  value={clinic?.password}
                  error={!!(errors && errors.password)}
                  helperText={errors && errors.password}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid xs={12} md={6} item>
                <CTextField
                  name="password_confirmation"
                  label="Confirm Password"
                  type="password"
                  value={clinic?.password_confirmation}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid xs={12} md={6} item>
                <CTextField
                  label="Phone"
                  name="phone1"
                  value={clinic?.phone1}
                  error={!!(errors && errors.phone1)}
                  helperText={errors && errors.phone1}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid xs={12} md={6} item>
                <CTextField
                  label="Commission"
                  name="commission"
                  value={clinic?.commission}
                  error={!!(errors && errors.commission)}
                  helperText={errors && errors.commission}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid xs={12} md={6} item>
                <CTextField
                  label="Additional Cost"
                  name="additional_cost"
                  value={clinic?.additional_cost}
                  error={!!(errors && errors.additional_cost)}
                  helperText={errors && errors.additional_cost}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <Stack direction="row" justifyContent="flex-end" mb={2}>
              <CButton label="Save" icon={<SaveIcon />} type="submit" />
            </Stack>
          </form>
        </Card>
      </Stack>
    </>
  )
}

export default ClinicUpdate
