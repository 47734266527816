// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../../../services/axiosConfig'

// Fetch Patient Based File List
export const fetchPrescriptionList = createAsyncThunk(
  'patient/prescription/list',
  async (patientId) => {
    const response = await axiosInstance.get(
      `patient/prescription/list/${patientId}`
    )
    return response.data
  }
)

export const fetchPrescriptionInfo = createAsyncThunk(
  'patient/prescription/info',
  async ({ patientId, prescriptionId }) => {
    const response = await axiosInstance.get(
      `patient/prescription/info/${patientId}/${prescriptionId}`
    )
    return response.data
  }
)

// Patient Based Prescription Create
export const create = createAsyncThunk(
  'patient/prescription/create',
  async ({ patientId, data }) => {
    const response = await axiosInstance.post(
      `patient/prescription/create/${patientId}`,
      data
    )
    return response.data
  }
)

// sendFax
export const sendFax = createAsyncThunk(
  'send-fax',
  async ({ prescriptionId, data }) => {
    const response = await axiosInstance.post(
      `send-fax/${prescriptionId}`,
      data
    )
    return response.data
  }
)

// sendFax
export const sendEmail = createAsyncThunk(
  'send-email',
  async ({ prescriptionId, data }) => {
    const response = await axiosInstance.post(
      `send-email/${prescriptionId}`,
      data
    )
    return response.data
  }
)

// Patient Based Prescription update
export const update = createAsyncThunk(
  'patient/prescription/update',
  async ({ data, prescriptionId }) => {
    const response = await axiosInstance.post(
      `patient/prescription/update/${prescriptionId}`,
      data
    )
    return response.data
  }
)

const prescriptionSlice = createSlice({
  name: 'prescription',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    prescriptions: [],
    prescription: null,
    doctors: [],
    doctor: null,
    pharmacy: null,
    pharmacies: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch  patient based dictation list
      .addCase(fetchPrescriptionList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchPrescriptionList.fulfilled, (state, { payload }) => {
        state.prescriptions = payload.prescriptions
        state.doctors = payload.doctors
        state.pharmacies = payload.pharmacies
        state.isLoading = false
      })
      .addCase(fetchPrescriptionList.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch  patient based dictation update info
      .addCase(fetchPrescriptionInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchPrescriptionInfo.fulfilled, (state, { payload }) => {
        state.prescription = payload.prescription
        state.doctors = payload.doctors
        state.doctor = payload.referralDoctors
        state.pharmacy = payload.pharmacy
        state.doctor = payload.doctor
        state.isLoading = false
      })
      .addCase(fetchPrescriptionInfo.rejected, (state) => {
        state.isLoading = false
      })

      // Patient based prescription  Create
      .addCase(create.pending, (state) => {
        state.isLoading = true
      })
      .addCase(create.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(create.rejected, (state) => {
        state.isLoading = false
      })

      // Patient based prescription  update
      .addCase(update.pending, (state) => {
        state.isLoading = true
      })
      .addCase(update.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(update.rejected, (state) => {
        state.isLoading = false
      })

      // Patient based fax
      .addCase(sendFax.pending, (state) => {
        state.isLoading = true
      })
      .addCase(sendFax.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(sendFax.rejected, (state) => {
        state.isLoading = false
      })

      // Patient based email
      .addCase(sendEmail.pending, (state) => {
        state.isLoading = true
      })
      .addCase(sendEmail.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(sendEmail.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default prescriptionSlice.reducer
