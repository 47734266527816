import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'

import CTextField from '../../../../components/CTextField'
import CCheckbox from '../../../../components/CCheckbox'
import {
  fetchReferralAddressList,
  createOrUpdateRefAddress,
  fetchReferralAddress,
} from '../referralSlice'

import { showError, showSuccess } from '../../../../redux/Slice/alertSlice'
import CButton from '../../../../components/CButton'

export default function ReferralAddressCreate() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [activeItem, setActiveItem] = useState(null)
  const [referralAddress, setReferralAddress] = useState({})
  const { errors, isLoading, referralAddresses } = useSelector(
    (state) => state.referral
  )
  const { referralId } = useParams()

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const res = await dispatch(
        createOrUpdateRefAddress({
          referralId: referralId,
          data: referralAddress,
        })
      )

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        navigate('/referral/list')
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Server error:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred while updating the doctor.',
        })
      )
    }
  }

  // onChange handler to update the local state
  const handleInputChange = (event) => {
    const { name, value } = event.target
    // Update the local state with the changed value
    setReferralAddress((referralAddress) => ({
      ...referralAddress,
      [name]: value,
    }))
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <CCheckbox
          label="Primary"
          name="is_primary"
          onChange={(e) =>
            setReferralAddress({
              ...referralAddress,
              is_primary: e.target.checked,
            })
          }
        />
        <CCheckbox
          label="Favorite"
          name="is_fav"
          onChange={(e) =>
            setReferralAddress({
              ...referralAddress,
              is_fav: e.target.checked,
            })
          }
        />
        <Grid container spacing={2}>
          <Grid item xl={12} md={12}>
            {/* Desc */}
            <CTextField
              label="Desc"
              name="desc"
              error={!!errors && errors.desc}
              helperText={errors && errors.desc}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xl={12} md={4}>
            {/* Line 1 */}

            <CTextField
              label="Line 1"
              name="line1"
              error={!!errors && errors.line1}
              helperText={errors && errors.line1}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xl={12} md={4}>
            {/* Line 2 */}

            <CTextField
              label="Line 2"
              name="line2"
              error={!!errors && errors.line2}
              helperText={errors && errors.line2}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xl={12} md={4}>
            {/* Line 3 */}
            <CTextField
              label="Line 3"
              name="line3"
              error={!!errors && errors.line3}
              helperText={errors && errors.line3}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xl={12} md={4}>
            {/* City */}

            <CTextField
              label="City"
              name="city"
              error={!!errors && errors.city}
              helperText={errors && errors.city}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xl={12} md={4}>
            {/* Prov */}

            <CTextField
              label="Prov"
              name="prov"
              error={!!errors && errors.prov}
              helperText={errors && errors.prov}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xl={12} md={4}>
            {/* PC */}
            <CTextField
              label="PC"
              name="pc"
              error={!!errors && errors.pc}
              helperText={errors && errors.pc}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xl={12} md={4}>
            {/* Country */}
            <CTextField
              label="Country"
              name="country"
              error={!!errors && errors.country}
              helperText={errors && errors.country}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xl={12} md={4}>
            {/* Phone */}
            <CTextField
              label="Phone"
              name="phone"
              error={!!errors && errors.phone}
              helperText={errors && errors.phone}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xl={12} md={4}>
            {/* Other */}
            <CTextField
              label="Other"
              name="phone2"
              error={!!errors && errors.phone2}
              helperText={errors && errors.phone2}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xl={12} md={4}>
            {/* Fax */}
            <CTextField
              label="Fax"
              name="fax"
              error={!!errors && errors.fax}
              helperText={errors && errors.fax}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xl={12} md={4}>
            {/* Email */}

            <CTextField
              label="Email"
              name="email"
              type="email"
              error={!!errors && errors.email}
              helperText={errors && errors.email}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xl={12} md={4}>
            {/* Contact Name */}
            <CTextField
              label="Contact Name"
              name="contact_name"
              error={!!errors && errors.contact_name}
              helperText={errors && errors.contact_name}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xl={12} md={4}>
            {/* Contact Phone */}
            <CTextField
              label="Contact Phone"
              name="contact_phone"
              error={!!errors && errors.contact_phone}
              helperText={errors && errors.contact_phone}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xl={12} md={4}>
            {/* Communication */}
            <CTextField
              label="Communication"
              name="communication"
              error={!!errors && errors.communication}
              helperText={errors && errors.communication}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>

        <hr />

        {/* Practitionaer Profile  */}
        <Typography variant="h6">Practitionaer Profile</Typography>
        <Grid container spacing={2}>
          <Grid item xl={12} md={4}>
            {/* ULI */}

            <CTextField
              label="ULI"
              name="uli"
              error={!!errors && errors.uli}
              helperText={errors && errors.uli}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xl={12} md={4}>
            {/* Lab Phys Code */}
            <CTextField
              label="Lab Phys Code"
              name="lab_phys_code"
              error={!!errors && errors.lab_phys_code}
              helperText={errors && errors.lab_phys_code}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xl={12} md={4}>
            {/* Discipline */}
            <CTextField
              label="Discipline"
              name="discipline"
              error={!!errors && errors.discipline}
              helperText={errors && errors.discipline}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xl={12} md={4}>
            {/* Lab Loc Code */}
            <CTextField
              label="Lab Loc Code"
              name="lab_loc_code"
              error={!!errors && errors.lab_loc_code}
              helperText={errors && errors.lab_loc_code}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xl={12} md={4}>
            {/* Skill */}
            <CTextField
              label="Skill"
              name="skill"
              error={!!errors && errors.skill}
              helperText={errors && errors.skill}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>

        <Stack direction="row" justifyContent="flex-end" sx={{ mb: 2 }}>
          <CButton label="Save" icon={<SaveIcon />} type="submit" />
        </Stack>
      </form>
    </>
  )
}
