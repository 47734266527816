import CDataTable from '@/components/CDatatable'
import { Card, CardHeader } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

const FaxInbox = () => {
  const { dashboardInfo, isLoading } = useSelector((state) => state.dashboard)
  const columns = [
    { field: 'sender', headerName: 'Sender' },
    {
      field: 'date',
      headerName: 'Date',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      field: 'attachment',
      headerName: 'Attachment',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => renderCell(''),
    },
    { field: 'deadline', headerName: 'Deadline', minWidth: 150, flex: 1 },

  ]

  return (
    <Card sx={{ p: 2, mb:2 }}>
      <CardHeader
        title="Fax Inbox"
      />

      <CDataTable
        rows={dashboardInfo?.faxes ?? []}
        columns={columns}
        isLoading={isLoading}
        getRowHeight="auto"
      />
    </Card>
  )
}

export default FaxInbox
