import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import TaskIcon from '@mui/icons-material/Task'
import {
  Box,
  Chip,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'

// Local Components
import CDialog from '../../../../../components/CDialog'

// Redux Actions
import { fetchTaskDetails } from '../TaskSlice'
import CCircularProgress from '../../../../../components/CCircularProgress'
import { formatDate, formatDateTime } from '../../../../../utils/cdayjs'

const TaskDetails = ({ taskId }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { patientId } = useParams()

  const { taskDetail, incNumber, isLoading } = useSelector(
    (state) => state.task
  )

  const [open, setOpen] = React.useState(false)

  const handleCDialogOpen = () => {
    setOpen(true)
  }

  const handleCDialogClose = () => {
    setOpen(false)
  }
  useEffect(() => {
    if (open) {
      dispatch(fetchTaskDetails(taskId))
    }
  }, [open, taskId])

  return (
    <>
      <CDialog
        btnTitle="Details"
        size='small'
        headerTitle="Task Details"
        icon={<InfoIcon />}
        handleCDialogOpen={handleCDialogOpen}
        handleCDialogClose={handleCDialogClose}
        open={open}
      >
        <Box maxWidth="50rem">
          {!isLoading && taskDetail ? (
            <>
              <Stack direction="row" justifyContent="space-between" mb={2}>
                <Box>
                  <Typography variant="h6">Priority</Typography>
                  <Chip label={taskDetail.priority} color="primary" />
                </Box>
                <Box>
                  <Typography variant="h6">Status</Typography>
                  <Chip label={taskDetail.status_name} color="warning" />
                </Box>
              </Stack>

              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Assign To</TableCell>
                      <TableCell>Assign By</TableCell>
                      <TableCell>Related Person</TableCell>
                      <TableCell>Date Time</TableCell>
                      <TableCell>Deadline</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {taskDetail.full_name}
                      </TableCell>
                      <TableCell>{taskDetail.assign_by}</TableCell>
                      <TableCell>
                        {taskDetail.first_name} {taskDetail.last_name}
                      </TableCell>
                      <TableCell>
                        {formatDateTime(taskDetail.datetime)}
                      </TableCell>
                      <TableCell>
                        {formatDateTime(taskDetail.deadline)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <Typography variant="h6" mt={2}>
                Task <Chip label="Confidential" color="primary" />
              </Typography>

              <Typography variant="inherit">{taskDetail.task}</Typography>

              <Typography variant="h6" mt={2}>
                Description
              </Typography>
              <Box
                sx={{ wordBreak: 'break-word' }}
                component="div"
                dangerouslySetInnerHTML={{
                  __html: taskDetail.description,
                }}
              />

              <Typography variant="h6" mt={2}>
                Comment
              </Typography>
              <Box
                sx={{ wordBreak: 'break-word' }}
                component="div"
                dangerouslySetInnerHTML={{ __html: taskDetail.comment }}
              />
            </>
          ) : (
            <Box minWidth="50rem" minHeight="30rem">
              <CCircularProgress />
            </Box>
          )}
        </Box>
      </CDialog>
    </>
  )
}

export default TaskDetails
