// // UserList.jsx
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Icon
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import AddIcon from '@mui/icons-material/Add'
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
// Components
import AdminLayout from '@/layouts/AdminLayout'

// Slice
import { setBreadcrumb } from '@/redux/Slice/breadcrumbSlice'
import { Link, useNavigate } from 'react-router-dom'
import CButton from '@/components/CButton'
import CDatatable from '@/components/CDatatable'
import { changeStatus, deleteRecord } from '@/redux/Slice/commonSlice'
import { showError, showSuccess } from '@/redux/Slice/alertSlice'
import CTextField from '@/components/CTextField'
import SaveIcon from '@mui/icons-material/Save'
import { Helmet } from 'react-helmet-async'
import { list } from '../noteTemplateSlice'
import NoteTemplateCreate from './NoteTemplateCreate'

const NoteTemplateList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { noteTemplates, errors, isLoading } = useSelector(
    (state) => state.inventoryNoteTemplate
  )

  const handleQuickFilterValue = (filterModel) => {
    const quickFilterValue = filterModel.quickFilterValues.join(' ')
    console.log(quickFilterValue)
  }
  const handleDelete = async (categoryId, model) => {
    try {
      const res = await dispatch(
        deleteRecord({
          id: categoryId,
          model: model,
        })
      )

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        dispatch(list())
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Error while creating File:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred during File creation.',
        })
      )
    }
  }

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        {
          path: 'inventory/note-template/list',
          label: 'Note Template',
          isLast: false,
        },
        { path: '', label: 'List', isLast: true },
      ])
    )
  }, [dispatch])

  const columns = [
    { field: 'title', headerName: 'Title', flex: 1 },
    { field: 'slug', headerName: 'Slug', flex: 1 },
    {
      field: 'action',
      headerName: 'Action',
      flex: 0.5,
      renderCell: (params) => (
        <ButtonGroup variant="contained" aria-label="Basic button group">
          <CButton
            component={Link}
            to={`/inventory/note-template/update/${params.row.id}`}
            size="small"
            label="Edit"
          />
          <CButton
            label="Delete"
            onClick={() => handleDelete(params.row.id, 'NoteTemplate')}
            sx={{ background: '#eb3333', color: 'white' }}
          />
        </ButtonGroup>
      ),
    },
  ]

  useEffect(() => {
    dispatch(list())
  }, [])

  return (
    <>
      <Helmet>
        <title>Bioaro | Note Template - List</title>
      </Helmet>

      <Card sx={{ p: 2 }}>
        <Stack direction="row" justifyContent="flex-end" mb={1}>
          <CButton
            label="Create"
            icon={<AddIcon />}
            component={Link}
            to={`/inventory/note-template/create`}
          />
        </Stack>
        <CDatatable
          rows={noteTemplates}
          columns={columns}
          isLoading={isLoading}
          handleQuickFilterValue={handleQuickFilterValue}
        />
      </Card>
    </>
  )
}

export default NoteTemplateList
