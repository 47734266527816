import React from 'react'
import NoteTemplateList from './partial/NoteTemplateList';
import NoteTemplateCreate from './partial/NoteTemplateCreate';
import NoteTemplateUpdate from './partial/NoteTemplateUpdate';
const NoteTemplateRoutes = [
  { path: "list/", element: <NoteTemplateList /> },
  { path: "create/", element: <NoteTemplateCreate /> },
  { path: "update/:noteTemplateId", element: <NoteTemplateUpdate /> },
];

export default NoteTemplateRoutes;

