import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  Stack,
  Typography,
  ClickAwayListener,
  ListItem,
} from '@mui/material'
import CIconButton from '@/components/CIconButton'
import {
  AddReaction as AddReactionIcon,
  MoreVert,
  Reply,
  EditNote,
  Redo,
  Delete,
} from '@mui/icons-material'
import EmojiPicker from 'emoji-picker-react'
import { formatDateTimeToTime } from '@/utils/cdayjs'
import { useDispatch, useSelector } from 'react-redux'
import {
  handleForwardClick,
  handleMsgClick,
  messageDelete,
  messageList,
  messageUpdate,
  setMessages,
} from '../chatSlice'
import COptionIconButton from '@/components/COptionIconButton'
import CButton from '@/components/CButton'
import MessageUpdate from './MessageUpdate'
import CImageList from '@/components/CImageList'
import Forward from './Forward'
import { currentUser } from '@/utils/cutils'

const MessageList = ({ socket }) => {
  const dispatch = useDispatch()
  const { messages, receiver, isReply, isOnline } = useSelector(
    (state) => state.chat
  )
  const sender = currentUser
  const room = sender.id.concat(receiver.user_id)
  const [msgUpdateOpen, setMsgUpdateOpen] = useState(false)
  const [forwardOpen, setForwardOpen] = useState(false)
  const scrollRef = useRef()
  const containerRef = useRef()
  const [isAutoScroll, setIsAutoScroll] = useState(true)

  useEffect(() => {
    const handleMessage = async (data) => {
      const res = await dispatch(messageList(receiver.user_id))
      if (res.payload) {
        console.log('hello')
        setMessages(res.payload)
      }
    }

    socket.on('getMessage', handleMessage)

    return () => socket.off('getMessage', handleMessage)
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current
      setIsAutoScroll(scrollHeight - scrollTop === clientHeight)
    }

    containerRef.current?.addEventListener('scroll', handleScroll)

    return () => {
      containerRef.current?.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (isAutoScroll) {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [messages])

  useEffect(() => {
    if (receiver.user_id) {
      dispatch(messageList(receiver.user_id))
    } else {
      setMessages([])
    }
  }, [])

  const handleEmojiClick = (e, index, messageId) => {
    dispatch(messageUpdate({ messageId, data: { reaction: e.emoji } })).then(
      () => {
        if (isOnline) {
          socket.emit('sendMessage', {
            receiverId: receiver.user_id,
          })
        }
        dispatch(messageList(receiver.user_id))
        handleCloseReaction(index)
      }
    )
  }

  const handleAddReaction = (index) => {
    const updatedMessage = [...messages]
    updatedMessage[index] = { ...updatedMessage[index], open: true }
    dispatch(setMessages(updatedMessage))
  }

  const handleCloseReaction = (index) => {
    const updatedMessage = [...messages]
    updatedMessage[index] = { ...updatedMessage[index], open: false }
    dispatch(setMessages(updatedMessage))
  }

  const handleDeleteMessage = (messageId) => {
    dispatch(messageDelete(messageId)).then((res) => {
      if (isOnline) {
        socket.emit('sendMessage', {
          receiverId: receiver.user_id,
        })
      }
      dispatch(messageList(receiver.user_id))
    })
  }

  const renderAttachments = (attachments) => {
    if (!attachments || attachments.length === 0) return null
    return <CImageList attachments={attachments} />
  }

  const renderMessageActions = (index, item) => (
    <Box
      className="more-and-react"
      sx={{ display: 'none', position: 'relative' }}
    >
      <CIconButton
        icon={<AddReactionIcon />}
        color="disabled"
        onClick={() => handleAddReaction(index)}
      />
      <CIconButton
        icon={<Reply />}
        color="disabled"
        onClick={() =>
          dispatch(
            handleMsgClick({
              isReply: !isReply,
              messageId: item.id,
              messageIndex: index,
            })
          )
        }
      />
      <COptionIconButton icon={<MoreVert />}>
        <ListItem>
          {isSender(item) && (
            <CButton
              label="Edit"
              icon={<EditNote />}
              variant="text"
              fullWidth
              onClick={() => {
                setMsgUpdateOpen(true)
                dispatch(
                  handleMsgClick({
                    isReply: false,
                    messageId: item.id,
                    messageIndex: index,
                  })
                )
              }}
            />
          )}
        </ListItem>
        <ListItem>
          {isSender(item) && (
            <CButton
              label="Delete"
              icon={<Delete />}
              variant="text"
              fullWidth
              onClick={() => handleDeleteMessage(item.id)}
            />
          )}
        </ListItem>
        <ListItem>
          <CButton
            label="Forward"
            icon={<Redo />}
            variant="text"
            fullWidth
            onClick={() => {
              setForwardOpen(true)
              dispatch(
                handleForwardClick({
                  messageId: item.id,
                })
              )
            }}
          />
        </ListItem>
      </COptionIconButton>
      {item.open && (
        <EmojiPicker
          reactionsDefaultOpen
          onEmojiClick={(e, emojiObject) => handleEmojiClick(e, index, item.id)}
          pickerStyle={{
            position: 'absolute',
            top: 0,
            right: -200,
            zIndex: 1,
          }}
        />
      )}
    </Box>
  )

  const isSender = (item) => {
    if (item.room === room) {
      return true
    }
    return false
  }

  return (
    <Box
      ref={containerRef}
      sx={{
        flexGrow: 1,
        padding: '16px',
        overflow: 'auto',
        backgroundColor: '#fafafa',
      }}
    >
      <Stack direction="column" spacing={3}>
        {messages?.map((item, index) => (
          <React.Fragment key={index}>
            <Stack
              ref={scrollRef}
              direction="row"
              spacing={1}
              justifyContent={isSender(item) ? 'end' : 'start'}
              alignItems="center"
              // onMouseLeave={() => handleCloseReaction(index)}
              sx={{
                '&:hover .more-and-react': {
                  display: 'flex',
                },
              }}
            >
              {isSender(item) && renderMessageActions(index, item)}
              <Box
                sx={{
                  backgroundColor: isSender(item) ? '#dcf8c6' : '#fff',
                  color: 'black',
                  borderRadius: '7.5px',
                  padding: '10px 15px',
                  maxWidth: '60%',
                  minWidth: '60px',
                  alignSelf: isSender(item) ? 'flex-end' : 'flex-start',
                  position: 'relative',
                }}
              >
                {item.parent_chat && (
                  <Box
                    sx={{
                      backgroundColor: '#e1f3fb',
                      borderRadius: '7.5px',
                      padding: '5px 10px',
                      marginBottom: '5px',
                      borderLeft: '3px solid #34b7f1',
                    }}
                  >
                    <Typography variant="body2" sx={{ color: '#34b7f1' }}>
                      {item.parent_chat.sender_name}
                    </Typography>
                    <Typography variant="body2">
                      {item.parent_chat.text}
                    </Typography>
                    <CImageList attachments={item.parent_chat.attachments} />
                  </Box>
                )}
                <Typography variant="body1">{item.text}</Typography>
                {renderAttachments(item.attachments)}
                <Typography
                  variant="caption"
                  sx={{
                    color: 'text.disabled',
                    position: 'absolute',
                    bottom: -22,
                    right: 5,
                  }}
                >
                  {formatDateTimeToTime(item.created_at)}
                </Typography>
                {item.reaction && (
                  <Typography
                    variant="caption"
                    sx={{
                      position: 'absolute',
                      top: -15,
                      right: 0,
                      backgroundColor: '#fff',
                      borderRadius: '50%',
                      padding: '5px',
                      boxShadow: '0 0 3px rgba(0,0,0,0.3)',
                    }}
                  >
                    {item.reaction}
                  </Typography>
                )}
              </Box>
              {!isSender(item) && renderMessageActions(index, item)}
            </Stack>
          </React.Fragment>
        ))}
      </Stack>

      {msgUpdateOpen && (
        <MessageUpdate
          handleMsgUpdateOpen={() => setMsgUpdateOpen(false)}
          socket={socket}
        />
      )}
      {forwardOpen && (
        <Forward handleForwardDialog={() => setForwardOpen(false)} />
      )}
    </Box>
  )
}

export default React.memo(MessageList)
