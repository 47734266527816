import React, { useEffect, useState } from 'react'
import { Box, IconButton, Tab } from '@mui/material'
import { Add as AddIcon } from '@mui/icons-material'
import CTextField from '@/components/CTextField'
import ChatList from './ChatList'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { useDispatch, useSelector } from 'react-redux'
import { patientList, setChatList, staffList } from '../chatSlice'
import { currentUser } from '@/utils/cutils'

const Sidebar = () => {
  const dispatch = useDispatch()
  const [tab, setTab] = useState('patient')
  const { onlineUsers, chatList } = useSelector((state) => state.chat)
  const [updatedChatList, setUpdatedChatList] = useState(chatList)

  useEffect(() => {
    const fetchPatients = async () => {
      const res = await dispatch(patientList())
      dispatch(setChatList(res.payload.patients))
    }
    fetchPatients()
  }, [dispatch])

  useEffect(() => {
    const onlineUserIds = new Set(onlineUsers?.map((user) => user.id))
    const updatedUsers = chatList?.map((user) => ({
      ...user,
      online: onlineUserIds.has(user.user_id),
    }))
    setUpdatedChatList(updatedUsers)
  }, [chatList, onlineUsers])

  const handleTabChange = async (event, value) => {
    setTab(value)
    if (value === 'patient') {
      const res = await dispatch(patientList())
      dispatch(setChatList(res.payload.patients))
    } else if (value === 'staff') {
      const res = await dispatch(staffList())
      dispatch(setChatList(res.payload.staffs))
    } else if (value === 'group') {
      // Handle group logic here if needed
    }
  }

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase()
    const onlineUserIds = new Set(onlineUsers.map((user) => user.id))
    const filteredChatList = chatList
      .map((user) => ({
        ...user,
        online: onlineUserIds.has(user.user_id),
      }))
      .filter((user) => user.full_name.toLowerCase().includes(searchTerm))
    setUpdatedChatList(filteredChatList)
  }

  const renderTabs = () => {
    const tabs = []

    if ([1, 5].includes(currentUser.role_id)) {
      tabs.push(<Tab label="Staff" value="staff" key="staff" />)
      tabs.push(<Tab label="Patient" value="patient" key="patient" />)
      tabs.push(<Tab label="Group" value="group" key="group" />)
    }

    if (currentUser.role_id === 2) {
      tabs.push(
        <Tab label="Health Professional" value="patient" key="patient" />
      )
      tabs.push(<Tab label="Group" value="group" key="group" />)
    }

    return tabs
  }

  return (
    <Box
      sx={{
        width: '300px',
        backgroundColor: '#f5f5f5',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {[1, 5].includes(currentUser.role_id) && (
        <Box
          sx={{
            padding: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <CTextField
            variant="outlined"
            placeholder="Search Messenger"
            size="small"
            onChange={handleSearch}
            sx={{ backgroundColor: 'white', borderRadius: 1 }}
          />
          <IconButton>
            <AddIcon />
          </IconButton>
        </Box>
      )}

      <TabContext value={tab}>
        <TabList onChange={handleTabChange} centered>
          {renderTabs()}
        </TabList>

        <TabPanel value="patient">
          <ChatList chatList={updatedChatList} />
        </TabPanel>

        <TabPanel value="staff">
          <ChatList chatList={updatedChatList} />
        </TabPanel>

        <TabPanel value="group">
          {/* Add group-related content here */}
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default React.memo(Sidebar)
