import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'

// Local components
import CEditor from '../../../../../components/CEditor'
import CDialog from '../../../../../components/CDialog'
import CAutocomplete from '../../../../../components/CAutocomplete'
import CDatePicker from '../../../../../components/CDatePicker'
import CTimePicker from '../../../../../components/CTimePicker'
import { Box, Button, Grid, Stack } from '@mui/material'

// Redux actions
import { fetchFollowUp, fetchFollowUpEdit, update } from '../followUpSlice'
import { showError, showSuccess } from '../../../../../redux/Slice/alertSlice'

// Utilities
import { formatDate, formatTime } from '../../../../../utils/cdayjs'
import CButton from '@/components/CButton'
import { Save } from '@mui/icons-material'

export const FollowUpUpdate = React.memo(({ followUpId }) => {
  const dispatch = useDispatch()
  const { patientId } = useParams()

  const [open, setOpen] = useState(false)
  const { errors, followUpDetails, staff } = useSelector(
    (state) => state.followUp
  )

  const [followUp, setFollowUp] = useState({
    assign_to: '',
    assign_date: '',
    assign_time: '',
    note: '',
  })

  useEffect(() => {
    if (open) {
      dispatch(fetchFollowUpEdit({ followUpId }))
    }
  }, [open, dispatch, followUpId])

  useEffect(() => {
    if (followUpDetails) {
      setFollowUp(followUpDetails)
    }
  }, [followUpDetails])

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setFollowUp((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const res = await dispatch(
        update({
          data: followUp,
          followUpId,
        })
      )

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        // dispatch(fetchFollowUp(patientId))
        setOpen(false)
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Error while updating follow-up:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred during follow-up update.',
        })
      )
    }
  }

  return (
    <CDialog
      btnTitle="Edit"
      size="small"
      headerTitle="Follow Up Update"
      handleCDialogOpen={() => setOpen(true)}
      handleCDialogClose={() => setOpen(false)}
      open={open}
    >
      <Box maxWidth="50rem">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <CAutocomplete
                options={staff}
                getOptionLabel={(option) => option.full_name}
                value={followUp?.assign_to}
                onChange={(e, value) =>
                  setFollowUp((prev) => ({
                    ...prev,
                    assign_to: value,
                  }))
                }
                label="Staff"
                name="assign_to"
                error={!!errors?.assign_to}
                helperText={errors?.assign_to}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CDatePicker
                label="Date"
                name="assign_date"
                onChange={(value) =>
                  setFollowUp((prev) => ({
                    ...prev,
                    assign_date: formatDate(value),
                  }))
                }
                value={
                  followUp?.assign_date ? dayjs(followUp.assign_date) : null
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CTimePicker
                label="Time"
                name="assign_time"
                value={
                  followUp?.assign_time
                    ? formatTime(followUp.assign_time)
                    : null
                }
                onChange={(value) =>
                  setFollowUp((prev) => ({
                    ...prev,
                    assign_time: dayjs(value).format('hh:mm A'),
                  }))
                }
              />
            </Grid>
            <Grid item xs={12}>
              <label htmlFor="note">Note</label>
              <CEditor
                name="note"
                value={followUp?.note}
                handleChange={(value) =>
                  setFollowUp((prev) => ({
                    ...prev,
                    note: value,
                  }))
                }
              />
            </Grid>
          </Grid>
          <Stack direction="row" justifyContent="flex-end" sx={{ mb: 2 }}>
            <CButton label="Save" icon={<Save />} type="submit" />
          </Stack>
        </form>
      </Box>
    </CDialog>
  )
})
