import { Box, Divider } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CachedIcon from '@mui/icons-material/Cached'

import CDrawer from 'src/components/CDrawer'
import CSelect from 'src/components/CSelect'

import { persistor } from 'src/redux/store'
import CButton from 'src/components/CButton'

const Settings = () => {
  const dispatch = useDispatch()

  const handleCache = () => {
    persistor.purge().then(() => {
      location.reload()
    })
  }

  return (
    <CDrawer>
      <Box sx={{ p: 2 }}>
        <CButton
          label="Clear Cache"
          icon={<CachedIcon />}
          onClick={handleCache}
          fullWidth={true}
        />
      </Box>
    </CDrawer>
  )
}

export default React.memo(Settings)
