// productSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '@/services/axiosConfig'

const PREFIX = 'product'

// Fetch product list
export const productList = createAsyncThunk(`${PREFIX}/list`, async () => {
  const response = await axiosInstance.get(`${PREFIX}/list`)
  return response.data
})

export const info = createAsyncThunk(`${PREFIX}/info`, async () => {
  const response = await axiosInstance.get(`${PREFIX}/info`)
  return response.data
})

// Fetch sub-category list
export const subCategoryList = createAsyncThunk(
  `${PREFIX}/sub-category`,
  async (categoryId) => {
    console.log(categoryId)
    const response = await axiosInstance.get(
      `${PREFIX}/sub-category-list/${categoryId}`
    )
    return response.data
  }
)

// Fetch product info
export const productDetail = createAsyncThunk(
  `${PREFIX}/detail`,
  async (productId) => {
    const response = await axiosInstance.get(`${PREFIX}/detail/${productId}`)
    console.log(response.data)
    return response.data
  }
)

// Create product
export const productCreate = createAsyncThunk(
  `${PREFIX}/create`,
  async (data) => {
    const response = await axiosInstance.post(`${PREFIX}/create`, data)
    return response.data
  }
)

// Update product
export const productUpdate = createAsyncThunk(
  `${PREFIX}/update`,
  async ({ productId, data }) => {
    const response = await axiosInstance.post(
      `${PREFIX}/update/${productId}`,
      data
    )
    return response.data
  }
)

const productSlice = createSlice({
  name: 'product',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    products: [],
    product: null,
    categories: [],
    subCategories: [],
    sites: [],
    users: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch product list
      .addCase(productList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(productList.fulfilled, (state, { payload }) => {
        state.products = payload.products
        state.isLoading = false
      })
      .addCase(productList.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch info
      .addCase(info.pending, (state) => {
        state.isLoading = true
      })
      .addCase(info.fulfilled, (state, { payload }) => {
        state.sites = payload.sites
        state.categories = payload.categories
        state.isLoading = false
      })
      .addCase(info.rejected, (state) => {
        state.isLoading = false
      })

      // Product Detail
      .addCase(productDetail.pending, (state) => {
        state.isLoading = true
      })
      .addCase(productDetail.fulfilled, (state, { payload } = action) => {
        state.product = payload.product
        state.isLoading = false
      })
      .addCase(productDetail.rejected, (state, { payload }) => {
        state.isLoading = false
      })

      // Fetch sub-category list
      .addCase(subCategoryList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(subCategoryList.fulfilled, (state, { payload }) => {
        state.subCategories = payload.subCategories
        state.isLoading = false
      })
      .addCase(subCategoryList.rejected, (state) => {
        state.isLoading = false
      })

      // Create product
      .addCase(productCreate.pending, (state) => {
        state.isLoading = true
      })
      .addCase(productCreate.fulfilled, (state, { payload }) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(productCreate.rejected, (state) => {
        state.isLoading = false
      })

      // Update product
      .addCase(productUpdate.pending, (state) => {
        state.isLoading = true
      })
      .addCase(productUpdate.fulfilled, (state, { payload }) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(productUpdate.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default productSlice.reducer
