import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { Box, Card, Stack, Typography } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import CDatatable from '@/components/CDatatable'
import CButton from '@/components/CButton'
import { setBreadcrumb } from '@/redux/Slice/breadcrumbSlice'
import { changeStatus, deleteRecord } from '@/redux/Slice/commonSlice'
import { showError, showSuccess } from '@/redux/Slice/alertSlice'
import { productList } from './productSlice'
import ProductDetails from './Partials/ProductDetails'
import { renderCell } from '@/utils/ctableTool'
import ProductCreate from './Partials/ProductCreate'

const ProductList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { sites, categories, subCategories, products, errors, isLoading } =
    useSelector((state) => state.product)

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          path: '/product',
          label: 'Product',
          isLast: false,
        },
        { path: '', label: 'List', isLast: true },
      ])
    )
  }, [dispatch])

  const handleQuickFilterValue = (filterModel) => {
    const quickFilterValue = filterModel.quickFilterValues.join(' ')
    console.log(quickFilterValue)
  }

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      field: 'invCategory',
      headerName: 'Category',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      field: 'invSubCategory',
      headerName: 'SubCategory',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      field: 'price',
      headerName: 'Price',
      minWidth: 100,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 100,
      flex: 1,
      renderCell: (params) =>
        renderCell(
          <CButton
            component={Link}
            label={params.row.status == 1 ? 'On' : 'Off'}
            sx={{ background: params.row.status == 1 ? '#0a8154' : '#cf2323' }}
            onClick={() =>
              handleStatusChange(
                params.row.id,
                params.row.status == 0 ? 1 : 0,
                'ProductList',
                'status'
              )
            }
          />
        ),
    },
    {
      field: 'action',
      headerName: 'Action',
      minWidth: 300,
      flex: 1,
      renderCell: (params) =>
        renderCell(
          <Stack direction="row" spacing={0.2}>
            <ProductDetails productInfo={params.row.site} />
            <CButton
              component={Link}
              label="View"
              sx={{ background: '#41464b' }}
              onClick={() =>
                handleStatusChange(
                  params.row.id,
                  params.row.default == 0 ? 1 : 0,
                  'Site',
                  'default'
                )
              }
            />
            <CButton
              component={Link}
              label="Edit"
              to={`/product/update/${params.row.id}`}
            />
            <CButton
              label="Delete"
              yesNo
              sx={{ background: 'red' }}
              onClick={() => handleDelete(params.row.id, 'ProductList')}
            />
          </Stack>
        ),
    },
  ]

  const handleDelete = async (bannerId, model) => {
    try {
      const res = await dispatch(deleteRecord({ id: bannerId, model: model }))
      if (res.payload && !res.payload.error) {
        dispatch(showSuccess({ success: true, message: res.payload.message }))
        dispatch(productList())
      } else {
        dispatch(showError({ error: true, message: res.payload.message }))
      }
    } catch (error) {
      console.error('Error while creating File:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred during File creation.',
        })
      )
    }
  }

  const handleStatusChange = async (productId, status, model, column) => {
    try {
      const res = await dispatch(
        changeStatus({
          id: productId,
          status: status,
          model: model,
          column: column,
        })
      )
      if (res.payload && !res.payload.error) {
        dispatch(showSuccess({ success: true, message: res.payload.message }))
        dispatch(productList())
      } else {
        dispatch(showError({ error: true, message: res.payload.message }))
      }
    } catch (error) {
      console.error('Error while creating File:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred during File creation.',
        })
      )
    }
  }

  useEffect(() => {
    dispatch(productList())
  }, [dispatch])

  return (
    <>
      <Helmet>
        <title>Bioaro | Product - List</title>
      </Helmet>
      <Stack direction="row" sx={{ width: '100%', pb: 2 }}>
        <ProductCreate />
      </Stack>
      <Card sx={{ p: 2 }}>
        <Typography variant="h5" p={2}>
          Product List
        </Typography>
        <CDatatable
          rows={products ?? []}
          columns={columns}
          isLoading={isLoading}
          handleQuickFilterValue={handleQuickFilterValue}
        />
      </Card>
    </>
  )
}

export default ProductList
