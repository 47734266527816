import React from 'react'
import SeoList from './partial/SeoList';
import SeoCreate from './partial/SeoCreate';
import SeoUpdate from './partial/SeoUpdate';
import SeoDefault from './partial/SeoDefault';
const seoRoutes = [
  { path: "list/", element: <SeoList /> },
  { path: "create/", element: <SeoCreate /> },
  { path: "update/:seoId", element: <SeoUpdate /> },
  { path: "default/", element: <SeoDefault /> },
];

export default seoRoutes;

