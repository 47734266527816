import { createTheme } from '@mui/material'

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    custom: {
      main: '#121212',
    },
    primary: {
      main: '#fff',
    },
    text: {
      primary: '#fff',
      nova: '#212B36',
    },
  },
})

export default darkTheme
