import { useNavigate } from 'react-router-dom'
import { retrieveData } from '@/services/localStorage'
import { toBoolean } from '@/utils/cutils'

const securityAnswerCheck = () => {
  const navigate = useNavigate()
  const isAdmin = retrieveData('isAdmin')
  const trustedBrowser = toBoolean(retrieveData('trustedBrowser'))
  const hasOtpSent = toBoolean(retrieveData('hasOtpSent'))

  if (!isAdmin) {
    if (hasOtpSent) {
      window.location = '/security-answer-reset'
    } else if (!trustedBrowser) {
       window.location = '/security-answer-verify'
    }
  }
}

export default securityAnswerCheck
