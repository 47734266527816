import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import dayjs from 'dayjs'

import {
  Card,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'

import { Helmet } from 'react-helmet-async'
import CTextField from '../../../components/CTextField'
import CAutocomplete from '../../../components/CAutocomplete'
import CSelect from '../../../components/CSelect'
import CDatePicker from '../../../components/CDatePicker'

import { create, getProvincesAndClientTypes } from './referralSlice' // Updated import for create action
import { showSuccess, showError } from '../../../redux/Slice/alertSlice'
import { setBreadcrumb } from '../../../redux/Slice/breadcrumbSlice'
import { formatDate } from '../../../utils/cdayjs'
import CButton from '../../../components/CButton'

const ReferralCreate = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { errors, provinces, clientTypes, isLoading } = useSelector(
    (state) => state.referral
  )

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        { path: '/referral/list', label: 'Referral', isLast: false },
        { path: '', label: 'Create', isLast: true },
      ])
    )
  }, [dispatch])

  // Initialize local state for the new referral
  const [referral, setReferral] = useState({
    first_name: '',
    middle_name: '',
    last_name: '',
    email: '',
    phone: '',
    fax: '',
    recovery_prov: null,
    reg_no: '',
    practice: '',
    client_type_id: null,
    end_date: null,
    end_date_reason: '',
    first_act_date: null,
    last_act_date: null,
    diag_code: '',
    default_doc: '',
    referral_doc: '',
    hosp_amd_date: null,
  })

  // Use useEffect to fetch provinces and client types when the component mounts
  useEffect(() => {
    dispatch(getProvincesAndClientTypes()) // Call the action to fetch data
  }, [dispatch])

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const res = await dispatch(create({ data: referral })) // Use the create action

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        navigate('/referral/list')
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Server error:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred while creating the doctor.',
        })
      )
    }
  }

  // onChange handler to update the local state
  const handleInputChange = (event) => {
    const { name, value } = event.target
    // Update the local state with the changed value
    setReferral((newReferral) => ({
      ...newReferral,
      [name]: value,
    }))
  }

  return (
    <>
      <Helmet>
        <title>Bioaro | Referral - Referral Create</title>
      </Helmet>
      {!isLoading ? (
        <Stack direction="row" justifyContent="center">
          <Card sx={{ p: 3 }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  {/* First Name */}
                  <CTextField
                    label="First Name"
                    name="first_name"
                    value={referral?.first_name}
                    error={!!errors && errors.first_name}
                    helperText={errors && errors.first_name}
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  {/* Middle Name */}
                  <CTextField
                    label="Middle Name"
                    name="middle_name"
                    value={referral?.middle_name}
                    error={!!errors && errors.middle_name}
                    helperText={errors && errors.middle_name}
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  {/* Last Name */}
                  <CTextField
                    label="Last Name"
                    name="last_name"
                    value={referral?.last_name}
                    error={!!errors && errors.last_name}
                    helperText={errors && errors.last_name}
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  {/* Email */}
                  <CTextField
                    label="Email"
                    name="email"
                    value={referral?.email}
                    error={!!errors && errors.email}
                    helperText={errors && errors.email}
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  {/* Phone */}
                  <CTextField
                    label="Phone"
                    name="phone"
                    value={referral?.phone}
                    error={!!errors && errors.phone}
                    helperText={errors && errors.phone}
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  {/* Fax */}
                  <CTextField
                    label="Fax"
                    name="fax"
                    value={referral?.fax}
                    error={!!errors && errors.fax}
                    helperText={errors && errors.fax}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>

              <Divider />
              {/* Address & Contact  */}
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h5">Address & Contact</Typography>
                  <Typography>
                    *Referral Doctor address, phone number and email are managed
                    on the 'Ref Doc tab
                  </Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <CAutocomplete
                        label="Recovery Province"
                        options={provinces}
                        getOptionLabel={(option) => option.name}
                        value={referral?.recovery_prov}
                        isOptionEqualToValue={(option, value) =>
                          option.name == value.name
                        }
                        onChange={(e, value) =>
                          setReferral({ ...referral, recovery_prov: value })
                        }
                        error={!!(errors && errors.recovery_prov)}
                        helperText={errors && errors.recovery_prov}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <CTextField
                        label="Reg No."
                        name="reg_no"
                        value={referral?.reg_no}
                        error={!!errors && errors.reg_no}
                        helperText={errors && errors.reg_no}
                        onChange={handleInputChange}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <CTextField
                        label="Practice Id"
                        name="practice"
                        value={referral?.practice}
                        error={!!errors && errors.practice}
                        helperText={errors && errors.practice}
                        onChange={handleInputChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Divider />
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={3}>
                  <CAutocomplete
                    label="Client Type"
                    options={clientTypes}
                    getOptionLabel={(option) => option.name}
                    defaultValue={referral?.client_type_id}
                    isOptionEqualToValue={(option, value) =>
                      option.name == value.name
                    }
                    onChange={(e, value) =>
                      setReferral({ ...referral, client_type_id: value })
                    }
                    error={!!(errors && errors.client_type_id)}
                    helperText={errors && errors.client_type_id}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CDatePicker
                    label="End Date"
                    name="end_date"
                    value={
                      referral?.end_date ? dayjs(referral?.end_date) : null
                    }
                    onChange={(value) =>
                      setReferral({ ...referral, end_date: formatDate(value) })
                    }
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CSelect
                    label="End Date Reason"
                    name="end_date_reason"
                    value={referral?.end_date_reason}
                    options={[
                      { label: 'r1', value: 'r1' },
                      { label: 'r2', value: 'r2' },
                    ]}
                    onChange={(e) =>
                      setReferral({
                        ...referral,
                        end_date_reason: e.target.value,
                      })
                    }
                    error={!!(errors && errors.end_date_reason)}
                    helperText={errors && errors.end_date_reason}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CDatePicker
                    label="First Act Date"
                    name="first_act_date"
                    value={
                      referral?.first_act_date
                        ? dayjs(referral?.first_act_date)
                        : null
                    }
                    onChange={(value) =>
                      setReferral({
                        ...referral,
                        first_act_date: formatDate(value),
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CDatePicker
                    label="Last Act Date"
                    name="last_act_date"
                    value={
                      referral?.last_act_date
                        ? dayjs(referral?.last_act_date)
                        : null
                    }
                    onChange={(value) =>
                      setReferral({
                        ...referral,
                        last_act_date: formatDate(value),
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CTextField
                    label="Dig Code 1"
                    name="diag_code"
                    value={referral?.diag_code}
                    error={!!errors && errors.diag_code}
                    helperText={errors && errors.diag_code}
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CTextField
                    label="Default Doc"
                    name="default_doc"
                    value={referral?.default_doc}
                    error={!!errors && errors.default_doc}
                    helperText={errors && errors.default_doc}
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CTextField
                    label="Ref Doc"
                    name="referral_doc"
                    value={referral?.referral_doc}
                    error={!!errors && errors.referral_doc}
                    helperText={errors && errors.referral_doc}
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CDatePicker
                    label="Hosp Adm Date"
                    name="hosp_amd_date"
                    value={
                      referral?.hosp_amd_date
                        ? dayjs(referral?.hosp_amd_date)
                        : null
                    }
                    onChange={(value) =>
                      setReferral({
                        ...referral,
                        hosp_amd_date: formatDate(value),
                      })
                    }
                  />
                </Grid>
              </Grid>

              <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
                <CButton label="Save" icon={<SaveIcon />} type="submit" />
              </Stack>
            </form>
          </Card>
        </Stack>
      ) : (
        <div
          style={{
            display: 'grid',
            placeItems: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress />
        </div>
      )}
    </>
  )
}

export default ReferralCreate
