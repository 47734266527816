import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import {
  Box,
  ClickAwayListener,
  Fab,
  InputAdornment,
  Stack,
  Typography,
  IconButton,
} from '@mui/material'
import {
  Add as AddIcon,
  Send as SendIcon,
  AddReaction as AddReactionIcon,
  Image,
  AttachFile,
  Close as CloseIcon,
} from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import CTextField from '@/components/CTextField'
import CIconButton from '@/components/CIconButton'
import EmojiPicker from 'emoji-picker-react'
import { useDispatch, useSelector } from 'react-redux'
import { messageCreate, messageList, setMessages } from '../chatSlice'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

const MessageInput = ({ socket }) => {
  const dispatch = useDispatch()
  const isOnline = useSelector((state) => state.chat.isOnline)
  const receiver = useSelector((state) => state.chat.receiver)
  const id = useSelector((state) => state.auth.user)
  const [message, setMessage] = useState('')
  const [emojiPicker, setEmojiPicker] = useState(false)
  const [openActionButtons, setOpenActionButtons] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState([])
  const fileInputRef = useRef(null)
  const imageInputRef = useRef(null)

  useEffect(() => {
    const handleMessage = async (data) => {
      console.log('message received')
      const res = await dispatch(messageList(receiver.user_id))
      if (res.payload) {
        setMessages(res.payload)
      }
    }

    socket.on('getMessage', handleMessage)

    return () => socket.off('getMessage', handleMessage)
  }, [])

  const handleSendMessage = useCallback(() => {
    if (message.trim() !== '' || selectedFiles.length > 0) {
      const data = {
        receiverId: receiver.user_id,
        text: message,
        attachments: selectedFiles,
      }

      dispatch(messageCreate(data)).then((res) => {
        console.log('first')
        if (isOnline) {
          console.log('second')
          socket.emit('sendMessage', data)
        }
        dispatch(messageList(data.receiverId))
        setMessage('')
        setSelectedFiles([])
      })
    }
  }, [message, selectedFiles, receiver.user_id, isOnline, dispatch, socket])

  const handleFileUpload = useCallback((event) => {
    const files = Array.from(event.target.files)
    setSelectedFiles((prevFiles) => [...prevFiles, ...files])
  }, [])

  const handleImageUpload = useCallback((event) => {
    const images = Array.from(event.target.files)
    setSelectedFiles((prevFiles) => [...prevFiles, ...images])
  }, [])

  const triggerFileInput = useCallback(() => {
    fileInputRef.current.click()
  }, [])

  const triggerImageInput = useCallback(() => {
    imageInputRef.current.click()
  }, [])

  const removeFile = useCallback((index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index))
  }, [])

  const actionButtons = useMemo(
    () => (
      <ClickAwayListener onClickAway={() => setOpenActionButtons(false)}>
        <Box sx={{ position: 'relative' }}>
          <CIconButton
            icon={<AddIcon />}
            onClick={() => setOpenActionButtons((prev) => !prev)}
          />
          {openActionButtons && (
            <Stack
              spacing={0.5}
              sx={{ position: 'absolute', bottom: 40, left: 0 }}
            >
              <Fab
                color="primary"
                aria-label="upload-image"
                size="small"
                onClick={triggerImageInput}
              >
                <Image />
              </Fab>
              <Fab
                color="primary"
                aria-label="upload-file"
                size="small"
                onClick={triggerFileInput}
              >
                <AttachFile />
              </Fab>
            </Stack>
          )}
        </Box>
      </ClickAwayListener>
    ),
    [openActionButtons, triggerFileInput, triggerImageInput]
  )

  return (
    <Box
      sx={{
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderTop: '1px solid #ddd',
      }}
    >
      <Stack direction="row" sx={{ width: '100%', marginBottom: '16px' }}>
        {actionButtons}
        <CTextField
          value={message}
          variant="outlined"
          placeholder="Aa"
          size="medium"
          multiline
          maxRows={5}
          sx={{ flex: 1, marginRight: '16px' }}
          onChange={(e) => setMessage(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CIconButton
                  icon={<AddReactionIcon />}
                  onClick={() => setEmojiPicker((prev) => !prev)}
                />
                {emojiPicker && (
                  <EmojiPicker
                    previewConfig={{ showPreview: true }}
                    height={400}
                    searchDisabled
                    lazyLoadEmojis
                    onEmojiClick={(param) =>
                      setMessage(
                        (prevMessage) => `${prevMessage}${param.emoji}`
                      )
                    }
                    style={{
                      position: 'absolute',
                      bottom: 60,
                      right: 0,
                      zIndex: 1,
                    }}
                  />
                )}
              </InputAdornment>
            ),
          }}
        />
        <CIconButton icon={<SendIcon />} onClick={handleSendMessage} />
      </Stack>
      <Stack
        direction="row"
        spacing={1}
        sx={{ width: '100%', flexWrap: 'wrap' }}
      >
        {selectedFiles.map((file, index) => (
          <Box
            key={index}
            sx={{
              position: 'relative',
              width: '50px',
              height: '50px',
              marginBottom: '8px',
              border: '1px solid #ddd',
              borderRadius: '8px',
              overflow: 'hidden',
            }}
          >
            {file.type.startsWith('image/') ? (
              <img
                src={URL.createObjectURL(file)}
                alt="preview"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            ) : (
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#f5f5f5',
                }}
              >
                <Typography variant="body2">{file.name}</Typography>
              </Box>
            )}
            <IconButton
              size="small"
              sx={{ position: 'absolute', top: -8, right: -8, color: 'red' }}
              onClick={() => removeFile(index)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        ))}
      </Stack>
      <VisuallyHiddenInput
        type="file"
        ref={fileInputRef}
        onChange={handleFileUpload}
      />
      <VisuallyHiddenInput
        type="file"
        accept="image/*"
        ref={imageInputRef}
        onChange={handleImageUpload}
      />
    </Box>
  )
}

export default React.memo(MessageInput)
