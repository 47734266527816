// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../../../services/axiosConfig'

// Fetch Patient Based Trash List
export const fetchTrashList = createAsyncThunk(
  'patient/trash/list',
  async (patientId) => {
    const response = await axiosInstance.get(`patient/trash/list/${patientId}`)
    return response.data
  }
)

// Restore Trash
export const restoreTrash = createAsyncThunk(
  'patient/trash/restore',
  async (trashId) => {
    const response = await axiosInstance.post(
      `patient/trash/restore/${trashId}`
    )
    return response.data
  }
)

const trashSlice = createSlice({
  name: 'trash',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    trashes: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch  Trash list
      .addCase(fetchTrashList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchTrashList.fulfilled, (state, { payload }) => {
        state.trashes = payload.trashes
        state.isLoading = false
      })
      .addCase(fetchTrashList.rejected, (state) => {
        state.isLoading = false
      })

      // Restore Trash
      .addCase(restoreTrash.pending, (state) => {
        state.isLoading = true
      })
      .addCase(restoreTrash.fulfilled, (state, { payload }) => {
        state.message = payload.message
        state.isLoading = false
      })
      .addCase(restoreTrash.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default trashSlice.reducer
