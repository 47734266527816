// // UserList.jsx
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { formatDateTime } from '../../../utils/cdayjs'

// Components
import { Helmet } from 'react-helmet-async'
import CDatatable from '../../../components/CDatatable'
import AddIcon from '@mui/icons-material/Add'
import DownloadIcon from '@mui/icons-material/Download'
import DeleteIcon from '@mui/icons-material/Delete'
import SendIcon from '@mui/icons-material/Send'
// Slice
import { setBreadcrumb } from '../../../redux/Slice/breadcrumbSlice'
import {
  Box,
  Button,
  Card,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from '@mui/material'
import CButton from '../../../components/CButton'
import { useDispatch, useSelector } from 'react-redux'
import CDataTable from '../../../components/CDatatable'
import { Edit } from '@mui/icons-material'
import dayjs from 'dayjs'
import { consultationPaymentList } from './consultationSlice'

const PaymentConsultation = () => {
  const dispatch = useDispatch()
  const { payments, isLoading } = useSelector((state) => state.consultation)
  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        {
          path: '/consultation/payment/list',
          label: 'Consultation',
          isLast: false,
        },
        { path: '', label: 'Consultation Payment List', isLast: true },
      ])
    )
  }, [dispatch])

  const [quickFilterValue, setQuickfilterValue] = useState(null)
  // add this when you use server side filter
  const handleQuickFilterValue = (filterModel) => {
    const quickFilterValue = filterModel.quickFilterValues.join(' ')
    dispatch(consultationPaymentList(quickFilterValue))
    setQuickfilterValue(quickFilterValue)
  }

  const columns = [
    {
      field: 'biller_name',
      headerName: 'User Name',
      flex: 0.5,
    },

    {
      field: 'invoice_number',
      headerName: 'Invoice Number',
      flex: 0.4,
    },
    {
      field: 'patientName',
      headerName: 'Type',
      flex: 0.7,
    },
    {
      field: 'addresses',
      headerName: 'Total',
      flex: 0.7,
    },
    {
      field: 'created_at',
      headerName: 'Purchase Date',
      flex: 0.6,
      renderCell: (params) => (
        <>{dayjs(params.created_at).format('DD MMM YYYY h:mm A')}</>
      ),
    },
    {
      field: 'total_balance',
      headerName: 'status',
      flex: 0.3,
    },
  ]

  useEffect(() => {
    dispatch(consultationPaymentList())
  }, [])

  return (
    <>
      <Helmet>
        <title>Bioaro | Consultation - Payment List</title>
      </Helmet>
      <Card sx={{ p: 3 }}>
        <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
          <Typography variant="h6">Consultation Payment List</Typography>
        </Stack>
        <CDataTable
          rows={payments}
          columns={columns}
          isLoading={isLoading}
          filterMode="server"
          handleQuickFilterValue={handleQuickFilterValue}
        />
      </Card>
    </>
  )
}

export default PaymentConsultation
