import PatientFollowUp from './Partials/PatientFollowUp'
import TodoList from './Partials/TodoList'

const Doctor = () => {
  return (
    <>
      <TodoList />
      <PatientFollowUp />
    </>
  )
}

export default Doctor
