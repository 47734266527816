import React from 'react'
import CouponList from './partial/CouponList';
import CouponCreate from './partial/CouponCreate';
import CouponUpdate from './partial/CouponUpdate';
const couponRoutes = [
  { path: "list/", element: <CouponList /> },
  { path: "create/", element: <CouponCreate /> },
  { path: "update/:couponId", element: <CouponUpdate /> },
];

export default couponRoutes;
