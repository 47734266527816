import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Typography } from '@mui/material'
import { renderCell } from '@/utils/ctableTool'
import CDataTable from '@/components/CDatatable'
import { pendingPaymentList, reportList } from '../dashboardSlice'

const PendingPaymentList = () => {
  const dispatch = useDispatch()
  const { pendingPayments, isLoading } = useSelector((state) => state.dashboard)

  const columns = [
    {
      field: 'serial',
      headerName: 'Sn.',
      minWidth: 200,
      flex: 1,
      renderCell: (params) => renderCell(params.value),
    },
    {
      field: 'invoice_number',
      headerName: 'Invoice Number',
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      field: 'created_at',
      headerName: 'Created at',
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      field: 'total_balance',
      headerName: 'Total Balance',
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
  ]

  useEffect(() => {
    dispatch(pendingPaymentList())
  }, [dispatch])

  return (
    <Card sx={{ p: 3, mt: 4 }}>
      <Typography mb={1}>Pending Payment List</Typography>
      <CDataTable
        rows={pendingPayments || []}
        columns={columns}
        isLoading={isLoading}
      />
    </Card>
  )
}

export default PendingPaymentList
