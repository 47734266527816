import React from 'react'
import InventorySubCategoryList from './partial/InventorySubCategoryList';
const inventorySubCategoryRoutes = [
  { path: "list/", element: <InventorySubCategoryList /> },
//   { path: "create/", element: <CategoryCreate /> },
//   { path: "update/:categoryId", element: <CategoryUpdate /> }
];

export default inventorySubCategoryRoutes;

