import { lazy, Suspense } from 'react'

const LabOrderList = lazy(() => import('./LabOrderList'))
const ClinicOrderList = lazy(() => import('./ClinicOrderList'))
const LogList = lazy(() => import('./LogList'))
const OrderManageList = lazy(() => import('./OrderManageList'))

const orderRoutes = [
  { path: 'lab-order-list', element: <LabOrderList /> },
  { path: 'clinic-order-list', element: <ClinicOrderList /> },
  { path: 'log-list/:appId', element: <LogList /> },
  { path: 'manage', element: <LogList /> },
  { path: 'order-manage-list', element: <OrderManageList /> },
]

export default orderRoutes
