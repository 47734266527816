import { Link, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Button from '@mui/material/Button'
import AdminLayout from '@/layouts/AdminLayout'
import CTextField from '@/components/CTextField'
import SaveIcon from '@mui/icons-material/Save'

import { create } from '../fileCategorySlice'
import { setBreadcrumb } from '@/redux/Slice/breadcrumbSlice'
import {
  Card,
  Grid,
  Stack,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import CSelect from '@/components/CSelect'
import CButton from '@/components/CButton'
import { showError, showSuccess } from '@/redux/Slice/alertSlice'
import CAutocomplete from '@/components/CAutocomplete'
import { Helmet } from 'react-helmet-async'

const FileCategoryCreate = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { errors } = useSelector((state) => state.category)

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        { path: '/file-category/list', label: 'File Category', isLast: false },
        { path: '', label: 'Create', isLast: true },
      ])
    )
  }, [dispatch])

  // Initialize local state with the data from Redux
  const [category, setCategory] = useState({
    name: '',
    category: '',
  })
  console.log(category)
  useEffect(() => {
    document.title = 'BioAro | File Category - Create'
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const res = await dispatch(create(category))
      console.log(res)

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        navigate('/file-category/list')
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Server error:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred while updating the Category.',
        })
      )
    }
  }

  // onChange handler to update the local state
  const handleInputChange = (event) => {
    const { name, value } = event.target
    // Update the local state with the changed value
    setCategory((category) => ({
      ...category,
      [name]: value,
    }))
  }
  const categoryType = [
    {
      id: 1,
      name: 'Lab Category',
    },
    {
      id: 2,
      name: 'File Category',
    },
  ]
  return (
    <>
      <Helmet>
        <title>Bioaro | File Category - create</title>
      </Helmet>

      <Stack direction="row" justifyContent="center">
        <Card sx={{ p: 4, maxWidth: '70rem' }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} alignItems="center">
              <Grid xs={12} m={1}>
                <CTextField
                  label="Name"
                  name="name"
                  value={category?.name}
                  error={errors && errors.name}
                  helperText={errors && errors.name}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <CAutocomplete
                  options={categoryType}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, value) =>
                    setCategory({ ...category, category: value.id })
                  }
                  label="Forms"
                  error={!!(errors && errors.category)}
                  helperText={errors && errors.category}
                />
              </Grid>
            </Grid>
            <Stack direction="row" justifyContent="flex-end" mb={2} mt={2}>
              <CButton label="Save" icon={<SaveIcon />} type="submit" />
            </Stack>
          </form>
        </Card>
      </Stack>
    </>
  )
}

export default FileCategoryCreate
