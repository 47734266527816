import { LinearProgress } from '@mui/material'
import React, { lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import BillingInvoice from './Billing/BillingInvoice'
import DentalChart from './Dental/DentalChart'

const PatientDetail = lazy(() => import('./PatientDetail'))
const Chart = lazy(() => import('./Chart/Chart'))
const PatientList = lazy(() => import('./PatientList'))
const ChartList = lazy(() => import('./Chart/ChartList'))
const PrevisitList = lazy(() => import('./Previsit/PrevisitList'))
const PrevisitCreate = lazy(() => import('./Previsit/Partials/PrevisitCreate'))
const PrevisitUpdate = lazy(() => import('./Previsit/Partials/PrevisitUpdate'))
const IntakeForm = lazy(() => import('./Intake/IntakeForm'))
const AppoinmentList = lazy(() => import('./Appointment/AppoinmentList'))
const PatientOrderList = lazy(() => import('./Order/PatientOrderList'))
const FileList = lazy(() => import('./File/FileList'))
const LabList = lazy(() => import('./Lab/LabList'))
const MessageList = lazy(() => import('./Message/MessageList'))
// const BillingInvoice = lazy(() => import('./Billing/BillingInvoice'))
const TrackOrder = lazy(() => import('./Billing/TrackOrder'))
const Billing = lazy(() => import('./Billing/Billing'))
const MedicineList = lazy(() => import('./Medicine/MedicineList'))
const MedicineUpdate = lazy(() => import('./Medicine/MedicinUpdate'))
const DictationList = lazy(() => import('./Dictation/DictationList'))
const DictationCreate = lazy(() =>
  import('./Dictation/Partials/DictationCreate')
)
const DictationUpdate = lazy(() =>
  import('./Dictation/Partials/DictationUpdate')
)
const Consent = lazy(() => import('./Consent/Consent'))
const ActivityList = lazy(() => import('./Activity/ActivityList'))
const TaskList = lazy(() => import('./Tasks/TaskList'))
const AppCalendar = lazy(() => import('./Appointment/AppCalendar'))
const TrashList = lazy(() => import('./Trash/TrashList'))
const FollowUpList = lazy(() => import('./FollowUp/FollowUpList'))
const BeforeAfterList = lazy(() => import('./BeforeAfter/BeforeAfterList'))
const JournalReport = lazy(() => import('./IbsJournal/JournalReport'))
const Profile = lazy(() => import('./Profile/Profile'))
const Prescription = lazy(() => import('./Prescription/Prescription'))
const PrescriptionCreate = lazy(() =>
  import('./Prescription/Partials/PrescriptionCreate')
)
const PrescriptionUpdate = lazy(() =>
  import('./Prescription/Partials/PrescriptionUpdate')
)
const TalkToMe = lazy(() => import('./TalkToMe/TalkToMe'))
const PatientDashboard = lazy(() => import('./Dashboard/PatientDashboard'))
const HealthCreate = lazy(() => import('./Health/HealthCreate'))

const patientRoutes = [
  {
    path: 'detail/:patientId',
    element: <PatientDetail />,
    children: [
      // Profile
      { path: 'dashboard', element: <PatientDashboard /> },
      // Profile
      { path: 'profile', element: <Profile /> },

      // Dental Chart
      { path: 'dental-chart', element: <DentalChart /> },

      // Chart
      {
        path: 'chart/',
        element: <Chart />,
        children: [
          { path: 'list/', element: <ChartList /> },
          { path: 'staff/list/', element: <ChartList /> },
          { path: 'list/:formBuilderId', element: <ChartList /> },
          { path: 'update/:previsitId', element: <PrevisitUpdate /> },
          // File
          { path: 'file/list', element: <FileList /> },
          // Lab
          { path: 'lab/list', element: <LabList /> },
          // Dictation
          {
            path: 'dictation',
            element: <DictationList />,
            children: [
              { path: 'create', element: <DictationCreate /> },
              { path: 'update/:dictationId', element: <DictationUpdate /> },
            ],
          },

          // Prescription
          {
            path: 'prescription',
            element: <Prescription />,
            children: [
              { path: 'create', element: <PrescriptionCreate /> },
              {
                path: 'update/:prescriptionId',
                element: <PrescriptionUpdate />,
              },
            ],
          },
        ],
      },
      { path: 'chart-list/:formBuilderId', element: <ChartList /> },

      // Previsit
      {
        path: 'previsit',
        element: <PrevisitList />,
        children: [
          { path: 'create', element: <PrevisitCreate /> },
          { path: 'update/:previsitId', element: <PrevisitUpdate /> },
        ],
      },

      // Intake
      { path: 'intake/update', element: <IntakeForm /> },

      // Appointment
      { path: 'appointment/list', element: <AppoinmentList /> },
      { path: 'appointment/create', element: <AppCalendar /> },

      // Order
      { path: 'order/patient-order-list', element: <PatientOrderList /> },

      // Message
      { path: 'message/list', element: <MessageList /> },

      // Billing
      { path: 'billing', element: <Billing /> },
      { path: 'billing/invoice', element: <BillingInvoice /> },
      { path: 'billing/track-order/:paymentId', element: <TrackOrder /> },

      // Medicine
      { path: 'medicine/list', element: <MedicineList /> },
      { path: 'medicine/update/:medicineId', element: <MedicineUpdate /> },

      // Tasks
      { path: 'tasks/list', element: <TaskList /> },
      { path: 'tasks/update', element: <TaskList /> },

      // Consent
      { path: 'consent', element: <Consent /> },

      // IBS-Journal
      { path: 'ibs-journal/report', element: <JournalReport /> },

      // Before After
      { path: 'before-after/list', element: <BeforeAfterList /> },

      // Activity
      { path: 'activity/list', element: <ActivityList /> },

      // Talk to me
      { path: 'talk-to-me', element: <TalkToMe /> },

      // Health
      { path: 'health', element: <HealthCreate /> },

      // Follow Up
      { path: 'follow-up/list', element: <FollowUpList /> },

      // Trash
      { path: 'trash/list', element: <TrashList /> },
    ],
  },
  { path: 'list', element: <PatientList /> },
]

export default patientRoutes
