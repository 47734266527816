import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'

import SendOutlinedIcon from '@mui/icons-material/SendOutlined'
import { Box, Grid, InputLabel, Stack, Typography } from '@mui/material'
import CDialog from '../../../../../components/CDialog'
import CButton from '../../../../../components/CButton'
import CTextField from '../../../../../components/CTextField'
import CEditor from '../../../../../components/CEditor'
import { showError, showSuccess } from '../../../../../redux/Slice/alertSlice'
import SaveIcon from '@mui/icons-material/Save'
import { list, update } from '../securityQuestionSlice'
import CCheckbox from '@/components/CCheckbox'

const SecurityQuestionUpdate = ({ id, name, status }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  //   initial create value
  const [question, setQuestion] = useState({
    id: id,
    name: name,
    status: status,
  })
  const handleCDialogOpen = () => {
    setOpen(true)
  }
  const handleCDialogClose = () => {
    setQuestion({ ...question, status: status })
    setOpen(false)
  }
  const { errors, message, payload } = useSelector(
    (state) => state.securityQuestion
  )

  // submit form
  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const res = await dispatch(update(question))

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        dispatch(list())
        setOpen(false)
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Server error:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred while updating the security question .',
        })
      )
    }
  }

  // onChange handler to update the local state
  const handleInputChange = (event) => {
    const { name, value } = event.target
    // Update the local state with the changed value
    setQuestion((question) => ({
      ...question,
      [name]: value,
    }))
  }
  return (
    <CDialog
      btnTitle="Edit"
      headerTitle="Security question update"
      handleCDialogOpen={handleCDialogOpen}
      handleCDialogClose={handleCDialogClose}
      open={open}
    >
      <Box maxWidth="70rem" minWidth="40rem">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="center">
            <Grid xs={12} m={1}>
              <CTextField
                label="Name"
                name="name"
                value={question?.name}
                error={errors && errors.name}
                helperText={errors && errors.name}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CCheckbox
                label="Status"
                name="status"
                checked={question?.status == 1 ? 'true' : ''}
                onChange={(e) =>
                  setQuestion({ ...question, status: e.target.checked })
                }
              />
            </Grid>
          </Grid>
          <Box>
            {' '}
            <Stack sx={{ float: 'left' }}>
              {' '}
              <Typography variant="h5"></Typography>{' '}
            </Stack>
            <Stack
              direction="row"
              justifyContent="flex-end"
              mb={2}
              mt={2}
              mr={1.3}
            >
              <CButton label="Update" icon={<SaveIcon />} type="submit" />
            </Stack>
          </Box>
        </form>
      </Box>
    </CDialog>
  )
}

export default SecurityQuestionUpdate
