// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../../services/axiosConfig'

// Fetch intake type list
export const list = createAsyncThunk('intake-type/list', async () => {
  const response = await axiosInstance.get(`intake-type/list`)
  return response.data
})

//intak type create
export const create = createAsyncThunk('intake-type/create', async (data) => {
  const response = await axiosInstance.post(`intake-type/create`, data)
  return response.data
})

//intake type create
export const updateInfo = createAsyncThunk(
  'intake-type/update-info',
  async (intakeTypeId) => {
    const response = await axiosInstance.get(
      `intake-type/update-info/${intakeTypeId}`
    )
    return response.data
  }
)

export const info = createAsyncThunk(
  'intake-type/info',
  async() => {
    const response = await axiosInstance.get(`intake-type/info/`)
    return response.data
  }
)

//intake type update
export const update = createAsyncThunk(
  'intake-type/update',
  async ({ intakeTypeId, data }) => {
    const response = await axiosInstance.post(
      `intake-type/update/${intakeTypeId}`,
      data
    )
    return response.data
  }
)

const intakeTypeSlice = createSlice({
  name: 'intakeType',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    forms: '',
    categories: [],
    intakeType: null,
    intakeTypes: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      //IntakeType create
      .addCase(create.pending, (state) => {
        state.isLoading = true
      })
      .addCase(create.fulfilled, (state, { payload } = action) => {
        state.errors = payload.errors
        state.message = payload.message
        state.isLoading = false
      })
      .addCase(create.rejected, (state, { payload }) => {
        state.isLoading = false
      })

      // intakeType list List
      .addCase(list.pending, (state) => {
        state.isLoading = true
      })
      .addCase(list.fulfilled, (state, { payload } = action) => {
        state.intakeTypes = payload.intakeTypes
        state.forms = payload.forms
        state.isLoading = false
      })
      .addCase(list.rejected, (state, { payload }) => {
        state.isLoading = false
      })

      // intakeType Update info
      .addCase(updateInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateInfo.fulfilled, (state, { payload } = action) => {
        state.intakeType = payload.intakeType
        state.isLoading = false
      })
      .addCase(updateInfo.rejected, (state, { payload }) => {
        state.isLoading = false
      })

      // info
      .addCase(info.pending, (state) => {
        state.isLoading = true
      })
      .addCase(info.fulfilled, (state, { payload } = action) => {
        state.categories = payload.categories
        state.isLoading = false
      })
      .addCase(info.rejected, (state, { payload }) => {
        state.isLoading = false
      })

      //IntakeType update
      .addCase(update.pending, (state) => {
        state.isLoading = true
      })
      .addCase(update.fulfilled, (state, { payload } = action) => {
        state.errors = payload.errors
        state.message = payload.message
        state.isLoading = false
      })
      .addCase(update.rejected, (state, { payload }) => {
        state.isLoading = false
      })
  },
})

export default intakeTypeSlice.reducer
