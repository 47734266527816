// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '@/services/axiosConfig'

// Fetch dictationTemplate list
export const list = createAsyncThunk(
  'inventory/dictation-template/list',
  async () => {
    const response = await axiosInstance.get(
      'inventory/dictation-template/list'
    )
    return response.data
  }
)

// Fetch dictationTemplate info
export const dictationTemplateInfo = createAsyncThunk(
  'inventory/dictation-template/info',
  async (templateId) => {
    const response = await axiosInstance.get(
      `inventory/dictation-template/info/${templateId}`
    )
    console.log(response.data)
    return response.data
  }
)

// Fetch dictationTemplate list
export const create = createAsyncThunk(
  'inventory/dictation-template/create',
  async (data) => {
    const response = await axiosInstance.post(
      'inventory/dictation-template/create',
      data
    )
    return response.data
  }
)

// Fetch dictationTemplate list
export const update = createAsyncThunk(
  'inventory/dictation-template/update',
  async (data) => {
    const response = await axiosInstance.post(
      'inventory/dictation-template/update',
      data
    )
    return response.data
  }
)

const dictationTemplateSlice = createSlice({
  name: 'dictationTemplate',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    dictationTemplates: [],
    doctors: [],
    info: '',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      //dictationTemplate List
      .addCase(list.pending, (state) => {
        state.isLoading = true
      })
      .addCase(list.fulfilled, (state, { payload } = action) => {
        state.dictationTemplates = payload.dictationTemplates
        state.doctors = payload.doctors
        state.isLoading = false
      })

      .addCase(list.rejected, (state) => {
        state.isLoading = false
      })

      //dictationTemplate info
      .addCase(dictationTemplateInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        dictationTemplateInfo.fulfilled,
        (state, { payload } = action) => {
          state.doctors = payload.doctors
          state.info = payload.info
          state.isLoading = false
        }
      )

      .addCase(dictationTemplateInfo.rejected, (state) => {
        state.isLoading = false
      })

      //dictationTemplate create
      .addCase(create.pending, (state) => {
        state.isLoading = true
      })
      .addCase(create.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(create.rejected, (state) => {
        state.isLoading = false
      })

      //dictationTemplate update
      .addCase(update.pending, (state) => {
        state.isLoading = true
      })
      .addCase(update.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(update.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default dictationTemplateSlice.reducer
