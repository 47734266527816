import React from 'react'
import GiftCardList from './partial/GiftCardList';
import GiftCardCreate from './partial/GiftCardCreate';
import GiftCardUpdate from './partial/GiftCardUpdate';
const giftCardRoutes = [
  { path: "list/", element: <GiftCardList /> },
  { path: "create/", element: <GiftCardCreate /> },
  { path: "update/:giftCardId", element: <GiftCardUpdate /> },
];

export default giftCardRoutes;
