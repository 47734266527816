import React from 'react'
import {
  Modal,
  Box,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  IconButton,
  Radio,
  Stack,
  Divider,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import CTextField from '@/components/CTextField'
import CDialog from '@/components/CDialog'
import CCheckbox from '@/components/CCheckbox'
import CSelect from '@/components/CSelect'
import CNumberField from '@/components/CNumberField'
import { formatDate } from '@/utils/cdayjs'
import dayjs from 'dayjs'
import CDatePicker from '@/components/CDatePicker'

const UpdateFieldAttrs = ({
  open,
  onClose,
  editValues,
  setEditValues,
  saveEdit,
  formElements,
  editingElement,
  addOption,
  removeOption,
  handleOptionChange,
  handleDefaultOptionChange,
}) => {
  const handleChange = (field, value) => {
    setEditValues((prevValues) => ({ ...prevValues, [field]: value }))
  }

  return (
    <CDialog
      headerTitle="Update Field Attributes"
      open={open}
      handleCDialogClose={onClose}
    >
      <Box sx={{ minWidth: '50rem' }}>
        <Stack direction="column" spacing={2}>
          {editValues?.key === 'text' && (
            <>
              {/* Required  */}
              <CCheckbox
                label="Required"
                checked={editValues?.required || false}
                onChange={(e) => handleChange('required', e.target.checked)}
              />

              {/* Label  */}
              <CTextField
                label="Label"
                value={editValues?.label}
                onChange={(e) => handleChange('label', e.target.value)}
              />

              {/* Name  */}
              <CTextField
                label="Name"
                value={editValues?.name}
                onChange={(e) => handleChange('name', e.target.value)}
              />

              {/* Value */}
              <CTextField
                label="Value"
                value={editValues?.value}
                onChange={(e) => handleChange('value', e.target.value)}
              />

              {/* Placeholder  */}
              <CTextField
                label="Placeholder"
                value={editValues?.placeholder}
                onChange={(e) => handleChange('placeholder', e.target.value)}
              />

              {/* Helper Text  */}
              <CTextField
                label="Helper Text"
                value={editValues?.helperText}
                onChange={(e) => handleChange('helperText', e.target.value)}
              />

              {/* Variant  */}
              <CSelect
                label="Variant"
                value={editValues.variant}
                options={[
                  {
                    label: 'Outlined',
                    value: 'outlined',
                  },
                  {
                    label: 'Filled',
                    value: 'filled',
                  },
                  {
                    label: 'Standard',
                    value: 'standard',
                  },
                ]}
                onChange={(e) => handleChange('variant', e.target.value)}
              />
            </>
          )}

          {editValues?.key === 'checkbox' && (
            <>
              {/* Required  */}
              <CCheckbox
                label="Required"
                checked={editValues?.required || false}
                onChange={(e) => handleChange('required', e.target.checked)}
              />

              {/* Label  */}
              <CTextField
                label="Label"
                value={editValues?.label}
                onChange={(e) => handleChange('label', e.target.value)}
              />

              {/* Name  */}
              <CTextField
                label="Name"
                value={editValues?.name}
                onChange={(e) => handleChange('name', e.target.value)}
              />

              {/* checked  */}
              <CCheckbox
                label="Checked"
                checked={editValues?.checked}
                onChange={(e) => handleChange('checked', e.target.checked)}
              />
            </>
          )}

          {editValues?.key === 'textarea' && (
            <>
              {/* Required  */}
              <CCheckbox
                label="Required"
                checked={editValues?.required || false}
                onChange={(e) => handleChange('required', e.target.checked)}
              />

              {/* Label  */}
              <CTextField
                label="Label"
                value={editValues?.label}
                onChange={(e) => handleChange('label', e.target.value)}
              />

              {/* Name  */}
              <CTextField
                label="Name"
                value={editValues?.name}
                onChange={(e) => handleChange('name', e.target.value)}
              />

              {/* Value */}
              <CTextField
                label="Value"
                value={editValues?.value}
                onChange={(e) => handleChange('value', e.target.value)}
              />

              {/* Placeholder  */}
              <CTextField
                label="Placeholder"
                value={editValues?.placeholder}
                onChange={(e) => handleChange('placeholder', e.target.value)}
              />

              {/* Helper Text  */}
              <CTextField
                label="Helper Text"
                value={editValues?.helperText}
                onChange={(e) => handleChange('helperText', e.target.value)}
              />

              {/* Variant  */}
              <CSelect
                label="Variant"
                value={editValues.variant}
                options={[
                  {
                    label: 'Outlined',
                    value: 'outlined',
                  },
                  {
                    label: 'Filled',
                    value: 'filled',
                  },
                  {
                    label: 'Standard',
                    value: 'standard',
                  },
                ]}
                onChange={(e) => handleChange('variant', e.target.value)}
              />
            </>
          )}

          {editValues?.key === 'header' && (
            <>
              {/* Variant  */}
              <CSelect
                label="Variant"
                value={editValues.variant || 'h5'}
                options={[
                  {
                    label: 'h1',
                    value: 'h1',
                  },
                  {
                    label: 'h2',
                    value: 'h2',
                  },
                  {
                    label: 'h3',
                    value: 'h3',
                  },
                  {
                    label: 'h4',
                    value: 'h4',
                  },
                  {
                    label: 'h5',
                    value: 'h5',
                  },
                  {
                    label: 'h6',
                    value: 'h6',
                  },
                ]}
                onChange={(e) => handleChange('variant', e.target.value)}
              />

              {/* Value */}
              <CTextField
                label="Value"
                value={editValues?.value}
                onChange={(e) => handleChange('value', e.target.value)}
              />
            </>
          )}

          {editValues?.key === 'paragraph' && (
            <CTextField
              label="Value"
              value={editValues?.value}
              onChange={(e) => handleChange('value', e.target.value)}
            />
          )}

          {editValues?.key === 'number' && (
            <>
              {/* Required  */}
              <CCheckbox
                label="Required"
                checked={editValues?.required || false}
                onChange={(e) => handleChange('required', e.target.checked)}
              />

              {/* Label  */}
              <CTextField
                label="Label"
                value={editValues?.label}
                onChange={(e) => handleChange('label', e.target.value)}
              />

              {/* Name  */}
              <CTextField
                label="Name"
                value={editValues?.name}
                onChange={(e) => handleChange('name', e.target.value)}
              />

              {/* Value */}
              <CNumberField
                label="Value"
                value={editValues?.value}
                onChange={(e) => handleChange('value', e.target.value)}
              />

              {/* Placeholder  */}
              <CTextField
                label="Placeholder"
                value={editValues?.placeholder}
                onChange={(e) => handleChange('placeholder', e.target.value)}
              />

              {/* Helper Text  */}
              <CTextField
                label="Helper Text"
                value={editValues?.helperText}
                onChange={(e) => handleChange('helperText', e.target.value)}
              />

              {/* Variant  */}
              <CSelect
                label="Variant"
                value={editValues.variant}
                options={[
                  {
                    label: 'Outlined',
                    value: 'outlined',
                  },
                  {
                    label: 'Filled',
                    value: 'filled',
                  },
                  {
                    label: 'Standard',
                    value: 'standard',
                  },
                ]}
                onChange={(e) => handleChange('variant', e.target.value)}
              />
            </>
          )}

          {editValues?.key === 'file' && (
            <>
              {/* Required  */}
              <CCheckbox
                label="Required"
                checked={editValues?.required || false}
                onChange={(e) => handleChange('required', e.target.checked)}
              />

              {/* Label  */}
              <CTextField
                label="Label"
                value={editValues?.label}
                onChange={(e) => handleChange('label', e.target.value)}
              />

              {/* Name  */}
              <CTextField
                label="Name"
                value={editValues?.name}
                onChange={(e) => handleChange('name', e.target.value)}
              />

              {/* Multiple  */}
              <CCheckbox
                label="Multiple"
                checked={editValues?.multiple}
                onChange={(e) => handleChange('multiple', e.target.checked)}
              />
            </>
          )}

          {editValues?.key === 'date' && (
            <>
              {/* Required  */}
              <CCheckbox
                label="Required"
                checked={editValues?.required || false}
                onChange={(e) => handleChange('required', e.target.checked)}
              />

              {/* Label  */}
              <CTextField
                label="Label"
                value={editValues?.label}
                onChange={(e) => handleChange('label', e.target.value)}
              />

              {/* Name  */}
              <CTextField
                label="Name"
                value={editValues?.name}
                onChange={(e) => handleChange('name', e.target.value)}
              />

              {/* Value */}
              <CDatePicker
                label="Value"
                value={editValues?.value && dayjs(editValues?.value)}
                onChange={(value) => handleChange('value', formatDate(value))}
              />

              {/* Placeholder  */}
              <CTextField
                label="Placeholder"
                value={editValues?.placeholder}
                onChange={(e) => handleChange('placeholder', e.target.value)}
              />

              {/* Helper Text  */}
              <CTextField
                label="Helper Text"
                value={editValues?.helperText}
                onChange={(e) => handleChange('helperText', e.target.value)}
              />

              {/* Variant  */}
              <CSelect
                label="Variant"
                value={editValues.variant}
                options={[
                  {
                    label: 'Outlined',
                    value: 'outlined',
                  },
                  {
                    label: 'Filled',
                    value: 'filled',
                  },
                  {
                    label: 'Standard',
                    value: 'standard',
                  },
                ]}
                onChange={(e) => handleChange('variant', e.target.value)}
              />
            </>
          )}

          {editValues?.key === 'radio' && (
            <>
              {/* Required  */}
              <CCheckbox
                label="Required"
                checked={editValues?.required || false}
                onChange={(e) => handleChange('required', e.target.checked)}
              />

              {/* Label  */}
              <CTextField
                label="Label"
                value={editValues?.label}
                onChange={(e) => handleChange('label', e.target.value)}
              />

              {/* Name  */}
              <CTextField
                label="Name"
                value={editValues?.name}
                onChange={(e) => handleChange('name', e.target.value)}
              />

              <Box>
                {editValues?.options.map((option, index) => (
                  <Stack
                    direction="row"
                    key={index}
                    alignItems="center"
                    // justifyContent="start"
                    spacing={1}
                  >
                    <CCheckbox
                      checked={editValues?.value === option.value}
                      onChange={() => handleDefaultOptionChange(option.value)}
                    />
                    <CTextField
                      placeholder="Option Label"
                      value={option.label}
                      onChange={(e) =>
                        handleOptionChange(index, 'label', e.target.value)
                      }
                    />
                    <CTextField
                      placeholder="Option Value"
                      value={option.value}
                      onChange={(e) =>
                        handleOptionChange(index, 'value', e.target.value)
                      }
                    />
                    <IconButton onClick={() => removeOption(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                ))}
                <Button onClick={addOption}>Add Option</Button>
              </Box>
            </>
          )}

          {editValues?.key === 'select' && (
            <>
              {/* Required  */}
              <CCheckbox
                label="Required"
                checked={editValues?.required || false}
                onChange={(e) => handleChange('required', e.target.checked)}
              />

              {/* Label  */}
              <CTextField
                label="Label"
                value={editValues?.label}
                onChange={(e) => handleChange('label', e.target.value)}
              />

              {/* Name  */}
              <CTextField
                label="Name"
                value={editValues?.name}
                onChange={(e) => handleChange('name', e.target.value)}
              />

              {/* Placeholder  */}
              <CTextField
                label="Placeholder"
                value={editValues?.placeholder}
                onChange={(e) => handleChange('placeholder', e.target.value)}
              />

              {/* Helper Text  */}
              <CTextField
                label="Helper Text"
                value={editValues?.helperText}
                onChange={(e) => handleChange('helperText', e.target.value)}
              />

              <Box>
                {editValues?.options.map((option, index) => (
                  <Stack
                    direction="row"
                    key={index}
                    alignItems="center"
                    // justifyContent="start"
                    spacing={1}
                  >
                    <CCheckbox
                      checked={editValues?.value === option.value}
                      onChange={() => handleDefaultOptionChange(option.value)}
                    />
                    <CTextField
                      placeholder="Option Label"
                      value={option.label}
                      onChange={(e) =>
                        handleOptionChange(index, 'label', e.target.value)
                      }
                    />
                    <CTextField
                      placeholder="Option Value"
                      value={option.value}
                      onChange={(e) =>
                        handleOptionChange(index, 'value', e.target.value)
                      }
                    />
                    <IconButton onClick={() => removeOption(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                ))}
                <Button onClick={addOption}>Add Option</Button>
              </Box>
            </>
          )}

          {editValues?.key === 'autocomplete' && (
            <>
              {/* Required  */}
              <CCheckbox
                label="Required"
                checked={editValues?.required || false}
                onChange={(e) => handleChange('required', e.target.checked)}
              />

              {/* Label  */}
              <CTextField
                label="Label"
                value={editValues?.label}
                onChange={(e) => handleChange('label', e.target.value)}
              />

              {/* Name  */}
              <CTextField
                label="Name"
                value={editValues?.name}
                onChange={(e) => handleChange('name', e.target.value)}
              />

              {/* Placeholder  */}
              <CTextField
                label="Placeholder"
                value={editValues?.placeholder}
                onChange={(e) => handleChange('placeholder', e.target.value)}
              />

              {/* Helper Text  */}
              <CTextField
                label="Helper Text"
                value={editValues?.helperText}
                onChange={(e) => handleChange('helperText', e.target.value)}
              />

              <Box>
                {editValues?.options.map((option, index) => (
                  <Stack
                    direction="row"
                    key={index}
                    alignItems="center"
                    // justifyContent="start"
                    spacing={1}
                  >
                    <CCheckbox
                      checked={editValues?.value === option.value}
                      onChange={() => handleDefaultOptionChange(option.value)}
                    />
                    <CTextField
                      placeholder="Option Label"
                      value={option.label}
                      onChange={(e) =>
                        handleOptionChange(index, 'label', e.target.value)
                      }
                    />
                    <CTextField
                      placeholder="Option Value"
                      value={option.value}
                      onChange={(e) =>
                        handleOptionChange(index, 'value', e.target.value)
                      }
                    />
                    <IconButton onClick={() => removeOption(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                ))}
                <Button onClick={addOption}>Add Option</Button>
              </Box>
            </>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button onClick={saveEdit} variant="contained" color="primary">
              Save
            </Button>
            <Button onClick={onClose} variant="outlined" sx={{ ml: 2 }}>
              Cancel
            </Button>
          </Box>
        </Stack>
      </Box>
    </CDialog>
  )
}

export default UpdateFieldAttrs
