import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CAutocomplete from '@/components/CAutocomplete'
import { Card, Grid, Stack, Typography } from '@mui/material'

import { fetchPlaceOrderInfo } from './placeOrderSlice'
import { setBreadcrumb } from '@/redux/Slice/breadcrumbSlice'
import CButton from '@/components/CButton'
import CTextField from '@/components/CTextField'
import CustomerCreate from './Partials/CustomerCreate'

const PlaceOrderCreate = () => {
  const dispatch = useDispatch()
  const placeOrderInfoFromRedux = useSelector(
    (state) => state.placeOrder?.placeOrderInfo
  )

  const [createdCustomer, setCreatedCustomer] = useState(null)

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        { path: '/place-order', label: 'Place Order', isLast: false },
        { path: '', label: 'Create', isLast: true },
      ])
    )
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchPlaceOrderInfo())
  }, [])

  const handleChildData = (customer) => {
    setCreatedCustomer(customer)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <Card sx={{ p: 2 }}>
          <Grid container spacing={2}>
            {/* Category */}
            <Grid item xs={12} md={6}>
              <CAutocomplete
                label="Category"
                options={placeOrderInfoFromRedux?.categories || []}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id == value.id}
                // onChange={(e, value) =>
                //   handleAutocompleteChange({
                //     name: 'categories',
                //     value: value,
                //     id_column: 'category_id',
                //   })
                // }
              />
            </Grid>

            {/* Sub Category */}
            <Grid item xs={12} md={6}>
              <CAutocomplete
                label="Sub Category"
                options={[]}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id == value.id}
                // onChange={(e, value) =>
                //   handleAutocompleteChange({
                //     name: 'categories',
                //     value: value,
                //     id_column: 'category_id',
                //   })
                // }
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>

      <Grid item xs={12} md={4}>
        <Grid container spacing={2}>
          {/* Select Site */}
          <Grid item xs={12}>
            <Card sx={{ p: 2 }}>
              <CAutocomplete
                label="Select Site"
                options={placeOrderInfoFromRedux?.sites || []}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id == value.id}
                // onChange={(e, value) =>
                //   handleAutocompleteChange({
                //     name: 'categories',
                //     value: value,
                //     id_column: 'category_id',
                //   })
                // }
              />
            </Card>
          </Grid>

          {/* Select Customer */}
          <Grid item xs={12}>
            <Card sx={{ p: 2 }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={2}
              >
                <Typography variant="h6">Select Customer</Typography>
                <CustomerCreate sendDataToParent={handleChildData} />
              </Stack>
              <CAutocomplete
                label=""
                options={placeOrderInfoFromRedux?.customers || []}
                value={createdCustomer}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id == value.id}
                // onChange={(e, value) =>
                //   handleAutocompleteChange({
                //     name: 'categories',
                //     value: value,
                //     id_column: 'category_id',
                //   })
                // }
              />
            </Card>
          </Grid>

          {/* Items */}
          <Grid item xs={12}>
            <Card sx={{ p: 2 }}>
              <Typography variant="h6">Items:</Typography>
            </Card>
          </Grid>

          {/*  */}
          <Grid item xs={12}>
            <Card sx={{ p: 2 }}>
              <CTextField label="Discount" />

              <Stack direction="row" justifyContent="space-between" mt={2}>
                <Typography fontWeight="bold">Sub Total:</Typography>
                <Typography>0.00</Typography>
              </Stack>

              <Stack direction="row" justifyContent="space-between" mt={2}>
                <Typography fontWeight="bold">Total:</Typography>
                <Typography>0.00</Typography>
              </Stack>

              <Stack direction="row" justifyContent="center" mt={2}>
                <CButton label="Submit Order" fullWidth={true} />
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PlaceOrderCreate
