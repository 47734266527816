// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../../../services/axiosConfig'

// Create previsit
export const create = createAsyncThunk(
  'patient/pre-visit/create',
  async (data) => {
    const response = await axiosInstance.post('patient/pre-visit/create', data)
    return response.data
  }
)

// Update previsit
export const update = createAsyncThunk(
  'patient/pre-visit/update',
  async ({ previsitId, data }) => {
    const response = await axiosInstance.post(
      `patient/pre-visit/update/${previsitId}`,
      data
    )
    return response.data
  }
)

// Fetch Doctors
export const fetchDoctorList = createAsyncThunk(
  'patient/pre-visit/list-doctors',
  async () => {
    const response = await axiosInstance.get('patient/pre-visit/list-doctors')
    return response.data
  }
)

// Fetch all chart notes by patient id
export const fetchPrevisitListByPatientId = createAsyncThunk(
  'patient/pre-visit/list',
  async (id) => {
    const response = await axiosInstance.get(`patient/pre-visit/list/${id}`)
    return response.data
  }
)

// Fetch PreVisit Detail by its id
export const fetchPreVisitDetail = createAsyncThunk(
  'patient/pre-visit/detail',
  async (previsitId) => {
    const response = await axiosInstance.get(
      `patient/pre-visit/detail/${previsitId}`
    )
    return response.data
  }
)

// Print previsit
export const previsitPrint = createAsyncThunk(
  'patient/pre-visit/print',
  async (previsitId) => {
    const response = await axiosInstance.get(
      `patient/pre-visit/print/${previsitId}`,
      {
        responseType: 'blob',
      }
    )
    return response.data
  }
)

// sendFax
export const sendFax = createAsyncThunk(
  'patient/pre-visit/send-fax',
  async ({ previsitId, data }) => {
    const response = await axiosInstance.post(
      `patient/pre-visit/send-fax/${previsitId}`,
      data
    )
    return response.data
  }
)

// send Email
export const sendEmail = createAsyncThunk(
  'patient/pre-visit/send-email',
  async ({ previsitId, data }) => {
    const response = await axiosInstance.post(
      `patient/pre-visit/send-email/${previsitId}`,
      data
    )
    return response.data
  }
)

// Fetch Fax and Email log list by previsit id
export const fetchFaxAndEmailLogList = createAsyncThunk(
  'patient/pre-visit/fax-and-email-log-list',
  async (previsitId) => {
    const response = await axiosInstance.get(
      `patient/pre-visit/fax-and-email-log-list/${previsitId}`
    )
    return response.data
  }
)

// Download previsit
export const previsitDownload = createAsyncThunk(
  'patient/pre-visit/download',
  async (previsitId) => {
    const response = await axiosInstance.get(
      `patient/pre-visit/download/${previsitId}`,
      {
        responseType: 'blob',
      }
    )
    return response.data
  }
)

// Change previsit is_locked status
export const updateIsLock = createAsyncThunk(
  'patient/pre-visit/change-lock-status',
  async (data) => {
    const response = await axiosInstance.post(
      'patient/pre-visit/change-lock-status',
      data
    )
    return response.data
  }
)

// Previsit trash (temporary delete it can be restored)
export const previsitTrash = createAsyncThunk(
  'patient/pre-visit/trash',
  async (previsitId) => {
    const response = await axiosInstance.get(
      `patient/pre-visit/trash/${previsitId}`
    )
    return response.data
  }
)

const previsitSlice = createSlice({
  name: 'previsit',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    doctors: [],
    previsits: [],
    faxLogs: [],
    emailLogs: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Create previsit
      .addCase(create.pending, (state) => {
        state.isLoading = true
      })
      .addCase(create.fulfilled, (state, action) => {
        if (action.payload.error) {
          state.message = action.payload.message
          state.errors = action.payload.errors
        } else {
          state.message = action.payload.message
          state.errors = null
        }
        state.isLoading = false
      })
      .addCase(create.rejected, (state) => {
        state.isLoading = false
      })

      // fetch doctor list
      .addCase(fetchDoctorList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchDoctorList.fulfilled, (state, { payload } = action) => {
        state.doctors = payload.doctors
        state.isLoading = false
      })
      .addCase(fetchDoctorList.rejected, (state) => {
        state.isLoading = false
      })

      // fetch previsit list by patient id
      .addCase(fetchPrevisitListByPatientId.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        fetchPrevisitListByPatientId.fulfilled,
        (state, { payload } = action) => {
          state.previsits = payload.preVisits
          state.isLoading = false
        }
      )
      .addCase(fetchPrevisitListByPatientId.rejected, (state) => {
        state.isLoading = false
      })

      // Update previsit
      .addCase(update.pending, (state) => {
        state.isLoading = true
      })
      .addCase(update.fulfilled, (state, action) => {
        if (action.payload.error) {
          state.message = action.payload.message
          state.errors = action.payload.errors
        } else {
          state.message = action.payload.message
          state.errors = null
        }
        state.isLoading = false
      })
      .addCase(update.rejected, (state) => {
        state.isLoading = false
      })

      // fetch previsit detail by its id
      .addCase(fetchPreVisitDetail.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchPreVisitDetail.fulfilled, (state, { payload } = action) => {
        state.isLoading = false
      })
      .addCase(fetchPreVisitDetail.rejected, (state) => {
        state.isLoading = false
      })

      // fetch Fax and Email log list
      .addCase(fetchFaxAndEmailLogList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchFaxAndEmailLogList.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.faxLogs = payload.faxLogs
        state.emailLogs = payload.emailLogs
      })
      .addCase(fetchFaxAndEmailLogList.rejected, (state) => {
        state.isLoading = false
      })

      // Previsit Download
      .addCase(previsitDownload.pending, (state) => {
        state.isLoading = true
      })
      .addCase(previsitDownload.fulfilled, (state, { payload } = action) => {
        state.isLoading = false
      })
      .addCase(previsitDownload.rejected, (state) => {
        state.isLoading = false
      })

      // Update is_lock status
      .addCase(updateIsLock.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateIsLock.fulfilled, (state, action) => {
        state.message = action.payload.message
        state.isLoading = false
      })
      .addCase(updateIsLock.rejected, (state) => {
        state.isLoading = false
      })

      // Previsit Trash
      .addCase(previsitTrash.pending, (state) => {
        state.isLoading = true
      })
      .addCase(previsitTrash.fulfilled, (state, action) => {
        state.message = action.payload.message
        state.isLoading = false
      })
      .addCase(previsitTrash.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default previsitSlice.reducer
