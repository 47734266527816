import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material'

const PopupDialog = ({ open, onClose, remainingTime }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Confirmation</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Do you want to continue using the site? This popup will expire in{' '}
        {remainingTime} seconds.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => onClose('no')}>No</Button>
      <Button onClick={() => onClose('yes')} autoFocus>
        Yes
      </Button>
    </DialogActions>
  </Dialog>
)

export default PopupDialog
