import { Link, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Button from '@mui/material/Button'
import { Helmet } from 'react-helmet-async'
import CTextField from '@/components/CTextField'
import SaveIcon from '@mui/icons-material/Save'

import { create } from '../categorySlice'
import { setBreadcrumb } from '@/redux/Slice/breadcrumbSlice'
import {
  Card,
  Grid,
  Stack,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import CSelect from '@/components/CSelect'
import CButton from '@/components/CButton'
import { showError, showSuccess } from '@/redux/Slice/alertSlice'

const CategoryCreate = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { errors } = useSelector((state) => state.category)

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        { path: '/category', label: 'Category', isLast: false },
        { path: '', label: 'Create', isLast: true },
      ])
    )
  }, [dispatch])

  // Initialize local state with the data from Redux
  const [category, setCategory] = useState({
    name: '',
    position: '',
    service_type: '',
    link: '',
  })

  useEffect(() => {
    document.title = 'BioAro | Category - Create'
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const res = await dispatch(create(category))
      console.log(res)

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        navigate('/category/')
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Server error:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred while updating the Category.',
        })
      )
    }
  }

  // onChange handler to update the local state
  const handleInputChange = (event) => {
    const { name, value } = event.target
    // Update the local state with the changed value
    setCategory((category) => ({
      ...category,
      [name]: value,
    }))
  }

  return (
    <>
      <Helmet>
        <title>Bioaro | Category - create</title>
      </Helmet>
      <Stack direction="row" justifyContent="center">
        <Card sx={{ p: 4, maxWidth: '50rem' }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} alignItems="center">
              <Grid xs={12} m={1}>
                <CTextField
                  label="Name"
                  name="name"
                  value={category?.name}
                  error={errors && errors.name}
                  helperText={errors && errors.name}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid xs={12} m={1}>
                <CTextField
                  label="Position"
                  name="position"
                  type="number"
                  value={category?.position}
                  error={errors && errors.position}
                  helperText={errors && errors.position}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid m={1}>
                <FormControl>
                  <FormLabel>Gut Clinic</FormLabel>
                  <RadioGroup
                    row
                    name="service_type"
                    value={category.service_type ?? ''}
                    onChange={handleInputChange}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid xs={12}>
                <CTextField
                  name="link"
                  label="Link"
                  value={category?.link}
                  error={errors && errors.link}
                  helperText={errors && errors.link}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <Stack direction="row" justifyContent="flex-end" mb={2} mt={2}>
              <CButton label="Save" icon={<SaveIcon />} type="submit" />
            </Stack>
          </form>
        </Card>
      </Stack>
    </>
  )
}

export default CategoryCreate
