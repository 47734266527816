import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Card, Grid, Stack } from '@mui/material'
import CTextField from '../../../../components/CTextField'
import CAutocomplete from '../../../../components/CAutocomplete'
import CButton from '../../../../components/CButton'
import SaveIcon from '@mui/icons-material/Save'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { setBreadcrumb } from '../../../../redux/Slice/breadcrumbSlice'
import { showError, showSuccess } from '../../../../redux/Slice/alertSlice'
import { create, info, update, updateInfo } from '../intakeTypeSlice'

export default function IntakeTypeUpdate() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { intakeTypeId } = useParams()
  const { categories, errors, isLoading } = useSelector(
    (state) => state.intakeType
  )

  const intakeTypeFromFromRedux = useSelector(
    (state) => state.intakeType.intakeType
  )

  const [intakeType, setIntakeType] = useState(intakeTypeFromFromRedux)

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        {
          path: '/intake-type/list',
          label: 'Intake Type',
          isLast: false,
        },
        { path: '', label: 'Update', isLast: true },
      ])
    )
  }, [])

  useEffect(() => {
    dispatch(updateInfo(intakeTypeId))
  }, [intakeTypeId])

  useEffect(() => {
    setIntakeType(intakeTypeFromFromRedux)
  }, [intakeTypeFromFromRedux])

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      const res = await dispatch(
        // Wait for the pharmacy create action to complete.
        update({
          intakeTypeId: intakeTypeId,
          data: intakeType,
        })
      )
      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        navigate('/intake-type/list')
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      // Handle any errors that occur during the login action dispatch
      console.error('An error occurred:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred.',
        })
      )
    }
  }

  return (
    <>
      <Helmet>
        <title>Bioaro | Intake Type - Update</title>
      </Helmet>
      {intakeType ? (
        <Card sx={{ p: 3, maxWidth: '100rem' }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={6}>
                <CTextField
                  label="Title"
                  name="title"
                  value={intakeType?.title}
                  required
                  error={!!(errors && errors.title)}
                  helperText={errors && errors.title}
                  onChange={(e) =>
                    setIntakeType({ ...intakeType, title: e.target.value })
                  }
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <CAutocomplete
                  options={intakeType?.categories ?? []}
                  getOptionLabel={(option) => option.name}
                  value={intakeType?.category}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(e, value) =>
                    setIntakeType({
                      ...intakeType,
                      category: value,
                    })
                  }
                  label="Category"
                  multiple
                  error={!!(errors && errors.category)}
                  helperText={errors && errors.category}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <CAutocomplete
                  options={forms}
                  getOptionLabel={(option) => option}
                  value={intakeType?.form}
                  onChange={(e, value) =>
                    // console.log(value)
                    setIntakeType({
                      ...intakeType,
                      form: value,
                    })
                  }
                  label="Forms"
                  multiple
                  error={!!(errors && errors.forms)}
                  helperText={errors && errors.forms}
                />
              </Grid>
            </Grid>
            <Stack direction="row" justifyContent="flex-end" mt={2}>
              <CButton label="Save" icon={<SaveIcon />} type="submit" />
            </Stack>
          </form>
        </Card>
      ) : null}
    </>
  )
}

const forms = [
  'general_background',
  'about',
  'genetic',
  'Smoking',
  'medication',
  'history_of_antibiotics',
  'surgeries',
  'men_history',
  'hospitalizations',
  'injuries',
  'history_of_cancer',
  'history_of_brain',
  'history_of_Gastrointestinal',
  'history_of_skin',
  'history_of_metabolic',
  'vaginal',
  'oral_history',
  'diet_and_nutrition',
  'skin',
  'gut_mental_health',
  'sumtomps',
  'benefit',
  'kinesiology',
  'family_history',
]
