import React, { lazy, Suspense } from 'react'
import { createBrowserRouter } from 'react-router-dom'
import { LinearProgress } from '@mui/material'

// components
import AdminLayout from '@/layouts/AdminLayout'

import roleRoutes from '@/pages/Admin/Role/roleRoutes'
import patientRoutes from '@/pages/Admin/Patient/patientRoutes'
import scheduleRoutes from '@/pages/Admin/Schedule/scheduleRoutes'
import orderRoutes from '@/pages/Admin/Order/orderRoutes'
import physicianRoutes from '@/pages/Admin/Physician/physicianRoutes'
import leadRoutes from '../pages/Admin/Leads/leadRoutes'
import financialRoutes from '@/pages/Admin/Financial/financialRoutes'
import supportRoutes from '@/pages/Admin/Support/supportRoutes'
import intakeTypeRoutes from '@/pages/Admin/IntakeType/intakeTypeRoutes'
import documentResendRoutes from '../pages/Admin/DocumentResend/documentResendRoutes'
import pharmacyRoutes from '../pages/Admin/Pharmacy/pharmacyRoutes'
import sellerSiteRoutes from '../pages/Admin/SellerSite/sellerSiteRoutes'
import noteRoutes from '@/pages/Admin/Templates/Note/noteRoutes'
import referralRoutes from '@/pages/Admin/Referral/referralRoutes'
import userRoutes from '@/pages/Admin/User/userRoutes'
import categoryRoutes from '@/pages/Admin/Category/categoryRoutes'
import NotFound from '@/components/ErrorPages/NotFound'
import Login from '@/pages/Auth/Login'
import Home from '@/pages/Admin/Dashboard/Home'
import consultationRoutes from '@/pages/Admin/PaymentConsultation/consultationRoutes'
import gutClinicRoutes from '@/pages/Admin/GutClinic/gutClinicRoutes'
import LongevityCategoryRoute from '@/pages/Admin/LongevityCategory/LongevityCategoryRoute'
import tagRoutes from '@/pages/Admin/Tag/tagRoutes'
import FileCategoryRoute from '@/pages/Admin/FileCategory/FileCategoryRoute'
import ClamServiceRoute from '@/pages/Admin/ClamService/ClamServiceRoute'
import paidConsultationRoutes from '@/pages/Admin/PaidConsultation/paidConsultationRoutes'
import inventoryCategoryRoutes from '@/pages/Admin/InventoryManagement/InventoryCategory/inventoryCategoryRoutes'
import inventorySubCategoryRoutes from '@/pages/Admin/InventoryManagement/InventorySubCategory/inventorySubCategoryRoutes'
import inventoryUserRoutes from '@/pages/Admin/InventoryManagement/InventoryUser/inventoryUserRoutes'
import expenseCategoryRoutes from '@/pages/Admin/InventoryManagement/ExpenseCategory/expenseCategoryRoutes'
import gutCommunicationCategoryRoutes from '@/pages/Admin/InventoryManagement/GutCommunicationCategory/gutCommunicationCategoryRoutes'
import orderInvoiceRoutes from '@/pages/Admin/InventoryManagement/OrderInvoice/orderInvoiceRoutes'
import DictationTemplateRoutes from '@/pages/Admin/InventoryManagement/DictationTemplate/dictationTemplateRoutes'
import NoteTemplateRoutes from '@/pages/Admin/InventoryManagement/NoteTemplate/noteTemplateRoutes'
import MailTemplateRoutes from '@/pages/Admin/InventoryManagement/MailTemplate/mailTemplateRoutes'
import ConsentTemplateRoutes from '@/pages/Admin/InventoryManagement/ConsentTemplate/consentTemplateRoutes'
import AcknowledgeRoutes from '@/pages/Admin/InventoryManagement/Acknowledge/acknowledgeRoutes'
import taskRoutes from '@/pages/Admin/InventoryManagement/Task/taskRoutes'
import blogRoutes from '@/pages/Admin/ManageWebsite/Blog/blogRoutes'
import newsRoutes from '@/pages/Admin/ManageWebsite/News/newsRoutes'
import bannerRoutes from '@/pages/Admin/ManageWebsite/Banner/bannerRoutes'
import seoRoutes from '@/pages/Admin/ManageWebsite/SocialSeo/seoRoutes'
import securityQuestionRoutes from '@/pages/Admin/ManageWebsite/SecurityQuestions/securityQuestionRoutes'
import ProvinceRoutes from '@/pages/Admin/ManageWebsite/Province/provinceRoutes'
import giftCardRoutes from '@/pages/Admin/ManageWebsite/GiftCard/giftCardRoutes'
import DepartmentRoutes from '@/pages/Admin/HospitalManagement/Department/departmentRoutes'
import floorRoutes from '@/pages/Admin/HospitalManagement/Floor/floorRoutes'
import RoomRoutes from '@/pages/Admin/HospitalManagement/Room/roomRoutes'
import couponRoutes from '@/pages/Admin/ManageWebsite/Coupon/couponRoutes'
import DataAnalysisRoute from '@/pages/Admin/DataAnalysis/dataAnalysisRouts'
import ClientTypeRoutes from '@/pages/Admin/ManageUser/ClientType/clientRoutes'
import sserviceRoutes from '@/pages/Admin/SService/sserviceRoutes'
import placeOrderRoutes from '@/pages/Admin/PlaceOrder/placeOrderRoutes'
import gutComScheduleRoutes from '@/pages/Admin/GutComSchedule/gutComScheduleRoutes'
import WarehouseRoutes from '@/pages/Admin/InventoryManagement/Warehouse/warehouseRoutes'
import productRoutes from '@/pages/Admin/Product/productRoutes'
import ExpenseRoutes from '@/pages/Admin/InventoryManagement/Expense/expenseRoutes'
import clinicRoutes from '@/pages/Admin/ManageUser/Clinic/clinicRoutes'
import formBuilderRoutes from '@/pages/Admin/FormBuilder/formBuilderRoutes'
import chatRoutes from '@/pages/Admin/Chat/chatRoutes'

import AccessDenied from '@/components/ErrorPages/AccessDenied'
import patientModulesRoutes from '@/pages/Admin/PatientModules/patientModulesRoutes'
import tenantRoutes from '@/pages/Admin/Tenant/tenantRoutes'
import authRoutes from '@/pages/Auth/authRoutes'
import AuthLayout from '@/layouts/AuthLayout'
import documentRoutes from '@/pages/Admin/Document/documentRoutes'
import intakeRoutes from '@/pages/Admin/Intake/intakeRoutes'
import appointmentRoutes from '@/pages/Admin/Appointment/appointmentRoutes'
import purchaseRoutes from '@/pages/Admin/Purchase/purchaseRoutes'

const SecurityAnswerUpdate = React.lazy(() =>
  import('@/pages/Auth/SecurityAnswerUpdate')
)

const adminRoutes = createBrowserRouter([
  {
    element: <AdminLayout />,
    errorElement: <NotFound />,
    children: [
      // error pages
      { path: '/403', element: <AccessDenied /> },
      // Dashboard
      { path: '/dashboard', element: <Home /> },

      // User Routes
      { path: '/user/', children: userRoutes },

      // Tenant Routes
      { path: '/tenant/', children: tenantRoutes },

      // Role Routes
      { path: '/role/', children: roleRoutes },

      // Patient Routes
      { path: '/patient/', children: patientRoutes },
      // Patient Routes
      { path: '/patients/data-analysis/', children: DataAnalysisRoute },

      // Schedule Routes
      { path: '/schedule/', children: scheduleRoutes },

      // Order Routes
      { path: '/order/', children: orderRoutes },

      // Physician Routes
      { path: '/physician/', children: physicianRoutes },

      // Gut Clinic Routes
      { path: '/gut-clinic/', children: gutClinicRoutes },

      // Leads Routes
      { path: '/leads/', children: leadRoutes },

      // Consultation New Booking Routes
      { path: '/consultation/', children: consultationRoutes },

      // Financial Routes
      { path: '/financial/', children: financialRoutes },

      // Subscription Routes
      // { path: '/subscription/list', children: subscriptionList },

      // Support Routes
      { path: '/support/', children: supportRoutes },

      // Intake Type Routes
      { path: '/intake-type/', children: intakeTypeRoutes },

      // Document Resend Routes
      { path: '/document-resend/', children: documentResendRoutes },

      // Pharmacy Routes
      { path: '/pharmacy/', children: pharmacyRoutes },

      // FormBuilder Routes
      { path: '/form-builder/', children: formBuilderRoutes },

      // Seller Site Routes
      { path: '/seller-site/', children: sellerSiteRoutes },

      // Category Routes
      { path: '/category/', children: categoryRoutes },

      // longevity Category Routes
      { path: '/longevity-category/', children: LongevityCategoryRoute },

      // Sequencing Service Routes
      { path: '/sservice/', children: sserviceRoutes },

      // Place Order Routes
      { path: '/place-order/', children: placeOrderRoutes },

      // paid consultation Routes
      { path: '/paid-consultation/', children: paidConsultationRoutes },

      // longevity Category Routes
      { path: '/tag/', children: tagRoutes },

      // longevity Category Routes
      { path: '/file-category/', children: FileCategoryRoute },

      // longevity Category Routes
      { path: '/clam-service/', children: ClamServiceRoute },

      // INVENTORY MANAGE
      //  Category Routes
      { path: 'inventory/manage-category/', children: inventoryCategoryRoutes },

      // sub Category Routes
      { path: 'inventory/sub-category/', children: inventorySubCategoryRoutes },

      // warehouse Routes
      { path: 'inventory/warehouse/', children: WarehouseRoutes },

      // product Routes
      { path: 'product/', children: productRoutes },

      // inventory expense Routes
      { path: 'inventory/expense/', children: ExpenseRoutes },

      //  inventory user Routes
      { path: 'inventory/user/', children: inventoryUserRoutes },

      //  expense-category Routes
      { path: 'inventory/expense-category/', children: expenseCategoryRoutes },

      //  gut-communication-category Routes
      {
        path: 'inventory/gut-communication-category/',
        children: gutCommunicationCategoryRoutes,
      },

      // Gut Communication Schedule Routes
      { path: '/gut-com-schedule/', children: gutComScheduleRoutes },

      //  Order invoice Routes
      {
        path: 'inventory/order-invoice/',
        children: orderInvoiceRoutes,
      },

      //  dictation template Routes
      {
        path: 'inventory/dictation-template/',
        children: DictationTemplateRoutes,
      },

      //  note template Routes
      {
        path: 'inventory/note-template/',
        children: NoteTemplateRoutes,
      },

      //  mail template Routes
      {
        path: 'inventory/mail-template/',
        children: MailTemplateRoutes,
      },

      //  consent template Routes
      {
        path: 'inventory/consent-template/',
        children: ConsentTemplateRoutes,
      },

      //  acknowledge template Routes
      {
        path: 'inventory/acknowledge/',
        children: AcknowledgeRoutes,
      },

      //  acknowledge template Routes
      {
        path: 'inventory/task/',
        children: taskRoutes,
      },

      // all ManageWebsite route //

      //  blog
      {
        path: 'website/blog/',
        children: blogRoutes,
      },

      //  news
      {
        path: 'website/news/',
        children: newsRoutes,
      },

      //  news
      {
        path: 'website/banner/',
        children: bannerRoutes,
      },

      // website  Seo
      {
        path: 'website/seo/',
        children: seoRoutes,
      },

      // website  security question
      {
        path: 'website/security-question/',
        children: securityQuestionRoutes,
      },

      // website  province
      {
        path: 'website/province/',
        children: ProvinceRoutes,
      },

      // website  gift card
      {
        path: 'website/gift-card/',
        children: giftCardRoutes,
      },

      // website  gift card
      {
        path: 'website/coupon/',
        children: couponRoutes,
      },

      // website  gift card
      {
        path: 'hospital/department/',
        children: DepartmentRoutes,
      },

      // website  gift card
      {
        path: 'hospital/floor/',
        children: floorRoutes,
      },

      // website  gift card
      {
        path: 'hospital/room/',
        children: RoomRoutes,
      },

      // Physician Routes (Second Declaration)
      { path: '/physician/', children: physicianRoutes },

      // Physician Routes (Second Declaration)
      { path: '/clinic/', children: clinicRoutes },

      // clinic Type
      { path: '/client-type/', children: ClientTypeRoutes },

      // Template Note Routes
      { path: '/template/note/', children: noteRoutes },

      // Referral Routes
      { path: '/referral/', children: referralRoutes },

      // Chat Routes
      { path: '/chat/', children: chatRoutes },

      { path: '/security-answer-update/', element: <SecurityAnswerUpdate /> },
      // Ibs Journal
      { path: '/document/', children: documentRoutes },
      { path: '/intake/', children: intakeRoutes },
      { path: '/appointment/', children: appointmentRoutes },
      { path: '/purchase/', children: purchaseRoutes },

      
    ],
  },
  { path: '/', element: <AuthLayout />, children: authRoutes },
])

export default adminRoutes
