import { FormControl, FormLabel, InputLabel, Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import React from 'react'

function CTextField({
  type = 'text',
  name,
  value,
  error,
  label,
  helperText,
  fullWidth = true,
  autoFocus,
  autoComplete,
  placeholder,
  required = false,
  variant = 'outlined',
  multiline,
  margin = 'none',
  rows,
  maxRows,
  size = 'small',
  onChange = null,
  disabled = false,
  ...other
}) {
  return (
    <>
      <FormLabel htmlFor={`${label}${name}`}>
        {label}
        {required && (
          <Typography sx={{ display: 'inline', color: 'red' }}>*</Typography>
        )}
      </FormLabel>
      <TextField
        id={`${label}${name}`}
        type={type}
        name={name}
        value={value}
        error={error}
        helperText={helperText}
        fullWidth={fullWidth}
        margin={margin}
        autoFocus={autoFocus}
        autoComplete={autoComplete}
        placeholder={placeholder}
        required={required}
        variant={variant}
        multiline={multiline}
        rows={multiline && rows}
        maxRows={multiline && maxRows}
        size={size}
        disabled={disabled}
        onChange={onChange}
        // InputLabelProps={{ shrink: true }}
        {...other}
      />
    </>
  )
}

export default React.memo(CTextField)
