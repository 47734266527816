import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '@/services/axiosConfig'

// Health Create
export const healthCreate = createAsyncThunk(
  'patient/health/create',
  async ({ patientId, data }) => {
    const response = await axiosInstance.post(
      `patient/health/create/${patientId}`,
      data
    )
    return response.data
  }
)

const healthSlice = createSlice({
  name: 'health',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    info: null,
  },
  reducers: {
    setChartFormBuilder: (state, action) => {
      state.chartFormBuilder = action.payload
    },
  },
  // extraReducers: (builder) => {
  //   builder

  // },
})

export default healthSlice.reducer
