import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { persistor } from 'src/redux/store'

import { logout } from '@/pages/Auth/authSlice'
import { showSuccess, showError } from '/src/redux/Slice/alertSlice'

import { styled } from '@mui/system'
import { useTheme } from '@mui/material/styles'
import MuiAppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import MenuIcon from '@mui/icons-material/Menu'
import { AccountCircle, Logout, Update } from '@mui/icons-material'
import DarkModeIcon from '@mui/icons-material/DarkMode'

import { drawerWidth } from '../constants'
import Breadcrumb from '../../../components/Breadcrumb'
import { Avatar, Box, ListItemIcon } from '@mui/material'

import { setTheme, setDrawer } from '/src/redux/Slice/themeSlice'

import user1 from '/src/assets/images/profile/user-1.jpg'
import Settings from './Settings'

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open
    ? {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }
    : { width: `calc(100% - ${114}px)` }),
}))

const AdminTopbar = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const user = useSelector((state) => state.auth.user)

  const themeMode = useSelector((state) => state.theme.mode)
  const drawerOpen = useSelector((state) => state.theme.drawerOpen)

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const navigateToProfileUpdate = () => {
    setAnchorEl(null)
    navigate(`/user/profile-update/${user.id}`)
  }

  const navigateToSecurityAnswerUpdate = () => {
    setAnchorEl(null)
    navigate('/security-answer-update')
  }

  const handleLogout = async () => {
    try {
      const res = await dispatch(logout()) // Wait for the logout action to complete
      if (res.payload && !res.payload.error) {
        persistor.purge().then(() => {
          location.reload()
        })
        // navigate('/') // Redirect user to login page if logut is success.
        // Show flash message for success logout
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
      } else {
        // Show flash message for error message during logout
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      // Show flash message for server error
      dispatch(
        showError({
          error: true,
          message: error.message,
        })
      )
      console.error('Logout failed:', error)
    }
  }

  const toggleTheme = () => {
    const newThemeMode = themeMode === 'light' ? 'dark' : 'light'
    dispatch(setTheme(newThemeMode))
  }

  const toggleDrawer = () => {
    dispatch(setDrawer(!drawerOpen))
  }

  return (
    <AppBar
      position="fixed"
      open={drawerOpen}
      sx={{ backdropFilter: 'blur(6px)' }}
      color="custom"
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          edge="start"
          sx={{
            marginRight: 1,
            alignItems: 'center',
          }}
        >
          <MenuIcon />
        </IconButton>

        <Box sx={{ flexGrow: 1 }}>
          <Breadcrumb />
        </Box>

        {/* <IconButton size="large" color="inherit" onClick={toggleTheme}>
          <DarkModeIcon />
        </IconButton> */}

        <Settings />
        <IconButton size="large" onClick={handleMenu} color="primary">
          <Avatar alt="S" src={user1} />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={navigateToProfileUpdate}>
            <ListItemIcon>
              <AccountCircle fontSize="small" />
            </ListItemIcon>
            Profile
          </MenuItem>

          <MenuItem onClick={navigateToSecurityAnswerUpdate}>
            <ListItemIcon>
              <Update fontSize="small" />
            </ListItemIcon>
            Update Security Question
          </MenuItem>

          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  )
}

export default AdminTopbar
