import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined'
import { Box, Grid, InputLabel, Stack } from '@mui/material'
import CDialog from '../../../../components/CDialog'
import CButton from '../../../../components/CButton'
import CTextField from '../../../../components/CTextField'
import CEditor from '../../../../components/CEditor'
import { noteCreate } from '../../../../redux/Slice/commonSlice'
import { showError, showSuccess } from '../../../../redux/Slice/alertSlice'
import CAutocomplete from '../../../../components/CAutocomplete'
import { list, statusUpdate } from '../leadsSlice'
import { all } from 'axios'

// Local Components

const StatusChange = ({ status, id, lead }) => {
  const dispatch = useDispatch()
  const { leadId } = useParams()

  const [open, setOpen] = useState(false)
  const handleCDialogOpen = () => {
    setOpen(true)
  }
  const handleCDialogClose = () => {
    setOpen(false)
  }
  const [data, setData] = useState({
    note: '',
    status: lead,
    id: id,
    is_lead: '',
    note_image: [],
  })
  useEffect(() => {}, [])

  // modal
  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const res = await dispatch(
        statusUpdate({
          status: data?.status,
          id: data?.id,
          note: data?.note,
          note_image: data?.note_image,
          is_lead: data?.is_lead,
        })
      )
      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        dispatch(list({ status: data.status }))
        setOpen(false)
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('An error occurred!', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred!',
        })
      )
    }
  }

  const statusList = [
    {
      id: 11,
      name: 'Lead',
    },
    {
      id: 12,
      name: 'Closed Success',
    },
    {
      id: 13,
      name: 'Not Interested',
    },
  ]

  return (
    <CDialog
      btnTitle={status}
      headerTitle="Status Change"
      handleCDialogOpen={handleCDialogOpen}
      handleCDialogClose={handleCDialogClose}
      open={open}
    >
      <Box maxWidth="50rem">
        <form onSubmit={handleSubmit}>
          <Grid item xs={12} md={6}>
            <CAutocomplete
              options={statusList}
              getOptionLabel={(option) => option.name}
              onChange={(e, value) => setData({ ...data, is_lead: value.id })}
              label="Status"
              name="is_lead"
            />
          </Grid>
          <Grid container spacing={2}>
            {/* Other Email Receivers */}
            <Grid item xs={12}>
              <Grid item xs={12}>
                <InputLabel>Note</InputLabel>
                <CEditor
                  value={data.note}
                  handleChange={(value) =>
                    setData({
                      ...data,
                      note: value,
                    })
                  }
                />
              </Grid>
            </Grid>

            {/* Email Message */}
            <Grid item xs={12}>
              <InputLabel>Choose File</InputLabel>
              <CTextField
                name="note_image"
                type="file"
                fullWidth
                variant="outlined"
                onChange={(e) =>
                  setData({ ...data, note_image: e.target.files[0] })
                }
                // error={!!(errors && errors.file_image)}
                // helperText={errors && errors.file_image}
              />
            </Grid>
            {/* Add any error handling for email message if needed */}
          </Grid>

          <Stack
            direction="row"
            justifyContent="flex-end"
            sx={{ mb: 2 }}
            mt={2}
          >
            <CButton label="Send" icon={<SendOutlinedIcon />} type="submit" />
          </Stack>
        </form>
      </Box>
    </CDialog>
  )
}

export default StatusChange
