// // UserList.jsx
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Icon
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import AddIcon from '@mui/icons-material/Add'
import { Box, Button, ButtonGroup, Card, Stack } from '@mui/material'
// Components
import AdminLayout from '@/layouts/AdminLayout'

// Slice
import { setBreadcrumb } from '@/redux/Slice/breadcrumbSlice'
import { Link } from 'react-router-dom'
import CButton from '@/components/CButton'
import CDatatable from '@/components/CDatatable'
import { changeStatus, deleteRecord } from '@/redux/Slice/commonSlice'
import { showError, showSuccess } from '@/redux/Slice/alertSlice'
import { list } from '../bannerSlice'

import { renderCell } from '@/utils/ctableTool'
import { Helmet } from 'react-helmet-async'

const bannerList = () => {
  const dispatch = useDispatch()
  const { banners, isLoading } = useSelector((state) => state.websiteBanner)

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        {
          path: '/website/banner/list',
          label: 'Website Banner',
          isLast: false,
        },
        { path: '', label: 'List', isLast: true },
      ])
    )
  }, [dispatch])

  const handleQuickFilterValue = (filterModel) => {
    const quickFilterValue = filterModel.quickFilterValues.join(' ')
    console.log(quickFilterValue)
  }

  const columns = [
    { field: 'title', headerName: 'Title', flex: 1 },
    {
      field: 'web_image',
      headerName: 'Web Image',
      flex: 1,
      renderCell: (params) =>
        renderCell(
          <Box
            sx={{
              overflow: 'hidden',
              display: '-webkit-box',
              webkitLineClamp: '1',
              webkitBoxOrient: 'vertical',
              padding: 1,
            }}
            component="div"
            dangerouslySetInnerHTML={{
              __html: params.row.description,
            }}
          />
        ),
    },

    {
      field: 'mobile_image',
      headerName: 'Mobile Image',
      flex: 1,
      renderCell: (params) =>
        renderCell(
          <Box
            sx={{
              overflow: 'hidden',
              display: '-webkit-box',
              webkitLineClamp: '1',
              webkitBoxOrient: 'vertical',
              padding: 1,
            }}
            component="div"
            dangerouslySetInnerHTML={{
              __html: params.row.description,
            }}
          />
        ),
    },

    {
      field: 'action',
      headerName: 'Action',
      flex: 0.4,
      renderCell: (params) => (
        <ButtonGroup variant="contained" aria-label="Basic button group">
          <CButton
            component={Link}
            to={`/website/banner/update/${params.row.id}`}
            size="small"
            label="Edit"
            sx={{ background: '#149865', color: 'white' }}
          />
          <CButton
            label="Delete"
            sx={{ background: '#dbe1e9', color: 'black' }}
            onClick={() => handleDelete(params.row.id, 'Banner')}
          />
        </ButtonGroup>
      ),
    },
  ]

  useEffect(() => {
    dispatch(list())
  }, [])

  // Handle form submission
  const handleDelete = async (bannerId, model) => {
    try {
      const res = await dispatch(
        deleteRecord({
          id: bannerId,
          model: model,
        })
      )

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        dispatch(list())
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Error while creating File:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred during File creation.',
        })
      )
    }
  }

  // Handle form submission
  const handleStatusChange = async (bannerId, status, model, column) => {
    try {
      const res = await dispatch(
        changeStatus({
          id: bannerId,
          status: status,
          model: model,
          column: column,
        })
      )

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        dispatch(list())
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Error while creating File:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred during File creation.',
        })
      )
    }
  }

  return (
    <>
      <Helmet>
        <title>Bioaro | Website Banner - List</title>
      </Helmet>
      <Card sx={{ p: 2 }}>
        <Stack direction="row" justifyContent="flex-end" mb={1}>
          <CButton
            label="Create"
            icon={<AddIcon />}
            component={Link}
            to={`/website/banner/create`}
          />
        </Stack>

        <CDatatable
          rows={banners}
          columns={columns}
          isLoading={isLoading}
          handleQuickFilterValue={handleQuickFilterValue}
          getRowHeight="auto"
        />
      </Card>
    </>
  )
}

export default bannerList
