import React from 'react'
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material'

function CRadioGroup({ label, name, value, options, onChange, disabled, required=false, row = true }) {
  return (
    <FormControl sx={{ mt: 2 }} disabled={disabled}>
      <FormLabel>
        {label}{' '}
        {required && (
          <Typography sx={{ display: 'inline', color: 'red' }}>*</Typography>
        )}
      </FormLabel>
      <RadioGroup row name={name} value={value ?? ''} onChange={onChange}>
        {options?.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default CRadioGroup
