import { createSlice } from "@reduxjs/toolkit";


export const alertSlice = createSlice({
    name: 'alert',
    initialState: {
        info: false,
        success: false,
        warning: false,
        error: false,
        message: ''

    },
    reducers: {
        showInfo: (state, action) => {
            state.info = action.payload.info,
            state.success = false,
            state.warning = false,
            state.error = false,
            state.message = action.payload.message
        },
        showSuccess: (state, action) => {
            state.success = action.payload.success
            state.info = false
            state.warning = false
            state.error = false
            state.message = action.payload.message
        },
        showWarning: (state, action) => {
            state.warning = action.payload.warning
            state.success = false
            state.info = false
            state.error = false
            state.message = action.payload.message
        },
        showError: (state, action) => {
            state.error = action.payload.error
            state.warning = false
            state.success = false
            state.info = false
            state.message = action.payload.message
        }
    }
    
})

export const {showInfo, showSuccess, showWarning, showError} = alertSlice.actions;
export default alertSlice.reducer;