// // UserList.jsx
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Icon
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import AddIcon from '@mui/icons-material/Add'
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
// Components
import AdminLayout from '@/layouts/AdminLayout'

// Slice
import { setBreadcrumb } from '@/redux/Slice/breadcrumbSlice'
import { Link, useNavigate } from 'react-router-dom'
import CButton from '@/components/CButton'
import CDatatable from '@/components/CDatatable'
import { changeStatus, deleteRecord } from '@/redux/Slice/commonSlice'
import { showError, showSuccess } from '@/redux/Slice/alertSlice'
import CTextField from '@/components/CTextField'
import SaveIcon from '@mui/icons-material/Save'
import { Helmet } from 'react-helmet-async'
import { create, list } from '../warehouseSlice'
import WarehouseUpdate from './WarehouseUpdate'
import CCheckbox from '@/components/CCheckbox'
import CAutocomplete from '@/components/CAutocomplete'
import { renderCell } from '@/utils/ctableTool'

const WarehouseList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { users, warehouses, errors, isLoading } = useSelector(
    (state) => state.warehouse
  )

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        {
          path: 'inventory/warehouse/list',
          label: ' Warehouse',
          isLast: false,
        },
        { path: '', label: 'List', isLast: true },
      ])
    )
  }, [dispatch])

  const handleQuickFilterValue = (filterModel) => {
    const quickFilterValue = filterModel.quickFilterValues.join(' ')
    console.log(quickFilterValue)
  }

  const columns = [
    { field: 'userNames', headerName: 'User', minWidth: 150, flex: 1 },
    { field: 'name', headerName: 'Site Name', minWidth: 150, flex: 1 },
    { field: 'phone', headerName: 'Phone ', minWidth: 150, flex: 1 },
    { field: 'address', headerName: 'Address ', minWidth: 150, flex: 1 },
    {
      field: 'product_count',
      headerName: 'Total Product ',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'total_remain_qty',
      headerName: 'Total Qty ',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 100,
      flex: 1,
      renderCell: (params) =>
        renderCell(
          <CButton
            component={Link}
            //   to={`/leads/view-log/${params.row.id}`}
            label={params.row.status == 1 ? 'On' : 'Off'}
            sx={{
              background: params.row.status == 1 ? '#0a8154' : '#cf2323',
            }}
            onClick={() =>
              handleStatusChange(
                params.row.id,
                params.row.status == 0 ? 1 : 0,
                'Site',
                'status'
              )
            }
          />
        ),
    },
    {
      field: 'default',
      headerName: 'Default',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <>
          <CButton
            component={Link}
            //   to={`/leads/view-log/${params.row.id}`}
            label={params.row.default == 1 ? 'On' : 'Off'}
            sx={{
              background: params.row.default == 1 ? '#0a8154' : '#cf2323',
            }}
            onClick={() =>
              handleStatusChange(
                params.row.id,
                params.row.default == 0 ? 1 : 0,
                'Site',
                'default'
              )
            }
          />
        </>
      ),
    },

    {
      field: 'action',
      headerName: 'Action',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <ButtonGroup variant="contained" aria-label="Basic button group">
          <WarehouseUpdate warehouseInfo={params.row} />
        </ButtonGroup>
      ),
    },
  ]

  // Handle form submission
  const handleStatusChange = async (warehouseId, status, model, column) => {
    try {
      const res = await dispatch(
        changeStatus({
          id: warehouseId,
          status: status,
          model: model,
          column: column,
        })
      )

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        dispatch(list())
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Error while creating File:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred during File creation.',
        })
      )
    }
  }

  useEffect(() => {
    // page title
    document.title = 'BioAro |  Warehouse - List'
    dispatch(list())
  }, [])

  //   initial create value
  const [warehouse, setWarehouse] = useState({
    name: '',
    user_id: [],
    phone1: '',
    address: '',
  })

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      const res = await dispatch(create(warehouse))

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        setWarehouse({
          name: '',
          phone1: '',
          user_id: [],
          address: '',
        })
        dispatch(list())
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Server error:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred while Creating  Warehouse.',
        })
      )
    }
  }

  // onChange handler to update the local state
  const handleInputChange = (event) => {
    const { name, value } = event.target
    // Update the local state with the changed value
    setWarehouse((warehouse) => ({
      ...warehouse,
      [name]: value,
    }))
  }

  return (
    <>
      <Helmet>
        <title>Bioaro | Warehouse - List</title>
      </Helmet>
      <Stack direction="row" sx={{ width: '100%', pb: 2 }}>
        <Card sx={{ p: 3, width: '90rem' }}>
          <form onSubmit={handleSubmit}>
            <Box>
              {' '}
              <Stack sx={{ float: 'left' }}>
                {' '}
                <Typography variant="h5">Create Warehouse</Typography>{' '}
              </Stack>
              <Stack
                direction="row"
                justifyContent="flex-end"
                mb={2}
                mt={2}
                mr={1.3}
              >
                <CButton label="Save" icon={<SaveIcon />} type="submit" />
              </Stack>
            </Box>

            <Grid container spacing={2} alignItems="center">
              <Grid xs={6} item>
                <CTextField
                  label="Site Name"
                  name="name"
                  value={warehouse?.name}
                  error={errors && errors.name}
                  helperText={errors && errors.name}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid xs={6} item>
                <CTextField
                  label="Phone"
                  name="phone1"
                  value={warehouse?.phone1}
                  error={errors && errors.phone1}
                  helperText={errors && errors.phone1}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <CAutocomplete
                  options={users}
                  getOptionLabel={(option) => option.full_name}
                  value={warehouse?.user_id}
                  onChange={(e, value) =>
                    // console.log(value)
                    setWarehouse({
                      ...warehouse,
                      user_id: value,
                      user: value.id,
                    })
                  }
                  label="User"
                  multiple
                  error={!!(errors && errors.user_id)}
                  helperText={errors && errors.user_id}
                />
              </Grid>

              <Grid xs={12} item>
                <CTextField
                  label="Address"
                  name="address"
                  value={warehouse?.address}
                  error={errors && errors.address}
                  helperText={errors && errors.address}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
          </form>
        </Card>
      </Stack>
      <Card sx={{ p: 2 }}>
        <Typography variant="h5" p={2}>
          Warehouse List
        </Typography>
        <CDatatable
          rows={warehouses}
          columns={columns}
          isLoading={isLoading}
          handleQuickFilterValue={handleQuickFilterValue}
        />
      </Card>
    </>
  )
}

export default WarehouseList
