import React from 'react'

const TagList = React.lazy(() => import('./partial/TagList'))
const TagCreate = React.lazy(() => import('./partial/TagCreate'))
const TagUpdate = React.lazy(() => import('./partial/TagUpdate'))

const tagRoutes = [
  { path: 'list/', element: <TagList /> },
  { path: 'create/', element: <TagCreate /> },
  { path: 'update/:tagId', element: <TagUpdate /> },
]

export default tagRoutes
