// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '@/services/axiosConfig'

setAuthToken() // This function is called to set the auth header

// Fetch category list
export const list = createAsyncThunk('paid-consultation/list', async () => {
  const response = await axiosInstance.get('paid-consultation/list')
  console.log(response.data)
  return response.data
})

// Fetch category info
export const paidConsultationInfo = createAsyncThunk(
  'paid-consultation/info',
  async (categoryId) => {
    const response = await axiosInstance.get(
      `paid-consultation/info/${categoryId}`
    )
    console.log(response.data)
    return response.data
  }
)

// Fetch paid-consultation create
export const create = createAsyncThunk(
  'paid-consultation/create',
  async (data) => {
    const response = await axiosInstance.post('paid-consultation/create', data)
    return response.data
  }
)

// Fetch paid-consultation update
export const update = createAsyncThunk(
  'paid-consultation/update',
  async ({data, consultationId}) => {
    console.log(consultationId)
    const response = await axiosInstance.post(
      `paid-consultation/update/${consultationId}`,
      data
    )
    return response.data
  }
)

const paidConsultationSlice = createSlice({
  name: 'paidConsultation',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    paidConsultations: [],
    info: '',
    categories: [],
    doctors: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      //paid-consultation List
      .addCase(list.pending, (state) => {
        state.isLoading = true
      })
      .addCase(list.fulfilled, (state, { payload } = action) => {
        state.paidConsultations = payload.paidConsultations
        state.categories = payload.categories
        state.doctors = payload.doctors
        state.isLoading = false
      })

      .addCase(list.rejected, (state) => {
        state.isLoading = false
      })

      //Category info
      .addCase(paidConsultationInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        paidConsultationInfo.fulfilled,
        (state, { payload } = action) => {
          state.info = payload.info
          state.isLoading = false
        }
      )

      .addCase(paidConsultationInfo.rejected, (state) => {
        state.isLoading = false
      })

      //Category create
      .addCase(create.pending, (state) => {
        state.isLoading = true
      })
      .addCase(create.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(create.rejected, (state) => {
        state.isLoading = false
      })

      //Category update
      .addCase(update.pending, (state) => {
        state.isLoading = true
      })
      .addCase(update.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(update.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default paidConsultationSlice.reducer
