import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import {
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import CTextField from '@/components/CTextField';
import CAutocomplete from '@/components/CAutocomplete';
import CSelect from '@/components/CSelect';
import CButton from '@/components/CButton';
import CNumberField from '@/components/CNumberField';
import CFileField from '@/components/CFileField';
import CRadioGroup from '@/components/CRadioGroup';
import CEditor from '@/components/CEditor';
import { slugify } from '@/utils/cutils';
import { showSuccess, showError } from '@/redux/Slice/alertSlice';
import { setBreadcrumb } from '@/redux/Slice/breadcrumbSlice';
import { fetchSServiceInfo, create } from './sserviceSlice';

const SServiceCreate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sserviceInfoFromRedux = useSelector((state) => state.sservice?.sserviceInfo);
  const errors = useSelector((state) => state.sservice.errors);

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        { path: '/sservice/list', label: 'Sequence Service', isLast: false },
        { path: '', label: 'Create', isLast: true },
      ])
    );
  }, [dispatch]);



  const [sservice, setSService] = useState({
    name: '',
    slug: '',
    title: '',
    genes: '',
    price: '',
    image: null,
    position: sserviceInfoFromRedux?.position,
    service_type: false,
    disabled_services: [],
    disabled_id: [],
    suggestion_services: [],
    suggestion_id: [],
    categories: [],
    category_id: [],
    tags: [],
    tag_id: [],
    description: '',
    keyword_title: '',
    keyword_des: '',
  });


  useEffect(()=>{dispatch(fetchSServiceInfo())},[])
  useEffect(()=>{},[sserviceInfoFromRedux])

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const res = await dispatch(create({ data: sservice }));

      if (res.payload && !res.payload.error) {
        dispatch(showSuccess({ success: true, message: res.payload.message }));
        navigate('/sservice/list');
      } else {
        dispatch(showError({ error: true, message: res.payload.message }));
      }
    } catch (error) {
      console.error('Server error:', error);
      dispatch(showError({ error: true, message: 'An error occurred!' }));
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSService((prevSService) => ({
      ...prevSService,
      [name]: value,
    }));
    if (name === 'name') {
      setSService((prevSService) => ({
        ...prevSService,
        slug: slugify(value),
      }));
    }
  };

  const handleAutocompleteChange = (data) => {
    const { name, value, id_column } = data;
    const ids = value.map((item) => item.id);
    setSService((prevSService) => ({
      ...prevSService,
      [name]: value,
      [id_column]: ids,
    }));
  };

  return (
    <>
      <Helmet>
        <title>Bioaro | Sequence Service create</title>
      </Helmet>
      <Card sx={{ p: 2 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <CTextField
                label="Name"
                name="name"
                value={sservice?.name}
                required={true}
                error={!!errors && errors.name}
                helperText={errors && errors.name}
                onChange={handleInputChange}
              />
            </Grid>

            {/* Slug */}
            <Grid item xs={12} md={6}>
              <CTextField
                label="Slug"
                name="slug"
                value={sservice?.slug}
                disabled={true}
              />
            </Grid>

            {/* Title  */}
            <Grid item xs={12} md={6}>
              <CTextField
                label="Title"
                name="title"
                value={sservice?.title}
                required={true}
                error={!!errors && errors.title}
                helperText={errors && errors.title}
                onChange={handleInputChange}
              />
            </Grid>

            {/* Disabled Service  */}
            <Grid item xs={12} md={6}>
              <CAutocomplete
                label="Disabled Service"
                options={sserviceInfoFromRedux?.services || []}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id == value.id}
                multiple={true}
                onChange={(e, value) =>
                  handleAutocompleteChange({
                    name: 'disabled_services',
                    value: value,
                    id_column: 'disabled_id',
                  })
                }
              />
            </Grid>

            {/* Suggestion Service  */}
            <Grid item xs={12} md={6}>
              <CAutocomplete
                label="Suggestion Service"
                options={sserviceInfoFromRedux?.services || []}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) =>
                  option.name == value.name
                }
                multiple={true}
                onChange={(e, value) =>
                  handleAutocompleteChange({
                    name: 'suggestion_services',
                    value: value,
                    id_column: 'suggestion_id',
                  })
                }
              />
            </Grid>

            {/* Tags */}
            <Grid item xs={12} md={6}>
              <CAutocomplete
                label="Tag"
                options={sserviceInfoFromRedux?.tags || []}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) =>
                  option.name == value.name
                }
                multiple={true}
                onChange={(e, value) =>
                  handleAutocompleteChange({
                    name: 'tags',
                    value: value,
                    id_column: 'tag_id',
                  })
                }
              />
            </Grid>

            {/* Price  */}
            <Grid item xs={12} md={6}>
              <CNumberField
                label="Price"
                name="price"
                value={sservice?.price}
                required={true}
                error={!!errors && errors.price}
                helperText={errors && errors.price}
                onChange={handleInputChange}
              />
            </Grid>

            {/* File  */}
            <Grid item xs={12} md={6}>
              <CFileField
                label="File"
                multiple={false}
                onChange={(e) =>
                  setSService({ ...sservice, image: e.target.files[0] })
                }
              />
            </Grid>

            {/* Attach Existing Panels To This */}
            <Grid item xs={12} md={6}>
              <CAutocomplete
                label="Attach Existing Panels To This"
                options={sserviceInfoFromRedux?.panels || []}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) =>
                  option.name == value.name
                }
                multiple={true}
                onChange={(e, value) =>
                  handleAutocompleteChange({
                    name: 'panels',
                    value: value,
                    id_column: 'panel_id',
                  })
                }
              />
            </Grid>

            {/* Position  */}
            <Grid item xs={12} md={6}>
              <CNumberField
                label="Position"
                name="position"
                value={sservice?.position}
                error={!!errors && errors.position}
                helperText={errors && errors.position}
                onChange={handleInputChange}
              />
            </Grid>

            {/* Category */}
            <Grid item xs={12} md={6}>
              <CAutocomplete
                label="Category"
                options={sserviceInfoFromRedux?.categories || []}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id == value.id}
                multiple={true}
                onChange={(e, value) =>
                  handleAutocompleteChange({
                    name: 'categories',
                    value: value,
                    id_column: 'category_id',
                  })
                }
              />
            </Grid>

            {/* Gut Clinic  */}
            <Grid item xs={12} md={6}>
              <CRadioGroup
                label="Gut Clinic"
                name="service_type"
                value={sservice?.service_type}
                options={[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <CEditor
                label="Description"
                value={sservice.description}
                error={!!errors && errors.description}
                helperText={errors && errors.description}
                handleChange={(value) =>
                  setSService({
                    ...sservice,
                    description: value,
                  })
                }
              />
            </Grid>

            {/* Keyword */}
            <Grid item xs={12} md={6}>
              <CTextField
                label="Keyword"
                name="keyword"
                value={sservice?.keyword}
                error={!!errors && errors.keyword}
                helperText={errors && errors.keyword}
                onChange={handleInputChange}
              />
            </Grid>

            {/* Keyword Description */}
            <Grid item xs={12} md={6}>
              <CTextField
                label="Keyword Description"
                name="keyword_des"
                value={sservice?.keyword_des}
                error={!!errors && errors.keyword_des}
                helperText={errors && errors.keyword_des}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Stack direction="row" justifyContent="flex-end" mt={2}>
            <CButton label="Save" icon={<SaveIcon />} type="submit" />
          </Stack>
        </form>
      </Card>
    </>
  );
};

export default SServiceCreate;
