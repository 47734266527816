import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Button from '@mui/material/Button'
import Radio from '@mui/material/Radio'
import {
  Box,
  Card,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import SaveIcon from '@mui/icons-material/Save'

// Local Components
import CDialog from '../../../../components/CDialog'

// Redux Actions

// import { fetchNoteList, fetchNoteDetail } from "../../Templates/Note/noteSlice";
import CTextField from '@/components/CTextField'
import CButton from '@/components/CButton'
import CPhoneNumberField from '@/components/CPhoneNumberField'

import { showSuccess, showError } from '@/redux/Slice/alertSlice'
import { customerCreate, fetchPlaceOrderInfo } from '../placeOrderSlice'

const CustomerCreate = ({ sendDataToParent }) => {
  const dispatch = useDispatch()

  // CDialog start
  const [open, setOpen] = React.useState(false)

  const handleCDialogOpen = () => {
    setOpen(true)
  }

  const handleCDialogClose = () => {
    setOpen(false)
  }

  const { errors, isLoading } = useSelector((state) => state.placeOrder)

  const [customer, setCustomer] = useState({
    name: '',
    phone: '',
    email: '',
  })

  // Handel Input Change
  const handleInputChange = (event) => {
    const { name, value } = event.target
    // Update the local state with the changed value
    setCustomer((customer) => ({
      ...customer,
      [name]: value,
    }))
  }
  //Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      const res = await dispatch(customerCreate(customer))

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        // Close CDialog after success request
        setOpen(false)

        const newCustomer = res.payload.customer
        sendDataToParent(newCustomer)

        dispatch(fetchPlaceOrderInfo())
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Error while creating Customer:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred during Customer creation.',
        })
      )
    }
  }
  console.log(customer)
  return (
    <CDialog
      btnTitle="Create"
      headerTitle="Customer Create"
      icon={<AddIcon />}
      handleCDialogOpen={handleCDialogOpen}
      handleCDialogClose={handleCDialogClose}
      open={open}
    >
      <Box maxWidth="50rem">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <CTextField
                label="Name"
                name="name"
                value={customer?.name}
                required={true}
                error={!!(errors && errors.name)}
                helperText={errors && errors.name}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <CTextField
                type="email"
                label="Email"
                name="email"
                value={customer?.email}
                required={true}
                error={!!(errors && errors.email)}
                helperText={errors && errors.email}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <CPhoneNumberField
                label="Phone"
                value={customer.phone}
                name="phone"
                error={!!(errors && errors.phone)}
                helperText={errors && errors.phone}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>

          <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
            <CButton label="Save" icon={<SaveIcon />} type="submit" />
          </Stack>
        </form>
      </Box>
    </CDialog>
  )
}

export default CustomerCreate
