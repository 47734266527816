// // UserList.jsx
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Icon
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import AddIcon from '@mui/icons-material/Add'
import { Box, Button, ButtonGroup, Card, Stack } from '@mui/material'
// Components
import AdminLayout from '@/layouts/AdminLayout'

// Slice
import { setBreadcrumb } from '@/redux/Slice/breadcrumbSlice'
import { Link } from 'react-router-dom'
import CButton from '@/components/CButton'
import CDatatable from '@/components/CDatatable'
import { changeStatus, deleteRecord } from '@/redux/Slice/commonSlice'
import { showError, showSuccess } from '@/redux/Slice/alertSlice'
import { list } from '../paidConsultationSlice'
import DeleteRow from '../../Common/DeleteRow'
import { renderCell } from '@/utils/ctableTool'
import { Helmet } from 'react-helmet-async'

const PaidConsultationList = () => {
  const dispatch = useDispatch()
  const { paidConsultations, isLoading } = useSelector(
    (state) => state.paidConsultation
  )

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        {
          path: '/paid-consultation/list',
          label: 'Paid Consultation',
          isLast: false,
        },
        { path: '', label: 'List', isLast: true },
      ])
    )
  }, [dispatch])

  const handleQuickFilterValue = (filterModel) => {
    const quickFilterValue = filterModel.quickFilterValues.join(' ')
    console.log(quickFilterValue)
  }

  const columns = [
    {
      field: 'cat',
      headerName: 'Category',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      field: 'price',
      headerName: 'Price',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      field: 'advance_payment',
      headerName: 'Advance Payment',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      field: 'duration',
      headerName: 'Duration',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      field: 'action',
      headerName: 'Action',
      minWidth: 300,
      flex: 1,
      renderCell: (params) =>
        renderCell(
          <ButtonGroup variant="contained" aria-label="Basic button group">
            <CButton
              component={Link}
              to={`/paid-consultation/update/${params.row.id}`}
              size="small"
              label="Edit"
              sx={{ background: '#149865', color: 'white' }}
            />

            <CButton
              label="Delete"
              color="error"
              yesNo
              onClick={() => handleDelete(params.row.id, 'ConsultationService')}
            />
          </ButtonGroup>
        ),
    },
  ]

  useEffect(() => {
    dispatch(list())
  }, [])

  // Handle form submission
  const handleDelete = async (consultationId, model) => {
    try {
      const res = await dispatch(
        deleteRecord({
          id: consultationId,
          model: model,
        })
      )

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        dispatch(list())
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Error while creating File:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred during File creation.',
        })
      )
    }
  }

  // Handle form submission
  const handleStatusChange = async (consultationId, status, model, column) => {
    try {
      const res = await dispatch(
        changeStatus({
          id: consultationId,
          status: status,
          model: model,
          column: column,
        })
      )

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        dispatch(list())
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('An error occurred:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred.',
        })
      )
    }
  }

  return (
    <>
      <Helmet>
        <title>Bioaro | Paid Consultation - List</title>
      </Helmet>
      <Card sx={{ p: 2 }}>
        <Stack direction="row" justifyContent="flex-end" mb={1}>
          <CButton
            label="Consultation service"
            icon={<AddIcon />}
            component={Link}
            to={`/paid-consultation/create`}
          />
        </Stack>

        <CDatatable
          rows={paidConsultations}
          columns={columns}
          isLoading={isLoading}
          handleQuickFilterValue={handleQuickFilterValue}
          getRowHeight="auto"
        />
      </Card>
    </>
  )
}

export default PaidConsultationList
