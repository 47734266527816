// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../../../services/axiosConfig'

// Fetch patient medicine active inactive list
export const fetchBeforeAfterList = createAsyncThunk(
  'patient/before-after/list',
  async (patientId) => {
    const response = await axiosInstance.get(
      `patient/before-after/list/${patientId}`
    )
    return response.data
  }
)

//beforeImageCreate create
export const beforeImageCreate = createAsyncThunk(
  'patient/before-after/beforeImageCreate',
  async ({ data, patientId }) => {
    const response = await axiosInstance.post(
      `patient/before-after/beforeImageCreate/${patientId}`,
      data
    )
    return response.data
  }
)

//beforeImageCreate create
export const afterImageCreate = createAsyncThunk(
  'patient/before-after/afterImageCreate',
  async ({ data, patientId }) => {
    const response = await axiosInstance.post(
      `patient/before-after/afterImageCreate/${patientId}`,
      data
    )
    return response.data
  }
)

//before after update
export const update = createAsyncThunk(
  'patient/before-after/update-image',
  async ({ data }) => {
    const response = await axiosInstance.post(
      `patient/before-after/update-image`,
      data
    )
    return response.data
  }
)

//before after delete
export const beforeAfterDelete = createAsyncThunk(
  'patient/before-after/delete',
  async ({ itemId }) => {
    const response = await axiosInstance.get(
      `patient/before-after/delete/${itemId}`
    )
    return response.data
  }
)

const beforeAfter = createSlice({
  name: 'beforeAfter',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    error: '',
    beforeAfters: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch  patient based file list
      .addCase(fetchBeforeAfterList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        fetchBeforeAfterList.fulfilled,
        (state, { payload } = action) => {
          state.beforeAfters = payload.beforeAfters
          state.isLoading = false
        }
      )
      .addCase(fetchBeforeAfterList.rejected, (state) => {
        state.isLoading = false
      })

      // beforeImageCreate
      .addCase(beforeImageCreate.pending, (state) => {
        state.isLoading = true
      })
      .addCase(beforeImageCreate.fulfilled, (state, { payload } = action) => {
        state.errors = payload.errors
        state.message = payload.message
        state.isLoading = false
      })
      .addCase(beforeImageCreate.rejected, (state) => {
        state.isLoading = false
      })

      // afterImageCreate
      .addCase(afterImageCreate.pending, (state) => {
        state.isLoading = true
      })
      .addCase(afterImageCreate.fulfilled, (state, { payload } = action) => {
        state.errors = payload.errors
        state.message = payload.message
        state.isLoading = false
      })
      .addCase(afterImageCreate.rejected, (state) => {
        state.isLoading = false
      })

      // before after update
      .addCase(update.pending, (state) => {
        state.isLoading = true
      })
      .addCase(update.fulfilled, (state, { payload } = action) => {
        state.errors = payload.errors
        state.message = payload.message
        state.isLoading = false
      })
      .addCase(update.rejected, (state) => {
        state.isLoading = false
      })

      // before after delete
      .addCase(beforeAfterDelete.pending, (state) => {
        state.isLoading = true
      })
      .addCase(beforeAfterDelete.fulfilled, (state, { payload } = action) => {
        state.errors = payload.errors
        state.message = payload.message
        state.isLoading = false
      })
      .addCase(beforeAfterDelete.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default beforeAfter.reducer
