import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Card, Grid, InputLabel, Stack, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import SaveIcon from '@mui/icons-material/Save'
import CButton from '@/components/CButton'
import CTextField from '@/components/CTextField'
import CAutocomplete from '@/components/CAutocomplete'
import { showError, showSuccess } from '@/redux/Slice/alertSlice'
import {
  info,
  productUpdate,
  productList,
  subCategoryList,
  productDetail,
} from './productSlice'
import CIconButton from '@/components/CIconButton'
import { Delete } from '@mui/icons-material'
import { useNavigate, useParams } from 'react-router-dom'
import CCircularProgress from '@/components/CCircularProgress'

const ProductUpdate = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { productId } = useParams()
  const { sites, categories, subCategories, errors } = useSelector(
    (state) => state.product
  )

  const productFromRedux = useSelector((state) => state.product.product)

  const [product, setProduct] = useState(productFromRedux)

  useEffect(() => {
    dispatch(productDetail(productId))
  }, [])

  useEffect(() => {
    setProduct(productFromRedux)
  }, [productFromRedux])

  useEffect(() => {
    if (product?.category) {
      dispatch(subCategoryList(product.category.id))
      setProduct((prevProduct) => ({ ...prevProduct, sub_category_id: null }))
    }
  }, [product?.category, dispatch])

  useEffect(() => {
    dispatch(info())
  }, [dispatch])

  const addMoreSites = () => {
    const newRow = {
      site_id: null,
      site_qty: '',
      notify_qty: '',
    }
    setProduct((prevProduct) => ({
      ...prevProduct,
      sites: [...prevProduct.sites, newRow],
    }))
  }

  const removeRow = (index) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      sites: prevProduct.sites.filter((_, i) => i !== index),
    }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      const res = await dispatch(
        productUpdate({ productId: productId, data: product })
      )

      if (res.payload && !res.payload.error) {
        dispatch(showSuccess({ success: true, message: res.payload.message }))
        navigate('/product')
      } else {
        dispatch(showError({ error: true, message: res.payload.message }))
      }
    } catch (error) {
      console.error('Server error:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred while updating the product.',
        })
      )
    }
  }

  const handleInputChange = (e, index) => {
    const { name, value, type, checked } = e.target
    const inputValue = type === 'checkbox' ? checked : value
    setProduct((prevProduct) => ({
      ...prevProduct,
      sites: prevProduct.sites.map((site, i) =>
        i === index ? { ...site, [name]: inputValue } : site
      ),
    }))
  }

  return (
    <>
      {product ? (
        <Card sx={{ p: 3, width: '90rem' }}>
          <form onSubmit={handleSubmit}>
            <Box>
              <Stack sx={{ float: 'left' }}>
                <Typography variant="h5">Update Product</Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="flex-end"
                mb={2}
                mt={2}
                mr={1.3}
              >
                <CButton label="Save" icon={<SaveIcon />} type="submit" />
              </Stack>
            </Box>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={4}>
                <CAutocomplete
                  options={categories ?? []}
                  getOptionLabel={(option) => option.name}
                  value={product.category}
                  onChange={(e, value) =>
                    setProduct({
                      ...product,
                      category_id: value.id,
                      category: value,
                    })
                  }
                  label="Category"
                  error={!!errors?.category_id}
                  helperText={errors?.category_id}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <CAutocomplete
                  options={subCategories ?? []}
                  getOptionLabel={(option) => option.name}
                  value={product.subCategory}
                  onChange={(e, value) =>
                    setProduct({
                      ...product,
                      sub_category_id: value.id,
                      subCategory: value,
                    })
                  }
                  label="Sub Category"
                  error={!!errors?.sub_category_id}
                  helperText={errors?.sub_category_id}
                />
              </Grid>

              <Grid item xs={4}>
                <CTextField
                  label="Name"
                  name="name"
                  value={product.name}
                  error={errors?.name}
                  helperText={errors?.name}
                  onChange={(e) =>
                    setProduct({ ...product, name: e.target.value })
                  }
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputLabel>Images</InputLabel>
                <CTextField
                  name="image"
                  type="file"
                  fullWidth
                  variant="outlined"
                  onChange={(e) =>
                    setProduct({ ...product, image: e.target.files })
                  }
                  error={!!errors?.image}
                  helperText={errors?.image}
                />
              </Grid>

              <Grid item xs={4}>
                <CTextField
                  label="Price"
                  name="price"
                  value={product.price}
                  error={errors?.price}
                  helperText={errors?.price}
                  onChange={(e) =>
                    setProduct({ ...product, price: e.target.value })
                  }
                  type="number"
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <CTextField
                  label="Quantity"
                  name="qty"
                  value={product.qty}
                  error={errors?.qty}
                  helperText={errors?.qty}
                  onChange={(e) =>
                    setProduct({ ...product, qty: e.target.value })
                  }
                  type="number"
                />
              </Grid>

              <Grid item container spacing={1} alignItems="center">
                {product.sites.map((item, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={12} md={3}>
                      <CAutocomplete
                        options={sites}
                        getOptionLabel={(option) => option.name}
                        value={item.site}
                        onChange={(e, value) =>
                          setProduct((prevProduct) => ({
                            ...prevProduct,
                            sites: prevProduct.sites.map((site, i) =>
                              i === index
                                ? { ...site, site: value, site_id: value.id }
                                : site
                            ),
                          }))
                        }
                        label="Site"
                        error={!!errors?.[`sites.${index}.site_id`]}
                        helperText={errors?.[`sites.${index}.site_id`]}
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <CTextField
                        label="Quantity"
                        name="site_qty"
                        value={item.site_qty}
                        error={errors?.[`sites.${index}.site_qty`]}
                        helperText={errors?.[`sites.${index}.site_qty`]}
                        onChange={(e) => handleInputChange(e, index)}
                        type="number"
                      />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <CTextField
                        label="Notify Quantity"
                        name="notify_qty"
                        value={item.notify_qty}
                        error={errors?.[`sites.${index}.notify_qty`]}
                        helperText={errors?.[`sites.${index}.notify_qty`]}
                        onChange={(e) => handleInputChange(e, index)}
                        type="number"
                      />
                    </Grid>

                    <Grid item xs={1} mt={3}>
                      {index !== 0 && (
                        <CIconButton
                          icon={<Delete />}
                          color="error"
                          onClick={() => removeRow(index)}
                        />
                      )}
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>

              <Grid item md={12} textAlign="right" mt={2}>
                <CButton
                  label="Add More"
                  icon={<AddIcon />}
                  onClick={addMoreSites}
                />
              </Grid>
            </Grid>
          </form>
        </Card>
      ) : (
        <CCircularProgress />
      )}
    </>
  )
}

export default ProductUpdate
