// // UserList.jsx
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Icon
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import AddIcon from '@mui/icons-material/Add'
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material'
// Components
import AdminLayout from '@/layouts/AdminLayout'

// Slice
import { setBreadcrumb } from '@/redux/Slice/breadcrumbSlice'
import { Link, useNavigate } from 'react-router-dom'
import CButton from '@/components/CButton'
import CDatatable from '@/components/CDatatable'
import { changeStatus, deleteRecord } from '@/redux/Slice/commonSlice'
import { showError, showSuccess } from '@/redux/Slice/alertSlice'
import CTextField from '@/components/CTextField'
import SaveIcon from '@mui/icons-material/Save'
import { Helmet } from 'react-helmet-async'
import { create, list } from './expenseSlice'
import ExpenseUpdate from './ExpenseUpdate'
import CCheckbox from '@/components/CCheckbox'
import CAutocomplete from '@/components/CAutocomplete'
import dayjs from 'dayjs'
import CDatePicker from '@/components/CDatePicker'
import { formatDateTime } from '@/utils/cdayjs'
import CEditor from '@/components/CEditor'

const ExpenseList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { staffs, expenses, categories, errors, isLoading } = useSelector(
    (state) => state.expense
  )

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        {
          path: 'inventory/expense/list',
          label: ' Expense',
          isLast: false,
        },
        { path: '', label: 'List', isLast: true },
      ])
    )
  }, [dispatch])

  const handleQuickFilterValue = (filterModel) => {
    const quickFilterValue = filterModel.quickFilterValues.join(' ')
    console.log(quickFilterValue)
  }

  const columns = [
    {
      field: 'expense_number',
      headerName: 'Expense Number',
      minWidth: 150,
      flex: 1,
    },
    { field: 'category_id', headerName: 'Category', minWidth: 150, flex: 1 },
    { field: 'staff_id', headerName: 'Staff ', minWidth: 150, flex: 1 },
    { field: 'price_format', headerName: 'Price ', minWidth: 50, flex: 1 },
    {
      field: 'discount_format',
      headerName: 'Discount ',
      minWidth: 50,
      flex: 1,
    },
    {
      field: 'total_price_format',
      headerName: 'Total Price ',
      minWidth: 50,
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <>
          <CButton
            component={Link}
            //   to={`/leads/view-log/${params.row.id}`}
            label={params.row.status == 1 ? 'Appro' : 'Reject'}
            sx={{
              background: params.row.status == 1 ? '#0a8154' : '#cf2323',
            }}
            onClick={() =>
              handleStatusChange(
                params.row.id,
                params.row.status == 0 ? 1 : 0,
                'Expense',
                'status'
              )
            }
          />
        </>
      ),
    },

    {
      field: 'action',
      headerName: 'Action',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <ButtonGroup variant="contained" aria-label="Basic button group">
          <ExpenseUpdate expenseInfo={params.row} />
        </ButtonGroup>
      ),
    },
  ]

  // Handle form submission
  const handleStatusChange = async (expenseId, status, model, column) => {
    try {
      const res = await dispatch(
        changeStatus({
          id: expenseId,
          status: status,
          model: model,
          column: column,
        })
      )

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        dispatch(list())
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Error while creating File:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred during File creation.',
        })
      )
    }
  }

  useEffect(() => {
    // page title
    document.title = 'BioAro |  Expense - List'
    dispatch(list())
  }, [])

  //   initial create value
  const [expense, setExpense] = useState({
    category_type: 'travel',
    category_id: null,
    category: null,
    staff_id: null,
    staff: null,
    expense_from: '',
    expense_to: '',
    expense_amount: '',
    expense_attachment: [],
    description: '',
  })

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      const res = await dispatch(create(expense))

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        setExpense({
          category_type: 'travel',
          category_id: null,
          category: null,
          staff_id: null,
          staff: null,
          expense_from: '',
          expense_to: '',
          expense_amount: '',
          expense_attachment: [],
          description: '',
        })
        dispatch(list())({
          category_type: 'travel',
          category_id: null,
          category: null,
          staff_id: null,
          staff: null,
          expense_from: '',
          expense_to: '',
          expense_amount: '',
          expense_attachment: [],
          description: '',
        })
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Server error:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred while Creating  Expense.',
        })
      )
    }
  }

  // onChange handler to update the local state
  const handleInputChange = (event) => {
    const { name, value } = event.target
    // Update the local state with the changed value
    setExpense((expense) => ({
      ...expense,
      [name]: value,
    }))
  }

  return (
    <>
      <Helmet>
        <title>Bioaro | Expense - List</title>
      </Helmet>
      <Stack direction="row" sx={{ width: '100%', pb: 2 }}>
        <Card sx={{ p: 3, width: '90rem' }}>
          <form onSubmit={handleSubmit}>
            <Box>
              {' '}
              <Stack sx={{ float: 'left' }}>
                {' '}
                <Typography variant="h5">Create Expense</Typography>{' '}
              </Stack>
              <Stack
                direction="row"
                justifyContent="flex-end"
                mb={2}
                mt={2}
                mr={1.3}
              >
                <CButton label="Save" icon={<SaveIcon />} type="submit" />
              </Stack>
            </Box>
            <hr />
            <Grid container spacing={2} alignItems="center" mt={2}>
              <Grid xs={12} item>
                <FormControl>
                  <RadioGroup
                    row
                    name="category_type"
                    value={expense?.category_type ?? 'travel'}
                    onChange={handleInputChange}
                  >
                    <FormControlLabel
                      value="travel"
                      control={<Radio />}
                      label="Travel"
                    />
                    <FormControlLabel
                      value="others"
                      control={<Radio />}
                      label="Others"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <CAutocomplete
                  options={categories}
                  getOptionLabel={(option) => option.name}
                  value={expense?.category}
                  onChange={(e, value) =>
                    // console.log(value)
                    setExpense({
                      ...expense,
                      category: value,
                      category_id: value.id,
                    })
                  }
                  label="Category"
                  error={!!(errors && errors.category_id)}
                  helperText={errors && errors.category_id}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <CAutocomplete
                  options={staffs}
                  getOptionLabel={(option) => option.full_name}
                  value={expense?.staff}
                  onChange={(e, value) =>
                    // console.log(value)
                    setExpense({
                      ...expense,
                      staff: value,
                      staff_id: value.id,
                    })
                  }
                  label="Staffs"
                  error={!!(errors && errors.staff_id)}
                  helperText={errors && errors.staff_id}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                display={expense?.category_type != 'travel' ? 'none' : ''}
              >
                <CDatePicker
                  label="From"
                  name="expense_from"
                  value={
                    expense?.expense_from ? dayjs(expense?.expense_from) : null
                  }
                  onChange={(value) =>
                    setExpense({
                      ...expense,
                      expense_from: formatDateTime(dayjs(value)),
                    })
                  }
                  error={!!(errors && errors.expense_from)}
                  helperText={errors && errors.expense_from}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                display={expense?.category_type != 'travel' ? 'none' : ''}
              >
                <CDatePicker
                  label="To"
                  name="expense_to"
                  value={
                    expense?.expense_to ? dayjs(expense?.expense_to) : null
                  }
                  onChange={(value) =>
                    setExpense({
                      ...expense,
                      expense_to: formatDateTime(dayjs(value)),
                    })
                  }
                  error={!!(errors && errors.expense_to)}
                  helperText={errors && errors.expense_to}
                />
              </Grid>

              <Grid xs={6} item>
                <CTextField
                  label="Amount"
                  name="expense_amount"
                  type="number"
                  value={expense?.expense_amount}
                  error={errors && errors.expense_amount}
                  helperText={errors && errors.expense_amount}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel>Receipt/Invoice</InputLabel>
                <CTextField
                  name="expense_attachment"
                  type="file"
                  fullWidth
                  variant="outlined"
                  onChange={(e) =>
                    setExpense({ ...expense, file_image: e.target.files[0] })
                  }
                  // error={!!(errors && errors.file_image)}
                  // helperText={errors && errors.file_image}
                />
              </Grid>

              <Grid item xs={12} mb={6}>
                <InputLabel>Description</InputLabel>
                <CEditor
                  value={expense.description}
                  handleChange={(value) =>
                    setExpense({
                      ...expense,
                      description: value,
                    })
                  }
                />
              </Grid>
            </Grid>
          </form>
        </Card>
      </Stack>
      <Card sx={{ p: 2 }}>
        <Typography variant="h5" p={2}>
          Expense List
        </Typography>
        <CDatatable
          rows={expenses}
          columns={columns}
          isLoading={isLoading}
          handleQuickFilterValue={handleQuickFilterValue}
        />
      </Card>
    </>
  )
}

export default ExpenseList
