// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../../services/axiosConfig'

// Fetch pharmacy list
export const fetchPharmacyList = createAsyncThunk('pharmacy/list', async () => {
  const response = await axiosInstance.get(`pharmacy/list`)
  return response.data
})

// Fetch pharmacy info
export const fetchPharmacyUpdateInfo = createAsyncThunk(
  'pharmacy/info',
  async (pharmacyId) => {
    const response = await axiosInstance.get(`pharmacy/info/${pharmacyId}`)
    return response.data
  }
)

//pharmacy create
export const create = createAsyncThunk('pharmacy/create', async (data) => {
  const response = await axiosInstance.post('pharmacy/create', data)
  return response.data
})

//pharmacy create
export const update = createAsyncThunk('pharmacy/update', async (data) => {
  const response = await axiosInstance.post(`pharmacy/update`, data)
  return response.data
})

const pharmacySlice = createSlice({
  name: 'pharmacy',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    pharmacy: null,
    pharmacies: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Create pharmacy
      .addCase(create.pending, (state) => {
        state.isLoading = true
      })
      .addCase(create.fulfilled, (state, action) => {
        if (action.payload.error) {
          state.message = action.payload.message
          state.errors = action.payload.errors
        } else {
          state.message = action.payload.message
          state.errors = null
        }
        state.isLoading = false
      })
      .addCase(create.rejected, (state) => {
        state.isLoading = false
      })
      // pharmacy list List
      .addCase(fetchPharmacyList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchPharmacyList.fulfilled, (state, { payload } = action) => {
        state.pharmacies = payload.pharmacies
        state.isLoading = false
      })
      .addCase(fetchPharmacyList.rejected, (state, { payload }) => {
        state.isLoading = false
      })

      // pharmacy update info
      .addCase(fetchPharmacyUpdateInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        fetchPharmacyUpdateInfo.fulfilled,
        (state, { payload } = action) => {
          state.pharmacy = payload.pharmacy
          state.isLoading = false
        }
      )
      .addCase(fetchPharmacyUpdateInfo.rejected, (state, { payload }) => {
        state.isLoading = false
      })
  },
})

export default pharmacySlice.reducer
