import React from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

const CIconButton = ({
  icon,
  toolTip,
  color,
  placement,
  onClick,
  yesNo=false,
  yesNoText='',
  ...other
}) => {
  const handleClick = () => {
    if (yesNo) {
      if (
        confirm(
          yesNoText
            ? yesNoText
            : 'Are you sure you want to perform this action?'
        )
      ) {
        onClick && onClick()
      }
    } else {
      onClick && onClick()
    }
  }
  return (
    <Tooltip title={toolTip} placement={placement || 'top'}>
      <IconButton color={color || 'primary'} onClick={handleClick} {...other}>
        {icon}
      </IconButton>
    </Tooltip>
  )
}

export default CIconButton
