import * as React from 'react'

import Drawer from '@mui/material/Drawer'
import { Box, Typography, Button, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Settings } from '@mui/icons-material'

export default function CDrawer({ title, anchor, children }) {
  const [open, setOpen] = React.useState(false)

  const toggleDrawer = () => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      setOpen(!open)
    }
    setOpen(!open)
  }

  return (
    <React.Fragment>
      <IconButton color="primary" onClick={toggleDrawer()}>
        <Settings />
      </IconButton>
      <Drawer
        anchor={anchor || 'right'}
        open={open}
        onClose={toggleDrawer()}
        sx={{ zIndex: 1202 }}
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          // onClick={toggleDrawer()}
          // onKeyDown={toggleDrawer()}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              p: 1.4,
              bgcolor: 'primary.main',
              color: 'text.nova',
            }}
          >
            <Typography fontWeight="bold">{title || 'Settings'}</Typography>
            <IconButton color="error" onClick={toggleDrawer()}>
              <CloseIcon />
            </IconButton>
          </Box>

          {children}
        </Box>
      </Drawer>
    </React.Fragment>
  )
}
