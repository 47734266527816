import SServiceCreate from './SServiceCreate';
import SServiceList from './SServiceList';
import SServiceUpdate from './SServiceUpdate';

const sserviceRoutes = [
  { path: "list", element: <SServiceList /> },
  { path: "create", element: <SServiceCreate /> },
  { path: "update/:sserviceId", element: <SServiceUpdate /> }
];

export default sserviceRoutes;
