import html2pdf from 'html2pdf.js'
import { useReactToPrint } from 'react-to-print'

// This function opens a PDF file in a new browser window.
// It takes a fileStream as input,
// which is expected to be a Blob or a similar binary stream containing the PDF data.
export const openPdfInNewWindow = (fileStream) => {
  try {
    // Create a blob url
    const blobUrl = URL.createObjectURL(fileStream)

    // Open new window with pdf
    window.open(blobUrl, '_blank')
  } catch (error) {
    console.error('Error opening PDF in new window:', error)
  }
}

// This function download a PDF file.
// It takes a fileStream as input,
// which is expected to be a Blob or a similar binary stream containing the PDF data.
export const downloadPdf = (fileStream) => {
  try {
    // Create a blob url
    const url = URL.createObjectURL(fileStream)

    // Create a temporary <a> element and set its attributes
    const a = document.createElement('a')
    a.href = url
    a.download = ''

    // Append the <a> element to the document
    document.body.appendChild(a)

    // Trigger a click event on the <a> element to start the download
    a.click()
    document.body.removeChild(a)
    URL.revokeObjectURL(url)
  } catch (error) {
    console.error('Error creating Blob URL or initiating download:', error)
  }
}

// This function download a PDF file.
export const downloadFile = (fileUrl) => {
  try {
    // Create a temporary <a> element and set its attributes
    const a = document.createElement('a')
    a.href = fileUrl
    a.download = ''

    // Append the <a> element to the document
    document.body.appendChild(a)

    // Trigger a click event on the <a> element to start the download
    a.click()
    document.body.removeChild(a)
    URL.revokeObjectURL(fileUrl)
  } catch (error) {
    console.error('Error initiating download:', error)
  }
}


/**
 * Downloads the HTML content as a PDF.
 * @param {React.RefObject} contentToDownloadRef - Reference to the HTML element containing the content to download.
 * @param {string} filename - Name of the PDF file to download (optional).
 */
export const cdownloadHtml2Pdf = (contentToDownloadRef, filename) => {
  try {
    const element = contentToDownloadRef.current
    if (!element) {
      throw new Error('Ref to content element is not set.')
    }

    html2pdf()
      .set({
        filename: `${filename || 'html_document'}.pdf`,
        html2canvas: { scale: 3 }, // Increase scale for higher resolution
        jsPDF: { format: 'a4', orientation: 'portrait', unit: 'in' }, // Adjust PDF configuration
      })
      .from(element)
      .save()
  } catch (error) {
    console.error('Error occurred while generating PDF:', error.message)
  }
}
