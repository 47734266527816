import React from 'react'
import { Route, Routes } from 'react-router-dom'
import SellerSiteList from './SellerSiteList'
import SellerSiteCreate from './SellerSiteCreate'
import SellerSiteOrderList from './SellerSiteOrderList'
import SellerSiteUpdate from './SellerSiteUpdate'

const sellerSiteRoutes = [
  { path: 'list', element: <SellerSiteList /> },
  { path: 'order-list/:sellerSiteId', element: <SellerSiteOrderList /> },
  { path: 'update/:sellerSiteId', element: <SellerSiteUpdate /> },
  { path: 'create', element: <SellerSiteCreate /> },
]

export default sellerSiteRoutes
