import * as React from 'react'
import { useState } from 'react'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

export default function CFileField({
  label,
  onChange,
  multiple,
  disabled,
  ...other
}) {
  const [selectedFilesCount, setSelectedFilesCount] = useState(0)

  const handleFileChange = (e) => {
    setSelectedFilesCount(e.target.files.length)
    onChange(e)
  }

  return (
    <Button
      component="label"
      variant="outlined"
      color="secondary"
      fullWidth
      size="large"
      startIcon={<CloudUploadIcon />}
      sx={{ justifyContent: 'flex-start', height: 40, mt: 3 }}
      disabled={disabled}
    >
      {selectedFilesCount === 0
        ? label
        : `${selectedFilesCount} file${
            selectedFilesCount > 1 ? 's' : ''
          } selected`}
      <VisuallyHiddenInput
        type="file"
        multiple={multiple}
        onChange={handleFileChange}
        {...other}
      />
    </Button>
  )
}
