import React from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'

import CCircularProgress from '@/components/CCircularProgress'
import { Container } from '@mui/material'

export default function AuthLayout() {
  return (
    <Container sx={{ mt: 2 }}>
      <React.Suspense fallback={<CCircularProgress color="inherit" />}>
        <Outlet />
      </React.Suspense>
    </Container>
  )
}
