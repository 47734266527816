import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import SaveIcon from '@mui/icons-material/Save'
import { Card, Grid, Stack } from '@mui/material'
import CTextField from '@/components/CTextField'
import CAutocomplete from '@/components/CAutocomplete'
import CNumberField from '@/components/CNumberField'
import CFileField from '@/components/CFileField'
import CRadioGroup from '@/components/CRadioGroup'
import CEditor from '@/components/CEditor'
import { slugify } from '@/utils/cutils'
import { showSuccess, showError } from '@/redux/Slice/alertSlice'
import { fetchSServiceDetail, fetchSServiceInfo, update } from './sserviceSlice'
import { useNavigate, useParams } from 'react-router-dom'
import CButton from '@/components/CButton'
import { setBreadcrumb } from '@/redux/Slice/breadcrumbSlice'
import CCircularProgress from '@/components/CCircularProgress'

const SServiceUpdate = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { sserviceId } = useParams()
  const sserviceInfoFromRedux = useSelector(
    (state) => state.sservice?.sserviceInfo
  )
  const sserviceFromRedux = useSelector((state) => state.sservice?.sservice)
  const errors = useSelector((state) => state.sservice.errors)
  const [sservice, setSService] = useState(null)

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        { path: '/sservice/list', label: 'Sequence Service', isLast: false },
        { path: '', label: 'Update', isLast: true },
      ])
    )
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchSServiceInfo())
    dispatch(fetchSServiceDetail(sserviceId))
  }, [dispatch, sserviceId])

  useEffect(() => {
    if (sserviceFromRedux) {
      setSService(sserviceFromRedux)
    }
  }, [sserviceFromRedux, sserviceInfoFromRedux])

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const res = await dispatch(update({ sserviceId, data: sservice }))

      if (res.payload && !res.payload.error) {
        dispatch(showSuccess({ success: true, message: res.payload.message }))
        navigate('/sservice/list')
      } else {
        dispatch(showError({ error: true, message: res.payload.message }))
      }
    } catch (error) {
      console.error('Server error:', error)
      dispatch(showError({ error: true, message: 'An error occurred!' }))
    }
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setSService((prevSService) => ({
      ...prevSService,
      [name]: value,
    }))
    if (name === 'name') {
      setSService((prevSService) => ({
        ...prevSService,
        slug: slugify(value),
      }))
    }
  }

  const handleAutocompleteChange = (data) => {
    const { name, value, id_column } = data
    const ids = value.map((item) => item.id)
    setSService((prevSService) => ({
      ...prevSService,
      [name]: value,
      [id_column]: ids,
    }))
  }

  return (
    <>
      <Helmet>
        <title>Bioaro | Sequence Service Update</title>
      </Helmet>
      {sservice ? (
        <Card sx={{ p: 2 }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={6}>
                <CTextField
                  label="Name"
                  name="name"
                  value={sservice.name}
                  required
                  error={!!errors && errors.name}
                  helperText={errors && errors.name}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <CTextField
                  label="Slug"
                  name="slug"
                  value={sservice.slug}
                  disabled
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <CTextField
                  label="Title"
                  name="title"
                  value={sservice.title}
                  required
                  error={!!errors && errors.title}
                  helperText={errors && errors.title}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <CAutocomplete
                  label="Disabled Service"
                  value={sservice?.disabled_services}
                  options={sserviceInfoFromRedux?.services || []}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  multiple
                  onChange={(e, value) =>
                    handleAutocompleteChange({
                      name: 'disabled_services',
                      value,
                      id_column: 'disabled_id',
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <CAutocomplete
                  label="Suggestion Service"
                  value={sservice?.suggestion_services}
                  options={sserviceInfoFromRedux?.services || []}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  multiple
                  onChange={(e, value) =>
                    handleAutocompleteChange({
                      name: 'suggestion_services',
                      value,
                      id_column: 'suggestion_id',
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <CAutocomplete
                  label="Tag"
                  value={sservice?.tags}
                  options={sserviceInfoFromRedux?.tags || []}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  multiple
                  onChange={(e, value) =>
                    handleAutocompleteChange({
                      name: 'tags',
                      value,
                      id_column: 'tag_id',
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <CNumberField
                  label="Price"
                  name="price"
                  value={sservice.price}
                  required
                  error={!!errors && errors.price}
                  helperText={errors && errors.price}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <CFileField
                  label="File"
                  multiple={false}
                  onChange={(e) =>
                    setSService({ ...sservice, image: e.target.files[0] })
                  }
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <CAutocomplete
                  label="Attach Existing Panels To This"
                  value={sservice?.panels}
                  options={sserviceInfoFromRedux?.panels || []}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  multiple
                  onChange={(e, value) =>
                    handleAutocompleteChange({
                      name: 'panels',
                      value,
                      id_column: 'panel_id',
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <CNumberField
                  label="Position"
                  name="position"
                  value={sservice.position}
                  error={!!errors && errors.position}
                  helperText={errors && errors.position}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <CAutocomplete
                  label="Category"
                  value={sservice?.categories}
                  options={sserviceInfoFromRedux?.categories || []}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  multiple
                  onChange={(e, value) =>
                    handleAutocompleteChange({
                      name: 'categories',
                      value,
                      id_column: 'category_id',
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <CAutocomplete
                  label="Product"
                  value={sservice?.products}
                  options={sserviceInfoFromRedux?.products || []}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  multiple
                  onChange={(e, value) =>
                    handleAutocompleteChange({
                      name: 'products',
                      value,
                      id_column: 'product_id',
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <CRadioGroup
                  label="Gut Clinic"
                  name="service_type"
                  value={sservice.service_type}
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12}>
                <CEditor
                  label="Description"
                  value={sservice.description ?? ''}
                  helperText={errors && errors.description}
                  handleChange={(value) =>
                    setSService({
                      ...sservice,
                      description: value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <CTextField
                  label="Keyword"
                  name="keyword"
                  value={sservice.keyword}
                  error={!!errors && errors.keyword}
                  helperText={errors && errors.keyword}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <CTextField
                  label="Keyword Description"
                  name="keyword_des"
                  value={sservice.keyword_des}
                  error={!!errors && errors.keyword_des}
                  helperText={errors && errors.keyword_des}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <Stack direction="row" justifyContent="flex-end" mt={2}>
              <CButton label="Save" icon={<SaveIcon />} type="submit" />
            </Stack>
          </form>
        </Card>
      ) : (
        <CCircularProgress />
      )}
    </>
  )
}

export default SServiceUpdate
