import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import AddIcon from '@mui/icons-material/Add'
import { Card, Stack, Typography } from '@mui/material'

// Components
import { Helmet } from 'react-helmet-async'
import CDatatable from '../../../../components/CDatatable'

// Slice
import { fetchNoteList } from './noteSlice'
import { setBreadcrumb } from '../../../../redux/Slice/breadcrumbSlice'
import CButton from '../../../../components/CButton'

const NoteList = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        { path: '/template/note/list', label: 'Note', isLast: false },
        { path: '', label: 'List', isLast: true },
      ])
    )
  }, [dispatch])

  const { notes, isLoading } = useSelector((state) => state.noteTemplate)

  // add this when you use server side filter
  const handleQuickFilterValue = (filterModel) => {
    const quickFilterValue = filterModel.quickFilterValues.join(' ')
    console.log(quickFilterValue)
  }

  const columns = [
    { field: 'title', headerName: 'Title', flex: 1 },
    { field: 'slug', headerName: 'Slug', flex: 1 },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      renderCell: (params) => (
        <Link to={`/template/note/update/${params.id}`} className="mr-1">
          <VisibilityOutlinedIcon />
        </Link>
      ),
    },
  ]

  return (
    <>
      <Helmet>
        <title>Bioaro | Template | Note - List</title>
      </Helmet>
      <Card sx={{ p: 2 }}>
        <Stack direction="row" justifyContent="flex-end" mb={1}>
          <CButton
            label="Create"
            icon={<AddIcon />}
            component={Link}
            to={`/template/note/create`}
          />
        </Stack>

        <CDatatable
          rows={notes}
          columns={columns}
          isLoading={isLoading}
          handleQuickFilterValue={handleQuickFilterValue}
        />
      </Card>
    </>
  )
}

export default NoteList
