// // UserList.jsx

// Icon
import AddIcon from '@mui/icons-material/Add'
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Grid,
  InputLabel,
  Stack,
  Typography,
} from '@mui/material'
// Components
import AdminLayout from '@/layouts/AdminLayout'

// Slice
import { setBreadcrumb } from '@/redux/Slice/breadcrumbSlice'
import { Link, useNavigate } from 'react-router-dom'
import CButton from '@/components/CButton'
import CDatatable from '@/components/CDatatable'
import { changeStatus, deleteRecord } from '@/redux/Slice/commonSlice'
import { showError, showSuccess } from '@/redux/Slice/alertSlice'
import CTextField from '@/components/CTextField'
import SaveIcon from '@mui/icons-material/Save'
import { Helmet } from 'react-helmet-async'
import { useEffect, useState } from 'react'
import { create, list } from '../giftCardSlice'
import { useDispatch, useSelector } from 'react-redux'
import CAutocomplete from '@/components/CAutocomplete'
import CEditor from '@/components/CEditor'

export default function GiftCardCreate() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { errors, error, isLoading } = useSelector((state) => state.giftCard)

  //   initial create value
  const [giftCard, setGiftCard] = useState({
    name: '',
    price: '',
    avail_month: '',
    description: '',
  })

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const res = await dispatch(create(giftCard))

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )

        navigate('/website/gift-card/list')
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Server error:', error)
      dispatch(
        showError({
          error: true,
          message:
            'An error occurred while Creating Website gift card service.',
        })
      )
    }
  }

  // onChange handler to update the local state
  const handleInputChange = (event) => {
    const { name, value } = event.target
    setGiftCard((giftCard) => ({
      ...giftCard,
      [name]: value,
    }))
  }

  return (
    <>
      <Helmet>
        <title>Website Gift-Card Create</title>
      </Helmet>
      <Stack direction="row" sx={{ pr: 4, width: '79rem', pb: 2 }}>
        <Card sx={{ p: 3, width: '90rem' }}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              width="60rem"
              sx={{ alignContent: 'center', margin: 'auto' }}
            >
              <Grid xs={12} mb={2}>
                <Box>
                  {' '}
                  <Stack sx={{ float: 'left' }}>
                    {' '}
                    <Typography variant="h5">
                      Create Security Question
                    </Typography>{' '}
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    mb={2}
                    mt={2}
                    mr={1.3}
                  >
                    <CButton label="Save" icon={<SaveIcon />} type="submit" />
                  </Stack>
                  <hr />
                </Box>
              </Grid>

              <Grid xs={6} pr={2}>
                <CTextField
                  label="Name"
                  name="name"
                  value={giftCard?.name}
                  error={errors && errors.name}
                  helperText={errors && errors.name}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid xs={6}>
                <CTextField
                  label="Price"
                  name="price"
                  value={giftCard?.price}
                  error={errors && errors.price}
                  helperText={errors && errors.price}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid xs={6}>
                <CTextField
                  label="Availability/Expire After (Month) "
                  name="avail_month"
                  value={giftCard?.avail_month}
                  error={errors && errors.avail_month}
                  helperText={errors && errors.avail_month}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} ml={-1} mb={6}>
                <InputLabel>Description</InputLabel>
                <CEditor
                  value={giftCard?.description}
                  handleChange={(value) =>
                    setGiftCard({
                      ...giftCard,
                      description: value,
                    })
                  }
                />
              </Grid>
            </Grid>
          </form>
        </Card>
      </Stack>
    </>
  )
}
