// // UserList.jsx
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Icon
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import AddIcon from '@mui/icons-material/Add'
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
// Components
import AdminLayout from '@/layouts/AdminLayout'

// Slice
import { setBreadcrumb } from '@/redux/Slice/breadcrumbSlice'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import CButton from '@/components/CButton'
import CDatatable from '@/components/CDatatable'
import { changeStatus, deleteRecord } from '@/redux/Slice/commonSlice'
import { showError, showSuccess } from '@/redux/Slice/alertSlice'
import CTextField from '@/components/CTextField'
import SaveIcon from '@mui/icons-material/Save'
import { Helmet } from 'react-helmet-async'
import { create, list } from '../taskSlice'
import TaskUpdate from './TaskUpdate'
import TaskDetails from '@/pages/Admin/Patient/Tasks/Partial/TaskDetails'
import { renderCell } from '@/utils/ctableTool'
import TaskStatus from '@/pages/Admin/Patient/Tasks/Partial/TaskStatus'
import TaskComment from '@/pages/Admin/Patient/Tasks/Partial/TaskComment'

const TaskList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { tasks, errors, isLoading } = useSelector(
    (state) => state.inventoryTask
  )

  const location = useLocation()

  // Get the current path
  const currentPath = location.pathname

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        {
          path: 'inventory/task/list',
          label: 'Inventory Task',
          isLast: false,
        },
        { path: '', label: 'List', isLast: true },
      ])
    )
  }, [dispatch])

  const handleQuickFilterValue = (filterModel) => {
    const quickFilterValue = filterModel.quickFilterValues.join(' ')
    console.log(quickFilterValue)
  }

  const columns = [
    {
      field: 'task',
      headerName: 'Task',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      field: 'patient',
      headerName: 'Patient',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      field: 'assign_date',
      headerName: 'Assign Date',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      field: 'deadline',
      headerName: 'Deadline',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    // {
    //   field: 'assign_to',
    //   headerName: 'Assign To',
    //   minWidth: 150,
    //   flex: 1,
    //   renderCell: ({ value }) => renderCell(value),
    // },
    // {
    //   field: 'assign_by',
    //   headerName: 'Assign By',
    //   minWidth: 150,
    //   flex: 1,
    //   renderCell: ({ value }) => renderCell(value),
    // },
    {
      field: 'action',
      headerName: 'Action',
      minWidth: 350,
      flex: 1,
      renderCell: (params) =>
        renderCell(
          <Grid container spacing={0.5}>
            <Grid item>
              <TaskDetails taskId={params.row.id} />
            </Grid>
            <Grid item>
              <TaskStatus
                statusName={params.row.status}
                taskId={params.row.id}
                callback={() => dispatch(list())}
              />
            </Grid>
            <Grid item>
              <TaskComment
                taskId={params.row.id}
                callback={() => dispatch(list())}
              />
            </Grid>
          </Grid>
        ),
    },
  ]

  useEffect(() => {
    dispatch(list())
  }, [])

  return (
    <>
      <Helmet>
        <title>Bioaro | Inventory Task - List</title>
      </Helmet>
      <Card sx={{ p: 2 }}>
        {currentPath != '/dashboard' && (
          <Stack direction="row" justifyContent="flex-end" mb={1}>
            <CButton
              label="Create"
              icon={<AddIcon />}
              component={Link}
              to={`/inventory/task/create`}
            />
          </Stack>
        )}

        <CDatatable
          rows={tasks}
          columns={columns}
          isLoading={isLoading}
          handleQuickFilterValue={handleQuickFilterValue}
          getRowHeight="auto"
        />
      </Card>
    </>
  )
}

export default TaskList

const statusOptions = [
  {
    label: 'Pending',
    value: '0',
  },
  {
    label: 'Review',
    value: '1',
  },
  {
    label: 'In Progress',
    value: '2',
  },
  {
    label: 'Success',
    value: '3',
  },
]

function getStatusLabel(value) {
  const status = statusOptions.find((option) => option.value === value)
  return status ? status.label : 'Unknown Status'
}
