import React, { useEffect, useState } from 'react'

import {
  Box,
  Typography,
  Checkbox,
  TextField,
  Button,
  FormControlLabel,
  FormGroup,
} from '@mui/material'
import CCheckbox from '@/components/CCheckbox'
import CTextField from '@/components/CTextField'
import { dentalChartCreate, dentalChartDetail } from '../dentalChartSlice'
import { showError, showSuccess } from '@/redux/Slice/alertSlice'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

const TreatmentDetails = ({
  selectedTooth,
  dentalChart,
  setSelectedTooth,
  setDentalChart,
}) => {
  const dispatch = useDispatch()
  const { patientId } = useParams()
  console.log(patientId)
  const handleChange = (event) => {
    const { name, checked } = event.target

    // Create a shallow copy of the selectedTooth object
    const updatedTooth = {
      ...selectedTooth,
      details: { ...selectedTooth.details, [name]: checked },
    }

    // Determine the type of tooth and get a shallow copy of the relevant teeth array
    const toothType = updatedTooth.toothType
    const teeth = [...dentalChart[toothType]]

    // Find the index of the tooth to update
    const toothIndex = teeth.findIndex(
      (tooth) => tooth.toothNumber === updatedTooth.toothNumber
    )

    // Update the specific tooth in the copied array
    teeth[toothIndex] = updatedTooth

    // Create a new dentalChart object with the updated teeth array
    const updatedDentalChart = { ...dentalChart, [toothType]: teeth }

    // Update the state with the new dentalChart
    setDentalChart(updatedDentalChart)
    setSelectedTooth(updatedTooth)
  }

  const handleNoteChange = (event) => {
    // Create a shallow copy of the selectedTooth object
    const updatedTooth = {
      ...selectedTooth,
      note: event.target.value,
    }

    // Determine the type of tooth and get a shallow copy of the relevant teeth array
    const toothType = updatedTooth.toothType
    const teeth = [...dentalChart[toothType]]

    // Find the index of the tooth to update
    const toothIndex = teeth.findIndex(
      (tooth) => tooth.toothNumber === updatedTooth.toothNumber
    )

    // Update the specific tooth in the copied array
    teeth[toothIndex] = updatedTooth

    // Create a new dentalChart object with the updated teeth array
    const updatedDentalChart = { ...dentalChart, [toothType]: teeth }

    // Update the state with the new dentalChart
    setDentalChart(updatedDentalChart)
    setSelectedTooth(updatedTooth)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      const res = await dispatch(
        dentalChartCreate({
          patient_id: patientId,
          upperTeeth: dentalChart.upperTeeth,
          lowerTeeth: dentalChart.lowerTeeth,
        })
      )
      if (res.payload && !res.payload.error) {
        dispatch(showSuccess({ success: true, message: res.payload.message }))
        dispatch(dentalChartDetail(patientId))
      } else {
        dispatch(showError({ error: true, message: res.payload.message }))
      }
    } catch (error) {
      console.error('Error while creating:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred during creation.',
        })
      )
    }
  }

  console.log(dentalChart)

  return (
    <>
      {selectedTooth && (
        <form onSubmit={handleSubmit}>
          <Typography variant="h6">Treatment Details</Typography>
          <Typography>Teeth No: {selectedTooth.toothNumber}</Typography>

          <Box>
            <Typography>Filling</Typography>
            {['buccal', 'lingual', 'occlusal', 'mesial', 'distal'].map(
              (name) => (
                <CCheckbox
                  key={name}
                  label={name.charAt(0).toUpperCase() + name.slice(1)}
                  name={name}
                  checked={toBoolean(selectedTooth.details[name])}
                  onChange={handleChange}
                  disabled={toBoolean(selectedTooth.details.missing)}
                />
              )
            )}
          </Box>

          <CCheckbox
            label="Missing"
            name="missing"
            checked={toBoolean(toBoolean(selectedTooth.details.missing))}
            onChange={handleChange}
          />

          <CCheckbox
            label="Implant"
            name="implant"
            checked={toBoolean(selectedTooth.details.implant)}
            disabled={toBoolean(selectedTooth.details.missing)}
            onChange={handleChange}
          />

          <Typography>FPD (Bridge)</Typography>
          {['abutment', 'pontic', 'rct', 'postCore'].map((name) => (
            <CCheckbox
              key={name}
              label={name.charAt(0).toUpperCase() + name.slice(1)}
              name={name}
              checked={toBoolean(selectedTooth.details[name])}
              disabled={toBoolean(selectedTooth.details.missing)}
              onChange={handleChange}
            />
          ))}

          <Typography>Crown</Typography>
          {['veneers', 'laminates', 'fullCrowns'].map((name) => (
            <CCheckbox
              key={name}
              label={name.charAt(0).toUpperCase() + name.slice(1)}
              name={name}
              checked={toBoolean(selectedTooth.details[name])}
              disabled={toBoolean(selectedTooth.details.missing)}
              onChange={handleChange}
            />
          ))}

          <CTextField
            label="Note"
            multiline
            rows={4}
            value={selectedTooth.note}
            onChange={handleNoteChange}
            variant="outlined"
            fullWidth
          />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            sx={{ marginTop: 2 }}
          >
            Save
          </Button>
        </form>
      )}
    </>
  )
}

export default TreatmentDetails

function toBoolean(value) {
  if (value === 'true') return true
  if (value === 'false') return false
  return Boolean(value)
}
