import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '@/services/axiosConfig'

// Dashboard Info
export const dashboardInfo = createAsyncThunk(
  'patient/dashboard/info',
  async (patientId) => {
    const response = await axiosInstance.get(
      `patient/dashboard/info/${patientId}`
    )
    return response.data
  }
)

const patientDashboardSlice = createSlice({
  name: 'patientDashboard',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    info: null,
  },
  reducers: {
    setChartFormBuilder: (state, action) => {
      state.chartFormBuilder = action.payload
    },
  },
  extraReducers: (builder) => {
    builder

      // Dashboard Info
      .addCase(dashboardInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(dashboardInfo.fulfilled, (state, { payload } = action) => {
        state.info = payload
        state.isLoading = false
      })
      .addCase(dashboardInfo.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default patientDashboardSlice.reducer
