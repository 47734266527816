import { currentUser } from '@/utils/cutils'
import {
  IconUserSquare,
  IconClipboardData,
  IconPictureInPicture,
  IconUserCheck,
  IconHome,
  IconUserFilled,
  IconArrowNarrowRight,
} from '@tabler/icons-react'

import { IconMessageDots } from '@tabler/icons-react'
import { IconChartBar } from '@tabler/icons-react'

const baseURL = import.meta.env.VITE_BASE_URL

const shortMenus = [
  [1, 5].includes(currentUser?.role_id) && {
    menuLabel: false,
    label: 'Dashboards',
    icon: <IconHome />,
    path: '/dashboard',
  },
  [1, 5].includes(currentUser?.role_id) && {
    menuLabel: false,
    label: 'Chat',
    icon: <IconMessageDots />,
    path: `/chat/`,
  },
  [1, 5].includes(currentUser?.role_id) && {
    menuLabel: false,
    label: 'Patient List',
    icon: <IconUserSquare />,
    path: '/patient/list',
  },
  [1].includes(currentUser?.role_id) && {
    menuLabel: false,
    label: 'Data Analysis',
    icon: <IconChartBar />,
    path: 'patients/data-analysis',
  },
  [1, 5].includes(currentUser?.role_id) && {
    menuLabel: false,
    label: 'Schedule',
    icon: <IconClipboardData />,
    path: '/schedule',
  },
  [1].includes(currentUser?.role_id) && {
    menuLabel: false,
    label: 'Lab Orders',
    icon: <IconUserSquare />,
    path: '/order/lab-order-list',
  },
  [1].includes(currentUser?.role_id) && {
    menuLabel: false,
    label: 'Orders From Clinic',
    icon: <IconUserSquare />,
    path: '/order/clinic-order-list',
  },
  [1].includes(currentUser?.role_id) && {
    menuLabel: false,
    label: 'Gut Clinic.',
    icon: <IconClipboardData />,
    path: '/gut-clinic/list/',
  },
  [1].includes(currentUser?.role_id) && {
    menuLabel: false,
    label: 'Send Pre/Post Assisment',
    icon: <IconUserSquare />,
    path: '/document-resend/list',
  },
  [1].includes(currentUser?.role_id) && {
    menuLabel: false,
    label: 'Pharmacy',
    icon: <IconUserSquare />,
    path: '/pharmacy/list',
  },
  [1].includes(currentUser?.role_id) && {
    menuLabel: false,
    label: 'Seller Site',
    icon: <IconUserSquare />,
    path: '/seller-site/list',
  },
].filter((item) => item !== false)
export default shortMenus
