import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { Typography } from '@mui/material'

export default function CCheckbox({
  name,
  label,
  checked,
  onChange,
  required,
  ...other
}) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          name={name}
          checked={checked}
          onChange={onChange}
          {...other}
        />
      }
      label={
        <>
          {label}
          {required && (
            <Typography component="span" sx={{ color: 'red' }}>
              *
            </Typography>
          )}
        </>
      }
    />
  )
}
