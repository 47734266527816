import React, { useEffect, useState } from 'react'
import { Card, Grid, Stack } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import CTextField from '../../../../components/CTextField'
import CButton from '../../../../components/CButton'
import { setBreadcrumb } from '../../../../redux/Slice/breadcrumbSlice'
import { showError, showSuccess } from '../../../../redux/Slice/alertSlice'
import { info, update, updateInfo } from '../documentResendSlice'
import CAutocomplete from '../../../../components/CAutocomplete'

const DocumentResendUpdate = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { documentId } = useParams()
  console.log(documentId)
  const { users, errors, isLoading, } = useSelector((state) => state.document)
  const documentFromRedux = useSelector((state) => state.document.document)
  const [document, setDocument] = useState(documentFromRedux)
  useEffect(() => {
    dispatch(updateInfo(document))
    dispatch(info())
  }, [])

  useEffect(() => {
    setDocument(documentFromRedux)
  }, [documentFromRedux])

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        {
          path: '/document-resend/list',
          label: 'Document resend',
          isLast: false,
        },
        { path: '', label: 'Update', isLast: true },
      ])
    )
  }, [dispatch])

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      const res = await dispatch(
        // Wait for the pharmacy update action to complete.
        update({
          patient_id: document?.patient_id,
          template: document?.template,
          documentId: documentId,
        })
      )
      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        navigate('/document-resend/list')
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      // Handle any errors that occur during the login action dispatch
      console.error('Server error:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred during creating user.',
        })
      )
    }
  }
  useEffect(() => {
    dispatch(updateInfo(documentId))
    setDocument(documentFromRedux)
  }, [documentId])

  const template = ['template1', 'template2', 'template3', 'template4']
  return (
    <>
      <Helmet>
        <title>Bioaro | Document Resend - Update</title>
      </Helmet>
      <Card sx={{ p: 3, maxWidth: '100rem' }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={5}>
              <CAutocomplete
                options={users}
                getOptionLabel={(option) => option.full_name}
                value={document?.patient}
                onChange={(e, value) =>
                  setDocument({ ...document, patient_id: value.id })
                }
                label="Patient"
                error={!!(errors && errors.patient_id)}
                helperText={errors && errors.patient_id}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <CAutocomplete
                options={template}
                getOptionLabel={(option) => option}
                value={document?.template}
                onChange={(e, value) =>
                  // console.log(value)
                  setDocument({
                    ...document,
                    template: value,
                  })
                }
                label="Forms"
                multiple
                error={!!(errors && errors.template)}
                helperText={errors && errors.template}
              />
            </Grid>
          </Grid>
          <Stack direction="row" justifyContent="flex-end" mt={2}>
            <CButton label="Save" icon={<SaveIcon />} type="submit" />
          </Stack>
        </form>
      </Card>
    </>
  )
}

export default DocumentResendUpdate
