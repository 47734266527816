import { Route, Routes } from "react-router-dom";

import ReferralList from "./ReferralList";
import ReferralUpdate from "./ReferralUpdate";
import ReferralCreate from "./ReferralCreate";
import ReferralAddress from "./ReferralAddress";
import ReferralAddressCreate from "./Partials/ReferralAddressCreate";
import ReferralAddressUpdate from "./Partials/ReferralAddressUpdate";

const referralRoutes = [
  { path: "list", element: <ReferralList /> },
  { path: "create", element: <ReferralCreate /> },
  { path: "update/:referralId", element: <ReferralUpdate /> },
  {
    path: ":referralId/address",
    element: <ReferralAddress />,
    children: [
      { path: "create", element: <ReferralAddressCreate /> },
      { path: "update/:refAddressId", element: <ReferralAddressUpdate /> }
    ]
  }
];

export default referralRoutes;

