// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../../../services/axiosConfig'

// Fetch patient tasks list
export const fetchTaskList = createAsyncThunk(
  'patient/task/list',
  async (patientId) => {
    const response = await axiosInstance.get(`patient/task/list/${patientId}`)
    return response.data
  }
)

// Fetch patient task info for create
export const fetchTaskInfo = createAsyncThunk('patient/task/info', async () => {
  const response = await axiosInstance.get(`patient/task/info`)
  return response.data
})

// Fetch patient task info for create
export const fetchTaskDetails = createAsyncThunk(
  'patient/task/details',
  async (taskId) => {
    const response = await axiosInstance.get(`patient/task/details/${taskId}`)
    return response.data
  }
)
// Patient task create
export const create = createAsyncThunk(
  'patient/task/create',
  async ({ data, patientId }) => {
    const response = await axiosInstance.post(
      `patient/task/create/${patientId}`,
      data
    )
    return response.data
  }
)

// Patient task note update
export const comment = createAsyncThunk(
  'patient/task/comment',
  async ({ data, taskId }) => {
    const response = await axiosInstance.post(
      `patient/task/comment/${taskId}`,
      data
    )
    return response.data
  }
)

//Patient task status update
export const updateStatus = createAsyncThunk(
  'patient/task/status-update',
  async ({ data, taskId }) => {
    const response = await axiosInstance.post(
      `patient/task/status-update/${taskId}`,
      data
    )

    return response.data
  }
)

// task Update
export const update = createAsyncThunk(
  'patient/task/update',
  async ({ data, taskId }) => {
    const response = await axiosInstance.post(
      `patient/task/update/${taskId}`,
      data
    )
    return response.data
  }
)

// // Fetch doctor detail by user id
// export const fetchDoctorDetail = createAsyncThunk(
//   "doctor/detail",
//   async (id) => {
//     const response = await axiosInstance.get(`doctor/detail/${id}`);
//     return response.data;
//   }
// );

const taskSlice = createSlice({
  name: 'task',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    taskTemps: [],
    taskUsers: [],
    tasks: [],
    taskDetail: '',
    incNumber: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Fetch  task list
      .addCase(fetchTaskList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchTaskList.fulfilled, (state, { payload }) => {
        state.tasks = payload.tasks
        state.isLoading = false
      })
      .addCase(fetchTaskList.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch  task info for create page
      .addCase(fetchTaskInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchTaskInfo.fulfilled, (state, { payload } = action) => {
        state.taskTemps = payload.taskTemps
        state.taskUsers = payload.taskUsers
        state.incNumber += 1
        state.isLoading = false
      })
      .addCase(fetchTaskInfo.rejected, (state) => {
        state.isLoading = false
      })

      //patient medicine detail
      .addCase(comment.pending, (state) => {
        state.isLoading = true
      })
      .addCase(comment.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.incNumber += 1
        state.isLoading = false
      })
      .addCase(comment.rejected, (state) => {
        state.isLoading = false
      })

      //Patient based file  Create
      .addCase(updateStatus.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateStatus.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.incNumber += 1
        state.isLoading = false
      })
      .addCase(updateStatus.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch  task details
      .addCase(fetchTaskDetails.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchTaskDetails.fulfilled, (state, { payload } = action) => {
        state.taskDetail = payload.taskDetails
        state.incNumber += 1
        state.isLoading = false
      })
      .addCase(fetchTaskDetails.rejected, (state) => {
        state.isLoading = false
      })

      //task Update
      .addCase(update.pending, (state) => {
        state.isLoading = true
      })
      .addCase(update.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(update.rejected, (state) => {
        state.isLoading = false
      })

      //Patient based file  Create
      .addCase(create.pending, (state) => {
        state.isLoading = true
      })
      .addCase(create.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.incNumber += 1
        state.isLoading = false
      })
      .addCase(create.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default taskSlice.reducer
