// // UserList.jsx
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { formatDateTime } from '@/utils/cdayjs'

// Components
import { Helmet } from 'react-helmet-async'
import CDatatable from '@/components/CDatatable'
import AddIcon from '@mui/icons-material/Add'
import DownloadIcon from '@mui/icons-material/Download'
import DeleteIcon from '@mui/icons-material/Delete'
import SendIcon from '@mui/icons-material/Send'
// Slice
import { setBreadcrumb } from '@/redux/Slice/breadcrumbSlice'
import {
  Box,
  Button,
  Card,
  FormControlLabel,
  ListItem,
  Stack,
  Switch,
  Typography,
} from '@mui/material'
import CButton from '@/components/CButton'
import { useDispatch, useSelector } from 'react-redux'
import CDataTable from '@/components/CDatatable'
import { Edit } from '@mui/icons-material'
import dayjs from 'dayjs'
import FinancialButton from './FinancialButton'
import { fetchMedicardInvoiceList } from '../financialSlice'
import { renderCell } from '@/utils/ctableTool'
import COptionButton from '@/components/COptionButton'

const MedicardInvoiceList = () => {
  const dispatch = useDispatch()
  const { invoices, isLoading } = useSelector((state) => state.financial)
  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        {
          path: '/financial/invoice-list',
          label: 'Financial',
          isLast: false,
        },
        { path: '', label: 'Medicard invoice List', isLast: true },
      ])
    )
  }, [dispatch])

  const columns = [
    {
      field: 'biller_name',
      headerName: 'Biller Name',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      field: 'patient',
      headerName: 'Patient Name',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      field: 'invoice',
      headerName: 'Invoice Number',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      field: 'address',
      headerName: 'Address',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      field: 'purchase_date',
      headerName: 'Purchase Date',
      minWidth: 200,
      flex: 1,
      renderCell: (params) =>
        renderCell(dayjs(params.row?.created_at).format('DD MMM YYYY h:mm A')),
    },
    {
      field: 'amount',
      headerName: 'Amount',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      headerName: 'Action',
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="row" spacing={1}>
            <CButton icon={<DownloadIcon />} />
            <COptionButton label="Track Order" color="primary" size="small">
              {params.row?.orders?.map((item, index) => (
                <ListItem key={index}>
                  <CButton
                    variant="text"
                    color="primary"
                    label={item.tracking_number}
                    component={Link}
                    to={`/financial/track-order/${item.id}`}
                    sx={{ padding: 0 }}
                    fullWidth
                  />
                </ListItem>
              ))}
            </COptionButton>
          </Stack>
        </>
      ),
    },
  ]

  useEffect(() => {
    dispatch(fetchMedicardInvoiceList())
  }, [])

  return (
    <>
      <Helmet>
        <title>Bioaro | Financial - Medicard Invoice List</title>
      </Helmet>

      <Card sx={{ p: 3 }}>
        <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
          <Typography variant="h6">Medicard Invoice List</Typography>
        </Stack>
        <CDataTable
          rows={invoices || []}
          columns={columns}
          isLoading={isLoading}
          filterMode="server"
        />
      </Card>
    </>
  )
}

export default MedicardInvoiceList
