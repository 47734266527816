// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../../services/axiosConfig'

// Fetch leads list
export const list = createAsyncThunk('leads/leads', async (filterData) => {
  const response = await axiosInstance.get('leads/leads', {
    params: filterData,
  })
  return response.data
})

//note create
export const noteCreate = createAsyncThunk(
  'leads/note-create',
  async (data) => {
    const response = await axiosInstance.post(`leads/note-create`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  }
)

//note create
export const statusUpdate = createAsyncThunk(
  'leads/status-update',
  async (data) => {
    console.log(data)
    const response = await axiosInstance.post(`leads/status-update`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  }
)

// Fetch leads list
export const logs = createAsyncThunk('leads/view-log', async (leadsId) => {
  const response = await axiosInstance.get(`leads/view-log/${leadsId}`)
  console.log(response.data)
  return response.data
})

// leads delete
export const leadsDelete = createAsyncThunk('leads/delete', async (leadsId) => {
  const response = await axiosInstance.get(`leads/delete/${leadsId}`)
  return response.data
})

const leadsSlice = createSlice({
  name: 'lead',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    leads: [],
    doctorNote: [],
    data: '',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(list.pending, (state) => {
        state.isLoading = true
      })
      .addCase(list.fulfilled, (state, { payload } = action) => {
        state.leads = payload.leads
        state.isLoading = false
      })
      .addCase(list.rejected, (state, { payload }) => {
        state.isLoading = false
      })

      //leads delete
      .addCase(leadsDelete.pending, (state) => {
        state.isLoading = true
      })
      .addCase(leadsDelete.fulfilled, (state, { payload } = action) => {
        state.isLoading = false
        state.error = payload.error
        state.message = payload.message
      })
      .addCase(leadsDelete.rejected, (state, { payload }) => {
        state.isLoading = false
      })

      //view log
      .addCase(logs.pending, (state) => {
        state.isLoading = true
      })
      .addCase(logs.fulfilled, (state, { payload } = action) => {
        state.data = payload.data
        state.isLoading = false
      })
      .addCase(logs.rejected, (state, { payload }) => {
        state.isLoading = false
      })

      //note create
      .addCase(noteCreate.pending, (state) => {
        state.isLoading = true
      })
      .addCase(noteCreate.fulfilled, (state, { payload } = action) => {
        state.errors = payload.errors
        state.message = payload.message
        state.isLoading = false
      })
      .addCase(noteCreate.rejected, (state, { payload }) => {
        state.isLoading = false
      })

      //status update
      .addCase(statusUpdate.pending, (state) => {
        state.isLoading = true
      })
      .addCase(statusUpdate.fulfilled, (state, { payload } = action) => {
        state.error = payload.error
        state.message = payload.message
        state.isLoading = false
      })
      .addCase(statusUpdate.rejected, (state, { payload }) => {
        state.isLoading = false
      })
  },
})

export default leadsSlice.reducer
