// // UserList.jsx

// Icon
import AddIcon from '@mui/icons-material/Add'
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Grid,
  InputLabel,
  Stack,
  Typography,
} from '@mui/material'
// Components
import AdminLayout from '@/layouts/AdminLayout'

// Slice
import { setBreadcrumb } from '@/redux/Slice/breadcrumbSlice'
import { Link, useNavigate } from 'react-router-dom'
import CButton from '@/components/CButton'
import CDatatable from '@/components/CDatatable'
import { changeStatus, deleteRecord } from '@/redux/Slice/commonSlice'
import { showError, showSuccess } from '@/redux/Slice/alertSlice'
import CTextField from '@/components/CTextField'
import SaveIcon from '@mui/icons-material/Save'
import { Helmet } from 'react-helmet-async'
import { useEffect, useState } from 'react'
import { create, list } from '../couponSlice'
import { useDispatch, useSelector } from 'react-redux'
import CAutocomplete from '@/components/CAutocomplete'
import CEditor from '@/components/CEditor'
import CDatePicker from '@/components/CDatePicker'

export default function CouponCreate() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { users, errors, error, isLoading } = useSelector(
    (state) => state.coupon
  )
  console.log(users)

  useEffect(() => {
    dispatch(list())
  }, [])

  //   initial create value
  const [coupon, setCoupon] = useState({
    user_id: [],
    coupon_type: null,
    type: null,
    description: '',
  })

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const res = await dispatch(create(coupon))

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )

        navigate('/website/coupon/list')
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Server error:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred while Creating Website coupon service.',
        })
      )
    }
  }

  // onChange handler to update the local state
  const handleInputChange = (event) => {
    const { name, value } = event.target
    setCoupon((coupon) => ({
      ...coupon,
      [name]: value,
    }))
  }

  const couponType = [
    {
      id: 1,
      name: 'Percent',
    },
    {
      id: 2,
      name: 'Amount',
    },
  ]

  const types = [
    {
      id: 1,
      name: 'single',
    },
    {
      id: 2,
      name: 'Multiple',
    },
  ]

  return (
    <>
      <Helmet>
        <title>Website coupon Create</title>
      </Helmet>
      <Stack direction="row" sx={{ pr: 4, width: '79rem', pb: 2 }}>
        <Card sx={{ p: 3, width: '100%' }}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              width="100%"
              sx={{ alignContent: 'center', margin: 'auto' }}
            >
              <Grid xs={12} mb={2}>
                <Box>
                  {' '}
                  <Stack sx={{ float: 'left' }}>
                    {' '}
                    <Typography variant="h5">Create Coupon</Typography>{' '}
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    mb={2}
                    mt={2}
                    mr={1.3}
                  >
                    <CButton label="Save" icon={<SaveIcon />} type="submit" />
                  </Stack>
                  <hr />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <CAutocomplete
                  options={users}
                  getOptionLabel={(option) => option.name}
                  value={coupon?.user_id}
                  onChange={(e, value) =>
                    // console.log(value)
                    setCoupon({
                      ...coupon,
                      user_id: value,
                    })
                  }
                  label="User"
                  multiple
                  error={!!(errors && errors.user_id)}
                  helperText={errors && errors.user_id}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <CAutocomplete
                  options={couponType}
                  getOptionLabel={(option) => option.name}
                  value={coupon?.coupon_type}
                  onChange={(e, value) =>
                    // console.log(value)
                    setCoupon({
                      ...coupon,
                      coupon_type: value,
                    })
                  }
                  label="Coupon Type"
                  error={!!(errors && errors.coupon_type)}
                  helperText={errors && errors.coupon_type}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <CAutocomplete
                  options={types}
                  getOptionLabel={(option) => option.name}
                  value={coupon?.type}
                  onChange={(e, value) =>
                    // console.log(value)
                    setCoupon({
                      ...coupon,
                      type: value,
                    })
                  }
                  label="Coupon Use Time"
                  error={!!(errors && errors.type)}
                  helperText={errors && errors.type}
                />
              </Grid>

              <Grid xs={6} pl={2} mt={2}>
                <CTextField
                  label="Price"
                  name="price"
                  value={coupon?.price}
                  error={errors && errors.price}
                  helperText={errors && errors.price}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <CDatePicker
                  label="Expire Date"
                  name="expiry_date"
                  onChange={(value) =>
                    setCoupon({ ...coupon, expiry_date: formatDate(value) })
                  }
                  error={!!(errors && errors.expiry_date)}
                  helperText={errors && errors.expiry_date}
                />
              </Grid>
            </Grid>
          </form>
        </Card>
      </Stack>
    </>
  )
}
