// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../../services/axiosConfig'

// Fetch Sequencing service List
export const fetchSServiceList = createAsyncThunk('sservice/list', async () => {
  const response = await axiosInstance.get('sservice/list')
  return response.data
})

// Fetch Sequencing service detail
export const fetchSServiceDetail = createAsyncThunk('sservice/detail/', async (sserviceId) => {
  const response = await axiosInstance.get(`sservice/detail/${sserviceId}`)
  return response.data
})

// Sequencing Service Create
export const create = createAsyncThunk('sservice/create', async ({ data }) => {
  console.log(data)
  const response = await axiosInstance.post(`sservice/create`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data
})

// // Sequencing Service Update
export const update = createAsyncThunk(
  'sservice/update',
  async ({ sserviceId, data }) => {
    const response = await axiosInstance.post(
      `sservice/update/${sserviceId}`,
      data
    )
    return response.data
  }
)

// Sequencing Service change status
// export const changeRefStatus = createAsyncThunk(
//   'sservice/change-status',
//   async ({ referralId, data }) => {
//     const response = await axiosInstance.post(
//       `sservice/change-status/${referralId}`,
//       data
//     )
//     return response.data
//   }
// )

// Fetch Sequencing Service info
export const fetchSServiceInfo = createAsyncThunk('sservice/info', async () => {
  const response = await axiosInstance.get('sservice/info')
  return response.data
})

const sserviceSlice = createSlice({
  name: 'sservice',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    sservices: [],
    sserviceInfo: null,
    sservice: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      // sservice create
      .addCase(create.pending, (state) => {
        state.isLoading = true
      })
      .addCase(create.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(create.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch  sservice list
      .addCase(fetchSServiceList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchSServiceList.fulfilled, (state, { payload }) => {
        state.sservices = payload.sservices
        state.isLoading = false
      })
      .addCase(fetchSServiceList.rejected, (state) => {
        state.isLoading = false
      })


      // Fetch  sservice detail
      .addCase(fetchSServiceDetail.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchSServiceDetail.fulfilled, (state, { payload }) => {
        state.sservice = payload.sservice
        state.isLoading = false
      })
      .addCase(fetchSServiceDetail.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch  sservice info
      .addCase(fetchSServiceInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchSServiceInfo.fulfilled, (state, { payload }) => {
        state.sserviceInfo = payload
        state.isLoading = false
      })
      .addCase(fetchSServiceInfo.rejected, (state) => {
        state.isLoading = false
      })

    // // get Provinces And ClientTypes
    // .addCase(getProvincesAndClientTypes.pending, (state) => {
    //   state.isLoading = true
    // })
    // .addCase(getProvincesAndClientTypes.fulfilled, (state, { payload }) => {
    //   state.clientTypes = payload.clientTypes
    //   state.provinces = payload.provinces
    //   state.isLoading = false
    // })
    // .addCase(getProvincesAndClientTypes.rejected, (state) => {
    //   state.isLoading = false
    // })

    //  sservice update
    .addCase(update.pending, (state) => {
      state.isLoading = true
    })
    .addCase(update.fulfilled, (state, { payload }) => {
      state.message = payload.message
      state.errors = payload.errors
      state.isLoading = false
    })
    .addCase(update.rejected, (state) => {
      state.isLoading = false
    })

    // // change  sservice status
    // .addCase(changeRefStatus.pending, (state) => {
    //   state.isLoading = true
    // })
    // .addCase(changeRefStatus.fulfilled, (state, { payload }) => {
    //   state.message = payload.message
    //   state.errors = payload.errors
    //   state.isLoading = false
    // })
    // .addCase(changeRefStatus.rejected, (state) => {
    //   state.isLoading = false
    // })
  },
})

export default sserviceSlice.reducer
