import { Route, Routes } from "react-router-dom";

import RoleList from "./RoleList";
import RoleCreate from "./RoleCreate";
import RoleUpdate from "./RoleUpdate";

const roleRoutes = [
  { path: "list", element: <RoleList /> },
  { path: "create", element: <RoleCreate /> },
  { path: "update/:roleId", element: <RoleUpdate /> },
];

export default roleRoutes
