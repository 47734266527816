// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../../services/axiosConfig'

// This function is called to set the auth header

// Fetch Patient order list by its patient id id

export const fetchPatientOrderList = createAsyncThunk(
  'patient/order/patient-order-list/',
  async (id) => {
    const response = await axiosInstance.get(
      `patient/order/patient-order-list/${id}`
    )
    return response.data
  }
)

export const fetchOrderStatusList = createAsyncThunk(
  'order/status-list',
  async (params) => {
    const response = await axiosInstance.get('order/status-list', {
      params: params,
    })
    return response.data
  }
)

export const fetchOrderList = createAsyncThunk(
  'order/list',
  async (filterData) => {
    const response = await axiosInstance.get('order/list', {
      params: filterData,
    })
    return response.data
  }
)

export const fetchClinicOrderList = createAsyncThunk(
  'order/clinic-order-list',
  async (filterData) => {
    const response = await axiosInstance.get('order/clinic-order-list', {
      params: filterData,
    })
    return response.data
  }
)

export const changeStatus = createAsyncThunk(
  'order/change-status/',
  async ({ orderId, data }) => {
    const response = await axiosInstance.post(
      `order/change-status/${orderId}`,
      data
    )
    return response.data
  }
)

export const fetchOrderLogList = createAsyncThunk(
  'order/order-log-list/',
  async (appId) => {
    const response = await axiosInstance.get(`order/order-log-list/${appId}`)
    return response.data
  }
)

export const fetchRebookLogList = createAsyncThunk(
  'order/rebook-log-list/',
  async (appId) => {
    const response = await axiosInstance.get(`order/rebook-log-list/${appId}`)
    return response.data
  }
)

export const orderManageList = createAsyncThunk(
  'order/order-manage-list',
  async () => {
    const response = await axiosInstance.get('order/order-manage-list')
    return response.data
  }
)

const orderSlice = createSlice({
  name: 'order',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    statuses: [],
    patientOrderList: [],
    orderList: [],
    services: [],
    orderLogs: [],
    rebookLogs: [],
    orderManages: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Fetch status list
      .addCase(fetchOrderStatusList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        fetchOrderStatusList.fulfilled,
        (state, { payload } = action) => {
          state.statuses = payload.statuses
          state.isLoading = false
        }
      )
      .addCase(fetchOrderStatusList.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch Patient order list by its patient id id
      .addCase(fetchPatientOrderList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        fetchPatientOrderList.fulfilled,
        (state, { payload } = action) => {
          state.patientOrderList = payload.orders
          state.isLoading = false
        }
      )
      .addCase(fetchPatientOrderList.rejected, (state) => {
        state.isLoading = false
      })

      // Change Order Status
      .addCase(changeStatus.pending, (state) => {
        state.isLoading = true
      })
      .addCase(changeStatus.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.isLoading = false
      })
      .addCase(changeStatus.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch  order list
      .addCase(fetchOrderList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchOrderList.fulfilled, (state, { payload } = action) => {
        state.orderList = payload.orders
        state.services = payload.services
        state.isLoading = false
      })
      .addCase(fetchOrderList.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch clinic  order list
      .addCase(fetchClinicOrderList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        fetchClinicOrderList.fulfilled,
        (state, { payload } = action) => {
          state.orderList = payload.orders
          state.services = payload.services
          state.isLoading = false
        }
      )
      .addCase(fetchClinicOrderList.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch order log list
      .addCase(fetchOrderLogList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchOrderLogList.fulfilled, (state, { payload } = action) => {
        state.orderLogs = payload.orderLogs
        state.isLoading = false
      })
      .addCase(fetchOrderLogList.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch rebook log list
      .addCase(fetchRebookLogList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchRebookLogList.fulfilled, (state, { payload } = action) => {
        state.rebookLogs = payload.rebookLogs
        state.isLoading = false
      })
      .addCase(fetchRebookLogList.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch status list
      .addCase(orderManageList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(orderManageList.fulfilled, (state, { payload } = action) => {
        state.orderManages = payload.orderManages
        state.isLoading = false
      })
      .addCase(orderManageList.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default orderSlice.reducer
