import React from 'react'
import BlogList from './partial/BlogList';
import BlogCreate from './partial/BlogCreate';
import BlogUpdate from './partial/BlogUpdate';
const blogRoutes = [
  { path: "list/", element: <BlogList /> },
  { path: "create/", element: <BlogCreate /> },
  { path: "update/:blogId", element: <BlogUpdate /> },
];

export default blogRoutes;

