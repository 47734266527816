// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../../../services/axiosConfig'

// Fetch Patient Based File List
export const fetchPatientBasedFileList = createAsyncThunk(
  'patient/file/patient-based-list',
  async (patientId) => {
    const response = await axiosInstance.get(
      `patient/file/patient-based-list/${patientId}`
    )
    return response.data
  }
)

// Patient Based File Create
export const create = createAsyncThunk(
  'patient/file/create',
  async ({ patientId, data }) => {
    const response = await axiosInstance.post(
      `patient/file/create/${patientId}`,
      data
    )
    return response.data
  }
)

export const fetchFile = createAsyncThunk(
  'patient/file/view',
  async (fileId) => {
    const response = await axiosInstance.get(`patient/file/view/${fileId}`)
    return response.data
  }
)

// Patient Based File Create
export const updatePatientAccess = createAsyncThunk(
  'patient/file/update-patient-access',
  async ({ fileId, data }) => {
    const response = await axiosInstance.post(
      `patient/file/update-patient-access/${fileId}`,
      data
    )
    return response.data
  }
)

// sendFax
export const sendFax = createAsyncThunk(
  'patient/file/send-fax',
  async ({ fileId, data }) => {
    const response = await axiosInstance.post(
      `patient/file/send-fax/${fileId}`,
      data
    )
    return response.data
  }
)
// send Email
export const sendEmail = createAsyncThunk(
  'patient/file/send-email',
  async ({ fileId, data }) => {
    const response = await axiosInstance.post(
      `patient/file/send-email/${fileId}`,
      data
    )
    return response.data
  }
)

// Fetch Fax and Email log list by file id
export const fetchFaxAndEmailLogList = createAsyncThunk(
  'patient/file/fax-and-email-log-list',
  async (fileId) => {
    const response = await axiosInstance.get(
      `patient/file/fax-and-email-log-list/${fileId}`
    )
    return response.data
  }
)

// File trash (temporary delete it can be restored)
export const fileTrash = createAsyncThunk(
  'patient/file/trash',
  async (fileId) => {
    const response = await axiosInstance.get(`patient/file/trash/${fileId}`)
    return response.data
  }
)

const fileSlice = createSlice({
  name: 'file',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    files: null,
    fileCategories: [],
    file: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch  patient based file list
      .addCase(fetchPatientBasedFileList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        fetchPatientBasedFileList.fulfilled,
        (state, { payload } = action) => {
          state.files = payload.files
          state.fileCategories = payload.fileCategories
          state.isLoading = false
        }
      )
      .addCase(fetchPatientBasedFileList.rejected, (state) => {
        state.isLoading = false
      })

      // Patient based file  Create
      .addCase(create.pending, (state) => {
        state.isLoading = true
      })
      .addCase(create.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(create.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch  file detail
      .addCase(fetchFile.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchFile.fulfilled, (state, { payload } = action) => {
        state.file = payload.file
        state.isLoading = false
      })
      .addCase(fetchFile.rejected, (state) => {
        state.isLoading = false
      })

      // fetch Fax and Email log list
      .addCase(fetchFaxAndEmailLogList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchFaxAndEmailLogList.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.faxLogs = payload.faxLogs
        state.emailLogs = payload.emailLogs
      })
      .addCase(fetchFaxAndEmailLogList.rejected, (state) => {
        state.isLoading = false
      })
      // File Trash
      .addCase(fileTrash.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fileTrash.fulfilled, (state, action) => {
        state.message = action.payload.message
        state.isLoading = false
      })
      .addCase(fileTrash.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default fileSlice.reducer
