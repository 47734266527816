// // UserList.jsx
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Icon
import {
  ButtonGroup,
  Card,
  Typography,
} from '@mui/material'
// Slice
import CDatatable from '@/components/CDatatable'
import { Helmet } from 'react-helmet-async'
import { list } from './orderInvoiceSlice'
import CButton from '@/components/CButton'

const OrderInvoiceList = () => {
  const dispatch = useDispatch()
  const { orderInvoices, isLoading } = useSelector(
    (state) => state.orderInvoice
  )


  const handleQuickFilterValue = (filterModel) => {
    const quickFilterValue = filterModel.quickFilterValues.join(' ')
    console.log(quickFilterValue)
  }

  const columns = [
    { field: 'invoice_number', headerName: 'Number', flex: 1 },
    { field: 'qty', headerName: 'Total Quantity', flex: 1 },
    { field: 'price', headerName: 'Total Price', flex: 1 },
    { field: 'received_by', headerName: 'Received By', flex: 1 },
    { field: 'made_by', headerName: 'Made By', flex: 1 },
    { field: 'date', headerName: 'Date', flex: 1 },
    { field: 'status', headerName: 'Status', flex: 1 },
  
    {
      field: 'action',
      headerName: 'Action',
      flex: 0.5,
      renderCell: (params) => (
        <ButtonGroup variant="contained" aria-label="Basic button group">
          <CButton label="Report"/>
        </ButtonGroup>
      ),
    },
  ]

  useEffect(() => {
    // page title
    document.title = 'BioAro | Inventory Order - List'
    dispatch(list())
  }, [])


  return (
    <>
      <Helmet>
        <title>Bioaro | Inventory Order - List</title>
      </Helmet>

      <Card sx={{ p: 2 }}>
        <Typography variant="h5" p={2}>
          Inventory Order
        </Typography>
        <CDatatable
          rows={orderInvoices}
          columns={columns}
          isLoading={isLoading}
          handleQuickFilterValue={handleQuickFilterValue}
        />
      </Card>
    </>
  )
}

export default OrderInvoiceList
