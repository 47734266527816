import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Typography } from '@mui/material'
import { renderCell } from '@/utils/ctableTool'
import CDataTable from '@/components/CDatatable'
import { reportList } from '../dashboardSlice'

const ReportList = () => {
  const dispatch = useDispatch()
  const { reports, isLoading } = useSelector((state) => state.dashboard)

  const columns = [
    {
      field: 'sn',
      headerName: 'Sn.',
      minWidth: 200,
      flex: 1,
      renderCell: (params) => renderCell(params.value),
    },
    {
      field: 'service_name',
      headerName: 'Service Name',
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      field: 'date',
      headerName: 'Date',
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      field: 'made_by',
      headerName: 'Email',
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => renderCell(value),
    },
  ]

  useEffect(() => {
    dispatch(reportList())
  }, [dispatch])

  return (
    <Card sx={{ p: 3, mt: 4 }}>
      <Typography mb={1}>Report List</Typography>
      <CDataTable
        rows={reports || []}
        columns={columns}
        isLoading={isLoading}
      />
    </Card>
  )
}

export default ReportList
