// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../services/axiosConfig'

// This function is called to set the auth header

// create Role
export const create = createAsyncThunk('role/create', async (data) => {
  const response = await axiosInstance.post('role/create', data)
  return response.data
})

// Update Role
export const update = createAsyncThunk('role/update', async ({ id, data }) => {
  const response = await axiosInstance.post(`role/update/${id}`, data)
  return response.data
})

// Fetch all permissions
export const fetchPermissions = createAsyncThunk(
  'role/permissions',
  async () => {
    const response = await axiosInstance.get('role/permissions')

    return response.data
  }
)

// Fetch role detail by role id
export const fetchRoleDetail = createAsyncThunk(
  'role/detail',
  async (roleId) => {
    const response = await axiosInstance.get(`role/detail/${roleId}`)
    return response.data
  }
)

const roleSlice = createSlice({
  name: 'role',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    permissions: [],
    rolePermissions: [],
    roleDetail: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Create role
      .addCase(create.pending, (state) => {
        state.isLoading = true
      })
      .addCase(create.fulfilled, (state, action) => {
        if (action.payload.error) {
          state.message = action.payload.message
          state.errors = action.payload.errors
        } else {
          state.message = action.payload.message
          state.errors = null
        }
        state.isLoading = false
      })
      .addCase(create.rejected, (state) => {
        state.isLoading = false
      })

      // Update role
      .addCase(update.pending, (state) => {
        state.isLoading = true
      })
      .addCase(update.fulfilled, (state, action) => {
        if (action.payload.error) {
          state.message = action.payload.message
          state.errors = action.payload.errors
        } else {
          state.message = action.payload.message
          state.errors = null
        }
        state.isLoading = false
      })
      .addCase(update.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch all Permissions
      .addCase(fetchPermissions.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchPermissions.fulfilled, (state, { payload } = action) => {
        state.permissions = payload.permissions
        state.isLoading = false
      })
      .addCase(fetchPermissions.rejected, (state) => {
        state.isLoading = false
      })
      // Fetch role detail by role id
      .addCase(fetchRoleDetail.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchRoleDetail.fulfilled, (state, { payload } = action) => {
        state.roleDetail = payload.role
        state.rolePermissions = payload.rolePermissions
        state.isLoading = false
      })
      .addCase(fetchRoleDetail.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default roleSlice.reducer
