import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'

import SendOutlinedIcon from '@mui/icons-material/SendOutlined'
import { Box, Grid, InputLabel, Stack } from '@mui/material'
import CDialog from '../../../../components/CDialog'
import CButton from '../../../../components/CButton'
import CTextField from '../../../../components/CTextField'
import CEditor from '../../../../components/CEditor'
import { noteCreate } from '../../../../redux/Slice/commonSlice'
import { showError, showSuccess } from '../../../../redux/Slice/alertSlice'
import { create, list } from '../longevityCategorySlice'

const LongevityCategoryCreate = () => {
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)
  const handleCDialogOpen = () => {
    setOpen(true)
  }
  const handleCDialogClose = () => {
    setOpen(false)
  }
  const { errors, message, payload } = useSelector(
    (state) => state.longevityCategory
  )
  const [category, setCategory] = useState({
    name: '',
    input_files: '',
    position: '',
    description: '',
  })
  const handleInputChange = (event) => {
    const { name, value } = event.target
    // Update the local state with the changed value
    setCategory((category) => ({
      ...category,
      [name]: value,
    }))
  }
  // submit form
  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const res = await dispatch(create(category))
      console.log(res)

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        dispatch(list())
        setOpen(false)
        setCategory({
          name: '',
          input_files: '',
          position: '',
          description: '',
        })
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Server error:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred while updating the Category.',
        })
      )
    }
  }
  return (
    <CDialog
      btnTitle="Create"
      icon={<AddIcon />}
      headerTitle="Create test"
      handleCDialogOpen={handleCDialogOpen}
      handleCDialogClose={handleCDialogClose}
      open={open}
    >
      <Box maxWidth="50rem">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {/* Other Email Receivers */}
            <Grid item xs={12}>
              <CTextField
                label="Name"
                name="name"
                error={errors && errors.name}
                helperText={errors && errors.name}
                value={category.name}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={6}>
              <CTextField
                label="Input Files"
                name="input_files"
                value={category.input_files}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={6} md={6}>
              <CTextField
                label="Position"
                name="position"
                error={errors && errors.position}
                helperText={errors && errors.position}
                value={category.position}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid item xs={12}>
                <InputLabel>Description</InputLabel>
                <CEditor
                  value={category.description}
                  handleChange={(value) =>
                    setCategory({
                      ...category,
                      description: value,
                    })
                  }
                />
              </Grid>
            </Grid>
          </Grid>

          <Stack
            direction="row"
            justifyContent="flex-end"
            sx={{ mb: 2 }}
            mt={2}
          >
            <CButton label="Save" type="submit" />
          </Stack>
        </form>
      </Box>
    </CDialog>
  )
}

export default LongevityCategoryCreate
