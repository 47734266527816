// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '@/services/axiosConfig'

// Fetch room list
export const list = createAsyncThunk('hospital/room/list', async () => {
  const response = await axiosInstance.get('hospital/room/list')
  return response.data
})

// Fetch room info
export const roomInfo = createAsyncThunk(
  'hospital/room/info',
  async (roomId) => {
    const response = await axiosInstance.get(`hospital/room/info/${roomId}`)
    console.log(response.data)
    return response.data
  }
)

// Fetch room list
export const create = createAsyncThunk('hospital/room/create', async (data) => {
  const response = await axiosInstance.post('hospital/room/create', data)
  return response.data
})

// Fetch room list
export const update = createAsyncThunk('hospital/room/update', async (data) => {
  const response = await axiosInstance.post('hospital/room/update', data)
  return response.data
})

const roomSlice = createSlice({
  name: 'room',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    rooms: [],
    floors: [],
    departments:[],
    info: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      //Category List
      .addCase(list.pending, (state) => {
        state.isLoading = true
      })
      .addCase(list.fulfilled, (state, { payload } = action) => {
        state.rooms = payload.rooms
        state.floors = payload.floors
        state.departments = payload.departments
        state.isLoading = false
      })

      .addCase(list.rejected, (state) => {
        state.isLoading = false
      })

      //Category info
      .addCase(roomInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(roomInfo.fulfilled, (state, { payload } = action) => {
        state.info = payload.info
        state.isLoading = false
      })

      .addCase(roomInfo.rejected, (state) => {
        state.isLoading = false
      })

      //Category create
      .addCase(create.pending, (state) => {
        state.isLoading = true
      })
      .addCase(create.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(create.rejected, (state) => {
        state.isLoading = false
      })

      //Category update
      .addCase(update.pending, (state) => {
        state.isLoading = true
      })
      .addCase(update.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(update.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default roomSlice.reducer
