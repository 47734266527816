import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FormCanvas from './Partials/FormCanvas'
import Sidebar from './Partials/Sidebar'
import { useNavigate, useParams } from 'react-router-dom'
import { Grid, Stack, Typography } from '@mui/material'
import FormPreview from './Partials/FormPreview'
import CButton from '@/components/CButton'
import CTextField from '@/components/CTextField'
import { formBuilderUpdate, setElements } from './formBuilderSlice'
import { showError, showSuccess } from '@/redux/Slice/alertSlice'

const FormBuilderUpdate = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { formBuilderId } = useParams() // Extract patient id from url

  const { formBuilders, elements, errors } = useSelector(
    (state) => state.formBuilder
  )

  const [formName, setFormName] = useState('')
  const [isPreviewMode, setIsPreviewMode] = useState(false)
  const [formBuilder, setFormBuilder] = useState(null)
  useEffect(() => {
    const formBuilder = formBuilders.find((item) => item.id === formBuilderId)

    if (formBuilder) {
      setFormName(formBuilder.name)
      setFormBuilder(formBuilder)
      dispatch(setElements(formBuilder.form))
    }
  }, [formBuilderId])

  const togglePreviewMode = () => {
    setIsPreviewMode((prevMode) => !prevMode)
  }

  // Handle form submission
  const handleUpdate = async () => {
    try {
      const res = await dispatch(
        formBuilderUpdate({
          formBuilderId: formBuilderId,
          data: { name: formName, form: elements },
        })
      )

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        dispatch(setElements([]))
        navigate(`/form-builder/list`)
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Error while creating:', error)
      dispatch(
        showError({
          error: true,
          message: 'An Error while creating',
        })
      )
    }
  }

  return (
    <>
      {formBuilder ? (
        <>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <CTextField
                label="Name"
                value={formName}
                onChange={(e) => setFormName(e.target.value)}
                required
                error={!(errors && errors.name)}
                helperText={errors && errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography color="warning">
                {errors?.form && errors.form}
              </Typography>
            </Grid>
            <Grid item md={9}>
              {!isPreviewMode && <FormCanvas />}
            </Grid>
            <Grid item md={3}>
              {!isPreviewMode ? <Sidebar /> : null}
            </Grid>
          </Grid>
          <Stack
            direction="row"
            justifyContent="end"
            spacing={1}
            sx={{ mt: 0.5 }}
          >
            <CButton
              label={isPreviewMode ? 'Back to Builder' : 'Preview'}
              variant="contained"
              size="small"
              color="primary"
              onClick={togglePreviewMode}
            />

            {!isPreviewMode && (
              <CButton
                label="Update"
                variant="contained"
                size="small"
                color="primary"
                onClick={handleUpdate}
              />
            )}
          </Stack>
          {isPreviewMode && (
            <Stack direction="row" justifyContent="center">
              <FormPreview />
            </Stack>
          )}
        </>
      ) : (
        ''
      )}
    </>
  )
}

export default FormBuilderUpdate
