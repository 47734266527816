// // UserList.jsx

// Icon
import AddIcon from '@mui/icons-material/Add'
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Grid,
  InputLabel,
  Stack,
  Typography,
} from '@mui/material'
// Components
import AdminLayout from '@/layouts/AdminLayout'

// Slice
import { setBreadcrumb } from '@/redux/Slice/breadcrumbSlice'
import { Link, useNavigate } from 'react-router-dom'
import CButton from '@/components/CButton'
import CDatatable from '@/components/CDatatable'
import { changeStatus, deleteRecord } from '@/redux/Slice/commonSlice'
import { showError, showSuccess } from '@/redux/Slice/alertSlice'
import CTextField from '@/components/CTextField'
import SaveIcon from '@mui/icons-material/Save'
import { Helmet } from 'react-helmet-async'
import { useEffect, useState } from 'react'
import { create, gptContent, list } from '../blogSlice'
import { useDispatch, useSelector } from 'react-redux'
import CAutocomplete from '@/components/CAutocomplete'
import CEditor from '@/components/CEditor'

export default function BlogCreate() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { content, errors, error, isLoading } = useSelector(
    (state) => state.websiteBlog
  )

  //   initial create value
  const [blog, setBlog] = useState({
    name: '',
    slug: '',
    description: '',
    blog_image: [],
    keyword: '',
    seo_description: '',
    social_title: '',
    social_description: '',
  })

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const res = await dispatch(create(blog))

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )

        navigate('/website/blog/list')
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Server error:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred while Creating Website Blog service.',
        })
      )
    }
  }

  // onChange handler to update the local state
  const handleInputChange = (event) => {
    const { name, value } = event.target
    console.log(name)
    // Update the local state with the changed value
    if (name == 'name') {
      setBlog((blog) => ({
        ...blog,
        slug: value,
      }))
    }
    setBlog((blog) => ({
      ...blog,
      [name]: value,
    }))
  }

  // Handle form submission
  const handleGptContent = async (content) => {
    try {
      const res = await dispatch(
        gptContent({
          content: content,
        })
      )
      if (res.payload && !res.payload.error) {
        setBlog({
          ...blog,
          description: res.payload.content,
        })
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Error while creating File:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred during File creation.',
        })
      )
    }
  }

  return (
    <>
      <Helmet>
        <title>Website Blog Create</title>
      </Helmet>
      <Stack direction="row" sx={{ pr: 4, width: '79rem', pb: 2 }}>
        <Card sx={{ p: 3, width: '90rem' }}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              width="60rem"
              sx={{ alignContent: 'center', margin: 'auto' }}
            >
              <Stack sx={{ float: 'left' }} mb={3}>
                {' '}
                <Typography variant="h5">Website Blog Create</Typography>{' '}
              </Stack>

              <Grid xs={10.8} ml={1}>
                <CTextField
                  label="Name"
                  name="name"
                  value={blog?.name}
                  error={errors && errors.name}
                  helperText={errors && errors.name}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid xs={1} mt={3}>
                <CButton
                  label="Get"
                  sx={{ background: '##174395', color: 'white' }}
                  onClick={() => handleGptContent(blog?.name)}
                />
              </Grid>

              <Grid xs={12} m={1}>
                <CTextField
                  label="Slug"
                  name="slug"
                  value={blog?.slug}
                  error={errors && errors.slug}
                  helperText={errors && errors.slug}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} ml={-1} mb={6}>
                <InputLabel>Description</InputLabel>
                <CEditor
                  value={blog?.description}
                  handleChange={(value) =>
                    setBlog({
                      ...blog,
                      description: value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <InputLabel>Blog Images</InputLabel>
                <CTextField
                  name="blog_image"
                  type="file"
                  fullWidth
                  variant="outlined"
                  onChange={
                    (e) => setBlog({ ...blog, blog_images: e.target.files }) // Note: Use e.target.files to get multiple files
                  }
                  error={!!(errors && errors.blog_images)}
                  helperText={errors && errors.blog_images}
                  inputProps={{ multiple: true }} // Add multiple attribute here
                />
              </Grid>

              <Grid xs={12} m={1}>
                <CTextField
                  label="Keyword"
                  name="keyword"
                  value={blog?.keyword}
                  error={errors && errors.keyword}
                  helperText={errors && errors.keyword}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid xs={12} m={1}>
                <CTextField
                  label="Meta Description"
                  name="seo_description"
                  value={blog?.seo_description}
                  error={errors && errors.seo_description}
                  helperText={errors && errors.seo_description}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid xs={12} m={1}>
                <CTextField
                  label="Social Title"
                  name="social_title"
                  value={blog?.social_title}
                  error={errors && errors.social_title}
                  helperText={errors && errors.social_title}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid xs={12} m={1}>
                <CTextField
                  label="Social Description"
                  name="social_description"
                  value={blog?.social_description}
                  error={errors && errors.social_description}
                  helperText={errors && errors.social_description}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid mb={4} md={12}>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  mb={2}
                  mt={2}
                  mr={1.3}
                >
                  <CButton label="Save" icon={<SaveIcon />} type="submit" />
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Stack>
    </>
  )
}
