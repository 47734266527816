// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../../../services/axiosConfig'

// Fetch Patient Based File List
export const fetchMessageList = createAsyncThunk(
  'patient/message/list',
  async (patientId) => {
    const response = await axiosInstance.get(
      `patient/message/list/${patientId}`
    )
    return response.data
  }
)

export const fetchMessageDetail = createAsyncThunk(
  'patient/message/detail',
  async (messageId) => {
    const response = await axiosInstance.get(
      `patient/message/detail/${messageId}`
    )
    console.log(response.data)
    return response.data
  }
)

const messageSlice = createSlice({
  name: 'message',
  initialState: {
    isLoading: false,
    errors: '',
    messages: [],
    message: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch  patient based message list
      .addCase(fetchMessageList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchMessageList.fulfilled, (state, { payload }) => {
        state.messages = payload.messages
        state.isLoading = false
      })
      .addCase(fetchMessageList.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch  message detail
      .addCase(fetchMessageDetail.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchMessageDetail.fulfilled, (state, { payload }) => {
        state.message = payload.message
        state.isLoading = false
      })
      .addCase(fetchMessageDetail.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default messageSlice.reducer
