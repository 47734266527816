import React from 'react'
import MailTemplateList from './partial/MailTemplateList';
import MailTemplateCreate from './partial/MailTemplateCreate';
import MailTemplateUpdate from './partial/MailTemplateUpdate';
const MailTemplateRoutes = [
  { path: "list/", element: <MailTemplateList /> },
  { path: "create/", element: <MailTemplateCreate /> },
  { path: "update/:mailTemplateId", element: <MailTemplateUpdate /> },
];

export default MailTemplateRoutes;

