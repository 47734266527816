import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../../../services/axiosConfig'
import dayjs from 'dayjs'

// Fetch Patient Based Appointment List
export const fetchAppointmentList = createAsyncThunk(
  'patient/appointment/list',
  async (patientId) => {
    const response = await axiosInstance.get(
      `patient/appointment/list/?patientId=${patientId}`
    )
    return response.data
  }
)

export const fetchAppEvents = createAsyncThunk(
  'patient/appointment/app-events',
  async (params) => {
    const response = await axiosInstance.get('patient/appointment/app-events', {
      params: params,
    })

    return response.data
  }
)

export const fetchAppEventInfo = createAsyncThunk(
  'patient/appointment/app-event-info',
  async ({ data }) => {
    const response = await axiosInstance.post(
      'patient/appointment/app-event-info',
      data
    )

    return response.data
  }
)

export const createAppEvent = createAsyncThunk(
  'patient/appointment/create-app-event',
  async ({ data }) => {
    const response = await axiosInstance.post(
      'patient/appointment/create-app-event',
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )

    return response.data
  }
)

export const fetchUpdateInfo = createAsyncThunk(
  'patient/appointment/update-info',
  async (appEventId) => {
    const response = await axiosInstance.get(
      `patient/appointment/update-info/${appEventId}`
    )
    return response.data
  }
)

export const updateAppEvent = createAsyncThunk(
  'patient/appointment/update-app-event',
  async ({ appEventId, data }) => {
    const response = await axiosInstance.post(
      `patient/appointment/update-app-event/${appEventId}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )

    return response.data
  }
)

export const updateDefaultDoctors = createAsyncThunk(
  'patient/appointment/update-default-doctors',
  async ({ appEventId, data }) => {
    const response = await axiosInstance.post(
      'patient/appointment/update-default-doctors',
      data
    )

    return response.data
  }
)

export const updateAppEventOnDrop = createAsyncThunk(
  'patient/appointment/update-app-event-on-drop',
  async ({ appEventId, data }) => {
    const response = await axiosInstance.post(
      `patient/appointment/update-app-event-on-drop/${appEventId}`,
      data
    )

    return response.data
  }
)

const appointmentSlice = createSlice({
  name: 'appointment',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    appointments: [],
    allDoctors: [],
    resources: [],
    backgroundEvents: [],
    events: [],
    patients: [],
    services: [],
    updateInfo: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch  patient based appointment list
      .addCase(fetchAppointmentList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        fetchAppointmentList.fulfilled,
        (state, { payload } = action) => {
          state.appointments = payload.appointments
          state.isLoading = false
        }
      )
      .addCase(fetchAppointmentList.rejected, (state) => {
        state.isLoading = false
      })

      // fetch App Calendar Info
      .addCase(fetchAppEvents.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchAppEvents.fulfilled, (state, { payload }) => {
        state.allDoctors = payload.allDoctors
        state.resources = payload.resources
        state.backgroundEvents = payload.backgroundEvents
        state.events = payload.events

        state.isLoading = false
      })
      .addCase(fetchAppEvents.rejected, (state) => {
        state.isLoading = false
      })

      // fetch App Calendar Info
      .addCase(fetchAppEventInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchAppEventInfo.fulfilled, (state, { payload }) => {
        state.patients = payload.patients
        state.services = payload.services
        state.isLoading = false
      })
      .addCase(fetchAppEventInfo.rejected, (state) => {
        state.isLoading = false
      })

      // Create Appointment Event
      .addCase(createAppEvent.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createAppEvent.fulfilled, (state, { payload }) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(createAppEvent.rejected, (state) => {
        state.isLoading = false
      })

      // fetch update info to update app event
      .addCase(fetchUpdateInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchUpdateInfo.fulfilled, (state, { payload }) => {
        state.updateInfo = payload.updateInfo

        state.isLoading = false
      })
      .addCase(fetchUpdateInfo.rejected, (state) => {
        state.isLoading = false
      })

      // Update Appointment Event
      .addCase(updateAppEvent.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateAppEvent.fulfilled, (state, { payload }) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(updateAppEvent.rejected, (state) => {
        state.isLoading = false
      })

      // Update Default Doctors
      .addCase(updateDefaultDoctors.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateDefaultDoctors.fulfilled, (state, { payload }) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(updateDefaultDoctors.rejected, (state) => {
        state.isLoading = false
      })

      // Update Appointment Event on drop
      .addCase(updateAppEventOnDrop.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateAppEventOnDrop.fulfilled, (state, { payload }) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(updateAppEventOnDrop.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default appointmentSlice.reducer
