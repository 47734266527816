import { combineReducers } from 'redux'

import breadcrumbReducer from './Slice/breadcrumbSlice'
import settingReducer from './Slice/settingSlice'
import commonReducer from './Slice/commonSlice'
import authReducer from '@/pages/Auth/authSlice'
import alertReducer from './Slice/alertSlice'
import cdialogReducer from './Slice/cdialogSlice'
import userReducer from '../pages/Admin/User/userSlice'
import roleReducer from './Slice/roleSlice'
import profileReducer from '../pages/Admin/Patient/Profile/profileSlice'
import patientReducer from '../pages/Admin/Patient/patientSlice'
import noteReducer from '../pages/Admin/Templates/Note/noteSlice'
import chartReducer from '../pages/Admin/Patient/Chart/chartSlice'
import previsitReducer from '../pages/Admin/Patient/Previsit/previsitSlice'
import intakeReducer from '../pages/Admin/Patient/Intake/intakeSlice'
import appointmentReducer from '../pages/Admin/Patient/Appointment/appointmentSlice'
import orderReducer from '../pages/Admin/Order/orderSlice'
import physicianReducer from '../pages/Admin/Physician/physicianSlice'
import fileReducer from '../pages/Admin/Patient/File/fileSlice'
import labReducer from '../pages/Admin/Patient/Lab/labSlice'
import messageReducer from '../pages/Admin/Patient/Message/messageSlice'
import billingReducer from '../pages/Admin/Patient/Billing/billingSlice'
import opReducer from '../pages/Admin/Patient/Profile/Partials/OrderingPhysician/opSlice'
import referralReducer from '../pages/Admin/Referral/referralSlice'
import medicineReducer from '../pages/Admin/Patient/Medicine/medicineSlice'
import AckReducer from '../pages/Admin/Patient/Profile/Partials/Ack/ackSlice'
import consentReducer from '../pages/Admin/Patient/Consent/consentSlice'
import familyTreeReducer from '../pages/Admin/Patient/Profile/Partials/FamilyTree/familyTreeSlice'
import dictationReducer from '../pages/Admin/Patient/Dictation/dictationSlice'
import activityReducer from '../pages/Admin/Patient/Activity/activitySlice'
import taskReducer from '../pages/Admin/Patient/Tasks/TaskSlice'
import trashReducer from '../pages/Admin/Patient/Trash/trashSlice'
import followUpReducer from '../pages/Admin/Patient/FollowUp/followUpSlice'
import beforeAfterReducer from '../pages/Admin/Patient/BeforeAfter/beforeAfterSlice'
import ibsJournalReducer from '../pages/Admin/Patient/IbsJournal/ibsJournalSlice'
import themeReducer from './Slice/themeSlice'
import prescriptionReducer from '../pages/Admin/Patient/Prescription/prescriptionSlice'
import talkToMeReducer from '../pages/Admin/Patient/TalkToMe/talkToMeslice'
import sellerSiteReducer from '../pages/Admin/SellerSite/sellerSiteSlice'
import pharmacyReducer from '../pages/Admin/Pharmacy/pharmacySlice'
import documentResendReducer from '../pages/Admin/DocumentResend/documentResendSlice'
import intakeTypeReducer from '../pages/Admin/IntakeType/intakeTypeSlice'
import supportReducer from '../pages/Admin/Support/supportSlice'
import financialReducer from '../pages/Admin/Financial/financialSlice'
import gutClinicReducer from '../pages/Admin/GutClinic/gutClinicSlice'
import consultationSlice from '../pages/Admin/PaymentConsultation/consultationSlice'
import leadsReducer from '../pages/Admin/Leads/leadsSlice'
import categoryReducer from '../pages/Admin/Category/categorySlice'
import tagReducer from '@/pages/Admin/Tag/tagSlice'
import longevityCategoryReducer from '@/pages/Admin/LongevityCategory/longevityCategorySlice'
import fileCategoryReducer from '@/pages/Admin/FileCategory/fileCategorySlice'
import clamServiceReducer from '@/pages/Admin/ClamService/clamServiceSlice'
import paidConsultationReducer from '@/pages/Admin/PaidConsultation/paidConsultationSlice'
import inventoryCategoryReducer from '@/pages/Admin/InventoryManagement/InventoryCategory/inventoryCategorySlice'
import inventorySubCategoryReducer from '@/pages/Admin/InventoryManagement/InventorySubCategory/inventorySubCategorySlice'
import inventoryUserReducer from '@/pages/Admin/InventoryManagement/InventoryUser/inventoryUserSlice'
import expenseCategoryReducer from '@/pages/Admin/InventoryManagement/ExpenseCategory/expenseCategorySlice'
import gutCommunicationCategoryReducer from '@/pages/Admin/InventoryManagement/GutCommunicationCategory/gutCommunicationCategorySlice'
import orderInvoiceReducer from '@/pages/Admin/InventoryManagement/OrderInvoice/orderInvoiceSlice'
import dictationTemplateReducer from '@/pages/Admin/InventoryManagement/DictationTemplate/dictationTemplateSlice'
import noteTemplateReducer from '@/pages/Admin/InventoryManagement/NoteTemplate/noteTemplateSlice'
import mailTemplateReducer from '@/pages/Admin/InventoryManagement/MailTemplate/mailTemplateSlice'
import consentTemplateReducer from '@/pages/Admin/InventoryManagement/ConsentTemplate/consentTemplateSlice'
import acknowledgeReducer from '@/pages/Admin/InventoryManagement/Acknowledge/acknowledgeSlice'
import inventoryTaskReducer from '@/pages/Admin/InventoryManagement/Task/taskSlice'
import blogReducer from '@/pages/Admin/ManageWebsite/Blog/blogSlice'
import newsReducer from '@/pages/Admin/ManageWebsite/News/newsSlice'
import bannerReducer from '@/pages/Admin/ManageWebsite/Banner/bannerSlice'
import seoReducer from '@/pages/Admin/ManageWebsite/SocialSeo/seoSlice'
import securityQuestionReducer from '@/pages/Admin/ManageWebsite/SecurityQuestions/securityQuestionSlice'
import provinceReducer from '@/pages/Admin/ManageWebsite/Province/provinceSlice'
import giftCardReducer from '@/pages/Admin/ManageWebsite/GiftCard/giftCardSlice'
import departmentReducer from '@/pages/Admin/HospitalManagement/Department/departmentSlice'
import departmentSlice from '@/pages/Admin/HospitalManagement/Department/departmentSlice'
import floorReducer from '@/pages/Admin/HospitalManagement/Floor/floorSlice'
import roomReducer from '@/pages/Admin/HospitalManagement/Room/roomSlice'
import couponReducer from '@/pages/Admin/ManageWebsite/Coupon/couponSlice'
import sserviceReducer from '@/pages/Admin/SService/sserviceSlice'
import dataAnalysisReducer from '@/pages/Admin/DataAnalysis/dataAnalysisSlice'
import clientReducer from '@/pages/Admin/ManageUser/ClientType/clientSlice'
import placeOrderReducer from '@/pages/Admin/PlaceOrder/placeOrderSlice'
import gutComScheduleReducer from '@/pages/Admin/GutComSchedule/gutComScheduleSlice'

import warehouseReducer from '@/pages/Admin/InventoryManagement/Warehouse/warehouseSlice'
import productReducer from '@/pages/Admin/Product/productSlice'
import expenseReducer from '@/pages/Admin/InventoryManagement/Expense/expenseSlice'
import dashboardReducer from '@/pages/Admin/Dashboard/dashboardSlice'
import clinicReducer from '@/pages/Admin/ManageUser/Clinic/clinicSlice'
import formBuilderReducer from '@/pages/Admin/FormBuilder/formBuilderSlice'
import chatReducer from '@/pages/Admin/Chat/chatSlice'
import dentalChartReducer from '@/pages/Admin/Patient/Dental/dentalChartSlice'
import patientDashboardReducer from '@/pages/Admin/Patient/Dashboard/patientDashboardSlice'
import healthReducer from '@/pages/Admin/Patient/Health/healthSlice'
import tenantReducer from '@/pages/Admin/Tenant/tenantSlice'

const rootReducer = combineReducers({
  setting: settingReducer,
  theme: themeReducer,
  breadcrumb: breadcrumbReducer,
  common: commonReducer,
  auth: authReducer,
  alert: alertReducer,
  cdialog: cdialogReducer,
  dashboard: dashboardReducer,
  user: userReducer,
  tenant: tenantReducer,
  role: roleReducer,
  patientDashboard: patientDashboardReducer,
  profile: profileReducer,
  patient: patientReducer,
  noteTemplate: noteReducer,
  chart: chartReducer,
  previsit: previsitReducer,
  intake: intakeReducer,
  appointment: appointmentReducer,
  order: orderReducer,
  physician: physicianReducer,
  file: fileReducer,
  lab: labReducer,
  message: messageReducer,
  billing: billingReducer,
  ordering_physician: opReducer,
  referral: referralReducer,
  medicine: medicineReducer,
  ack: AckReducer,
  dictation: dictationReducer,
  consent: consentReducer,
  ibsJournal: ibsJournalReducer,
  family_tree: familyTreeReducer,
  activity: activityReducer,
  task: taskReducer,
  trash: trashReducer,
  followUp: followUpReducer,
  beforeAfter: beforeAfterReducer,
  prescription: prescriptionReducer,
  health: healthReducer,
  talkToMe: talkToMeReducer,
  sellerSite: sellerSiteReducer,
  pharmacy: pharmacyReducer,
  document: documentResendReducer,
  intakeType: intakeTypeReducer,
  support: supportReducer,
  financial: financialReducer,
  consultation: consultationSlice,
  gutClinic: gutClinicReducer,
  lead: leadsReducer,
  category: categoryReducer,
  longevityCategory: longevityCategoryReducer,
  sservice: sserviceReducer,
  placeOrder: placeOrderReducer,
  tag: tagReducer,
  fileCategory: fileCategoryReducer,
  clamService: clamServiceReducer,
  paidConsultation: paidConsultationReducer,
  inventoryCategory: inventoryCategoryReducer,
  inventorySubCategory: inventorySubCategoryReducer,
  inventoryUser: inventoryUserReducer,
  expenseCategory: expenseCategoryReducer,
  gutCommunicationCategory: gutCommunicationCategoryReducer,
  gutComSchedule: gutComScheduleReducer,
  orderInvoice: orderInvoiceReducer,
  dictationTemplate: dictationTemplateReducer,
  inventoryNoteTemplate: noteTemplateReducer,
  inventoryMailTemplate: mailTemplateReducer,
  inventoryConsentTemplate: consentTemplateReducer,
  acknowledge: acknowledgeReducer,
  inventoryTask: inventoryTaskReducer,
  websiteBlog: blogReducer,
  websiteNews: newsReducer,
  websiteBanner: bannerReducer,
  websiteSeo: seoReducer,
  securityQuestion: securityQuestionReducer,
  province: provinceReducer,
  giftCard: giftCardReducer,
  coupon: couponReducer,
  department: departmentReducer,
  floor: floorReducer,
  room: roomReducer,
  dataAnalysis: dataAnalysisReducer,
  clientType: clientReducer,
  warehouse: warehouseReducer,
  product: productReducer,
  expense: expenseReducer,
  clinic: clinicReducer,
  formBuilder: formBuilderReducer,
  chat: chatReducer,
  dentalChart: dentalChartReducer,
})

export default rootReducer
