import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'

import SendOutlinedIcon from '@mui/icons-material/SendOutlined'
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material'
import CDialog from '../../../../components/CDialog'
import CButton from '../../../../components/CButton'
import CTextField from '../../../../components/CTextField'
import CEditor from '../../../../components/CEditor'
import { showError, showSuccess } from '../../../../redux/Slice/alertSlice'
import SaveIcon from '@mui/icons-material/Save'
import { list, update } from './expenseSlice'
import CCheckbox from '@/components/CCheckbox'
import CAutocomplete from '@/components/CAutocomplete'
import CDatePicker from '@/components/CDatePicker'
import { formatDateTime } from '@/utils/cdayjs'
import dayjs from 'dayjs'

const ExpenseUpdate = ({ expenseInfo }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  console.log(expenseInfo)
  //   initial create value
  const [expense, setExpense] = useState({
    id: expenseInfo.id,
    category_type: expenseInfo.category_type,
    category_id: expenseInfo.category_id,
    category: expenseInfo.category,
    staff_id: expenseInfo.staff_id,
    staff: expenseInfo.staff,
    expense_from: expenseInfo?.from ? dayjs(expenseInfo?.from) : null,
    expense_to: expenseInfo?.to ? dayjs(expenseInfo?.to) : null,
    expense_amount: expenseInfo.price,
    expense_attachment: [],
    description: expenseInfo.description,
  })

  const handleCDialogOpen = () => {
    setOpen(true)
  }
  const handleCDialogClose = () => {
    setOpen(false)
    setExpense({
      ...expense,
      category_type: 'travel',
      category_id: null,
      category: null,
      staff_id: null,
      staff: null,
      expense_from: '',
      expense_to: '',
      expense_amount: '',
      expense_attachment: [],
      description: '',
    })
  }
  const { staffs, expenses, categories, errors, isLoading } = useSelector(
    (state) => state.expense
  )

  // submit form
  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const res = await dispatch(update(expense))

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        dispatch(list())
        setOpen(false)
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Server error:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred while updating the expense .',
        })
      )
    }
  }

  // onChange handler to update the local state
  const handleInputChange = (event) => {
    const { name, value } = event.target
    // Update the local state with the changed value
    setExpense((expense) => ({
      ...expense,
      [name]: value,
    }))
  }
  return (
    <CDialog
      btnTitle="Edit"
      headerTitle=""
      handleCDialogOpen={handleCDialogOpen}
      handleCDialogClose={handleCDialogClose}
      open={open}
    >
      <Box maxWidth="50rem" minWidth="50rem" pb={4}>
        <form onSubmit={handleSubmit}>
          <Box>
            {' '}
            <Stack sx={{ float: 'left' }}>
              {' '}
              <Typography variant="h5">Create Expense</Typography>{' '}
            </Stack>
            <Stack
              direction="row"
              justifyContent="flex-end"
              mb={2}
              mt={2}
              mr={1.3}
            >
              <CButton label="Update" icon={<SaveIcon />} type="submit" />
            </Stack>
          </Box>
          <hr />
          <Grid container spacing={2} alignItems="center" mt={2}>
            <Grid xs={12} item>
              <FormControl>
                <RadioGroup
                  row
                  name="category_type"
                  value={expense?.category_type ?? 'travel'}
                  onChange={handleInputChange}
                >
                  <FormControlLabel
                    value="travel"
                    control={<Radio />}
                    label="Travel"
                  />
                  <FormControlLabel
                    value="others"
                    control={<Radio />}
                    label="Others"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <CAutocomplete
                options={categories}
                getOptionLabel={(option) => option.name}
                value={expense?.category}
                onChange={(e, value) =>
                  // console.log(value)
                  setExpense({
                    ...expense,
                    category: value,
                    category_id: value.id,
                  })
                }
                label="Category"
                error={!!(errors && errors.category_id)}
                helperText={errors && errors.category_id}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <CAutocomplete
                options={staffs}
                getOptionLabel={(option) => option.full_name}
                value={expense?.staff}
                onChange={(e, value) =>
                  // console.log(value)
                  setExpense({
                    ...expense,
                    staff: value,
                    staff_id: value.id,
                  })
                }
                label="Staffs"
                error={!!(errors && errors.staff_id)}
                helperText={errors && errors.staff_id}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              display={expense?.category_type != 'travel' ? 'none' : ''}
            >
              <CDatePicker
                label="From"
                name="expense_from"
                value={expense.expense_from}
                onChange={(value) =>
                  setExpense({
                    ...expense,
                    expense_from: formatDateTime(dayjs(value)),
                  })
                }
                error={!!(errors && errors.expense_from)}
                helperText={errors && errors.expense_from}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              display={expense?.category_type != 'travel' ? 'none' : ''}
            >
              <CDatePicker
                label="To"
                name="expense_to"
                value={expense.expense_to}
                onChange={(value) =>
                  setExpense({
                    ...expense,
                    expense_to: formatDateTime(dayjs(value)),
                  })
                }
                error={!!(errors && errors.expense_to)}
                helperText={errors && errors.expense_to}
              />
            </Grid>

            <Grid xs={6} item>
              <CTextField
                label="Amount"
                name="expense_amount"
                type="number"
                value={expense?.expense_amount}
                error={errors && errors.expense_amount}
                helperText={errors && errors.expense_amount}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel>Receipt/Invoice</InputLabel>
              <CTextField
                name="expense_attachment"
                type="file"
                fullWidth
                variant="outlined"
                onChange={(e) =>
                  setExpense({ ...expense, file_image: e.target.files[0] })
                }
                // error={!!(errors && errors.file_image)}
                // helperText={errors && errors.file_image}
              />
            </Grid>

            <Grid item xs={12} mb={6}>
              <InputLabel>Description</InputLabel>
              <CEditor
                value={expense.description}
                handleChange={(value) =>
                  setExpense({
                    ...expense,
                    description: value,
                  })
                }
              />
            </Grid>
          </Grid>
        </form>
      </Box>
    </CDialog>
  )
}

export default ExpenseUpdate
