import { FormLabel, MenuItem } from '@mui/material'
import TextField from '@mui/material/TextField'

export default function CSelect({
  name,
  value,
  error,
  label,
  helperText,
  autoFocus,
  className,
  placeholder,
  required = false,
  variant = 'outlined',
  margin,
  size = 'small',
  onChange = null,
  disabled = false,
  options = null,
}) {
  return (
    <>
      <FormLabel htmlFor={`${label}${name}`}>{label}</FormLabel>
      <TextField
        id={`${label}${name}`}
        select
        name={name}
        value={value ?? ''}
        error={error}
        helperText={helperText}
        fullWidth

        autoFocus={autoFocus}
        className={`${className}`}
        placeholder={placeholder}
        required={required}
        variant={variant}
        size={size}
        disabled={disabled}
        onChange={onChange}
        sx={{
          '& .MuiSelect-select .notranslate::after': placeholder
            ? {
                content: `"${placeholder}"`,
                opacity: 0.42,
              }
            : {},
              // mt: 3
        }}
      >
        {options?.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </>
  )
}
