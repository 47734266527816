// import { hasPerm, retrieveData } from '@/utils/cutils'
import { currentUser, hasPerm } from '@/utils/cutils'
import {
  IconUserSquare,
  IconClipboardData,
  IconHome,
  IconUserFilled,
  IconMessageDots,
} from '@tabler/icons-react'

const doctorMenu = [
  hasPerm('feeds.main') && {
    menuLabel: false,
    label: 'Dashboard',
    icon: <IconHome />,
    path: '/dashboard',
  },
  {
    menuLabel: false,
    label: 'Patient List',
    icon: <IconUserSquare />,
    path: '/patient/list',
  },
  {
    menuLabel: false,
    label: 'Schedule',
    icon: <IconClipboardData />,
    path: '/schedule',
  },
  {
    menuLabel: false,
    label: 'Chat',
    icon: <IconMessageDots />,
    path: '/chat/',
  },
  {
    menuLabel: false,
    label: 'Claim Service',
    icon: <IconClipboardData />,
    path: '/clam-service/list',
  },

  {
    menuLabel: true,
    label: 'Orders / Appointments',
  },

  {
    menuLabel: false,
    label: 'Form Builder',
    icon: <IconClipboardData />,
    path: '/form-builder/list',
  },

  {
    menuLabel: true,
    label: 'Manage Users',
  },

  {
    menuLabel: false,
    label: 'Referral',
    icon: <IconUserFilled />,
    path: '/referral/list',
  },

  {
    menuLabel: false,
    label: 'My Schedule',
    icon: <IconClipboardData />,
    path: `/physician/schedule/${currentUser?.user_info_id}`,
  },

  {
    menuLabel: false,
    label: 'Support',
    icon: <IconUserFilled />,
    path: '/support/list',
  },
].filter((item) => item !== false)

export default doctorMenu
