import React, { useEffect, useState } from 'react'
import { Card, Grid, Stack } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import CTextField from '../../../../components/CTextField'
import CButton from '../../../../components/CButton'
import { setBreadcrumb } from '../../../../redux/Slice/breadcrumbSlice'
import { showError, showSuccess } from '../../../../redux/Slice/alertSlice'
import { create, update, fetchPharmacyUpdateInfo } from '../pharmacySlice'

const PharmacyUpdate = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pharmacyId } = useParams()
  const { errors, isLoading } = useSelector((state) => state.pharmacy)
  const pharmacyFromRedux = useSelector((state) => state.pharmacy.pharmacy)
  const [pharmacy, setPharmacy] = useState(pharmacyFromRedux)

  useEffect(() => {
    dispatch(fetchPharmacyUpdateInfo(pharmacyId))
  }, [])

  useEffect(() => {
    setPharmacy(pharmacyFromRedux)
  }, [pharmacyFromRedux])
  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        { path: '/pharmacy/list', label: 'Pharmacy', isLast: false },
        { path: '', label: 'Create', isLast: true },
      ])
    )
  }, [dispatch])

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      const res = await dispatch(
        // Wait for the pharmacy update action to complete.
        update({
          id: pharmacy?.id,
          first_name: pharmacy?.first_name,
          last_name: pharmacy?.last_name,
          title: pharmacy?.title,
          email: pharmacy?.email,
          fax_number: pharmacy?.fax_number,
          address: pharmacy?.address,
        })
      )
      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        navigate('/pharmacy/list')
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      // Handle any errors that occur during the login action dispatch
      console.error('Server error:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred during creating user.',
        })
      )
    }
  }
  useEffect(() => {
    dispatch(fetchPharmacyUpdateInfo(pharmacyId))
  }, [pharmacyId])
  return (
    <>
      <Helmet>
        <title>Bioaro | Pharmacy - Create</title>
      </Helmet>
      <Stack direction="row" justifyContent="center">
        <Card sx={{ p: 3, maxWidth: '70rem' }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={6}>
                <CTextField
                  label="First Name"
                  name="first_name"
                  value={pharmacy?.first_name}
                  onChange={(e) =>
                    setPharmacy({ ...pharmacy, first_name: e.target.value })
                  }
                  error={!!(errors && errors.first_name)}
                  helperText={errors && errors.first_name}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CTextField
                  label="Last Name"
                  name="last_name"
                  value={pharmacy?.last_name}
                  onChange={(e) =>
                    setPharmacy({ ...pharmacy, last_name: e.target.value })
                  }
                  error={!!(errors && errors.last_name)}
                  helperText={errors && errors.last_name}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <CTextField
                  label="Pharmacy Title"
                  name="title"
                  value={pharmacy?.title}
                  onChange={(e) =>
                    setPharmacy({ ...pharmacy, title: e.target.value })
                  }
                  error={!!(errors && errors.title)}
                  helperText={errors && errors.title}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <CTextField
                  label="Email"
                  name="email"
                  value={pharmacy?.email}
                  onChange={(e) =>
                    setPharmacy({ ...pharmacy, email: e.target.value })
                  }
                  error={!!(errors && errors.email)}
                  helperText={errors && errors.email}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CTextField
                  name="fax_number"
                  label="Fax Number"
                  type="text"
                  value={pharmacy?.fax_number}
                  onChange={(e) =>
                    setPharmacy({ ...pharmacy, fax_number: e.target.value })
                  }
                  error={!!(errors && errors.fax_number)}
                  helperText={errors && errors.fax_number}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CTextField
                  name="address"
                  label="Address"
                  type="text"
                  value={pharmacy?.address}
                  onChange={(e) =>
                    setPharmacy({ ...pharmacy, address: e.target.value })
                  }
                />
              </Grid>
            </Grid>
            <Stack direction="row" justifyContent="flex-end" mb={2} mt={2}>
              <CButton label="Update" icon={<SaveIcon />} type="submit" />
            </Stack>
          </form>
        </Card>
      </Stack>
    </>
  )
}

export default PharmacyUpdate
