// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '@/services/axiosConfig'

// Fetch user list
export const list = createAsyncThunk('inventory/user/list', async () => {
  const response = await axiosInstance.get('inventory/user/list')
  return response.data
})

// Fetch user info
export const userInfo = createAsyncThunk(
  'inventory/user/info',
  async (userId) => {
    const response = await axiosInstance.get(`inventory/user/info/${userId}`)
    console.log(response.data)
    return response.data
  }
)

// Fetch user list
export const create = createAsyncThunk('inventory/user/create', async (data) => {
  const response = await axiosInstance.post('inventory/user/create', data)
  return response.data
})

// Fetch user list
export const update = createAsyncThunk('inventory/user/update', async (data) => {
  const response = await axiosInstance.post('inventory/user/update', data)
  return response.data
})

const inventoryUserSlice = createSlice({
  name: 'inventoryUser',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    users: [],
    info: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      //User List
      .addCase(list.pending, (state) => {
        state.isLoading = true
      })
      .addCase(list.fulfilled, (state, { payload } = action) => {
        state.users = payload.users
        state.isLoading = false
      })

      .addCase(list.rejected, (state) => {
        state.isLoading = false
      })

      //User info
      .addCase(userInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(userInfo.fulfilled, (state, { payload } = action) => {
        state.info = payload.info
        state.isLoading = false
      })

      .addCase(userInfo.rejected, (state) => {
        state.isLoading = false
      })

      //User create
      .addCase(create.pending, (state) => {
        state.isLoading = true
      })
      .addCase(create.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(create.rejected, (state) => {
        state.isLoading = false
      })

      //User update
      .addCase(update.pending, (state) => {
        state.isLoading = true
      })
      .addCase(update.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(update.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default inventoryUserSlice.reducer
