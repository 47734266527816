import React from 'react'
import { useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'

export default function Breadcrumb() {
  const breadcrumb = useSelector((state) => state.breadcrumb);

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {breadcrumb.map((item, index) => {
        const { path, label, isLast } = item;

        return (
          <div key={index}>
            {isLast ? (
              <Typography color="text.primary" sx={{ fontSize: {} }}>
                {label}
              </Typography>
            ) : (
              <Link
                key={index}
                component={RouterLink}
                underline="hover"
                color="inherit"
                to={path}
              >
                {label}
              </Link>
            )}
          </div>
        );
      })}
    </Breadcrumbs>
  );
}

