import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Card, Grid, Stack } from '@mui/material'
import CTextField from '../../../components/CTextField'
import CAutocomplete from '../../../components/CAutocomplete'
import CButton from '../../../components/CButton'
import SaveIcon from '@mui/icons-material/Save'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setBreadcrumb } from '../../../redux/Slice/breadcrumbSlice'
import { create, fetchSellerSiteInfo, fetchSellerSiteList } from './sellerSiteSlice'
import { showError, showSuccess } from '@/redux/Slice/alertSlice'

const SellerSiteCreate = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { sellerSiteInfo, errors } = useSelector((state) => state.sellerSite)

  const [sellerSite, setSellerSite] = useState({
    title: '',
    service_id: null,
    services: [],
    link: '',
  })

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        { path: '/seller-site/list', label: 'Seller Site', isLast: false },
        { path: '', label: 'Create', isLast: true },
      ])
    )
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchSellerSiteInfo())
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      const res = await dispatch(
        // Wait for the user create action to complete.
        create(sellerSite)
      )

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        dispatch(fetchSellerSiteList())
        navigate('/seller-site/list')
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      // Handle any errors that occur during the login action dispatch
      console.error('Server error:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred during creating user.',
        })
      )
    }
  }

  return (
    <>
      <Helmet>
        <title>Bioaro | Seller Site - Create</title>
      </Helmet>
      <Stack direction="row" justifyContent="center">
        <Card sx={{ p: 3, maxWidth: '50rem' }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <CTextField
                  label="Title"
                  required
                  autoFocus
                  error={!!(errors && errors.title)}
                  helperText={errors && errors.title}
                  onChange={(e, value) =>
                    setSellerSite({
                      ...sellerSite,
                      title: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <CAutocomplete
                  label="Select Service"
                  options={sellerSiteInfo?.services ?? []}
                  value={sellerSite.services}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id == value.id
                  }
                  multiple={true}
                  required
                  error={!!(errors && errors.service_id)}
                  helperText={errors && errors.service_id}
                  onChange={(e, value) =>
                    setSellerSite({
                      ...sellerSite,
                      service_id: value.map((item) => item.id),
                      services: value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <CTextField
                  label="Link"
                  value={sellerSite.link}
                  error={!!(errors && errors.link)}
                  helperText={errors && errors.link}
                  onChange={(e, value) =>
                    setSellerSite({
                      ...sellerSite,
                      link: e.target.value,
                    })
                  }
                />
              </Grid>
            </Grid>
            <Stack direction="row" justifyContent="flex-end" mb={2}>
              <CButton label="Save" icon={<SaveIcon />} type="submit" />
            </Stack>
          </form>
        </Card>
      </Stack>
    </>
  )
}

export default SellerSiteCreate
