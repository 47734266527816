import PaidConsultationCreate from './partial/PaidConsultationCreate'
import PaidConsultationList from './partial/PaidConsultationList'
import PaidConsultationUpdate from './partial/paidConsultationUpdate'

const paidConsultationRoutes = [
  { path: 'list/', element: <PaidConsultationList /> },
  { path: 'create/', element: <PaidConsultationCreate /> },
  { path: 'update/:consultationId', element: <PaidConsultationUpdate /> },
]

export default paidConsultationRoutes
