import React from 'react'
import { FormControl, FormLabel } from '@mui/material'
import { MobileTimePicker, TimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

export default function CTimePicker({
  name,
  value,
  label,
  autoFocus,
  error,
  helperText,
  mobileTimePicker = true,
  onChange = null,
  size = 'small',
  placeholder,
  ...other
}) {
  const [cleared, setCleared] = React.useState(false)

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FormLabel htmlFor={`${label}${name}`}>{label}</FormLabel>
      <FormControl fullWidth>
        {mobileTimePicker ? (
          <MobileTimePicker
            value={value}
            autoFocus={autoFocus}
            name={name}
            onChange={onChange}
            {...other}
            slotProps={{
              textField: {
                error: error,
                helperText: helperText,
                size: size,
              },
            }}
          />
        ) : (
          <TimePicker
            value={value}
            autoFocus={autoFocus}
            name={name}
            onChange={onChange}
            {...other}

            slotProps={{
              textField: {
                error: error,
                helperText: helperText,
                size: size,
                placeholder: placeholder,
              },
              // field: { clearable: true, onClear: () => setCleared(true) },
            }}
          />
        )}
      </FormControl>
    </LocalizationProvider>
  )
}
