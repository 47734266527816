// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../../services/axiosConfig'

// Fetch pharmacy list
export const fetchDocumentResendList = createAsyncThunk(
  'document-resend/list',
  async () => {
    const response = await axiosInstance.get(`document-resend/list`)
    return response.data
  }
)

export const info = createAsyncThunk('document-resend/info', async () => {
  const response = await axiosInstance.get(`document-resend/info`)
  return response.data
})

//document resend create
export const create = createAsyncThunk(
  'document-resend/create',
  async (data) => {
    const response = await axiosInstance.post(`document-resend/create`, data)
    return response.data
  }
)

//document resend create
export const update = createAsyncThunk(
  'document-resend/update',
  async (data, documentId) => {
    const response = await axiosInstance.post(`document-resend/update`, data)
    return response.data
  }
)

//document resend create
export const updateInfo = createAsyncThunk(
  'document-resend/update-info',
  async (documentId) => {
    const response = await axiosInstance.get(
      `document-resend/update-info/${documentId}`
    )
    return response.data
  }
)

const documentResendSlice = createSlice({
  name: 'document',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    document: null,
    documents: [],
    users: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      // pharmacy list List
      .addCase(fetchDocumentResendList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        fetchDocumentResendList.fulfilled,
        (state, { payload } = action) => {
          state.documents = payload.documents
          state.isLoading = false
        }
      )
      .addCase(fetchDocumentResendList.rejected, (state, { payload }) => {
        state.isLoading = false
      })

      // info
      .addCase(info.pending, (state) => {
        state.isLoading = true
      })
      .addCase(info.fulfilled, (state, { payload } = action) => {
        state.users = payload.users
        state.isLoading = false
      })
      .addCase(info.rejected, (state, { payload }) => {
        state.isLoading = false
      })

      // pharmacy list List
      .addCase(updateInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateInfo.fulfilled, (state, { payload } = action) => {
        state.document = payload.document
        state.isLoading = false
      })
      .addCase(updateInfo.rejected, (state, { payload }) => {
        state.isLoading = false
      })
  },
})

export default documentResendSlice.reducer
