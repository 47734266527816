// // UserList.jsx

// Icon
import AddIcon from '@mui/icons-material/Add'
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Grid,
  InputLabel,
  Stack,
  Typography,
} from '@mui/material'
// Components
import AdminLayout from '@/layouts/AdminLayout'

// Slice
import { setBreadcrumb } from '@/redux/Slice/breadcrumbSlice'
import { Link, useNavigate } from 'react-router-dom'
import CButton from '@/components/CButton'
import CDatatable from '@/components/CDatatable'
import { changeStatus, deleteRecord } from '@/redux/Slice/commonSlice'
import { showError, showSuccess } from '@/redux/Slice/alertSlice'
import CTextField from '@/components/CTextField'
import SaveIcon from '@mui/icons-material/Save'
import { Helmet } from 'react-helmet-async'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CAutocomplete from '@/components/CAutocomplete'
import { create, list } from '../acknowledgeSlice'
import CEditor from '@/components/CEditor'

export default function AcknowledgeCreate() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { patients, errors, error, isLoading } = useSelector(
    (state) => state.acknowledge
  )
  useEffect(() => {
    dispatch(list())
  }, [])

  console.log(patients)

  //   initial create value
  const [acknowledge, setAcknowledge] = useState({
    note: '',
    patient: null,
    patient_id: '',
  })
  console.log(patients)

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        {
          path: 'inventory/acknowledge/list',
          label: 'Acknowledge',
          isLast: false,
        },
        { path: '', label: 'Create', isLast: true },
      ])
    )
  }, [dispatch])

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const res = await dispatch(create(acknowledge))

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )

        navigate('/inventory/acknowledge/list')
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Server error:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred while Creating Acknowledge service.',
        })
      )
    }
  }

  return (
    <>
      <Helmet>
        <title>Bioaro | Acknowledge Create</title>
      </Helmet>
      <Stack direction="row" sx={{ pr: 4, width: '100%', pb: 2 }}>
        <Card sx={{ p: 3, width: '100%' }}>
          <form onSubmit={handleSubmit}>
            <Box borderBottom={2} mb={4}>
              {' '}
              <Stack sx={{ float: 'left' }}>
                {' '}
                <Typography variant="h5" sx={{ fontWeight: 'bolder' }}>
                  Acknowledge Create
                </Typography>{' '}
              </Stack>
              <Stack
                direction="row"
                justifyContent="flex-end"
                mb={2}
                mt={2}
                mr={1.3}
              >
                <CButton label="Save" icon={<SaveIcon />} type="submit" />
              </Stack>
            </Box>

            <Grid
              container
              spacing={2}
              alignItems="center"
              width="60rem"
              sx={{ alignContent: 'center', margin: 'auto' }}
            >
              <Grid item xs={12} ml={0} mb={6}>
                <InputLabel>Note</InputLabel>
                <CEditor
                  value={acknowledge?.note}
                  handleChange={(value) =>
                    setAcknowledge({
                      ...acknowledge,
                      note: value,
                    })
                  }
                />
              </Grid>
              <Grid xs={12}>
                <CAutocomplete
                  options={patients}
                  getOptionLabel={(option) => option.full_name}
                  value={acknowledge?.patient}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(e, value) =>
                    // console.log(value)
                    setAcknowledge({
                      ...acknowledge,
                      patient: value,
                      patient_id: value.id,
                    })
                  }
                  label="Patient"
                  error={!!(errors && errors.parent_id)}
                  helperText={errors && errors.parent_id}
                />
              </Grid>
            </Grid>
          </form>
        </Card>
      </Stack>
    </>
  )
}
