import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'

const CBarChart = ({ data, labelKey, dataKey, height=400 }) => {
  return (
    <ResponsiveContainer width="100%" height={height}>
      <BarChart
        data={data}
        margin={{ top: 20, right: 30, left: 0, bottom: 70 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey={labelKey}
          angle={-45}
          dy={10}
          textAnchor="end"
          tick={{ fontSize: 12 }}
        />
        <YAxis />
        <Tooltip />
        <Legend
          verticalAlign="top"
          align="center"
          height={36}
          layout="vertical"
        />
        <Bar dataKey={dataKey} fill="#2196f3" />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default CBarChart
