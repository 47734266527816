import { Link, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Button from '@mui/material/Button'
import AdminLayout from '@/layouts/AdminLayout'
import CTextField from '@/components/CTextField'
import SaveIcon from '@mui/icons-material/Save'

import { fileCategoryInfo, update } from '../fileCategorySlice'
import { setBreadcrumb } from '@/redux/Slice/breadcrumbSlice'
import { Card, Grid, Stack, InputLabel } from '@mui/material'
import CSelect from '@/components/CSelect'
import CButton from '@/components/CButton'
import { showError, showSuccess } from '@/redux/Slice/alertSlice'
import CEditor from '@/components/CEditor'
import { Save } from '@mui/icons-material'
import CAutocomplete from '@/components/CAutocomplete'
import { Helmet } from 'react-helmet-async'

const FileCategoryUpdate = () => {
  const dispatch = useDispatch()
  const { fileCategoryId } = useParams()
  const navigate = useNavigate()
  const { isLoading, errors } = useSelector((state) => state.fileCategory)

  const fileCategoryFromRedux = useSelector((state) => state.fileCategory.info)

  const [fileCategory, setCategory] = useState(fileCategoryFromRedux)
  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        { path: '/file-category/list', label: 'File Category', isLast: false },
        { path: '', label: 'Update', isLast: true },
      ])
    )
  }, [dispatch])

  // Initialize local state with the data from Redux

  useEffect(() => {
    dispatch(fileCategoryInfo(fileCategoryId))
    setCategory(fileCategoryFromRedux)
  }, [fileCategoryId])

  useEffect(() => {
    setCategory(fileCategoryFromRedux)
  }, [fileCategoryFromRedux])

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const res = await dispatch(update(fileCategory))
      console.log(res)

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        navigate('/file-category/list')
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Server error:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred while updating the file-category.',
        })
      )
    }
  }

  // onChange handler to update the local state
  const handleInputChange = (event) => {
    const { name, value } = event.target
    // Update the local state with the changed value
    setCategory((fileCategory) => ({
      ...fileCategory,
      [name]: value,
    }))
  }
  useEffect(() => {
    setCategory(fileCategoryFromRedux)
  }, [fileCategoryFromRedux])

  const categoryType = [
    {
      id: 1,
      name: 'Lab Category',
    },
    {
      id: 2,
      name: 'File Category',
    },
  ]
  console.log(fileCategory)
  return (
    <>
      <Helmet>
        <title>Bioaro | File Category - update</title>
      </Helmet>
      {fileCategory ? (
        <Stack direction="row" justifyContent="center">
          <Card sx={{ p: 4, maxWidth: '70rem' }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} alignItems="center">
                <Grid xs={12} m={1}>
                  <CTextField
                    label="Name"
                    name="name"
                    value={fileCategory?.name}
                    error={errors && errors.name}
                    helperText={errors && errors.name}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CAutocomplete
                    options={categoryType}
                    value={fileCategory?.type}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    getOptionLabel={(option) => option.name}
                    onChange={(e, value) =>
                      setCategory({ ...fileCategory, fileCategory: value.id })
                    }
                    label="Forms"
                    error={!!(errors && errors.fileCategory)}
                    helperText={errors && errors.fileCategory}
                  />
                </Grid>
              </Grid>
              <Stack direction="row" justifyContent="flex-end" mb={2} mt={2}>
                <CButton label="Save" icon={<SaveIcon />} type="submit" />
              </Stack>
            </form>
          </Card>
        </Stack>
      ) : null}
    </>
  )
}

export default FileCategoryUpdate
