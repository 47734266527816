import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Card, CardContent, Stack, Typography } from '@mui/material'
import CDialog from '@/components/CDialog'

const ProductDetails = ({ productInfo }) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)

  const handleCDialogOpen = () => {
    setOpen(true)
  }
  const handleCDialogClose = () => {
    setOpen(false)
    setProduct({
      ...product,
      id: productInfo.id,
      name: productInfo.name,
      phone1: productInfo.phone,
      address: productInfo?.address,
      user: productInfo?.siteUser ?? [],
    })
  }

  return (
    <CDialog
      btnTitle="Detail"
      headerTitle="Detail"
      handleCDialogOpen={handleCDialogOpen}
      handleCDialogClose={handleCDialogClose}
      open={open}
    >
      <Box maxWidth="40rem" minWidth="40rem" pb={4}>
        <Card>
          <CardContent>
            {productInfo.length > 0 &&
              productInfo.map((product, index) => (
                <div key={index}>
                  <Typography variant="h5">
                    Site: {product.site.name}
                  </Typography>
                  <Typography variant="h5">
                    Qty: {product.remain_qty}
                  </Typography>
                  <Typography variant="h5">
                    Notify Qty: {product.notify_qty}
                  </Typography>
                </div>
              ))}
          </CardContent>
        </Card>
      </Box>
    </CDialog>
  )
}

export default ProductDetails
