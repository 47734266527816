// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../../../services/axiosConfig'

// Fetch Patient Based File List
export const fetchDictationList = createAsyncThunk(
  'patient/dictation/list',
  async (patientId) => {
    const response = await axiosInstance.get(
      `patient/dictation/list/${patientId}`
    )
    return response.data
  }
)

// Patient Based Dictation Create
export const create = createAsyncThunk(
  'patient/dictation/create',
  async ({ patientId, data }) => {
    const response = await axiosInstance.post(
      `patient/dictation/create/${patientId}`,
      data
    )
    return response.data
  }
)

// Patient Based Dictation Update
export const update = createAsyncThunk(
  'patient/dictation/update',
  async ({ dictationId, data }) => {
    const response = await axiosInstance.post(
      `patient/dictation/update/${dictationId}`,
      data
    )
    return response.data
  }
)

export const fetchDictation = createAsyncThunk(
  'patient/dictation/detail',
  async (dictationId) => {
    const response = await axiosInstance.get(
      `patient/dictation/detail/${dictationId}`
    )

    return response.data
  }
)

// Change dictation is_locked status
export const updateIsLock = createAsyncThunk(
  'patient/dictation/change-lock-status',
  async (data) => {
    const response = await axiosInstance.post(
      'patient/dictation/change-lock-status',
      data
    )
    return response.data
  }
)

// sendFax
export const sendFax = createAsyncThunk(
  'patient/dictation/send-fax',
  async ({ dictationId, data }) => {
    const response = await axiosInstance.post(
      `patient/dictation/send-fax/${dictationId}`,
      data
    )
    return response.data
  }
)

// Print dictation
export const dictationPrint = createAsyncThunk(
  'patient/dictation/print',
  async (dictationId) => {
    const response = await axiosInstance.get(
      `patient/dictation/print/${dictationId}`,
      {
        responseType: 'blob',
      }
    )
    return response.data
  }
)

// Download dictation
export const dictationDownload = createAsyncThunk(
  'patient/dictation/download',
  async (dictationId) => {
    const response = await axiosInstance.get(
      `patient/dictation/download/${dictationId}`,
      {
        responseType: 'blob',
      }
    )
    return response.data
  }
)

// send Email
export const sendEmail = createAsyncThunk(
  'patient/dictation/send-email',
  async ({ dictationId, data }) => {
    const response = await axiosInstance.post(
      `patient/dictation/send-email/${dictationId}`,
      data
    )
    return response.data
  }
)

// Fetch Fax and Email log list by dictation id
export const fetchFaxAndEmailLogList = createAsyncThunk(
  'patient/dictation/fax-and-email-log-list',
  async (dictationId) => {
    const response = await axiosInstance.get(
      `patient/dictation/fax-and-email-log-list/${dictationId}`
    )
    return response.data
  }
)

// Dictation trash (temporary delete it can be restored)
export const dictationTrash = createAsyncThunk(
  'patient/dictation/trash',
  async (dictationId) => {
    const response = await axiosInstance.get(
      `patient/dictation/trash/${dictationId}`
    )
    return response.data
  }
)

const dictationSlice = createSlice({
  name: 'dictation',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    dictations: [],
    physicians: [],
    doctors: [],
    labCategories: [],
    dictation: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch  patient based dictation list
      .addCase(fetchDictationList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchDictationList.fulfilled, (state, { payload }) => {
        state.dictations = payload.dictations
        state.physicians = payload.physicians
        state.doctors = payload.doctors
        state.isLoading = false
      })
      .addCase(fetchDictationList.rejected, (state) => {
        state.isLoading = false
      })
      // Patient based dictation  Create
      .addCase(create.pending, (state) => {
        state.isLoading = true
      })
      .addCase(create.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(create.rejected, (state) => {
        state.isLoading = false
      })

      // Patient based dictation  Update
      .addCase(update.pending, (state) => {
        state.isLoading = true
      })
      .addCase(update.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(update.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch  dictation detail
      .addCase(fetchDictation.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchDictation.fulfilled, (state, { payload } = action) => {
        state.dictation = payload.dictation

        state.isLoading = false
      })
      .addCase(fetchDictation.rejected, (state) => {
        state.isLoading = false
      })

      // fetch Fax and Email log list
      .addCase(fetchFaxAndEmailLogList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchFaxAndEmailLogList.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.faxLogs = payload.faxLogs
        state.emailLogs = payload.emailLogs
      })
      .addCase(fetchFaxAndEmailLogList.rejected, (state) => {
        state.isLoading = false
      })
      // File Trash
      .addCase(dictationTrash.pending, (state) => {
        state.isLoading = true
      })
      .addCase(dictationTrash.fulfilled, (state, action) => {
        state.message = action.payload.message
        state.isLoading = false
      })
      .addCase(dictationTrash.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default dictationSlice.reducer
