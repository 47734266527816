import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '@/services/axiosConfig'

import axios from 'axios'

const PREFIX = 'form-builder'

// Fetch Form Builder List
export const formBuilderList = createAsyncThunk(
  'formBuilder/list',
  async () => {
    const response = await axiosInstance.get(`${PREFIX}/list`)
    return response.data
  }
)

// Fetch Form Builder Detail
export const detailFormBuilder = createAsyncThunk(
  'formBuilder/detail',
  async (formBuilderId) => {
    const response = await axiosInstance.get(
      `${PREFIX}/detail/${formBuilderId}`
    )
    return response.data
  }
)

// Form Builder Create
export const formBuilderCreate = createAsyncThunk(
  'formBuilder/create',
  async ({ data }) => {
    const response = await axiosInstance.post(`${PREFIX}/create`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return response.data
  }
)

// Form Builder Update
export const formBuilderUpdate = createAsyncThunk(
  'formBuilder/update',
  async ({ formBuilderId, data }) => {
    const response = await axiosInstance.post(
      `${PREFIX}/update/${formBuilderId}`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    return response.data
  }
)

// Form Builder Destroy
export const formBuilderDelete = createAsyncThunk(
  'formBuilder/delete',
  async (formBuilderId) => {
    const response = await axiosInstance.post(
      `${PREFIX}/delete/${formBuilderId}`
    )
    return formBuilderId
  }
)

const formBuilderSlice = createSlice({
  name: 'formBuilder',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    elements: [],
    formBuilders: [],
    formBuilder: null,
  },
  reducers: {
    addElement: (state, action) => {
      state.elements.push(action.payload)
    },
    removeElement: (state, action) => {
      state.elements = state.elements.filter(
        (_, index) => index !== action.payload
      )
    },
    updateElement: (state, action) => {
      state.elements[action.payload.index] = action.payload.element
    },
    setElements: (state, action) => {
      state.elements = action.payload
    },
    removeEmptyObject: (state) => {
      state.elements = state.elements.filter(
        (item) => Object.keys(item).length !== 0
      )
    },
    moveElement: (state, action) => {
      const { dragIndex, hoverIndex } = action.payload
      const elements = [...state.elements] // Create a shallow copy of elements
      const [draggedElement] = elements.splice(dragIndex, 1) // Remove the dragged element
      elements.splice(hoverIndex, 0, draggedElement) // Insert the dragged element at the hover index
      state.elements = elements.filter((item) => Object.keys(item).length !== 0) // Call removeEmptyObject to filter out any empty objects
    },
  },
  extraReducers: (builder) => {
    builder
      // Form Builder create
      .addCase(formBuilderCreate.pending, (state) => {
        state.isLoading = true
      })
      .addCase(formBuilderCreate.fulfilled, (state, { payload }) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(formBuilderCreate.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch Form Builder list
      .addCase(formBuilderList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(formBuilderList.fulfilled, (state, { payload }) => {
        state.formBuilders = payload
        state.isLoading = false
      })
      .addCase(formBuilderList.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch Form Builder detail
      .addCase(detailFormBuilder.pending, (state) => {
        state.isLoading = true
      })
      .addCase(detailFormBuilder.fulfilled, (state, { payload }) => {
        state.formBuilder = payload
        state.isLoading = false
      })
      .addCase(detailFormBuilder.rejected, (state) => {
        state.isLoading = false
      })

      // Form Builder update
      .addCase(formBuilderUpdate.pending, (state) => {
        state.isLoading = true
      })
      .addCase(formBuilderUpdate.fulfilled, (state, { payload }) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(formBuilderUpdate.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export const {
  addElement,
  removeElement,
  updateElement,
  setElements,
  moveElement,
  removeEmptyObject,
} = formBuilderSlice.actions

export default formBuilderSlice.reducer
