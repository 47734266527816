import React from 'react'
import DraggableElement from './DraggableElement'
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
} from '@mui/material'
import TextFieldsIcon from '@mui/icons-material/TextFields'
import TitleIcon from '@mui/icons-material/Title'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import DateRangeIcon from '@mui/icons-material/DateRange'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import InputIcon from '@mui/icons-material/Input'
import ListIcon from '@mui/icons-material/List'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import ShortTextIcon from '@mui/icons-material/ShortText'
import NotesIcon from '@mui/icons-material/Notes'
import ButtonIcon from '@mui/icons-material/TouchApp'
import NumbersIcon from '@mui/icons-material/Numbers'
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule'
import { addElement } from '../formBuilderSlice'
import { useDispatch } from 'react-redux'

const elements = [
  // {
  //   key: 'autocomplete',
  //   label: 'Autocomplete',

  //   value: { label: 'Option 1', value: 'option-1' },
  //   options: [
  //     { label: 'Option 1', value: 'option-1' },
  //     { label: 'Option 2', value: 'option-2' },
  //     { label: 'Option 3', value: 'option-3' },
  //   ],
  //   grid: 12,
  //   icon: <ShortTextIcon />,
  // },
  {
    key: 'button',
    label: 'Button',
    variant: 'contained',
    grid: 12,
    icon: <ButtonIcon />,
  },
  {
    key: 'checkbox',
    label: 'Checkbox',
    checked: false,
    grid: 12,
    icon: <CheckBoxIcon />,
  },
  { key: 'date', label: 'Date Field', grid: 12, icon: <DateRangeIcon /> },
  { key: 'file', label: 'File Upload', grid: 12, icon: <CloudUploadIcon /> },
  {
    key: 'header',
    label: 'Header',
    value: 'Header',
    grid: 12,
    icon: <TitleIcon />,
  },
  {
    key: 'divider',
    label: 'Divider',
    icon: <HorizontalRuleIcon />,
  },
  { key: 'number', label: 'Number', grid: 12, icon: <NumbersIcon /> },
  {
    key: 'paragraph',
    label: 'Paragraph',
    value: 'Paragraph',
    grid: 12,
    icon: <NotesIcon />,
  },
  {
    key: 'radio',
    label: 'Radio Group',
    value: 'option-1',
    options: [
      { label: 'Option 1', value: 'option-1' },
      { label: 'Option 2', value: 'option-2' },
      { label: 'Option 3', value: 'option-3' },
    ],
    grid: 12,
    icon: <RadioButtonCheckedIcon />,
  },
  {
    key: 'select',
    label: 'Select',
    value: 'option-1',
    options: [
      { label: 'Option 1', value: 'option-1' },
      { label: 'Option 2', value: 'option-2' },
      { label: 'Option 3', value: 'option-3' },
    ],
    grid: 12,
    icon: <ListIcon />,
  },
  { key: 'text', label: 'Text Field', value: '', icon: <TextFieldsIcon /> },
  {
    key: 'textarea',
    label: 'Text Area',
    value: '',
    multiline: true,
    rows: 4,
    grid: 12,
    icon: <TextFieldsIcon />,
  },
]

const Sidebar = () => {
  const dispatch = useDispatch()

  const handleAddElement = (element) => {
    dispatch(addElement(element))
  }

  return (
    <Paper sx={{ height: '65vh', overflow: 'auto' }}>
      <List disablePadding>
        {elements.map((element, index) => (
          <ListItemButton key={index} onClick={() => handleAddElement(element)}>
            <ListItemIcon>{element.icon}</ListItemIcon>
            <ListItemText
              primary={<DraggableElement key={index} element={element} />}
            />
          </ListItemButton>
        ))}
      </List>
    </Paper>
  )
}

export default Sidebar
