import * as React from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import { useDispatch, useSelector } from 'react-redux'
import CBadge from '@/components/CBadge'
import { Stack, Typography } from '@mui/material'
import {
  setOnlineUsers,
  setReceiver,
  setIsOnline,
  messageList,
} from '../chatSlice'
import { io } from 'socket.io-client'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime' // Import the plugin

dayjs.extend(relativeTime)
const socketURL = import.meta.env.VITE_SOCKET_URL
const socket = io(socketURL)

function ChatList({ chatList }) {
  const dispatch = useDispatch()
  const { onlineUsers, messages } = useSelector((state) => state.chat)

  const handleUserClick = (item) => {
    const exists = onlineUsers.some((user) => user.id === item.user_id)
    dispatch(setIsOnline(exists))
    dispatch(setReceiver(item))
    item && dispatch(messageList(item.user_id))
  }

  return (
    <List sx={{ height: '60vh', overflow: 'auto' }}>
      {chatList?.map((item, index) => (
        <ListItem
          key={index}
          onClick={() => handleUserClick(item)}
          sx={{ cursor: 'pointer' }}
        >
          <ListItemAvatar>
            <CBadge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant={item.online && 'dot'}
              // color={isOnline(item) ? 'success' : 'error'}
            >
              <Avatar>{item.full_name.charAt(0)}</Avatar>
            </CBadge>
          </ListItemAvatar>
          <ListItemText
            primary={item.full_name}
            secondary={
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body2">
                  {item.lastMessage?.text}
                </Typography>
                <Typography variant="caption">
                  {item.lastMessage &&
                    dayjs(item.lastMessage?.created_at).fromNow()}
                </Typography>
              </Stack>
            }
          />
        </ListItem>
      ))}
    </List>
  )
}

export default React.memo(ChatList)
