import { Box } from '@mui/material'
import React from 'react'

export const renderCell = (value) => (
  <Box
    sx={{
      whiteSpace: 'normal',
      wordBreak: 'break-word',
    }}
  >
    {value}
  </Box>
)
