import { createSlice } from '@reduxjs/toolkit'

const themeSlice = createSlice({
  name: 'theme',
  initialState: {
    mode: 'light',
    drawerOpen: false,
  },
  reducers: {
    setTheme: (state, action) => {
      state.mode = action.payload
    },
    setDrawer: (state, action) => {
      state.drawerOpen = action.payload
    },
  },
})

export const { setTheme, setDrawer } = themeSlice.actions
export default themeSlice.reducer
