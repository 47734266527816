// // UserList.jsx
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Icon
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import AddIcon from '@mui/icons-material/Add'
import { Button, Card, Stack } from '@mui/material'
// Components
import { Helmet } from 'react-helmet-async'
import CDatatable from '../../../components/CDatatable'

// Slice
import { fetchRoles } from '../User/userSlice'
import { setBreadcrumb } from '../../../redux/Slice/breadcrumbSlice'
import { Link } from 'react-router-dom'
import CButton from '../../../components/CButton'

const UserRoles = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        { path: '/role/list', label: 'Role', isLast: false },
        { path: '/role/list', label: 'List', isLast: true },
      ])
    )
  }, [dispatch])

  const { roles, isLoading } = useSelector((state) => state.user)

  const handleQuickFilterValue = (filterModel) => {
    const quickFilterValue = filterModel.quickFilterValues.join(' ')
    console.log(quickFilterValue)
  }

  const columns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'name', headerName: 'Role Name', flex: 1 },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      renderCell: (params) => (
        <Link to={`/role/update/${params.id}`} className="mr-1">
          <VisibilityOutlinedIcon />
        </Link>
      ),
    },
  ]

  useEffect(() => {
    dispatch(fetchRoles())
  }, [])

  return (
    <>
      <Helmet>
        <title>Bioaro | User - Roles</title>
      </Helmet>
      <Card sx={{ p: 3 }}>
        <Stack direction="row" justifyContent="flex-end" sx={{ mb: 2 }}>
          <CButton
            label="Create"
            icon={<AddIcon />}
            component={Link}
            to={`/role/create`}
          />
        </Stack>

        <CDatatable
          rows={roles}
          columns={columns}
          isLoading={isLoading}
          handleQuickFilterValue={handleQuickFilterValue}
        />
      </Card>
    </>
  )
}

export default UserRoles
