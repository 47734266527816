import React from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { styled } from '@mui/system'
import MuiDrawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'

import { drawerWidth, miniDrawerWidth } from '../constants'

import { Link } from 'react-router-dom'
import { Typography, Box } from '@mui/material'

import logo from '/src/assets/images/logos/logo.png'
import shortLogo from '/src/assets/images/logos/shortLogo.png'

import adminMenu from './adminMenu'
import shortMenus from './shortMenus'
import doctorMenu from './doctorMenu'
import { currentUser } from '@/utils/cutils'
import patientMenu from './patientMenu'

const AdminSidebar = () => {
  const { pathname } = useLocation()
  const drawerOpen = useSelector((state) => state.theme.drawerOpen)
  const menuItems = drawerOpen
    ? getMenuItems(currentUser.role_id)
    : currentUser.role_id === 2
    ? patientMenu
    : shortMenus

  return (
    <Drawer variant="permanent" open={drawerOpen}>
      <DrawerHeader
        sx={{
          ...(drawerOpen
            ? { marginLeft: '13px' }
            : { display: 'flex', justifyContent: 'center' }),
        }}
      >
        {drawerOpen ? (
          <img src={logo} alt="Logo" width="120px" />
        ) : (
          <img src={shortLogo} alt="Logo" width={48} />
        )}
      </DrawerHeader>
      <Divider />
      <List sx={scrollbar}>
        {menuItems?.map((item, index) => (
          <ListItem key={index} sx={{ display: 'block' }}>
            {item.menuLabel ? (
              <Typography
                noWrap
                sx={{
                  justifyContent: drawerOpen ? 'initial' : 'center',
                  px: 2,
                  mt: index !== 0 && drawerOpen && 3,
                  fontWeight: 'bold',
                  ...(drawerOpen && {
                    borderTop: '1px solid #fff',
                    borderBottom: '1px solid #fff',
                    py: 2,
                  }),
                }}
              >
                {drawerOpen && item.label}
              </Typography>
            ) : (
              <ListItemButton
                component={Link}
                to={item.path}
                sx={{
                  bgcolor: item.path === pathname && '#174395',
                  minHeight: 48,
                  px: 1,
                  ...(drawerOpen
                    ? { justifyContent: 'initial' }
                    : {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }),
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: drawerOpen ? 2 : '',
                    justifyContent: 'center',
                    color: 'text.white',
                    width: drawerOpen ? '18px' : '25px',
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <Typography
                  sx={{
                    color: 'text.white',
                    fontSize: drawerOpen ? '14px' : '10px',
                  }}
                >
                  {item.label}
                </Typography>
              </ListItemButton>
            )}
          </ListItem>
        ))}
      </List>
    </Drawer>
  )
}

export default AdminSidebar

// ================================================================================
const openedMixin = (theme) => ({
  backgroundColor: theme.palette.background.sb,
  color: theme.palette.text.white,
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme, open) => ({
  backgroundColor: theme.palette.background.sb,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: open
      ? `calc(${theme.spacing(8)} + 1px)`
      : `calc(${theme.spacing(8)} + ${miniDrawerWidth}px)`,
  },
})
// ...(open
//   ? { width: `calc(${theme.spacing(8)} + 1px)` }
//   : { width: `calc(${theme.spacing(8)} + 100px)` }),
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',

  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

const scrollbar = {
  overflow: 'hidden', // Initially hide scrollbar
  '&:hover': {
    overflowY: 'auto', // Show scrollbar on hover
  },
  '&::-webkit-scrollbar': {
    width: '5px',
    borderRadius: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: '#555',
    },
  },
}

function getMenuItems(role_id) {
  if (role_id === 1) {
    return adminMenu
  } else if (role_id === 5) {
    return doctorMenu
  } else if (role_id === 2) {
    return patientMenu
  }
}
