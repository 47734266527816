// // UserList.jsx
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'

import dayjs from 'dayjs'

import {
  Box,
  Button,
  Card,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from '@mui/material'

import CDataTable from '../../../components/CDatatable'

import { setBreadcrumb } from '../../../redux/Slice/breadcrumbSlice'
import { gutClinicList } from './gutClinicSlice'

const GutClinic = () => {
  const dispatch = useDispatch()
  const { gutClinics, isLoading } = useSelector((state) => state.gutClinic)

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        {
          path: '/consolation/payment/list',
          label: 'Consolation',
          isLast: false,
        },
        { path: '', label: 'Gut Clinic List', isLast: true },
      ])
    )
  }, [dispatch])

  const columns = [
    {
      field: 'full_name',
      headerName: 'Name',
      flex: 0.5,
      renderCell: ({ value, row }) => (
        <>
          <Link
            to={`/patient/detail/${row.id}/profile`}
            style={{ textDecoration: 'none' }}
          >
            {value}
          </Link>
        </>
      ),
    },

    {
      field: 'chart_number',
      headerName: 'Chart Number',
      flex: 0.4,
    },
    {
      field: 'created_at',
      headerName: 'Order Date',
      flex: 0.5,
      renderCell: ({ value }) => <>{dayjs(value).format('DD MMM YYYY')}</>,
    },
  ]

  useEffect(() => {
    dispatch(gutClinicList())
  }, [])

  return (
    <>
      <Helmet>
        <title>Bioaro | Gut Clinic - List</title>
      </Helmet>
      <Card sx={{ p: 3 }}>
        <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
          <Typography variant="h6">Gut Clinic List</Typography>
        </Stack>
        <CDataTable
          rows={gutClinics}
          columns={columns}
          isLoading={isLoading}
          filterMode="server"
        />
      </Card>
    </>
  )
}

export default GutClinic
