import React, { useRef } from 'react'
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Box,
  Stack,
  Typography,
  Checkbox,
  FormControlLabel,
  Button,
  RadioGroup,
  Radio,
  Grid,
  Divider,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { useDrag, useDrop } from 'react-dnd'
import CTextField from '@/components/CTextField'
import CSelect from '@/components/CSelect'
import CRadioGroup from '@/components/CRadioGroup'
import CCheckbox from '@/components/CCheckbox'
import CFileField from '@/components/CFileField'

const FormElement = ({ element, index, onDelete, onEdit, moveElement }) => {
  const ref = useRef(null)

  const [{ isDragging }, drag] = useDrag({
    type: 'FORM_ELEMENT',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const [, drop] = useDrop({
    accept: 'FORM_ELEMENT',
    hover(item) {
      if (!ref.current) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      moveElement(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })

  drag(drop(ref))

  const handleDelete = () => {
    onDelete(index)
  }

  const handleEdit = () => {
    onEdit(index, element)
  }

  return (
    <Box
      ref={ref}
      sx={{
        position: 'relative',
        marginBottom: 2,
        opacity: isDragging ? 0.5 : 1,
        '&:hover .action-buttons': {
          display: 'flex',
        },
      }}
    >
      {element && (
        <>
          {element.key === 'header' && (
            <Typography variant={element?.variant}>{element.value}</Typography>
          )}

          {element.key === 'divider' && <Divider />}

          {element.key === 'paragraph' && (
            <Typography variant="body1">{element.value}</Typography>
          )}
          {element.key === 'text' && <CTextField disabled {...element} />}
          {element.key === 'textarea' && <CTextField disabled {...element} />}

          {element.key === 'select' && <CSelect disabled {...element} />}

          {element.key === 'groupCheckbox' && (
            <FormControl
              fullWidth
              variant={element.variant || 'outlined'}
              disabled
            >
              <InputLabel>{element.label}</InputLabel>
              <Select
                label={element.label}
                defaultValue={element.options?.[0]?.value || ''}
                value={element.value}
                name={element.name}
                className={element.class}
              >
                {element.options?.map((option, idx) => (
                  <MenuItem key={idx} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {element.key === 'checkbox' && (
            <CCheckbox
              disabled
              checked={element.checked}
              label={element.label}
              required={element?.required}
            />
          )}
          {element.key === 'radio' && <CRadioGroup disabled {...element} />}
          {element.key === 'button' && (
            <Button variant={element.variant || 'contained'} disabled>
              {element.label}
            </Button>
          )}
          {element.key === 'autocomplete' && (
            <CTextField disabled {...element} />
          )}
          {element.key === 'date' && <CTextField disabled {...element} />}
          {element.key === 'file' && <CFileField disabled {...element} />}

          {element.key === 'number' && <CTextField disabled {...element} />}
          <Stack
            direction="row"
            spacing={1}
            justifyContent="end"
            className="action-buttons"
            sx={{
              position: 'absolute',
              top:
                element.key === 'divider'
                  ? -30
                  : ['checkbox', 'header', 'paragraph'].includes(element.key)
                  ? 4
                  : 26,
              right: 0,
              display: 'none',
            }}
          >
            {element.key != 'divider' && (
              <IconButton size="small" onClick={handleEdit}>
                <EditIcon color="grey" />
              </IconButton>
            )}
            <IconButton size="small" onClick={handleDelete}>
              <DeleteIcon color="grey" />
            </IconButton>
          </Stack>
        </>
      )}
    </Box>
  )
}

export default FormElement
