// // UserList.jsx
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Icon
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import AddIcon from '@mui/icons-material/Add'
import { Button, Card, Chip, Grid, Stack } from '@mui/material'

import { Helmet } from 'react-helmet-async'

import { setBreadcrumb } from '@/redux/Slice/breadcrumbSlice'
import { Link } from 'react-router-dom'
import CButton from '@/components/CButton'
import { renderCell } from '@/utils/ctableTool'
import { fetchSServiceList } from './sserviceSlice'
import CDataTable from '@/components/CDatatable'
import { PanoramaSharp } from '@mui/icons-material'

const SServiceList = () => {
  const dispatch = useDispatch()
  const { sservices, isLoading } = useSelector((state) => state.sservice)

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        { path: '/sservice/list', label: 'Sequencing Service', isLast: false },
        { path: '', label: 'List', isLast: true },
      ])
    )
  }, [dispatch])

  const handleQuickFilterValue = (filterModel) => {
    const quickFilterValue = filterModel.quickFilterValues.join(' ')
    console.log(quickFilterValue)
  }

  const columns = [
    { field: 'position', headerName: 'Position' },
    {
      field: 'category',
      headerName: 'Category',
      width: 150,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      renderCell: ({ value }) => renderCell(value),
    },
    { field: 'price_gense', headerName: 'Price / Gense', width: 150 },
    {
      field: 'disabled_services',
      headerName: 'Disable Service',
      width: 250,
      renderCell: ({ value }) =>
        renderCell(
          <Grid container spacing={0.2}>
            {value.map((item, index) => (
              <Grid item key={index}>
                <Chip label={item} color="primary" size="small" />
              </Grid>
            ))}
          </Grid>
        ),
    },
    {
      field: 'suggestion_services',
      headerName: 'Suggest Service',
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) =>
        renderCell(
          <Grid container spacing={0.2}>
            {value.map((item, index) => (
              <Grid item key={index}>
                <Chip label={item} color="primary" size="small" />
              </Grid>
            ))}
          </Grid>
        ),
    },
    { field: 'gut', headerName: 'Gut' },
    {
      field: 'tags',
      headerName: 'Tag',
      minWidth: 270,
      renderCell: ({ value }) =>
        renderCell(
          // value.map((item, index) => (

          //     <Chip label={item} color="primary" size='small' key={index} />

          // ))
          <Grid container spacing={0.2}>
            {value.map((item, index) => (
              <Grid item key={index}>
                <Chip label={item} color="primary" size="small" />
              </Grid>
            ))}
          </Grid>
        ),
    },

    {
      headerName: 'Action',
      minWidth: 270,
      flex: 1,
      renderCell: ({ row }) =>
        renderCell(
          <CButton
            label="Edit"
            component={Link}
            to={`/sservice/update/${row.id}`}
          />
        ),
    },
  ]

  useEffect(() => {
    dispatch(fetchSServiceList())
  }, [])

  return (
    <>
      <Helmet>
        <title> Bioaro | Sequencing Service - List</title>
      </Helmet>
      <Card sx={{ p: 2 }}>
        <Stack direction="row" justifyContent="flex-end" mb={1}>
          <CButton
            label="Create"
            icon={<AddIcon />}
            component={Link}
            to={`/sservice/create`}
          />
        </Stack>

        <CDataTable
          rows={sservices}
          columns={columns}
          isLoading={isLoading}
          getRowHeight="auto"
        />
      </Card>
    </>
  )
}

export default SServiceList
