import React from 'react'
import AcknowledgeList from './partial/AcknowledgeList';
import AcknowledgeCreate from './partial/AcknowledgeCreate';
import AcknowledgeUpdate from './partial/AcknowledgeUpdate';
const AcknowledgeRoutes = [
  { path: "list/", element: <AcknowledgeList /> },
  { path: "create/", element: <AcknowledgeCreate /> },
  { path: "update/:acknowledgeId", element: <AcknowledgeUpdate /> },
];

export default AcknowledgeRoutes;

