import {
  IconUserSquare,
  IconClipboardData,
  IconPictureInPicture,
  IconUserCheck,
  IconHome,
  IconUserFilled,
  IconArrowNarrowRight,
  IconMessageDots,
} from '@tabler/icons-react'

const completedMenu = [
  {
    menuLabel: false,
    label: 'Dashboard',
    icon: <IconHome />,
    path: '/dashboard',
  },
  {
    menuLabel: false,
    label: 'Chat',
    icon: <IconMessageDots />,
    path: '/chat/',
  },
  {
    menuLabel: true,
    label: 'Orders / Appointments',
  },
  {
    menuLabel: false,
    label: 'Patient List',
    icon: <IconUserSquare />,
    path: '/patient/list',
  },
  {
    menuLabel: false,
    label: 'Data Analysis',
    icon: <IconClipboardData />,
    path: 'patients/data-analysis',
  },
  {
    menuLabel: false,
    label: 'Schedule',
    icon: <IconClipboardData />,
    path: '/schedule',
  },
  {
    menuLabel: false,
    label: 'Lab Orders',
    icon: <IconUserSquare />,
    path: '/order/lab-order-list',
  },
  {
    menuLabel: false,
    label: 'Orders From Clinic',
    icon: <IconUserSquare />,
    path: '/order/clinic-order-list',
  },
  {
    menuLabel: false,
    label: 'Gut Clinic',
    icon: <IconClipboardData />,
    path: '/gut-clinic/list/',
  },
  {
    menuLabel: false,
    label: 'Leads',
    icon: <IconUserSquare />,
    path: '/leads/list',
  },
  {
    menuLabel: false,
    // label: 'New Consultation',
    label: 'Paid Consultation',
    icon: <IconClipboardData />,
    path: '/consultation/new-booking/list',
  },
  {
    menuLabel: false,
    label: 'Consultation Invoice',
    icon: <IconClipboardData />,
    path: '/consultation/payment/list',
  },
  {
    menuLabel: false,
    label: 'Financial',
    icon: <IconClipboardData />,
    path: '/financial/invoice-list',
  },

  {
    menuLabel: false,
    label: 'Manage Intake form',
    icon: <IconUserFilled />,
    path: '/intake-type/list',
  },
  {
    menuLabel: false,
    label: 'Send Pre/Post Assisment',
    icon: <IconUserSquare />,
    path: '/document-resend/list',
  },
  {
    menuLabel: false,
    label: 'Pharmacy',
    icon: <IconUserSquare />,
    path: '/pharmacy/list',
  },
  {
    menuLabel: false,
    label: 'Create Reseller',
    icon: <IconUserSquare />,
    path: '/seller-site/list',
  },

  {
    menuLabel: true,
    label: 'E-commerce Store',
  },

  {
    menuLabel: false,
    label: 'Category',
    icon: <IconClipboardData />,
    path: '/category/',
  },

  {
    menuLabel: false,
    label: 'Longevity Category',
    icon: <IconClipboardData />,
    path: '/longevity-category/',
  },

  {
    menuLabel: false,
    label: 'Sequencing Service',
    icon: <IconClipboardData />,
    path: '/sservice/list',
  },
  {
    menuLabel: false,
    label: 'Manage Paid Consultation',
    icon: <IconClipboardData />,
    path: '/paid-consultation/list',
  },

  {
    menuLabel: false,
    label: 'Tags',
    icon: <IconClipboardData />,
    path: '/tag/list',
  },

  {
    menuLabel: false,
    label: 'File Category',
    icon: <IconClipboardData />,
    path: '/file-category/list',
  },
  {
    menuLabel: false,
    label: 'Clam Service',
    icon: <IconClipboardData />,
    path: '/clam-service/list',
  },

  {
    menuLabel: false,
    label: 'Form Builder',
    icon: <IconClipboardData />,
    path: '/form-builder/list',
  },
  {
    menuLabel: true,
    label: 'Inventory Management',
  },
  {
    menuLabel: false,
    label: 'Manage Category',
    icon: <IconClipboardData />,
    path: 'inventory/manage-category/list',
  },

  {
    menuLabel: false,
    label: 'Manage Sub-Category',
    icon: <IconClipboardData />,
    path: 'inventory/sub-category/list',
  },
  {
    menuLabel: false,
    label: 'Manage Warehouse',
    // icon: <IconClipboardData />,
    path: '/inventory/warehouse',
  },
  {
    menuLabel: false,
    label: 'Manage Product',
    icon: <IconClipboardData />,
    path: '/product',
  },
  {
    menuLabel: false,
    label: 'Place Order',
    icon: <IconClipboardData />,
    path: '/place-order/create',
  },
  {
    menuLabel: false,
    label: 'Order Invoices',
    icon: <IconClipboardData />,
    path: '/inventory/order-invoice/list',
  },
  {
    menuLabel: false,
    label: 'Other Staff',
    icon: <IconClipboardData />,
    path: 'inventory/user/list',
  },

  {
    menuLabel: false,
    label: 'Expense Category',
    icon: <IconClipboardData />,
    path: 'inventory/expense-category/list',
  },

  {
    menuLabel: false,
    label: 'Expense',
    icon: <IconClipboardData />,
    path: 'inventory/expense',
  },

  {
    menuLabel: false,
    label: 'Gut Communication Category',
    icon: <IconClipboardData />,
    path: 'inventory/gut-communication-category/list',
  },

  {
    menuLabel: false,
    label: 'Gut Communication Schedule',
    icon: <IconClipboardData />,
    path: 'gut-com-schedule/list',
  },

  {
    menuLabel: false,
    label: 'Task',
    icon: <IconClipboardData />,
    path: '/inventory/task/list',
  },

  {
    menuLabel: false,
    label: 'Dictation template',
    icon: <IconClipboardData />,
    path: 'inventory/dictation-template/list',
  },

  {
    menuLabel: false,
    label: 'Note template',
    icon: <IconClipboardData />,
    path: 'inventory/note-template/list',
  },

  {
    menuLabel: false,
    label: 'Mail template',
    icon: <IconClipboardData />,
    path: 'inventory/mail-template/list',
  },

  {
    menuLabel: false,
    label: 'Consent template',
    icon: <IconClipboardData />,
    path: 'inventory/consent-template/list',
  },

  {
    menuLabel: false,
    label: 'Acknowledge',
    icon: <IconClipboardData />,
    path: 'inventory/acknowledge/list',
  },

  {
    menuLabel: true,
    label: 'ManageWebsite',
  },

  {
    menuLabel: false,
    label: 'Blog',
    icon: <IconClipboardData />,
    path: 'website/blog/list',
  },

  {
    menuLabel: false,
    label: 'News',
    icon: <IconClipboardData />,
    path: 'website/news/list',
  },

  {
    menuLabel: false,
    label: 'Banner',
    icon: <IconClipboardData />,
    path: 'website/banner/list',
  },

  {
    menuLabel: false,
    label: 'Seo/Social',
    icon: <IconClipboardData />,
    path: 'website/seo/list',
  },

  {
    menuLabel: false,
    label: 'Security Questions',
    icon: <IconClipboardData />,
    path: 'website/security-question/list',
  },

  {
    menuLabel: false,
    label: 'Province',
    icon: <IconClipboardData />,
    path: 'website/province/list',
  },

  {
    menuLabel: false,
    label: 'Gift-Card',
    icon: <IconClipboardData />,
    path: 'website/gift-card/list',
  },

  {
    menuLabel: false,
    label: 'Coupon',
    icon: <IconClipboardData />,
    path: 'website/coupon/list',
  },

  {
    menuLabel: true,
    label: 'Hospital Management',
  },

  {
    menuLabel: false,
    label: 'Department',
    icon: <IconClipboardData />,
    path: 'hospital/department/list',
  },

  {
    menuLabel: false,
    label: 'Floor',
    icon: <IconClipboardData />,
    path: 'hospital/floor/list',
  },

  {
    menuLabel: false,
    label: 'Room',
    icon: <IconClipboardData />,
    path: 'hospital/room/list',
  },

  {
    menuLabel: true,
    label: 'Manage Users',
  },
  {
    menuLabel: false,
    label: 'Tenant',
    icon: <IconUserFilled />,
    path: '/tenant/list',
  },
  {
    menuLabel: false,
    label: 'User',
    icon: <IconUserFilled />,
    path: '/user/roles',
  },
  {
    menuLabel: false,
    label: 'Physician',
    icon: <IconClipboardData />,
    path: '/physician/list',
  },

  {
    menuLabel: false,
    label: 'Clinic',
    icon: <IconClipboardData />,
    path: '/clinic/list',
  },
  {
    menuLabel: false,
    label: 'Client Type',
    icon: <IconClipboardData />,
    path: 'client-type/list',
  },
  {
    menuLabel: false,
    label: 'Referral',
    icon: <IconUserFilled />,
    path: '/referral/list',
  },

  {
    menuLabel: false,
    label: 'Role & Permission',
    icon: <IconUserFilled />,
    path: '/role/list',
  },
  {
    menuLabel: false,
    label: 'Support',
    icon: <IconUserFilled />,
    path: '/support/list',
  },
]

export default completedMenu
