import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import {
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'

import CTextField from '../../../../components/CTextField'
import CCheckbox from '../../../../components/CCheckbox'
import {
  fetchReferralAddressList,
  createOrUpdateRefAddress,
  fetchReferralAddress,
} from '../referralSlice'

import { showError, showSuccess } from '../../../../redux/Slice/alertSlice'
import CButton from '../../../../components/CButton'

export default function ReferralAddressUpdate() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [activeItem, setActiveItem] = useState(null)
  const [referralAddress, setReferralAddress] = useState({})
  const { errors, isLoading, referralAddresses } = useSelector(
    (state) => state.referral
  )
  const refAddressFromRedux = useSelector(
    (state) => state.referral.referralAddress
  )
  const { referralId, refAddressId } = useParams()

  useEffect(() => {
    dispatch(fetchReferralAddress(refAddressId))
  }, [refAddressId])

  useEffect(() => {
    if (refAddressFromRedux) {
      setReferralAddress(refAddressFromRedux)
    }
  }, [refAddressFromRedux])

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const res = await dispatch(
        createOrUpdateRefAddress({
          referralId: referralId,
          data: referralAddress,
        })
      )

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        navigate('/referral/list')
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Server error:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred while updating the doctor.',
        })
      )
    }
  }

  // onChange handler to update the local state
  const handleInputChange = (event) => {
    const { name, value } = event.target
    // Update the local state with the changed value
    setReferralAddress((referralAddress) => ({
      ...referralAddress,
      [name]: value,
    }))
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <CCheckbox
          label="Primary"
          name="is_primary"
          checked={referralAddress?.is_primary ? true : false}
          onChange={(e) =>
            setReferralAddress({
              ...referralAddress,
              is_primary: e.target.checked,
            })
          }
        />
        <CCheckbox
          label="Favorite"
          name="is_fav"
          checked={referralAddress?.is_fav ? true : false}
          onChange={(e) =>
            setReferralAddress({
              ...referralAddress,
              is_fav: e.target.checked,
            })
          }
        />
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            {/* Desc */}
            <CTextField
              label="Desc"
              name="desc"
              value={referralAddress?.desc ?? ''}
              error={!!errors && errors.desc}
              helperText={errors && errors.desc}
              onChange={handleInputChange}
            />
          </Grid>

          {/* Line 1 */}
          <Grid item xs={12} md={6}>
            <CTextField
              label="Line 1"
              name="line1"
              value={referralAddress?.line1 ?? ''}
              error={!!errors && errors.line1}
              helperText={errors && errors.line1}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <CTextField
              label="Line 2"
              name="line2"
              value={referralAddress?.line2 ?? ''}
              error={!!errors && errors.line2}
              helperText={errors && errors.line2}
              onChange={handleInputChange}
            />
          </Grid>

          {/* Line 3 */}
          <Grid item xs={12} md={4}>
            <CTextField
              label="Line 3"
              name="line3"
              value={referralAddress?.line3 ?? ''}
              error={!!errors && errors.line3}
              helperText={errors && errors.line3}
              onChange={handleInputChange}
            />
          </Grid>

          {/* City */}
          <Grid item xs={12} md={4}>
            <CTextField
              label="City"
              name="city"
              value={referralAddress?.city ?? ''}
              error={!!errors && errors.city}
              helperText={errors && errors.city}
              onChange={handleInputChange}
            />
          </Grid>

          {/* Prov */}
          <Grid item xs={12} md={4}>
            <CTextField
              label="Prov"
              name="prov"
              value={referralAddress?.prov ?? ''}
              error={!!errors && errors.prov}
              helperText={errors && errors.prov}
              onChange={handleInputChange}
            />
          </Grid>

          {/* PC */}
          <Grid item xs={12} md={4}>
            <CTextField
              label="PC"
              name="pc"
              value={referralAddress?.pc ?? ''}
              error={!!errors && errors.pc}
              helperText={errors && errors.pc}
              onChange={handleInputChange}
            />
          </Grid>

          {/* Country */}
          <Grid item xs={12} md={4}>
            <CTextField
              label="Country"
              name="country"
              value={referralAddress?.country ?? ''}
              error={!!errors && errors.country}
              helperText={errors && errors.country}
              onChange={handleInputChange}
            />
          </Grid>

          {/* Phone */}
          <Grid item xs={12} md={4}>
            <CTextField
              label="Phone"
              name="phone"
              value={referralAddress?.phone ?? ''}
              error={!!errors && errors.phone}
              helperText={errors && errors.phone}
              onChange={handleInputChange}
            />
          </Grid>
          {/* Other */}
          <Grid item xs={12} md={4}>
            <CTextField
              label="Other"
              name="phone2"
              value={referralAddress?.phone2 ?? ''}
              error={!!errors && errors.phone2}
              helperText={errors && errors.phone2}
              onChange={handleInputChange}
            />
          </Grid>
          {/* Fax */}
          <Grid item xs={12} md={4}>
            <CTextField
              label="Fax"
              name="fax"
              value={referralAddress?.fax ?? ''}
              error={!!errors && errors.fax}
              helperText={errors && errors.fax}
              onChange={handleInputChange}
            />
          </Grid>
          {/* Email */}
          <Grid item xs={12} md={4}>
            <CTextField
              label="Email"
              name="email"
              type="email"
              value={referralAddress?.email ?? ''}
              error={!!errors && errors.email}
              helperText={errors && errors.email}
              onChange={handleInputChange}
            />
          </Grid>
          {/* Contact Name */}
          <Grid item xs={12} md={4}>
            <CTextField
              label="Contact Name"
              name="contact_name"
              value={referralAddress?.contact_name ?? ''}
              error={!!errors && errors.contact_name}
              helperText={errors && errors.contact_name}
              onChange={handleInputChange}
            />
          </Grid>
          {/* Contact Phone */}
          <Grid item xs={12} md={4}>
            <CTextField
              label="Contact Phone"
              name="contact_phone"
              value={referralAddress?.contact_phone ?? ''}
              error={!!errors && errors.contact_phone}
              helperText={errors && errors.contact_phone}
              onChange={handleInputChange}
            />
          </Grid>
          {/* Communication */}
          <Grid item xs={12} md={4}>
            <CTextField
              label="Communication"
              name="communication"
              value={referralAddress?.communication ?? ''}
              error={!!errors && errors.communication}
              helperText={errors && errors.communication}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>

        <hr />
        {/* Practitionaer Profile  */}
        <Typography variant="h6">Practitionaer Profile</Typography>
        <Grid container spacing={2}>
          {/* ULI */}
          <Grid item xs={12} md={4}>
            <CTextField
              label="ULI"
              name="uli"
              value={referralAddress?.uli ?? ''}
              error={!!errors && errors.uli}
              helperText={errors && errors.uli}
              onChange={handleInputChange}
            />
          </Grid>
          {/* Lab Phys Code */}
          <Grid item xs={12} md={4}>
            <CTextField
              label="Lab Phys Code"
              name="lab_phys_code"
              value={referralAddress?.lab_phys_code ?? ''}
              error={!!errors && errors.lab_phys_code}
              helperText={errors && errors.lab_phys_code}
              onChange={handleInputChange}
            />
          </Grid>
          {/* Discipline */}
          <Grid item xs={12} md={4}>
            <CTextField
              label="Discipline"
              name="discipline"
              value={referralAddress?.discipline ?? ''}
              error={!!errors && errors.discipline}
              helperText={errors && errors.discipline}
              onChange={handleInputChange}
            />
          </Grid>
          {/* Lab Loc Code */}
          <Grid item xs={12} md={4}>
            <CTextField
              label="Lab Loc Code"
              name="lab_loc_code"
              value={referralAddress?.lab_loc_code ?? ''}
              error={!!errors && errors.lab_loc_code}
              helperText={errors && errors.lab_loc_code}
              onChange={handleInputChange}
            />
          </Grid>
          {/* Skill */}
          <Grid item xs={12} md={4}>
            <CTextField
              label="Skill"
              name="skill"
              value={referralAddress?.skill ?? ''}
              error={!!errors && errors.skill}
              helperText={errors && errors.skill}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>

        <Stack direction="row" justifyContent="flex-end" sx={{ mb: 2 }}>
          <CButton label="Save" icon={<SaveIcon />} type="submit" />
        </Stack>
      </form>
    </>
  )
}
