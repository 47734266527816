import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import AddIcon from '@mui/icons-material/Add'
import { Button, Card, Chip, Grid, Stack } from '@mui/material'

import { Helmet } from 'react-helmet-async'

import { setBreadcrumb } from '@/redux/Slice/breadcrumbSlice'
import { Link } from 'react-router-dom'
import CButton from '@/components/CButton'
import { renderCell } from '@/utils/ctableTool'
import { fetchGutComScheduleList } from './gutComScheduleSlice'
import CDataTable from '@/components/CDatatable'
import { PanoramaSharp } from '@mui/icons-material'

const GutComScheduleList = () => {
  const dispatch = useDispatch()
  const { gutComSchedules, isLoading } = useSelector(
    (state) => state.gutComSchedule
  )

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          path: '/gut-com-schedule/list',
          label: 'Gut Communication Schedule',
          isLast: false,
        },
        { path: '', label: 'List', isLast: true },
      ])
    )
  }, [dispatch])

  const handleQuickFilterValue = (filterModel) => {
    const quickFilterValue = filterModel.quickFilterValues.join(' ')
    console.log(quickFilterValue)
  }

  const columns = [
    { field: 'days', headerName: 'Days', minWidth: 150, flex: 0.2 },

    {
      field: 'category',
      headerName: 'Category',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => renderCell(value?.name),
    },
    {
      field: 'subject',
      headerName: 'Subject',
      minWidth: 150,
      flex: 0.5,
      renderCell: ({ value }) => renderCell(value),
    },
    {
      headerName: 'Action',
      // minWidth: 270,
      flex: 1,
      renderCell: ({ row }) =>
        renderCell(
          <CButton
            label="Edit"
            component={Link}
            to={`/gut-com-schedule/update/${row.id}`}
          />
        ),
    },
  ]

  useEffect(() => {
    dispatch(fetchGutComScheduleList())
  }, [])

  return (
    <>
      <Helmet>
        <title> Bioaro | Gut Communication Schedule - List</title>
      </Helmet>
      <Card sx={{ p: 2 }}>
        <Stack direction="row" justifyContent="flex-end" mb={1}>
          <CButton
            label="Create"
            icon={<AddIcon />}
            component={Link}
            to={`/gut-com-schedule/create`}
          />
        </Stack>

        <CDataTable
          rows={gutComSchedules}
          columns={columns}
          isLoading={isLoading}
          getRowHeight="auto"
          getRowId={(row) => row.id}
        />
      </Card>
    </>
  )
}

export default GutComScheduleList
