import GutComScheduleCreate from './GutComScheduleCreate';
import GutComScheduleList from './GutComScheduleList';
import GutComScheduleUpdate from './GutComScheduleUpdate';

const gutComScheduleRoutes = [
  { path: "list", element: <GutComScheduleList /> },
  { path: "create", element: <GutComScheduleCreate /> },
  { path: "update/:gutComScheduleId", element: <GutComScheduleUpdate /> }
];

export default gutComScheduleRoutes;

