import React from 'react'
import { FormControl, FormLabel, TextField } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

export default function CDatePicker({
  name,
  value,
  label,
  autoFocus,
  className,
  onChange = null,
  error,
  helperText,
  fullWidth = true,
  required,
  disabled,
  size = 'small',
}) {
  const [cleared, setCleared] = React.useState(false)

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FormControl fullWidth={fullWidth}>
        <FormLabel htmlFor={`${label}${name}`}>{label}</FormLabel>
        <DatePicker
          id={`${label}${name}`}
          name={name}
          value={value}
          required={required}
          autoFocus={autoFocus}
          onChange={onChange}
          format="YYYY-MM-DD"
          closeOnSelect
          disabled={disabled}
          slotProps={{
            textField: {
              error: error,
              helperText: helperText,
              required: required,
              size: size,
            },
            field: { clearable: true, onClear: () => setCleared(true) },
          }}
        />
      </FormControl>
    </LocalizationProvider>
  )
}
