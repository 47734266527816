// // UserList.jsx

// Icon
import AddIcon from '@mui/icons-material/Add'
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
// Components
import AdminLayout from '@/layouts/AdminLayout'

// Slice
import { setBreadcrumb } from '@/redux/Slice/breadcrumbSlice'
import { Link, useNavigate, useParams } from 'react-router-dom'
import CButton from '@/components/CButton'
import CDatatable from '@/components/CDatatable'
import { changeStatus, deleteRecord } from '@/redux/Slice/commonSlice'
import { showError, showSuccess } from '@/redux/Slice/alertSlice'
import CTextField from '@/components/CTextField'
import SaveIcon from '@mui/icons-material/Save'
import { Helmet } from 'react-helmet-async'
import { useEffect, useState } from 'react'
import {
  create,
  list,
  paidConsultationInfo,
  update,
} from '../paidConsultationSlice'
import { useDispatch, useSelector } from 'react-redux'
import CAutocomplete from '@/components/CAutocomplete'

export default function PaidConsultationUpdate() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { consultationId } = useParams()
  const { info, doctors, categories, errors, error, isLoading } = useSelector(
    (state) => state.paidConsultation
  )
  const [consultation, setConsultation] = useState(info)

  useEffect(() => {
    dispatch(paidConsultationInfo(consultationId))
    dispatch(list())
  }, [])

  useEffect(()=> {setConsultation(info)},[info])

  //   initial update value

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const res = await dispatch(
        update({ data: consultation, consultationId: consultationId })
      )
      console.log(res)

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )

        navigate('/paid-consultation/list')
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Server error:', error)
      dispatch(
        showError({
          error: true,
          message:
            'An error occurred while Updating paid consultation service.',
        })
      )
    }
  }

  // onChange handler to update the local state
  const handleInputChange = (event) => {
    const { name, value } = event.target
    // Update the local state with the changed value
    setConsultation((consultation) => ({
      ...consultation,
      [name]: value,
    }))
  }

  return (
    <>
      <Helmet>
        <title>Paid Consultation Update</title>
      </Helmet>
      {consultation ? (
        <Stack direction="row" justifyContent="center">
          <Card sx={{ p: 3, width: '50rem' }}>
            <form onSubmit={handleSubmit}>
              <Box borderBottom={2} mb={4}>
                <Stack sx={{ float: 'left' }}>
                  <Typography variant="h5">Paid Consultation Update</Typography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  mb={2}
                  mt={2}
                  mr={1.3}
                >
                  <CButton label="Update" icon={<SaveIcon />} type="submit" />
                </Stack>
              </Box>

              <Grid
                container
                spacing={2}
                alignItems="center"
                width="60rem"
                sx={{ alignContent: 'center', margin: 'auto' }}
              >
                <Grid xs={12} m={1}>
                  <CTextField
                    label="Name"
                    name="name"
                    value={consultation?.name}
                    error={errors && errors.name}
                    helperText={errors && errors.name}
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid xs={12} m={1}>
                  <CTextField
                    label="Price"
                    name="price"
                    value={consultation?.price}
                    error={errors && errors.price}
                    helperText={errors && errors.price}
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid xs={12} m={1}>
                  <CTextField
                    label="Advance Price"
                    name="advance_payment"
                    value={consultation?.advance_payment}
                    error={errors && errors.advance_payment}
                    helperText={errors && errors.advance_payment}
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid xs={12} m={1}>
                  <CTextField
                    label="Duration"
                    name="duration"
                    value={consultation?.duration}
                    error={errors && errors.duration}
                    helperText={errors && errors.duration}
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid xs={12} m={1}>
                  <CAutocomplete
                    options={doctors}
                    getOptionLabel={(option) => option.full_name}
                    value={consultation?.doctor}
                    onChange={(e, value) =>
                      // console.log(value)
                      setConsultation({
                        ...consultation,
                        doctor: value,
                      })
                    }
                    label="Doctors"
                    multiple
                    error={!!(errors && errors.doctor)}
                    helperText={errors && errors.doctor}
                  />
                </Grid>

                <Grid xs={12} m={1}>
                  <CAutocomplete
                    options={categories}
                    getOptionLabel={(option) => option.name}
                    value={consultation?.category}
                    onChange={(e, value) =>
                      // console.log(value)
                      setConsultation({
                        ...consultation,
                        category: value,
                      })
                    }
                    label="Category"
                    error={!!(errors && errors.category)}
                    helperText={errors && errors.category}
                  />
                </Grid>
              </Grid>
            </form>
          </Card>
        </Stack>
      ) : null}
    </>
  )
}
