import React from 'react'
import NewConsultation from './NewConsultation'
import PaymentConsultation from './PaymentConsultation'

const consultationRoutes = [
  { path: 'new-booking/list', element: <NewConsultation /> },
  { path: 'payment/list', element: <PaymentConsultation /> },
]

export default consultationRoutes
