import { Route, Routes } from 'react-router-dom'

import ClinicList from './ClinicList'
import ClinicUpdate from './ClinicUpdate'
import ClinicCreate from './ClinicCreate'

const clinicRoutes = [
  { path: 'list', element: <ClinicList /> },
  { path: 'create', element: <ClinicCreate /> },
  { path: 'update/:clinicId', element: <ClinicUpdate /> },
]

export default clinicRoutes
