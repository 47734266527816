
import { useLocation, useNavigate } from 'react-router-dom'
import { setAuthToken } from '../services/axiosConfig'

const authCheck = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(location.search)
    const token = searchParams.get('_token')

    // This token comes from another domain
    if (token) {
        localStorage.setItem('accessToken', token)
    }

    const accessToken = localStorage.getItem('accessToken')
    if (accessToken) {
        setAuthToken()
        return true
    } else {
        return false
    }
}

export default authCheck
