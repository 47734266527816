import React, { useState, useRef, useEffect } from 'react'
import {
  Box,
  InputAdornment,
  Stack,
  Typography,
  IconButton,
  ClickAwayListener,
  Fab,
} from '@mui/material'
import {
  Add as AddIcon,
  Send as SendIcon,
  AddReaction as AddReactionIcon,
  Image,
  AttachFile,
  Close as CloseIcon,
} from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import CTextField from '@/components/CTextField'
import CIconButton from '@/components/CIconButton'
import EmojiPicker from 'emoji-picker-react'
import { useDispatch, useSelector } from 'react-redux'
import { messageUpdate, messageList, setMessages } from '../chatSlice'
import CDialog from '@/components/CDialog'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

const MessageUpdate = ({ handleMsgUpdateOpen, socket }) => {
  const dispatch = useDispatch()
  const { messageId, receiver, isOnline } = useSelector((state) => state.chat)
  const [updatedMessage, setUpdatedMessage] = useState('')
  const [emojiPicker, setEmojiPicker] = useState(false)
  const [openActionButtons, setOpenActionButtons] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState([])
  const imageInputRef = useRef(null)
  const fileInputRef = useRef(null)

  useEffect(() => {
    setOpenActionButtons(false) // Close action buttons on component mount
  }, [])

  useEffect(() => {
    const handleMessage = async (data) => {
      console.log('message received')
      const res = await dispatch(messageList(receiver.user_id))
      if (res.payload) {
        setMessages(res.payload)
      }
    }

    socket.on('getMessage', handleMessage)

    return () => socket.off('getMessage', handleMessage)
  }, [])

  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files)
    setSelectedFiles((prevFiles) => [...prevFiles, ...files])
  }

  const handleImageUpload = (event) => {
    const images = Array.from(event.target.files)
    setSelectedFiles((prevFiles) => [...prevFiles, ...images])
  }

  const removeFile = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index))
  }

  const handleMessageUpdate = () => {
    if (updatedMessage.trim() !== '') {
      const data = {
        receiverId: receiver.user_id,
        text: updatedMessage,
        attachments: selectedFiles,
      }

      dispatch(messageUpdate({ messageId, data: data })).then(() => {
        console.log('first')
        if (isOnline) {
          console.log('second')
          socket.emit('sendMessage', data)
        }
        dispatch(messageList(receiver.user_id))
        setUpdatedMessage('')
        handleMsgUpdateOpen()
      })
    }
  }

  return (
    <CDialog
      headerTitle="Edit Message"
      handleCDialogOpen={() => setOpenActionButtons(true)}
      handleCDialogClose={handleMsgUpdateOpen}
      open={true}
    >
      <Box maxWidth="50rem" mt={emojiPicker ? 50 : 20}>
        <Box>
          <Stack direction="row" alignItems="center">
            <ClickAwayListener onClickAway={() => setOpenActionButtons(false)}>
              <Box sx={{ position: 'relative' }}>
                <CIconButton
                  icon={<AddIcon />}
                  onClick={() => setOpenActionButtons(!openActionButtons)}
                />
                {openActionButtons && (
                  <Stack
                    spacing={0.5}
                    sx={{
                      position: 'absolute',
                      bottom: '100%',
                      left: 0,
                      zIndex: 10,
                      mb: 1,
                    }}
                  >
                    <Fab
                      color="primary"
                      aria-label="add image"
                      size="small"
                      onClick={() => imageInputRef.current.click()}
                    >
                      <Image />
                    </Fab>
                    <Fab
                      color="primary"
                      aria-label="add file"
                      size="small"
                      onClick={() => fileInputRef.current.click()}
                    >
                      <AttachFile />
                    </Fab>
                  </Stack>
                )}
              </Box>
            </ClickAwayListener>
            <CTextField
              value={updatedMessage}
              variant="outlined"
              placeholder="Aa"
              size="medium"
              multiline
              maxRows={4}
              sx={{ marginRight: '16px' }}
              onChange={(e) => setUpdatedMessage(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CIconButton
                      icon={<AddReactionIcon />}
                      onClick={() => setEmojiPicker(!emojiPicker)}
                    />
                    {emojiPicker && (
                      <Box
                        sx={{
                          position: 'absolute',
                          bottom: 60,
                          right: 0,
                          zIndex: 20,
                          backgroundColor: '#fff',
                          borderRadius: '10px',
                          boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                        }}
                      >
                        <EmojiPicker
                          previewConfig={{ showPreview: true }}
                          height={400}
                          searchDisabled
                          lazyLoadEmojis
                          onEmojiClick={(param) =>
                            setUpdatedMessage(`${updatedMessage}${param.emoji}`)
                          }
                        />
                      </Box>
                    )}
                  </InputAdornment>
                ),
              }}
            />
            <CIconButton icon={<SendIcon />} onClick={handleMessageUpdate} />
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            sx={{ flexWrap: 'wrap', marginTop: '16px' }}
          >
            {selectedFiles.map((file, index) => (
              <Box
                key={index}
                sx={{
                  position: 'relative',
                  width: '100px',
                  height: '100px',
                  marginBottom: '8px',
                  border: '1px solid #ddd',
                  borderRadius: '8px',
                  overflow: 'hidden',
                }}
              >
                {file.type.startsWith('image/') ? (
                  <img
                    src={URL.createObjectURL(file)}
                    alt="preview"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#f5f5f5',
                    }}
                  >
                    <Typography variant="body2">{file.name}</Typography>
                  </Box>
                )}
                <IconButton
                  size="small"
                  sx={{ position: 'absolute', top: 0, right: 0 }}
                  onClick={() => removeFile(index)}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            ))}
          </Stack>
          <VisuallyHiddenInput
            type="file"
            accept="image/*"
            ref={imageInputRef}
            onChange={handleImageUpload}
          />
          <VisuallyHiddenInput
            type="file"
            ref={fileInputRef}
            onChange={handleFileUpload}
          />
        </Box>
      </Box>
    </CDialog>
  )
}

export default MessageUpdate
