import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Card, Grid, Stack } from '@mui/material'
import CTextField from '../../../../components/CTextField'
import CAutocomplete from '../../../../components/CAutocomplete'
import CButton from '../../../../components/CButton'
import SaveIcon from '@mui/icons-material/Save'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setBreadcrumb } from '../../../../redux/Slice/breadcrumbSlice'
import { showError, showSuccess } from '../../../../redux/Slice/alertSlice'
import { create, info, list } from '../intakeTypeSlice'

export default function IntakeTypeCreate() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { categories, errors, isLoading } = useSelector(
    (state) => state.intakeType
  )
  const [intakeType, setIntakeType] = useState({
    title: '',
    forms: [],
    category: [],
  })
  useEffect(() => {
    dispatch(list())
    dispatch(info())
  }, [])
  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        {
          path: '/intake-type/list',
          label: 'Intake Type',
          isLast: false,
        },
        { path: '', label: 'Create', isLast: true },
      ])
    )
  }, [dispatch])
  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const res = await dispatch(
        // Wait for the pharmacy create action to complete.
        create({
          title: intakeType?.title,
          forms: intakeType?.forms,
          category: intakeType?.category,
        })
      )

      if (res.payload && !res.payload.error) {
        dispatch(
          showError({
            success: true,
            message: res.payload.message,
          })
        )
        navigate('/intake-type/list')
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      // Handle any errors that occur during the login action dispatch
      console.error('Server error:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred during creating user.',
        })
      )
    }
  }
  const forms = [
    'general_background',
    'about',
    'genetic',
    'Smoking',
    'medication',
    'history_of_antibiotics',
    'surgeries',
    'men_history',
    'hospitalizations',
    'injuries',
    'history_of_cancer',
    'history_of_brain',
    'history_of_Gastrointestinal',
    'history_of_skin',
    'history_of_metabolic',
    'vaginal',
    'oral_history',
    'diet_and_nutrition',
    'skin',
    'gut_mental_health',
    'sumtomps',
    'benefit',
    'kinesiology',
    'family_history',
  ]

  return (
    <>
      <Helmet>
        <title>Bioaro | Intake Type - Create</title>
      </Helmet>
      <Card sx={{ p: 3, maxWidth: '100rem' }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <CTextField
                label="Title"
                name="title"
                value={intakeType?.title}
                required={true}
                error={!!(errors && errors.title)}
                helperText={errors && errors.title}
                onChange={(e) =>
                  setIntakeType({ ...intakeType, title: e.target.value })
                }
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <CAutocomplete
                options={categories}
                getOptionLabel={(option) => option.name}
                value={intakeType?.category}
                onChange={(e, value) =>
                  // console.log(value)
                  setIntakeType({
                    ...intakeType,
                    category: value,
                  })
                }
                label="Category"
                multiple
                error={!!(errors && errors.category)}
                helperText={errors && errors.category}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <CAutocomplete
                options={forms}
                getOptionLabel={(option) => option}
                value={intakeType.forms}
                onChange={(e, value) =>
                  // console.log(value)
                  setIntakeType({
                    ...intakeType,
                    forms: value,
                  })
                }
                label="Forms"
                multiple
                error={!!(errors && errors.forms)}
                helperText={errors && errors.forms}
              />
            </Grid>
          </Grid>
          <Stack direction="row" justifyContent="flex-end" mt={2}>
            <CButton label="Save" icon={<SaveIcon />} type="submit" />
          </Stack>
        </form>
      </Card>
    </>
  )
}
