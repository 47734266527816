import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Button from '@mui/material/Button'
import TaskIcon from '@mui/icons-material/Task'
import { Box, FormControl, InputLabel, Stack, TextField } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'

// Local Components
import CDialog from '../../../../../components/CDialog'

// Redux Actions
import { updateStatus } from '../TaskSlice'
import { showSuccess, showError } from '../../../../../redux/Slice/alertSlice'

import CSelect from '../../../../../components/CSelect'

import CButton from '../../../../../components/CButton'

const TaskStatus = ({ statusName, taskId, callback=null }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { patientId } = useParams()

  const [taskStatus, setTasks] = useState({
    status: '',
  })

  const [open, setOpen] = React.useState(false)

  const handleCDialogOpen = () => {
    setOpen(true)
  }

  const handleCDialogClose = () => {
    setOpen(false)
  }

  // onChange handler to update the local state
  const handleInputChange = (event) => {
    const { name, value } = event.target
    // Update the local state with the changed value
    setTasks((taskStatus) => ({
      ...taskStatus,
      [name]: value,
    }))
  }

  // // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      const res = await dispatch(
        updateStatus({
          data: taskStatus,
          taskId: taskId,
        })
      )

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        // Close CDialog after success request
        setOpen(false)
        if(callback){
          callback()
        }
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Error while update status update:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred during update status update.',
        })
      )
    }
  }

  return (
    <>
      <CDialog
        btnTitle={statusName}
        headerTitle="Update Status"
        size='small'
        icon={<TaskIcon />}
        handleCDialogOpen={handleCDialogOpen}
        handleCDialogClose={handleCDialogClose}
        open={open}
        fullWidth
      >
        <Box minWidth="20rem">
          <form onSubmit={handleSubmit}>
            <CSelect
              label="Select Status"
              name="status"
              value={taskStatus?.status}
              options={[
                { label: 'Pending', value: 0 },
                { label: 'Review', value: 1 },
                { label: 'In Progress', value: 2 },
                { label: 'Success', value: 3 },
              ]}
              onChange={handleInputChange}
            />

            <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
              <CButton label="Save" icon={<SaveIcon />} type="submit" />
            </Stack>
          </form>
        </Box>
      </CDialog>
    </>
  )
}

export default TaskStatus
