// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../../services/axiosConfig'

// This function is called to set the auth header

// Fetch Physician List
export const fetchPhysicianList = createAsyncThunk(
  'physician/list',
  async () => {
    const response = await axiosInstance.get('physician/list')
    return response.data
  }
)
//  Physician Schedule List
export const scheduleList = createAsyncThunk(
  'physician/schedule-list',
  async () => {
    const response = await axiosInstance.get('physician/schedule-list')
    return response.data
  }
)

// Physician Create
export const create = createAsyncThunk('physician/create', async ({ data }) => {
  const response = await axiosInstance.post(`physician/create`, data)
  return response.data
})

// Physician Update
export const update = createAsyncThunk(
  'physician/update',
  async ({ physicianId, data }) => {
    const response = await axiosInstance.post(
      `physician/update/${physicianId}`,
      data
    )
    return response.data
  }
)
// Physician Schedule Update
export const scheduleUpdate = createAsyncThunk(
  'physician/schedule-update',
  async ({ scheduleId, data }) => {
    const response = await axiosInstance.post(
      `physician/schedule-update/${scheduleId}`,
      data
    )
    return response.data
  }
)

// Fetch physician detail by user id
export const fetchPhysicianDetail = createAsyncThunk(
  'physician/detail',
  async (id) => {
    const response = await axiosInstance.get(`physician/detail/${id}`)
    return response.data
  }
)

// Fetch physician schedule info
export const scheduleInfo = createAsyncThunk(
  'physician/schedule-info',
  async (id) => {
    const response = await axiosInstance.get(`physician/schedule-info/${id}`)
    return response.data
  }
)

// Fetch physician schedule by physicianId
export const fetchPhysicianSchedule = createAsyncThunk(
  'physician/schedule',
  async (physicianId) => {
    const response = await axiosInstance.get(
      `physician/schedule/${physicianId}`
    )
    return response.data
  }
)

// Physician Schedule Create
export const physicianScheduleCreate = createAsyncThunk(
  'physician/schedule-create',
  async ({ physicianId, data }) => {
    const response = await axiosInstance.post(
      `physician/schedule-create/${physicianId}`,
      data
    )
    return response.data
  }
)

// Physician Per day schedule Create
export const physicianPerDayScheduleCreate = createAsyncThunk(
  'physician/per-day-schedule-create',
  async ({ physicianId, data }) => {
    const response = await axiosInstance.post(
      `physician/per-day-schedule-create/${physicianId}`,
      data
    )
    return response.data
  }
)

// Fetch physician leaves by physicianId
export const fetchPhysicianLeave = createAsyncThunk(
  'physician/leaves',
  async (physicianId) => {
    const response = await axiosInstance.get(`physician/leaves/${physicianId}`)
    return response.data
  }
)

// Physician leave Create
export const physicianLeaveCreate = createAsyncThunk(
  'physician/leave-create',
  async ({ physicianId, data }) => {
    const response = await axiosInstance.post(
      `physician/leave-create/${physicianId}`,
      data
    )
    return response.data
  }
)

const orderSlice = createSlice({
  name: 'physician',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    physician: null,
    physicians: [],
    physicianSchedules: null,
    physicianLeaves: null,
    perDaySchedules: null,
    currentDate: null,
    schedules: [],
    schedule: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Fetch  physician Create
      .addCase(create.pending, (state) => {
        state.isLoading = true
      })
      .addCase(create.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(create.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch  physician list
      .addCase(fetchPhysicianList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchPhysicianList.fulfilled, (state, { payload } = action) => {
        state.physicians = payload.physicians
        state.isLoading = false
      })
      .addCase(fetchPhysicianList.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch  physician schedule list
      .addCase(scheduleList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(scheduleList.fulfilled, (state, { payload } = action) => {
        state.schedules = payload.schedules
        state.isLoading = false
      })
      .addCase(scheduleList.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch  physician detail
      .addCase(fetchPhysicianDetail.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        fetchPhysicianDetail.fulfilled,
        (state, { payload } = action) => {
          state.physician = payload.physician
          state.isLoading = false
        }
      )
      .addCase(fetchPhysicianDetail.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch  physician schedule info
      .addCase(scheduleInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        scheduleInfo.fulfilled,
        (state, { payload } = action) => {
          state.schedule = payload.schedule
          state.isLoading = false
        }
      )
      .addCase(scheduleInfo.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch  physician Schedules
      .addCase(fetchPhysicianSchedule.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        fetchPhysicianSchedule.fulfilled,
        (state, { payload } = action) => {
          state.physicianSchedules = payload.physician_schedules
          state.physician = payload.physician
          state.perDaySchedules = payload.per_day_schedules
          state.currentDate = payload.currentDate
          state.isLoading = false
        }
      )
      .addCase(fetchPhysicianSchedule.rejected, (state) => {
        state.isLoading = false
      })

      // physician schedule create
      .addCase(physicianScheduleCreate.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        physicianScheduleCreate.fulfilled,
        (state, { payload } = action) => {
          state.message = payload.message
          state.errors = payload.errors
          state.isLoading = false
        }
      )
      .addCase(physicianScheduleCreate.rejected, (state) => {
        state.isLoading = false
      })

      // physician perDaySchedule create
      .addCase(physicianPerDayScheduleCreate.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        physicianPerDayScheduleCreate.fulfilled,
        (state, { payload } = action) => {
          state.message = payload.message
          state.errors = payload.errors
          state.isLoading = false
        }
      )
      .addCase(physicianPerDayScheduleCreate.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch  physician Leave
      .addCase(fetchPhysicianLeave.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchPhysicianLeave.fulfilled, (state, { payload } = action) => {
        state.physicianLeaves = payload.physician_leaves
        state.physician = payload.physician
        state.isLoading = false
      })
      .addCase(fetchPhysicianLeave.rejected, (state) => {
        state.isLoading = false
      })

      // physician leave create
      .addCase(physicianLeaveCreate.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        physicianLeaveCreate.fulfilled,
        (state, { payload } = action) => {
          state.message = payload.message
          state.errors = payload.errors
          state.isLoading = false
        }
      )
      .addCase(physicianLeaveCreate.rejected, (state) => {
        state.isLoading = false
      })

      // physician update
      .addCase(update.pending, (state) => {
        state.isLoading = true
      })
      .addCase(update.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(update.rejected, (state) => {
        state.isLoading = false
      })

      //   physician schedule update
      .addCase(scheduleUpdate.pending, (state) => {
        state.isLoading = true
      })
      .addCase(scheduleUpdate.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(scheduleUpdate.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default orderSlice.reducer
