// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../../../services/axiosConfig'

// Fetch patient medicine active inactive list
export const fetchMedicineList = createAsyncThunk(
  'patient/medicine/list',
  async (patientId) => {
    const response = await axiosInstance.get(
      `patient/medicine/list/${patientId}`
    )
    return response.data
  }
)

// Patient medicince create
export const create = createAsyncThunk(
  'patient/medicine/create',
  async ({ patientId, data }) => {
    const response = await axiosInstance.post(
      `patient/medicine/create/${patientId}`,
      data
    )
    return response.data
  }
)

// Patient medicince Detail data
export const fetchMedicineDetail = createAsyncThunk(
  'patient/medicine/detail',
  async (medicineId) => {
    const response = await axiosInstance.get(
      `patient/medicine/detail/${medicineId}`
    )
    return response.data
  }
)

// patient medicine update

export const update = createAsyncThunk(
  'patient/medicine/update',
  async ({ medicineId, data }) => {
    const response = await axiosInstance.post(
      `patient/medicine/update/${medicineId}`,
      data
    )
    return response.data
  }
)

// medicine print
export const medPrint = createAsyncThunk(
  'patient/medicine/print',
  async (id) => {
    const response = await axiosInstance.get(`patient/medicine/print/${id}`)
    return response.data
  }
)

const medicineSlice = createSlice({
  name: 'medicine',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    activeMeds: [],
    inactiveMeds: [],
    medicine: null,
    incNumber: 1,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch  patient based file list
      .addCase(fetchMedicineList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchMedicineList.fulfilled, (state, { payload } = action) => {
        state.activeMeds = payload.activeMeds
        state.inactiveMeds = payload.inactiveMeds
        state.isLoading = false
      })
      .addCase(fetchMedicineList.rejected, (state) => {
        state.isLoading = false
      })

      // patient medicine detail
      .addCase(fetchMedicineDetail.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchMedicineDetail.fulfilled, (state, { payload } = action) => {
        state.medicine = payload.medicine
        state.isLoading = false
      })
      .addCase(fetchMedicineDetail.rejected, (state) => {
        state.isLoading = false
      })
      // Patient based file  Create
      .addCase(create.pending, (state) => {
        state.isLoading = true
      })
      .addCase(create.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
        state.incNumber += 1
      })
      .addCase(create.rejected, (state) => {
        state.isLoading = false
      })

      // update  medicine
      .addCase(update.pending, (state) => {
        state.isLoading = true
      })
      .addCase(update.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(update.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default medicineSlice.reducer
