import React from 'react'
import { Route, Routes } from 'react-router-dom'
import SupportList from './SupportList'
import SupportCreate from './partial/SupportCreate'
import SupportUpdate from './partial/SupportUpdate'

const supportRoutes = [
  { path: 'list', element: <SupportList /> },
  { path: 'create', element: <SupportCreate /> },
  { path: 'update/:supportId', element: <SupportUpdate /> },
]

export default supportRoutes
