// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../../services/axiosConfig'

// Fetch Referral List
export const fetchReferralList = createAsyncThunk('referral/list', async () => {
  const response = await axiosInstance.get('referral/list')
  return response.data
})

// Referral Create
export const create = createAsyncThunk('referral/create', async ({ data }) => {
  const response = await axiosInstance.post(`referral/create`, data)
  return response.data
})

// Referral Update
export const update = createAsyncThunk(
  'referral/update',
  async ({ referralId, data }) => {
    const response = await axiosInstance.post(
      `referral/update/${referralId}`,
      data
    )
    return response.data
  }
)

// Referral change status
export const changeRefStatus = createAsyncThunk(
  'referral/change-status',
  async ({ referralId, data }) => {
    const response = await axiosInstance.post(
      `referral/change-status/${referralId}`,
      data
    )
    return response.data
  }
)

// Fetch Referral detail by its id
export const fetchReferralDetail = createAsyncThunk(
  'referral/detail',
  async (referralId) => {
    const response = await axiosInstance.get(`referral/detail/${referralId}`)
    return response.data
  }
)

// Fetch Provinces And ClientTypes
export const getProvincesAndClientTypes = createAsyncThunk(
  'referral/get-provinces-and-client-types',
  async () => {
    const response = await axiosInstance.get(
      'referral/get-provinces-and-client-types'
    )
    return response.data
  }
)

// Fetch Referral address by its id
export const fetchReferralAddress = createAsyncThunk(
  'referral/fetch-referral-address',
  async (referralAddId) => {
    const response = await axiosInstance.get(
      `referral/fetch-referral-address/${referralAddId}`
    )
    return response.data
  }
)

// Fetch Referral address by its id
export const fetchReferralAddressList = createAsyncThunk(
  'referral/referral-address-list',
  async (referralId) => {
    const response = await axiosInstance.get(
      `referral/referral-address-list/${referralId}`
    )
    return response.data
  }
)

// Create or Update Referral address
export const createOrUpdateRefAddress = createAsyncThunk(
  'referral/create-or-update-referral-address',
  async ({ referralId, data }) => {
    const response = await axiosInstance.post(
      `referral/create-or-update-referral-address/${referralId}`,
      data
    )
    return response.data
  }
)

const referralSlice = createSlice({
  name: 'referral',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    referral: null,
    provinces: [],
    clientTypes: [],
    referrals: [],
    referralAddress: null,
    referralAddresses: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Fetch  referral Create
      .addCase(create.pending, (state) => {
        state.isLoading = true
      })
      .addCase(create.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(create.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch  referral list
      .addCase(fetchReferralList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchReferralList.fulfilled, (state, { payload }) => {
        state.referrals = payload.referrals
        state.isLoading = false
      })
      .addCase(fetchReferralList.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch  referral detail
      .addCase(fetchReferralDetail.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchReferralDetail.fulfilled, (state, { payload }) => {
        state.referral = payload.referral
        state.clientTypes = payload.clientTypes
        state.provinces = payload.provinces
        state.isLoading = false
      })
      .addCase(fetchReferralDetail.rejected, (state) => {
        state.isLoading = false
      })

      // get Provinces And ClientTypes
      .addCase(getProvincesAndClientTypes.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getProvincesAndClientTypes.fulfilled, (state, { payload }) => {
        state.clientTypes = payload.clientTypes
        state.provinces = payload.provinces
        state.isLoading = false
      })
      .addCase(getProvincesAndClientTypes.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch  referral update
      .addCase(update.pending, (state) => {
        state.isLoading = true
      })
      .addCase(update.fulfilled, (state, { payload }) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(update.rejected, (state) => {
        state.isLoading = false
      })

      // change  referral status
      .addCase(changeRefStatus.pending, (state) => {
        state.isLoading = true
      })
      .addCase(changeRefStatus.fulfilled, (state, { payload }) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(changeRefStatus.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch  referral address detail
      .addCase(fetchReferralAddress.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchReferralAddress.fulfilled, (state, { payload }) => {
        state.referralAddress = payload.referralAddress
        state.isLoading = false
      })
      .addCase(fetchReferralAddress.rejected, (state) => {
        state.isLoading = false
      })

      // Create or Update Referral Address
      .addCase(createOrUpdateRefAddress.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        createOrUpdateRefAddress.fulfilled,
        (state, { payload } = action) => {
          state.message = payload.message
          state.errors = payload.errors
          state.isLoading = false
        }
      )
      .addCase(createOrUpdateRefAddress.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch  referral address list
      .addCase(fetchReferralAddressList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchReferralAddressList.fulfilled, (state, { payload }) => {
        state.referralAddresses = payload.referralAddresses
        state.isLoading = false
      })
      .addCase(fetchReferralAddressList.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default referralSlice.reducer
