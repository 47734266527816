import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  useNavigate,
  useLocation,
  useParams,
  Outlet,
  useOutlet,
  NavLink,
  Link,
} from 'react-router-dom'

import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import { Helmet } from 'react-helmet-async'
import CTextField from '../../../components/CTextField'
import CCheckbox from '../../../components/CCheckbox'
import {
  fetchReferralAddressList,
  createOrUpdateRefAddress,
} from './referralSlice'
import { setBreadcrumb } from '../../../redux/Slice/breadcrumbSlice'
import { showError, showSuccess } from '../../../redux/Slice/alertSlice'
import ReferralAddressCreate from './Partials/ReferralAddressCreate'
import CButton from '../../../components/CButton'

export default function ReferralAddress() {
  const dispatch = useDispatch()
  const outlet = useOutlet()
  const { pathname } = useLocation()

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        { path: '/referral/list', label: 'Referral', isLast: false },
        { path: '', label: 'Address', isLast: true },
      ])
    )
  }, [dispatch])

  const { errors, isLoading, referralAddresses } = useSelector(
    (state) => state.referral
  )
  const { referralId } = useParams()

  useEffect(() => {
    dispatch(fetchReferralAddressList(referralId))
  }, [])

  return (
    <>
      <Helmet>
        <title>Bioaro | Referral - Address</title>
      </Helmet>
      <Card sx={{ p: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Typography variant="h6">Address & Contact</Typography>
            {outlet ? <Outlet /> : <ReferralAddressCreate />}
          </Grid>

          <Grid item xs={12} md={4}>
            <Stack direction="row" justifyContent="flex-end" sx={{ mb: 2 }}>
              <CButton
                label="New Address"
                icon={<AddIcon />}
                component={Link}
                to={`/referral/${referralId}/address/create`}
              />
            </Stack>

            <Box>
              {referralAddresses?.map((address, index) => (
                <List key={index}>
                  <ListItem disablePadding>
                    <ListItemButton
                      selected={
                        `/referral/${referralId}/address/update/${address.id}` ===
                        pathname
                      }
                      component={Link}
                      to={`/referral/${referralId}/address/update/${address.id}`}
                    >
                      <ListItemText
                        sx={{ overflowWrap: 'break-word' }}
                        primary={address.desc}
                        secondary={address.line1}
                      />
                    </ListItemButton>
                  </ListItem>
                </List>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Card>
    </>
  )
}
