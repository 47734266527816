import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'

import SendOutlinedIcon from '@mui/icons-material/SendOutlined'
import { Box, Grid, InputLabel, Stack, Typography } from '@mui/material'
import CDialog from '../../../../../components/CDialog'
import CButton from '../../../../../components/CButton'
import CTextField from '../../../../../components/CTextField'
import CEditor from '../../../../../components/CEditor'
import { showError, showSuccess } from '../../../../../redux/Slice/alertSlice'
import SaveIcon from '@mui/icons-material/Save'
import { list, update } from '../inventoryCategorySlice'

const InventoryCategoryUpdate = ({ id, name }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const handleCDialogOpen = () => {
    setOpen(true)
  }
  const handleCDialogClose = () => {
    setOpen(false)
  }
  const { categories, errors, message, payload } = useSelector(
    (state) => state.inventoryCategory
  )

  //   initial create value
  const [category, setCategory] = useState({
    id: id,
    name: name,
  })

  // submit form
  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const res = await dispatch(update(category))

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        dispatch(list())
        setOpen(false)
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Server error:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred while updating the Category.',
        })
      )
    }
  }

  // onChange handler to update the local state
  const handleInputChange = (event) => {
    const { name, value } = event.target
    // Update the local state with the changed value
    setCategory((category) => ({
      ...category,
      [name]: value,
    }))
  }
  return (
    <CDialog
      btnTitle="Edit"
      headerTitle="Inventory Category Update"
      handleCDialogOpen={handleCDialogOpen}
      handleCDialogClose={handleCDialogClose}
      open={open}
    >
      <Box maxWidth="50rem">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="center">
            <Grid xs={12} m={1}>
              <CTextField
                label="Name"
                name="name"
                value={category?.name}
                error={errors && errors.name}
                helperText={errors && errors.name}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Box>
            {' '}
            <Stack sx={{ float: 'left' }}>
              {' '}
              <Typography variant="h5"></Typography>{' '}
            </Stack>
            <Stack
              direction="row"
              justifyContent="flex-end"
              mb={2}
              mt={2}
              mr={1.3}
            >
              <CButton label="Update" icon={<SaveIcon />} type="submit" />
            </Stack>
          </Box>
        </form>
      </Box>
    </CDialog>
  )
}

export default InventoryCategoryUpdate
