import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '@/services/axiosConfig'

// Create Chart Note
export const createOrUpdate = createAsyncThunk(
  'patient/chart/create-or-update',
  async (data) => {
    const response = await axiosInstance.post(
      'patient/chart/create-or-update',
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  }
)

// Amend Chart Note
export const amend = createAsyncThunk(
  'patient/chart/amend',
  async ({ chartNoteId, data }) => {
    const response = await axiosInstance.post(
      `patient/chart/amend/${chartNoteId}`,
      data
    )
    return response.data
  }
)

// Update Chart Note
export const chartUpdate = createAsyncThunk(
  'patient/chart/update',
  async ({ chartNoteId, data }) => {
    const response = await axiosInstance.post(
      `patient/chart/update/${chartNoteId}`,
      data
    )
    return response.data
  }
)

// Fetch all chart notes by patient id
export const fetchChartListByPatientId = createAsyncThunk(
  'patient/patient-chart-list',
  async ({ patientId, chart_type }) => {
    const response = await axiosInstance.get(
      `patient/chart/patient-chart-list/${patientId}?chart_type=${chart_type}`
    )
    return response.data
  }
)

export const updateIsLock = createAsyncThunk(
  'patient/chart/update-is-lock',
  async (data) => {
    const response = await axiosInstance.post(
      'patient/chart/update-is-lock',
      data
    )
    return response.data
  }
)

// Print Chart note
export const chartPrint = createAsyncThunk(
  'patient/chart/print',
  async (chartId) => {
    const response = await axiosInstance.get(`patient/chart/print/${chartId}`)
    return response.data
  }
)

// sendFax
export const sendFax = createAsyncThunk(
  'patient/chart/send-fax',
  async ({ chartId, data }) => {
    const response = await axiosInstance.post(
      `patient/chart/send-fax/${chartId}`,
      data
    )
    return response.data
  }
)

const chartSlice = createSlice({
  name: 'chart',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    charts: null,
    stateChangeCount: 0,
    chartFormBuilder: [],
  },
  reducers: {
    setChartFormBuilder: (state, action) => {
      state.chartFormBuilder = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      // Create Note Template
      .addCase(createOrUpdate.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createOrUpdate.fulfilled, (state, action) => {
        if (action.payload.error) {
          state.message = action.payload.message
          state.errors = action.payload.errors
        } else {
          state.message = action.payload.message
          state.errors = null
        }
        state.isLoading = false
      })
      .addCase(createOrUpdate.rejected, (state) => {
        state.isLoading = false
      })

      // Amend Chart Note
      .addCase(amend.pending, (state) => {
        state.isLoading = true
      })
      .addCase(amend.fulfilled, (state, action) => {
        if (action.payload.error) {
          state.message = action.payload.message
          state.errors = action.payload.errors
        } else {
          state.message = action.payload.message
          state.errors = null
          state.stateChangeCount += 1
        }
        state.isLoading = false
      })
      .addCase(amend.rejected, (state) => {
        state.isLoading = false
      })

      // Chart list
      .addCase(fetchChartListByPatientId.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        fetchChartListByPatientId.fulfilled,
        (state, { payload } = action) => {
          state.charts = payload.charts
          state.isLoading = false
        }
      )
      .addCase(fetchChartListByPatientId.rejected, (state) => {
        state.isLoading = false
      })

      // Update is_lock status
      .addCase(updateIsLock.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateIsLock.fulfilled, (state, action) => {
        if (action.payload.error) {
          state.message = action.payload.message
          state.errors = action.payload.errors
        } else {
          state.message = action.payload.message
          state.errors = null
          state.stateChangeCount += 1
        }
        state.isLoading = false
      })
      .addCase(updateIsLock.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export const { setChartFormBuilder } = chartSlice.actions

export default chartSlice.reducer
