import React from 'react'
import Financial from './Financial'
import InvoiceList from './Partials/InvoiceList'
import PendingInvoiceList from './Partials/PendingInvoiceList'
import MedicardInvoiceList from './Partials/MedicardInvoiceList'
import MedicardPendingInvoiceList from './Partials/MedicardPendingInvoiceList'
// import TrackOrder from '../Patient/Billing/TrackOrder'
import TrackOrder from './Partials/TrackOrder'
import SubscriptionList from './Partials/SubscriptionList'

const financialRoutes = [
  {
    path: '',
    element: <Financial />,
    children: [
      { path: 'invoice-list', element: <InvoiceList /> },
      { path: 'pending-invoice-list', element: <PendingInvoiceList /> },
      { path: 'medicard-invoice-list', element: <MedicardInvoiceList /> },
      { path: 'subscription-list', element: <SubscriptionList /> },
      {
        path: 'medicard-pending-invoice-list',
        element: <MedicardPendingInvoiceList />,
      },
    ],
  },
  { path: 'track-order/:paymentId', element: <TrackOrder /> },
]

export default financialRoutes
