// // UserList.jsx
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { formatDateTime } from '../../../utils/cdayjs'

// Components
import { Helmet } from 'react-helmet-async'
import CDatatable from '../../../components/CDatatable'
import AddIcon from '@mui/icons-material/Add'
import DownloadIcon from '@mui/icons-material/Download'
import DeleteIcon from '@mui/icons-material/Delete'
import SendIcon from '@mui/icons-material/Send'
// Slice
import { setBreadcrumb } from '../../../redux/Slice/breadcrumbSlice'
import {
  Box,
  Button,
  Card,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from '@mui/material'
import CButton from '../../../components/CButton'
import { useDispatch, useSelector } from 'react-redux'
import CDataTable from '../../../components/CDatatable'
import { Edit } from '@mui/icons-material'
import dayjs from 'dayjs'
import { newBookingList } from './consultationSlice'

const NewConsultation = () => {
  const dispatch = useDispatch()
  const { newBooking, isLoading } = useSelector((state) => state.consultation)
  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        {
          path: '/consultation/payment/list',
          label: 'Consultation',
          isLast: false,
        },
        { path: '', label: 'Paid Consultation List', isLast: true },
      ])
    )
  }, [dispatch])

  const [quickFilterValue, setQuickfilterValue] = useState(null)
  // add this when you use server side filter
  const handleQuickFilterValue = (filterModel) => {
    const quickFilterValue = filterModel.quickFilterValues.join(' ')
    dispatch(newBookingList(quickFilterValue))
    setQuickfilterValue(quickFilterValue)
  }

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.5,
    },

    {
      field: 'doctor',
      headerName: 'Doctor',
      flex: 0.4,
    },
    {
      field: 'service_name',
      headerName: 'Service Name',
      flex: 0.7,
    },
    {
      field: 'start',
      headerName: 'Apt. Date',
      flex: 0.7,
      renderCell: (params) => (
        <>{dayjs(params.created_at).format('DD MMM YYYY')}</>
      ),
    },
    {
      field: 'end',
      headerName: 'Apt. Time',
      flex: 0.7,
      renderCell: (params) => <>{dayjs(params.created_at).format('h:mm A')}</>,
    },

    {
      field: 'booked',
      headerName: 'status',
      flex: 0.3,
    },
  ]

  useEffect(() => {
    dispatch(newBookingList())
  }, [])
  return (
    <>
      <Helmet>
        <title>Bioaro | Consultation - List</title>
      </Helmet>
      <Card sx={{ p: 3 }}>
        <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
          <Typography variant="h6">Paid Consultation List</Typography>
        </Stack>
        <CDataTable
          rows={newBooking}
          columns={columns}
          isLoading={isLoading}
          filterMode="server"
          handleQuickFilterValue={handleQuickFilterValue}
        />
      </Card>
    </>
  )
}

export default NewConsultation
