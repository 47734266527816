import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Link, useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'

import { Helmet } from 'react-helmet-async'
import CTextField from '../../../../components/CTextField'
import CEditor from '../../../../components/CEditor'

import { update, fetchNoteDetail } from './noteSlice'
import { showSuccess, showError } from '../../../../redux/Slice/alertSlice'
import { setBreadcrumb } from '../../../../redux/Slice/breadcrumbSlice'

const NoteUpdate = () => {
  const dispatch = useDispatch()

  // change Breadcrumb
  dispatch(
    setBreadcrumb({
      moduleLink: '/template/note/list',
      moduleName: 'Note',
      currentPageName: 'Update',
    })
  )

  const navigate = useNavigate()
  const { id } = useParams()

  const [updatedNote, setUpdatedNote] = useState({
    title: '',
    description: '',
  })
  const { errors, note, isLoading } = useSelector((state) => state.noteTemplate)

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const res = await dispatch(
        update({
          id,
          data: updatedNote,
        })
      )

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        navigate('/template/note/list')
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Server error:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred during updating note template.',
        })
      )
    }
  }

  useEffect(() => {
    document.title = 'BioAro | Note - Update'
    dispatch(fetchNoteDetail(id))
  }, [id])

  // Update the local state when the note data changes
  useEffect(() => {
    if (note) {
      setUpdatedNote({
        title: note.title,
        description: note.description,
      })
    }
  }, [note])

  return (
    <>
      <Helmet>
        <title>Bioaro | Note - Update</title>
      </Helmet>
      {!isLoading && note ? (
        <div className="card">
          <div className="p-3 d-flex justify-content-between align-items-center">
            <h5 className="mb-0">Note</h5>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/template/note/list">List</Link>
                </li>
                <li className="breadcrumb-item active">Update</li>
              </ol>
            </nav>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <CTextField
                    label="Title"
                    name="title"
                    value={updatedNote.title}
                    autoFocus
                    error={!!(errors && errors.title)}
                    helperText={
                      <span style={{ color: 'red' }}>
                        {errors && errors.title}
                      </span>
                    }
                    onChange={(e) =>
                      setUpdatedNote({
                        ...updatedNote,
                        title: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="col-md-8">
                  <CEditor
                    value={updatedNote.description}
                    handleChange={(value) =>
                      setUpdatedNote({ ...updatedNote, description: value })
                    }
                  />
                  <p className="mt-5" style={{ color: 'red' }}>
                    {errors && errors.description}
                  </p>
                </div>
              </div>

              <div className="text-center">
                <Button type="submit" variant="outlined">
                  Save
                </Button>
              </div>
            </form>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default NoteUpdate
