import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Button from '@mui/material/Button'
import TaskIcon from '@mui/icons-material/Task'
import { InputLabel, Stack } from '@mui/material'
import AddCommentIcon from '@mui/icons-material/AddComment'
import SaveIcon from '@mui/icons-material/Save'

// Local Components
import CEditor from '../../../../../components/CEditor'
import CDialog from '../../../../../components/CDialog'

// Redux Actions
import { comment, create, fetchTaskInfo, fetchTaskList } from '../TaskSlice'
import { showSuccess, showError } from '../../../../../redux/Slice/alertSlice'
import CButton from '../../../../../components/CButton'

const TaskComment = ({ taskId, callback = null }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { patientId } = useParams()

  const { errors } = useSelector((state) => state.task)

  const [taskNote, setNote] = useState({
    note: '',
  })
  const [open, setOpen] = React.useState(false)

  const handleCDialogOpen = () => {
    setOpen(true)
  }

  const handleCDialogClose = () => {
    setOpen(false)
  }

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      const res = await dispatch(
        comment({
          data: taskNote,
          taskId: taskId,
        })
      )

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        // Close CDialog after success request
        setOpen(false)
        if (callback) {
          callback()
        } else {
          dispatch(fetchTaskList(patientId))
        }
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Error while creating task:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred during task creation.',
        })
      )
    }
  }
  return (
    <>
      <CDialog
        btnTitle="Comment"
        headerTitle="Add Comment"
        size="small"
        icon={<AddCommentIcon />}
        handleCDialogOpen={handleCDialogOpen}
        handleCDialogClose={handleCDialogClose}
        open={open}
      >
        <form onSubmit={handleSubmit}>
          <CEditor
            name="note"
            value={taskNote.note}
            handleChange={(value) =>
              setNote({
                ...taskNote,
                note: value,
              })
            }
            helperText={errors && errors.note}
          />

          <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
            <CButton label="Save" icon={<SaveIcon />} type="submit" />
          </Stack>
        </form>
      </CDialog>
    </>
  )
}

export default TaskComment
