import { FormLabel, Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import React from 'react'
import { NumericFormat } from 'react-number-format'

function CNumberField(props) {
  const MuiProp = {
    name: props.name,
    value: props.value,
    error: props.error,
    helperText: props.helperText,
    prefix: props.prefix,
    autoFocus: props.autoFocus,
    className: props.className,
    autoComplete: props.autoComplete,
    placeholder: props.placeholder,
    required: props.required ?? false,
    variant: props.variant ?? 'outlined',
    fullWidth: true,
    multiline: props.multiline,
    rows: props.rows,
    maxRows: props.maxRows,
    size: props.size || 'small',
    onChange: props.onChange,
    disabled: false,
  }

  return (
    <>
      <FormLabel htmlFor={`${props.label}${props.name}`}>
        {props.label}
        {props?.required && (
          <Typography sx={{ display: 'inline', color: 'red' }}>*</Typography>
        )}
      </FormLabel>

      <NumericFormat
        id={`${props.label}${props.name}`}
        //   thousandSeparator
        customInput={TextField}
        {...MuiProp}
      />
    </>
  )
}

export default React.memo(CNumberField)
