import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// react-toastify
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { showSuccess, showError } from "../redux/Slice/alertSlice";

const CAlert = () => {
    const dispatch = useDispatch()

    const alert = useSelector((state) => state.alert)

    const tostify = (alert) => {
        if (alert.success){
            toast.success(alert.message, {
                position: 'top-center',
            })
            dispatch(showSuccess({success:false, message: ""}))
            
        }else if(alert.error){
            toast.error(alert.message, {
                position: 'top-center',
            })
            dispatch(showError({error:false, message: ""}))
        }else if(alert.info){
            toast.info(alert.message, {
                position: 'top-center',
            })
        }else if(alert.warning){
            toast.warning(alert.message, {
                position: 'top-center',
            })
        }
    }

    useEffect(() => {
        tostify(alert);
    }, [alert]); // Run the effect whenever `alert` changes

    return (
        <ToastContainer/>
    )
}

export default CAlert;
