import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Pharmacy from './Pharmacy'
import PharmacyCreate from './partial/PharmacyCreate'
import PharmacyUpdate from './partial/PharmacyUpdate'

const pharmacyRoutes = [
  { path: "list", element: <Pharmacy /> },
  { path: "create", element: <PharmacyCreate /> },
  { path: "update/:pharmacyId", element: <PharmacyUpdate /> }
];

export default pharmacyRoutes;

