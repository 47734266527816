import React from 'react'
import FileCategoryList from './partial/FileCategoryList';
import FileCategoryCreate from './partial/FileCategoryCreate';
import FileCategoryUpdate from './partial/FileCategoryUpdate';

const FileCategoryRoute = [
  { path: "list/", element: <FileCategoryList /> },
  { path: "create/", element: <FileCategoryCreate /> },
  { path: "update/:fileCategoryId", element: <FileCategoryUpdate /> }
];

export default FileCategoryRoute;