import CryptoJS from 'crypto-js'

const secretKey = import.meta.env.VITE_LOCAL_STORAGE_ENCRYPTION_KEY

/**
 * Encrypts the given data and stores it in localStorage.
 * @param {string} key - The key under which to store the encrypted data in localStorage.
 * @param {Object} data - The data to encrypt and store.
 */
export const storeData = (key, data) => {
  try {
    const cipherText = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      secretKey
    ).toString()
    localStorage.setItem(key, cipherText)
  } catch (error) {
    console.error('Error encrypting data:', error)
  }
}

/**
 * Retrieves and decrypts the data stored in localStorage.
 * @param {string} key - The key under which the encrypted data is stored in localStorage.
 * @returns {Object|null} - The decrypted data, or null if decryption fails.
 */
export const retrieveData = (key) => {
  try {
    const cipherText = localStorage.getItem(key)
    if (!cipherText) {
      return null
    }
    const bytes = CryptoJS.AES.decrypt(cipherText, secretKey)
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    return decryptedData
  } catch (error) {
    console.error('Error decrypting data:', error)
    return null
  }
}

/**
 * Removes the data stored in localStorage.
 * @param {string} key - The key under which the data is stored in localStorage.
 */
export const removeData = (key) => {
  localStorage.removeItem(key)
}
