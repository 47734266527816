import * as React from 'react'
import { alpha, styled } from '@mui/material/styles'
import { DataGrid, gridClasses } from '@mui/x-data-grid'
import { Box } from '@mui/system'
import CToolbar from './CToolbar'

const ODD_OPACITY = 0.2
const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}))

export default function CDataTable(props) {
  const {
    rows,
    columns,
    isLoading,
    filterMode,
    handleQuickFilterValue,
    CustomToolbar,
    hideFooter,
    getRowId,
    getRowHeight,
  } = props

  // const [quickFilterValue, setQuickfilterValue] = useState(null)
  // // add this when you use server side filter
  // const handleQuickFilterValue = (filterModel) => {
  //   const quickFilterValue = filterModel.quickFilterValues.join(' ')
  //   dispatch(fetchSearchedPatientList(quickFilterValue))
  //   setQuickfilterValue(quickFilterValue)
  // }

  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <StripedDataGrid
        // autoHeight
        density="standard"
        // filterMode="server"
        rows={rows}
        columns={columns}
        loading={isLoading}
        showCellVerticalBorder
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        disableColumnMenu
        checkboxSelection={false}
        hideFooter={hideFooter || false}
        disableRowSelectionOnClick
        disableExporting={true}
        // slots={{ toolbar: CToolbar  }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 30 },
          },
        }}
        pageSizeOptions={[10, 30, 50, 100]}
        filterMode={filterMode || 'client'}
        // onFilterModelChange={handleQuickFilterValue}
        getRowId={getRowId}
        getRowHeight={(params) => {
          return getRowHeight
        }}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
      />
    </Box>
  )
}

// {
//   field: 'patient',
//   headerName: 'Patient',
//   flex: 1,
//   renderCell: ({ value }) => <Box sx={{
//     whiteSpace: 'normal',
//     wordBreak: 'break-word',
//   }}>{value}</Box>,
// },
