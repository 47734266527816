import React from 'react'
import NewsList from './partial/NewsList';
import NewsCreate from './partial/NewsCreate';
import NewsUpdate from './partial/NewsUpdate';
const newsRoutes = [
  { path: "list/", element: <NewsList /> },
  { path: "create/", element: <NewsCreate /> },
  { path: "update/:newsId", element: <NewsUpdate /> },
];

export default newsRoutes;

