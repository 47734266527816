import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 8,
    border: 0,
    backgroundColor: theme.palette.grey[400],
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.grey[400],
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active ||
    (ownerState.completed && {
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    })),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;
  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {props.icon}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const StyledStepper = styled(Stepper)(({ theme }) => ({
  maxWidth: '100%', // Ensure the Stepper doesn't exceed its container's width
  margin: '0 auto', // Center the Stepper horizontally
}));

function CStepper({ icons, steps, activeStep }) {
  const customIcons = icons || {};

  return (
    <StyledStepper
      alternativeLabel
      activeStep={activeStep}
      connector={<ColorlibConnector />}
    >
      {steps.map((label, index) => (
        <Step key={label}>
          <StepLabel
            StepIconComponent={ColorlibStepIcon}
            icon={customIcons[index]}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </StyledStepper>
  );
}

CStepper.propTypes = {
  icons: PropTypes.objectOf(PropTypes.element).isRequired,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeStep: PropTypes.number.isRequired,
};

export default CStepper;


// Steps and icons sequence are will be same

// Step Example:
// const steps = [
//   'Select campaign settings',
//   'Create an ad group',
//   'Create an ad',
//   'Select campaign settings',
//   'Create an ad group',
//   'Create an ad',
//   'Select campaign settings',
// ]

// Icon Example:
// const icons = {
//     1: <IconReload />,
//     2: <IconUserCheck />,
//     3: <IconShoppingBagCheck />,
//     4: <IconBox />,
//     5: <IconFileCertificate />,
//     6: <IconCircleCheckFilled />,
//     7: <IconShoppingBag />,
//   }
