// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '@/services/axiosConfig'

// Seller Site Create
export const create = createAsyncThunk('seller-site/create', async (data) => {
  const response = await axiosInstance.post('seller-site/create', data)
  return response.data
})

// Update Note Template
// export const update = createAsyncThunk("template/note/update", async ({ id, data }) => {
//   const response = await axiosInstance.post(`template/note/update/${id}`, data);
//   console.log(response);
//   return response.data;
// });

// Fetch all seller site
export const fetchSellerSiteList = createAsyncThunk(
  'seller-site/list',
  async () => {
    const response = await axiosInstance.get('seller-site/list')
    return response.data
  }
)

// Fetch seller site order list
export const fetchSellerSiteOrderList = createAsyncThunk(
  'seller-site/order-list',
  async (sellerSiteId) => {
    const response = await axiosInstance.get(
      `seller-site/order-list/${sellerSiteId}`
    )
    return response.data
  }
)

// Fetch seller site detail
export const fetchSellerSiteDetail = createAsyncThunk(
  'seller-site/detail',
  async (sellerSiteId) => {
    const response = await axiosInstance.get(
      `seller-site/detail/${sellerSiteId}`
    )
    return response.data
  }
)

// Fetch seller site update
export const update = createAsyncThunk(
  'seller-site/update',
  async ({sellerSiteId, data}) => {
    const response = await axiosInstance.post(
      `seller-site/update/${sellerSiteId}`, data
    )
    return response.data
  }
)

// Fetch seller site service
export const fetchSellerSiteInfo = createAsyncThunk(
  'seller-site/info',
  async () => {
    const response = await axiosInstance.get('seller-site/info')
    return response.data
  }
)

// Fetch note template detail by note template id
// export const fetchNoteDetail = createAsyncThunk("template/note/detail", async (id) => {
//   const response = await axiosInstance.get(`template/note/detail/${id}`);
//   return response.data;
// });

const sellerSlice = createSlice({
  name: 'sellerSite',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    sellerSiteInfo: null,
    sellerSites: [],
    sellerSite: null,
    orders: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Seller Site Create
      .addCase(create.pending, (state) => {
        state.isLoading = true
      })
      .addCase(create.fulfilled, (state, action) => {
        if (action.payload.error) {
          state.message = action.payload.message
          state.errors = action.payload.errors
        } else {
          state.message = action.payload.message
          state.errors = null
        }
        state.isLoading = false
      })
      .addCase(create.rejected, (state) => {
        state.isLoading = false
      })

      //  seller site service list
      .addCase(fetchSellerSiteList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchSellerSiteList.fulfilled, (state, { payload } = action) => {
        state.sellerSites = payload.sellerSites
        state.isLoading = false
      })
      .addCase(fetchSellerSiteList.rejected, (state) => {
        state.sellerSites = []
        state.isLoading = false
      })

      //  seller site detail
      .addCase(fetchSellerSiteDetail.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        fetchSellerSiteDetail.fulfilled,
        (state, { payload } = action) => {
          state.sellerSite = payload.sellerSite
          state.isLoading = false
        }
      )
      .addCase(fetchSellerSiteDetail.rejected, (state) => {
        state.isLoading = false
      })

      //  seller site service list
      .addCase(fetchSellerSiteOrderList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        fetchSellerSiteOrderList.fulfilled,
        (state, { payload } = action) => {
          state.orders = payload.orders
          state.isLoading = false
        }
      )
      .addCase(fetchSellerSiteOrderList.rejected, (state) => {
        state.isLoading = false
      })

      // Seller Site Update
      .addCase(update.pending, (state) => {
        state.isLoading = true
      })
      .addCase(update.fulfilled, (state, action) => {
        if (action.payload.error) {
          state.message = action.payload.message
          state.errors = action.payload.errors
        } else {
          state.message = action.payload.message
          state.errors = null
        }
        state.isLoading = false
      })
      .addCase(update.rejected, (state) => {
        state.isLoading = false
      })

      // seller site info
      .addCase(fetchSellerSiteInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchSellerSiteInfo.fulfilled, (state, { payload } = action) => {
        state.sellerSiteInfo = payload
        state.isLoading = false
      })
      .addCase(fetchSellerSiteInfo.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default sellerSlice.reducer
