// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '@/services/axiosConfig'

// Fetch security-question list
export const list = createAsyncThunk('website/security-question/list', async () => {
  const response = await axiosInstance.get('website/security-question/list')
  return response.data
})

// Fetch security-question info
export const questionInfo = createAsyncThunk(
  'website/security-question/info',
  async (questionId) => {
    const response = await axiosInstance.get(`website/security-question/info/${questionId}`)
    console.log(response.data)
    return response.data
  }
)

// Fetch security-question list
export const create = createAsyncThunk('website/security-question/create', async (data) => {
  const response = await axiosInstance.post('website/security-question/create', data)
  return response.data
})

// Fetch security-question list
export const update = createAsyncThunk('website/security-question/update', async (data) => {
  const response = await axiosInstance.post('website/security-question/update', data)
  return response.data
})

const securityQuestionSlice = createSlice({
  name: 'securityQuestion',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    questions: [],
    info: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      //Category List
      .addCase(list.pending, (state) => {
        state.isLoading = true
      })
      .addCase(list.fulfilled, (state, { payload } = action) => {
        state.questions = payload.questions
        state.isLoading = false
      })

      .addCase(list.rejected, (state) => {
        state.isLoading = false
      })

      //Category info
      .addCase(questionInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(questionInfo.fulfilled, (state, { payload } = action) => {
        state.info = payload.info
        state.isLoading = false
      })

      .addCase(questionInfo.rejected, (state) => {
        state.isLoading = false
      })

      //Category create
      .addCase(create.pending, (state) => {
        state.isLoading = true
      })
      .addCase(create.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(create.rejected, (state) => {
        state.isLoading = false
      })

      //Category update
      .addCase(update.pending, (state) => {
        state.isLoading = true
      })
      .addCase(update.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(update.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default securityQuestionSlice.reducer
