// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../../services/axiosConfig'

// Fetch intake type list
export const fetchInvoiceList = createAsyncThunk(
  'financial/invoice-list',
  async () => {
    const response = await axiosInstance.get(`financial/invoice-list`)
    return response.data
  }
)

export const fetchPendingInvoiceList = createAsyncThunk(
  'financial/pending-invoice-list',
  async () => {
    const response = await axiosInstance.get(`financial/pending-invoice-list`)
    console.log(response.data)
    return response.data
  }
)

export const subscriptionList = createAsyncThunk(
  'subscription/list',
  async () => {
    const response = await axiosInstance.get(`subscription/list`)
    console.log(response.data)
    return response.data
  }
)
export const fetchMedicardInvoiceList = createAsyncThunk(
  'financial/medicard-invoice-list',
  async () => {
    const response = await axiosInstance.get(`financial/medicard-invoice-list`)
    return response.data
  }
)

export const fetchMedicardPendingInvoiceList = createAsyncThunk(
  'financial/medicard-pending-invoice-list',
  async () => {
    const response = await axiosInstance.get(
      `financial/medicard-pending-invoice-list`
    )
    return response.data
  }
)

export const fetchSubscriptionList = createAsyncThunk(
  'financial/subscription-list',
  async () => {
    const response = await axiosInstance.get(
      `financial/subscription-list`
    )
    return response.data
  }
)

const financialSlice = createSlice({
  name: 'financial',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    invoices: [],
    subscriptions: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Invoice List
      .addCase(fetchInvoiceList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchInvoiceList.fulfilled, (state, { payload } = action) => {
        state.invoices = payload.invoices
        state.isLoading = false
      })
      .addCase(fetchInvoiceList.rejected, (state, { payload }) => {
        state.isLoading = false
      })

      // pending invoice List
      .addCase(fetchPendingInvoiceList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        fetchPendingInvoiceList.fulfilled,
        (state, { payload } = action) => {
          state.invoices = payload.invoices
          state.isLoading = false
        }
      )
      .addCase(fetchPendingInvoiceList.rejected, (state, { payload }) => {
        state.isLoading = false
      })

      // medicard invoice List
      .addCase(fetchMedicardInvoiceList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        fetchMedicardInvoiceList.fulfilled,
        (state, { payload } = action) => {
          state.invoices = payload.invoices
          state.isLoading = false
        }
      )
      .addCase(fetchMedicardInvoiceList.rejected, (state, { payload }) => {
        state.isLoading = false
      })

      // medicard Pending Invoices List
      .addCase(fetchMedicardPendingInvoiceList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        fetchMedicardPendingInvoiceList.fulfilled,
        (state, { payload } = action) => {
          state.invoices = payload.invoices
          state.isLoading = false
        }
      )
      .addCase(
        fetchMedicardPendingInvoiceList.rejected,
        (state, { payload }) => {
          state.isLoading = false
        }
      )

      // Subscription List
      .addCase(fetchSubscriptionList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        fetchSubscriptionList.fulfilled,
        (state, { payload } = action) => {
          state.invoices = payload.invoices
          state.isLoading = false
        }
      )
      .addCase(fetchSubscriptionList.rejected, (state, { payload }) => {
        state.isLoading = false
      })
  },
})

export default financialSlice.reducer
