import { createTheme } from '@mui/material'

const lightTheme = createTheme({
  custom: '#F9FAFBCC',
  palette: {
    mode: 'light',
    custom: {
      main: '#F9FAFBCC', //'#F9FAFBCC',
    },
    primary: {
      main: '#174395',
    },

    secondary: {
      main: '#41464b',
    },

    success: {
      main: '#149865',
    },

    warning: {
      main: '#dda50e',
    },

    purple: {
      main: '#ce0965',
    },

    background: {
      default: '#F9FAFBCC', //'#F9FAFBCC',
      sb: '#121212',
    },

    text: {
      primary: '#212B36', //'#212B36',
      nova: '#fff',
      white: '#fff',
    },
  },
})

export default lightTheme
