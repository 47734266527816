import axios from 'axios'
import { removeData, retrieveData } from './localStorage'

const baseURL = import.meta.env.VITE_BASE_URL

const axiosInstance = axios.create({
  baseURL: `${baseURL}/api/`,
  credentials: 'include',
  headers: {
    'Cache-Control': 'no-cache',
  },
})

const setAuthToken = () => {
  const accessToken = retrieveData('accessToken')
  if (accessToken) {
    axiosInstance.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${accessToken}`
  }
}

setAuthToken()

const redirectToLogin = () => {
  localStorage.setItem('next', window.location.pathname)
  window.location.href = '/'
}

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      removeData('accessToken')
      redirectToLogin()
    }
    return Promise.reject(error)
  }
)

export { axiosInstance, setAuthToken }
