import { Link, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Button from '@mui/material/Button'

import { Helmet } from 'react-helmet-async'
import CTextField from '../../../components/CTextField'
import CAutocomplete from '../../../components/CAutocomplete'
import CSelect from '../../../components/CSelect'
import CDatePicker from '../../../components/CDatePicker'

import { fetchReferralDetail, update } from './referralSlice'
import { showSuccess, showError } from '../../../redux/Slice/alertSlice'
import { setBreadcrumb } from '../../../redux/Slice/breadcrumbSlice'
import { formatDate } from '../../../utils/cdayjs'
import dayjs from 'dayjs'
import { CircularProgress } from '@mui/material'

const ReferralUpdate = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { referralId } = useParams()
  const referralFromRedux = useSelector((state) => state.referral.referral)
  const { errors, provinces, clientTypes, isLoading } = useSelector(
    (state) => state.referral
  )

  // Initialize local state with the data from Redux
  const [referral, setReferral] = useState(null)

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        { path: '/referral/list', label: 'Referral', isLast: false },
        { path: '', label: 'Update', isLast: true },
      ])
    )
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchReferralDetail(referralId))
  }, [])

  useEffect(() => {
    if (referralFromRedux) {
      setReferral(referralFromRedux)
    }
  }, [referralFromRedux])

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const res = await dispatch(
        update({ referralId: referralId, data: referral })
      )

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        navigate('/referral/list')
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Server error:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred while updating the doctor.',
        })
      )
    }
  }

  // onChange handler to update the local state
  const handleInputChange = (event) => {
    const { name, value } = event.target
    // Update the local state with the changed value
    setReferral((doctor) => ({
      ...referral,
      [name]: value,
    }))
  }

  return (
    <>
      <Helmet>
        <title>Bioaro | Referral - Update</title>
      </Helmet>
      {!isLoading && referral ? (
        <div className="card">
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-4 col-12">
                  {/* First Name */}
                  <CTextField
                    label="First Name"
                    name="first_name"
                    value={referral?.first_name}
                    error={!!errors && errors.first_name}
                    helperText={errors && errors.first_name}
                    onChange={handleInputChange}
                  />
                </div>

                {/* Middle Name */}
                <div className="col-md-4 col-12">
                  <CTextField
                    label="Middle Name"
                    name="middle_name"
                    value={referral?.middle_name}
                    error={!!errors && errors.middle_name}
                    helperText={errors && errors.middle_name}
                    onChange={handleInputChange}
                  />
                </div>

                {/* Last Name */}
                <div className="col-md-4 col-12">
                  <CTextField
                    label="Last Name"
                    name="last_name"
                    value={referral?.last_name}
                    error={!!errors && errors.last_name}
                    helperText={errors && errors.last_name}
                    onChange={handleInputChange}
                  />
                </div>

                {/* Email */}
                <div className="col-md-4 col-12">
                  <CTextField
                    label="Email"
                    name="email"
                    value={referral?.email}
                    error={!!errors && errors.email}
                    helperText={errors && errors.email}
                    onChange={handleInputChange}
                  />
                </div>

                {/* Phone */}
                <div className="col-md-4 col-12">
                  <CTextField
                    label="Phone"
                    name="phone"
                    value={referral?.phone}
                    error={!!errors && errors.phone}
                    helperText={errors && errors.phone}
                    onChange={handleInputChange}
                  />
                </div>

                {/* Fax */}
                <div className="col-md-4 col-12">
                  <CTextField
                    label="Fax"
                    name="fax"
                    value={referral?.fax}
                    error={!!errors && errors.fax}
                    helperText={errors && errors.fax}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <hr />

              {/* Address & Contact  */}
              <div className="row mt-4">
                <div className="col-md-6 col-12">
                  <h5>Address & Contact</h5>
                  <p>
                    *Referral Doctor address, phone number and email are managed
                    on the 'Ref Doc tab
                  </p>
                </div>
                <div className="col-md-6 col-12">
                  <h5>Identifiers</h5>
                  <div className="row">
                    <div className="col-6">
                      <CAutocomplete
                        label="Recovery Province"
                        options={provinces}
                        getOptionLabel={(option) => option.name}
                        value={referral?.recovery_prov}
                        isOptionEqualToValue={(option, value) =>
                          option.name == value.name
                        }
                        onChange={(e, value) =>
                          setReferral({ ...referral, recovery_prov: value })
                        }
                        error={!!(errors && errors.recovery_prov)}
                        helperText={errors && errors.recovery_prov}
                      />
                    </div>
                    <div className="col-6">
                      <CTextField
                        label="Reg No."
                        name="reg_no"
                        value={referral?.reg_no}
                        error={!!errors && errors.reg_no}
                        helperText={errors && errors.reg_no}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-6">
                      <CTextField
                        label="Practice Id"
                        name="practice"
                        value={referral?.practice}
                        error={!!errors && errors.practice}
                        helperText={errors && errors.practice}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <hr />
              {/* Admin  */}
              <div className="row mt-4">
                <div className="col-md-3 col-12">
                  <CAutocomplete
                    label="Client Type"
                    options={clientTypes}
                    getOptionLabel={(option) => option.name}
                    defaultValue={referral?.client_type_id}
                    isOptionEqualToValue={(option, value) =>
                      option.name == value.name
                    }
                    onChange={(e, value) =>
                      setReferral({ ...referral, client_type_id: value })
                    }
                    error={!!(errors && errors.client_type_id)}
                    helperText={errors && errors.client_type_id}
                  />
                </div>
                <div className="col-md-3 col-12 mt-3">
                  <CDatePicker
                    label="End Date"
                    name="end_date"
                    value={
                      referral?.end_date ? dayjs(referral?.end_date) : null
                    }
                    onChange={(value) =>
                      setReferral({ ...referral, end_date: formatDate(value) })
                    }
                  />
                </div>
                <div className="col-md-3 col-12">
                  <CSelect
                    label="End Date Reason"
                    name="end_date_reason"
                    value={referral?.end_date_reason}
                    options={[
                      { label: 'r1', value: 'r1' },
                      { label: 'r2', value: 'r2' },
                    ]}
                    onChange={(e) =>
                      setReferral({
                        ...referral,
                        end_date_reason: e.target.value,
                      })
                    }
                    error={!!(errors && errors.end_date_reason)}
                    helperText={errors && errors.end_date_reason}
                  />
                </div>
                <div className="col-md-3 col-12 mt-3">
                  <CDatePicker
                    label="First Act Date"
                    name="first_act_date"
                    value={
                      referral?.first_act_date
                        ? dayjs(referral?.first_act_date)
                        : null
                    }
                    onChange={(value) =>
                      setReferral({
                        ...referral,
                        first_act_date: formatDate(value),
                      })
                    }
                  />
                </div>
                <div className="col-md-3 col-12 mt-3">
                  <CDatePicker
                    label="Last Act Date"
                    name="last_act_date"
                    value={
                      referral?.last_act_date
                        ? dayjs(referral?.last_act_date)
                        : null
                    }
                    onChange={(value) =>
                      setReferral({
                        ...referral,
                        last_act_date: formatDate(value),
                      })
                    }
                  />
                </div>
                <div className="col-md-3 col-12">
                  <CTextField
                    label="Dig Code 1"
                    name="diag_code"
                    value={referral?.diag_code}
                    error={!!errors && errors.diag_code}
                    helperText={errors && errors.diag_code}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-3 col-12">
                  <CTextField
                    label="Default Doc"
                    name="default_doc"
                    value={referral?.default_doc}
                    error={!!errors && errors.default_doc}
                    helperText={errors && errors.default_doc}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-3 col-12">
                  <CTextField
                    label="Ref Doc"
                    name="referral_doc"
                    value={referral?.referral_doc}
                    error={!!errors && errors.referral_doc}
                    helperText={errors && errors.referral_doc}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-3 col-12 mt-3">
                  <CDatePicker
                    label="Hosp Adm Date"
                    name="hosp_amd_date"
                    value={
                      referral?.hosp_amd_date
                        ? dayjs(referral?.hosp_amd_date)
                        : null
                    }
                    onChange={(value) =>
                      setReferral({
                        ...referral,
                        hosp_amd_date: formatDate(value),
                      })
                    }
                  />
                </div>
              </div>

              <div className="text-center mt-3">
                <Button type="submit" variant="outlined">
                  Save
                </Button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: 'grid',
            placeItems: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress />
        </div>
      )}
    </>
  )
}

export default ReferralUpdate
