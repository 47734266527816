// cdialogSlice.js

import { createSlice } from "@reduxjs/toolkit";

const cdialogSlice = createSlice({
  name: "cdialog",
  initialState: {
    cdialog1: false, 
    cdialog2: false,// Initially, the dialog is closed
  },
  reducers: {
    openCDialog: (state, action) => {
      state[action.payload] = true;
    },
    closeCDialog: (state, action) => {
      state[action.payload] = false;
    },
  },
});

export const { openCDialog, closeCDialog } = cdialogSlice.actions;

export default cdialogSlice.reducer;
