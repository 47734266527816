// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../../../services/axiosConfig'

export const create = createAsyncThunk(
  'patient/consent/create',
  async ({ patientId, data }) => {
    const response = await axiosInstance.post(
      `patient/consent/create/${patientId}`,
      data
    )
    return response.data
  }
)

// Fetch Consent List
export const fetchConsentList = createAsyncThunk(
  'patient/consent/list',
  async (patientId) => {
    const response = await axiosInstance.get(
      `patient/consent/list/${patientId}`
    )
    return response.data
  }
)

// Fetch Consent detail by its id
export const fetchConsentDetail = createAsyncThunk(
  'consent/detail',
  async (consentId) => {
    const response = await axiosInstance.get(
      `patient/consent/detail/${consentId}`
    )
    return response.data
  }
)

// consent resend
export const consentResend = createAsyncThunk(
  'consent/consent-resend',
  async (consentId) => {
    const response = await axiosInstance.post(
      `patient/consent/consent-resend/${consentId}`
    )
    return response.data
  }
)

// File trash (temporary delete it can be restored)
export const consentTrash = createAsyncThunk(
  'patient/consent/trash',
  async (consentId) => {
    const response = await axiosInstance.post(
      `patient/consent/trash/${consentId}`
    )
    return response.data
  }
)

const consentSlice = createSlice({
  name: 'consent',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    consent: null,
    pendingConsents: [],
    acceptConsents: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Consent Create
      .addCase(create.pending, (state) => {
        state.isLoading = true
      })
      .addCase(create.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(create.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch  consent list
      .addCase(fetchConsentList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchConsentList.fulfilled, (state, { payload }) => {
        state.pendingConsents = payload.pendingConsents
        state.acceptConsents = payload.acceptConsents
        state.isLoading = false
      })
      .addCase(fetchConsentList.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch  consent detail
      .addCase(fetchConsentDetail.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchConsentDetail.fulfilled, (state, { payload }) => {
        state.consent = payload.consent
        state.isLoading = false
      })
      .addCase(fetchConsentDetail.rejected, (state) => {
        state.isLoading = false
      })

      // Consent Resend
      .addCase(consentResend.pending, (state) => {
        state.isLoading = true
      })
      .addCase(consentResend.fulfilled, (state, { payload }) => {
        state.message = payload.message
        state.isLoading = false
      })
      .addCase(consentResend.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default consentSlice.reducer
