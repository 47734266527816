// // UserList.jsx
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { formatDateTime } from '../../../utils/cdayjs'

// Components
import { Helmet } from 'react-helmet-async'
import CDatatable from '../../../components/CDatatable'
import AddIcon from '@mui/icons-material/Add'
// Slice
import { setBreadcrumb } from '../../../redux/Slice/breadcrumbSlice'
import {
  Box,
  Button,
  Card,
  Chip,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Typography,
} from '@mui/material'
import { changeStatus, deleteRecord } from '@/redux/Slice/commonSlice'
import CButton from '../../../components/CButton'
import { useDispatch, useSelector } from 'react-redux'
import CDataTable from '../../../components/CDatatable'
import { Edit } from '@mui/icons-material'
import { list } from './intakeTypeSlice'
import { renderCell } from '@/utils/ctableTool'
import { showError, showSuccess } from '@/redux/Slice/alertSlice'

const IntakeType = () => {
  const dispatch = useDispatch()
  const { intakeTypes, isLoading, errors } = useSelector((state) => state.intakeType)
  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        {
          path: '/intake-type/list',
          label: 'Intake Type',
          isLast: false,
        },
        { path: '', label: 'List', isLast: true },
      ])
    )
  }, [dispatch])

  const [quickFilterValue, setQuickfilterValue] = useState(null)
  // add this when you use server side filter
  const handleQuickFilterValue = (filterModel) => {
    const quickFilterValue = filterModel.quickFilterValues.join(' ')
    dispatch(list(quickFilterValue))
    setQuickfilterValue(quickFilterValue)
  }

     // Handle form submission
     const handleStatusChange = async (floorId, status, model, column) => {
      try {
        const res = await dispatch(
          changeStatus({
            id: floorId,
            status: status.toString(),
            model: model,
            column: column,
          })
        )
  
        if (res.payload && !res.payload.error) {
          dispatch(
            showSuccess({
              success: true,
              message: res.payload.message,
            })
          )
          dispatch(list())
        } else {
          dispatch(
            showError({
              error: true,
              message: res.payload.message,
            })
          )
        }
      } catch (error) {
        console.error('Error while creating File:', error)
        dispatch(
          showError({
            error: true,
            message: 'An error occurred during File creation.',
          })
        )
      }
    }

  const columns = [
    {
      field: 'title',
      headerName: 'Title',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'form',
      headerName: 'Form',
      minWidth: 150,
      flex: 1,
      renderCell: (params) =>
        renderCell(
          <Grid container row spacing={0.5}>
            {params.row.form.map((item, index) => (
              <Grid item key={index} mr={0.5}>
                <Chip color="success" size="small" label={item} />
              </Grid>
            ))}
          </Grid>
        ),
    },
    {
      field: 'category',
      headerName: 'Category',
      minWidth: 150,
      flex: 1,
      renderCell: (params) =>
        renderCell(
          <Grid container row spacing={1} m={0.5}>
            {params.row.category == null
              ? ''
              : params.row.category.map((item, index) => (
                  <Grid item key={index} mr={0.5}>
                    <Chip color="success" size="small" label={item.name} />
                  </Grid>
                ))}
          </Grid>
        ),
    },
    {
      headerName: 'Action',
      minWidth: 250,
      flex: 1,
      renderCell: (params) => (
        <>
          <CButton
            label="Edit"
            icon={<Edit />}
            component={Link}
            to={`/intake-type/update/${params.row.id}`}
          />
          <CButton
            component={Link}
            //   to={`/leads/view-log/${params.row.id}`}
            label={params.row.status == 1 ? 'On' : 'Off'}
            sx={{
              background: params.row.status == 1 ? '#0a8154' : '#cf2323',marginLeft:"3px"
            }}
            onClick={() =>
              handleStatusChange(
                params.row.id,
                params.row.status == 0 ? 1 : 0,
                'IntakeType',
                'status'
              )
            }
          />
        </>
      ),
    },
  ]

  useEffect(() => {
    // page title
    document.title = 'BioAro | IntakeType - List'
    dispatch(list())
  }, [])

  return (
    <>
      <Helmet>
        <title>Bioaro | IntakeType - List</title>
      </Helmet>
      <Card sx={{ p: 3 }}>
        <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
          <Typography variant="h6">
            {quickFilterValue ? 'Patient List' : 'Intake Type'}
          </Typography>
          <CButton
            label="New intakeType"
            icon={<AddIcon />}
            component={Link}
            to={`/intake-type/create`}
          />
        </Stack>
        <CDataTable
          rows={intakeTypes}
          columns={columns}
          isLoading={isLoading}
          filterMode="server"
          handleQuickFilterValue={handleQuickFilterValue}
          getRowHeight="auto"
        />
      </Card>
    </>
  )
}

export default IntakeType
