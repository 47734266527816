import React from 'react'
import ReactDOM from 'react-dom/client'

import { PersistGate } from 'redux-persist/integration/react'

import App from './App.jsx'
import { store, persistor } from './redux/store.js'
import { Provider } from 'react-redux'
import CAlert from './components/CAlert.jsx'

ReactDOM.createRoot(document.getElementById('root')).render(
  // <React.StrictMode>
  <Provider store={store}>
    
    <PersistGate loading={null} persistor={persistor}>
      <CAlert />
      <App />
    </PersistGate>
  </Provider>
  // </React.StrictMode>
)
