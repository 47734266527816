// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../../services/axiosConfig'

export const fetchDashboardInfo = createAsyncThunk(
  'dashboard-info',
  async () => {
    const response = await axiosInstance.get('dashboard-info')
    return response.data
  }
)

// Fetch PatientFollowUp List
export const fetchPatientFollowUpList = createAsyncThunk(
  'patient-follow-up-list',
  async (filterData) => {
    const response = await axiosInstance.get('patient-follow-up-list', {
      params: filterData,
    })
    return response.data
  }
)

// task comment Create
export const taskCommentCreate = createAsyncThunk(
  'task-comment-create',
  async (data) => {
    const response = await axiosInstance.post('task-comment-create', data)
    return response.data
  }
)

// task Update
export const taskUpdate = createAsyncThunk(
  'task-update',
  async ({ data, taskId }) => {
    const response = await axiosInstance.post(`task-update/${taskId}`, data)
    return response.data
  }
)

// Fetch Report List
export const reportList = createAsyncThunk(
  'report-list',
  async (filterData) => {
    const response = await axiosInstance.get('report-list')
    return response.data
  }
)

// Fetch Report List
export const pendingPaymentList = createAsyncThunk(
  'pending-payment-list',
  async (filterData) => {
    const response = await axiosInstance.get('pending-payment-list')
    return response.data
  }
)

// Fetch physician detail by user id
// export const fetchPhysicianDetail = createAsyncThunk(
//   'physician/detail',
//   async (id) => {
//     const response = await axiosInstance.get(`physician/detail/${id}`)
//     return response.data
//   }
// )

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    dashboardInfo: null,
    patientFollowUps: [],
    reports: [],
    pendingPayments: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch  dashboard info
      .addCase(fetchDashboardInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchDashboardInfo.fulfilled, (state, { payload } = action) => {
        state.dashboardInfo = payload
        state.isLoading = false
      })
      .addCase(fetchDashboardInfo.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch  Patient Follow Up list
      .addCase(fetchPatientFollowUpList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        fetchPatientFollowUpList.fulfilled,
        (state, { payload } = action) => {
          state.patientFollowUps = payload.patientFollowUps
          state.isLoading = false
        }
      )
      .addCase(fetchPatientFollowUpList.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch  physician Create
      .addCase(taskCommentCreate.pending, (state) => {
        state.isLoading = true
      })
      .addCase(taskCommentCreate.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(taskCommentCreate.rejected, (state) => {
        state.isLoading = false
      })

      //task Update
      .addCase(taskUpdate.pending, (state) => {
        state.isLoading = true
      })
      .addCase(taskUpdate.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(taskUpdate.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch  Report list
      .addCase(reportList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(reportList.fulfilled, (state, { payload } = action) => {
        state.reports = payload.reports
        state.isLoading = false
      })
      .addCase(reportList.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch  Pending Payment list
      .addCase(pendingPaymentList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(pendingPaymentList.fulfilled, (state, { payload } = action) => {
        state.pendingPayments = payload.pendingPayments
        state.isLoading = false
      })
      .addCase(pendingPaymentList.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default dashboardSlice.reducer
