import * as React from 'react'
// // UserList.jsx
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import { formatDateTime, formatTime } from '../../../../utils/cdayjs'

// tab
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'

import ButtonGroup from '@mui/material/ButtonGroup'
// Components
import { Helmet } from 'react-helmet-async'
import AddIcon from '@mui/icons-material/Add'
import DownloadIcon from '@mui/icons-material/Download'

// Slice
import { setBreadcrumb } from '../../../../redux/Slice/breadcrumbSlice'
import {
  Box,
  Button,
  Card,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Typography,
} from '@mui/material'
import CButton from '../../../../components/CButton'
import { useDispatch, useSelector } from 'react-redux'
import CDataTable from '../../../../components/CDatatable'
import { Edit } from '@mui/icons-material'
import dayjs from 'dayjs'

import { logs } from '../leadsSlice'
import CDialog from '../../../../components/CDialog'
import SendOutlined from '@mui/icons-material/SendOutlined'
import NoteCreate from './NoteCreate'

const ViewLog = () => {
  const dispatch = useDispatch()
  const { leadId } = useParams()

  //tab
  const [value, setValue] = React.useState('1')
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const { data, isLoading } = useSelector((state) => state.lead)

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        {
          path: '/leads/list',
          label: 'Leads',
          isLast: false,
        },
        { path: '', label: 'View Log', isLast: true },
      ])
    )
  }, [dispatch])

  const [quickFilterValue, setQuickfilterValue] = useState(null)
  // add this when you use server side filter
  const handleQuickFilterValue = (filterModel) => {
    const quickFilterValue = filterModel.quickFilterValues.join(' ')
    dispatch(logs(quickFilterValue))
    setQuickfilterValue(quickFilterValue)
  }

  const columns = [
    {
      field: 'type',
      headerName: 'Type',
      flex: 0.4,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.4,
    },
    {
      field: 'date',
      headerName: 'Date',
      flex: 0.5,
    },

    {
      field: 'note',
      headerName: 'note',
      flex: 0.4,
    },
    {
      field: 'change_by',
      headerName: 'createdBy',
      flex: 0.3,
    },
    {
      headerName: 'Attachment',
      flex: 0.2,
      renderCell: (params) => (
        <>
          {params.row.file != null ? (
            <CButton
              label="View"
              component={Link}
              to={params.row.file}
              target="_blank"
            />
          ) : (
            ''
          )}
        </>
      ),
    },
  ]

  const rebookColumns = [
    {
      field: 'date',
      headerName: 'Date',
      flex: 0.4,
    },
    {
      field: 'start_time',
      headerName: 'Start Time',
      flex: 0.4,
    },
    {
      field: 'end_time',
      headerName: 'End Time',
      flex: 0.5,
    },

    {
      field: 'note',
      headerName: 'note',
      flex: 0.4,
    },

    {
      headerName: 'Attachment',
      flex: 0.2,
      renderCell: (params) => (
        <>
          {params.row.file != null ? (
            <CButton
              label="View"
              component={Link}
              to={params.row.file}
              target="_blank"
            />
          ) : (
            ''
          )}
        </>
      ),
    },
  ]

  useEffect(() => {
    dispatch(logs(leadId))
  }, [leadId])
  useEffect(() => {}, [data.doctorNote])

  return (
    <>
      <Helmet>
        <title>Bioaro | Leads - List</title>
      </Helmet>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Log" value="1" />
              <Tab label="Rebook Log" value="2" />

              <Grid container>
                <Grid item md={4}></Grid>
                <Grid item md={6}>
                  <Stack mt={0.5}>{data?.services}</Stack>
                </Grid>
                <Grid item>
                  <Stack mt={0.5}>Name : {data?.full_name}</Stack>
                  {/* <Tab label={`Name : ${data?.full_name}`} /> */}
                </Grid>
              </Grid>
            </TabList>
          </Box>
          <TabPanel value="1">
            <Card>
              <CDataTable
                rows={data?.doctorNote ?? []}
                columns={columns}
                isLoading={isLoading}
                filterMode="server"
                handleQuickFilterValue={handleQuickFilterValue}
              />
            </Card>
          </TabPanel>
          <TabPanel value="2">
            <Card>
              <CDataTable
                rows={data?.doctorRebook ?? []}
                columns={rebookColumns}
                isLoading={isLoading}
                filterMode="server"
                handleQuickFilterValue={handleQuickFilterValue}
              />
            </Card>
          </TabPanel>
        </TabContext>
      </Box>
    </>
  )
}

export default ViewLog
