import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../../services/axiosConfig'

export const patientList = createAsyncThunk('chat/patient-list', async () => {
  const response = await axiosInstance.get(`chat/patient-list`)
  return response.data
})

export const staffList = createAsyncThunk('chat/staff-list', async () => {
  const response = await axiosInstance.get(`chat/staff-list`)
  return response.data
})

export const messageList = createAsyncThunk(
  'chat/message-list',
  async (receiverId) => {
    const response = await axiosInstance.get(`chat/message-list/${receiverId}`)
    return response.data
  }
)

export const messageCreate = createAsyncThunk(
  'chat/message-create',
  async (data) => {
    const response = await axiosInstance.post('chat/message-create', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  }
)

export const messageUpdate = createAsyncThunk(
  'chat/message-update',
  async ({ messageId, data }) => {
    const response = await axiosInstance.post(
      `chat/message-update/${messageId}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  }
)

export const messageDelete = createAsyncThunk(
  'chat/message-delete',
  async (messageId) => {
    const response = await axiosInstance.get(`chat/message-delete/${messageId}`)
    return response.data
  }
)

export const messageForward = createAsyncThunk(
  'chat/message-forward',
  async ({ data }) => {
    const response = await axiosInstance.post(`chat/message-forward/`, data)
    return response.data
  }
)

const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    chatList: [],
    receiver: null,
    messages: [],
    onlineUsers: [],
    isOnline: false,
    messageId: null,
    messageIndex: null,
    isReply: false,
  },
  reducers: {
    setChatList: (state, action) => {
      state.chatList = action.payload
    },
    setReceiver: (state, action) => {
      state.receiver = action.payload
    },
    setOnlineUsers: (state, { payload }) => {
      state.onlineUsers = payload
      // console.log(payload)
    },
    setIsOnline: (state, { payload }) => {
      state.isOnline = payload
    },

    setMessages: (state, { payload }) => {
      state.messages = payload
    },

    handleMsgClick: (state, { payload }) => {
      state.isReply = payload.isReply
      state.messageId = payload.messageId
      state.messageIndex = payload.messageIndex
    },
    handleForwardClick: (state, { payload }) => {
      state.messageId = payload.messageId
    },
  },
  extraReducers: (builder) => {
    builder

      // Message List
      .addCase(messageList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(messageList.fulfilled, (state, { payload } = action) => {
        state.messages = payload.messages
        state.isLoading = false
      })
      .addCase(messageList.rejected, (state, { payload }) => {
        state.isLoading = false
      })
  },
})

export const {
  setChatList,
  setReceiver,
  setOnlineUsers,
  setIsOnline,
  setMessages,
  handleMsgClick,
  handleForwardClick,
} = chatSlice.actions

export default chatSlice.reducer
