// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../../../services/axiosConfig'

// This function is called to set the auth header

// Fetch Clinic List
export const fetchClinicList = createAsyncThunk(
  'clinic/list',
  async () => {
    const response = await axiosInstance.get('clinic/list')
    return response.data
  }
)

// Clinic Create
export const create = createAsyncThunk('clinic/create', async ({ data }) => {
  const response = await axiosInstance.post(`clinic/create`, data)
  return response.data
})

// Clinic Update
export const update = createAsyncThunk(
  'clinic/update',
  async ({ clinicId, data }) => {
    const response = await axiosInstance.post(
      `clinic/update/${clinicId}`,
      data
    )
    return response.data
  }
)

// Fetch clinic detail by user id
export const fetchClinicDetail = createAsyncThunk(
  'clinic/detail',
  async (id) => {
    const response = await axiosInstance.get(`clinic/detail/${id}`)
    return response.data
  }
)

// Fetch clinic schedule by clinicId
export const fetchClinicSchedule = createAsyncThunk(
  'clinic/schedule',
  async (clinicId) => {
    const response = await axiosInstance.get(
      `clinic/schedule/${clinicId}`
    )
    return response.data
  }
)

// Clinic Schedule Create
export const clinicScheduleCreate = createAsyncThunk(
  'clinic/schedule-create',
  async ({ clinicId, data }) => {
    const response = await axiosInstance.post(
      `clinic/schedule-create/${clinicId}`,
      data
    )
    return response.data
  }
)

// Clinic Per day schedule Create
export const clinicPerDayScheduleCreate = createAsyncThunk(
  'clinic/per-day-schedule-create',
  async ({ clinicId, data }) => {
    const response = await axiosInstance.post(
      `clinic/per-day-schedule-create/${clinicId}`,
      data
    )
    return response.data
  }
)

// Fetch clinic leaves by clinicId
export const fetchClinicLeave = createAsyncThunk(
  'clinic/leaves',
  async (clinicId) => {
    const response = await axiosInstance.get(`clinic/leaves/${clinicId}`)
    return response.data
  }
)

// Clinic leave Create
export const clinicLeaveCreate = createAsyncThunk(
  'clinic/leave-create',
  async ({ clinicId, data }) => {
    const response = await axiosInstance.post(
      `clinic/leave-create/${clinicId}`,
      data
    )
    return response.data
  }
)

const orderSlice = createSlice({
  name: 'clinic',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    clinic: null,
    clinics: [],
    clinicSchedules: null,
    clinicLeaves: null,
    perDaySchedules: null,
    currentDate: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Fetch  clinic Create
      .addCase(create.pending, (state) => {
        state.isLoading = true
      })
      .addCase(create.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(create.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch  clinic list
      .addCase(fetchClinicList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchClinicList.fulfilled, (state, { payload } = action) => {
        state.clinics = payload.clinics
        state.isLoading = false
      })
      .addCase(fetchClinicList.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch  clinic detail
      .addCase(fetchClinicDetail.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        fetchClinicDetail.fulfilled,
        (state, { payload } = action) => {
          state.clinic = payload.clinic
          state.isLoading = false
        }
      )
      .addCase(fetchClinicDetail.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch  clinic Schedule
      .addCase(fetchClinicSchedule.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        fetchClinicSchedule.fulfilled,
        (state, { payload } = action) => {
          state.clinicSchedules = payload.clinic_schedules
          state.clinic = payload.clinic
          state.perDaySchedules = payload.per_day_schedules
          state.currentDate = payload.currentDate
          state.isLoading = false
        }
      )
      .addCase(fetchClinicSchedule.rejected, (state) => {
        state.isLoading = false
      })

      // clinic schedule create
      .addCase(clinicScheduleCreate.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        clinicScheduleCreate.fulfilled,
        (state, { payload } = action) => {
          state.message = payload.message
          state.errors = payload.errors
          state.isLoading = false
        }
      )
      .addCase(clinicScheduleCreate.rejected, (state) => {
        state.isLoading = false
      })

      // clinic perDaySchedule create
      .addCase(clinicPerDayScheduleCreate.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        clinicPerDayScheduleCreate.fulfilled,
        (state, { payload } = action) => {
          state.message = payload.message
          state.errors = payload.errors
          state.isLoading = false
        }
      )
      .addCase(clinicPerDayScheduleCreate.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch  clinic Leave
      .addCase(fetchClinicLeave.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchClinicLeave.fulfilled, (state, { payload } = action) => {
        state.clinicLeaves = payload.clinic_leaves
        state.clinic = payload.clinic
        state.isLoading = false
      })
      .addCase(fetchClinicLeave.rejected, (state) => {
        state.isLoading = false
      })

      // clinic leave create
      .addCase(clinicLeaveCreate.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        clinicLeaveCreate.fulfilled,
        (state, { payload } = action) => {
          state.message = payload.message
          state.errors = payload.errors
          state.isLoading = false
        }
      )
      .addCase(clinicLeaveCreate.rejected, (state) => {
        state.isLoading = false
      })

      //   clinic update
      .addCase(update.pending, (state) => {
        state.isLoading = true
      })
      .addCase(update.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(update.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default orderSlice.reducer
