import { Stack } from '@mui/material'
import React from 'react'
import CButton from '@/components/CButton'
import { Link } from 'react-router-dom'

export default function FinancialButton() {
  return (
    <>
      <Stack direction="row" spacing={0.4} mb={2}>
        <CButton
          size="small"
          label="Invoice List"
          component={Link}
          to={'/financial/invoice-list/'}
        />
        <CButton
          size="small"
          label="Pending Invoice"
          component={Link}
          to={`/financial/pending-invoice-list/`}
        />
        <CButton
          size="small"
          label="Subscription List"
          component={Link}
          to={`/financial/subscription-list/`}
        />
        <CButton
          size="small"
          label="Medicard Invoice list"
          component={Link}
          to={`/financial/medicard-invoice-list/`}
        />
        <CButton
          size="small"
          label="Medicard pending Invoice"
          component={Link}
          to={`/financial/medicard-pending-invoice-list/`}
        />
      </Stack>
    </>
  )
}
