// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '@/services/axiosConfig'

// Fetch expense list
export const list = createAsyncThunk('expense/list', async () => {
  const response = await axiosInstance.get('expense/list')
  return response.data
})

// Fetch expense info
export const expenseInfo = createAsyncThunk(
  'expense/info',
  async (expenseId) => {
    const response = await axiosInstance.get(
      `inventory/expense/info/${expenseId}`
    )
    console.log(response.data)
    return response.data
  }
)

// Fetch expense create
export const create = createAsyncThunk('expense/create', async (data) => {
  const response = await axiosInstance.post('expense/create', data)
  return response.data
})

// Fetch expense update
export const update = createAsyncThunk('expense/update', async (data) => {
  const response = await axiosInstance.post('expense/update', data)
  return response.data
})

const expenseSlice = createSlice({
  name: 'expense',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    expenses: [],
    users: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      //Category List
      .addCase(list.pending, (state) => {
        state.isLoading = true
      })
      .addCase(list.fulfilled, (state, { payload } = action) => {
        state.expenses = payload.expenses
        state.staffs = payload.staffs
        state.categories = payload.categories
        state.isLoading = false
      })

      .addCase(list.rejected, (state) => {
        state.isLoading = false
      })

      //Category info
      .addCase(expenseInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(expenseInfo.fulfilled, (state, { payload } = action) => {
        state.info = payload.info
        state.isLoading = false
      })

      .addCase(expenseInfo.rejected, (state) => {
        state.isLoading = false
      })

      //Category create
      .addCase(create.pending, (state) => {
        state.isLoading = true
      })
      .addCase(create.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(create.rejected, (state) => {
        state.isLoading = false
      })

      //Category update
      .addCase(update.pending, (state) => {
        state.isLoading = true
      })
      .addCase(update.fulfilled, (state, { payload } = action) => {
        state.message = payload.message
        state.errors = payload.errors
        state.isLoading = false
      })
      .addCase(update.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default expenseSlice.reducer
