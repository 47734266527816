// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../../services/axiosConfig'



// Fetch Place Order info
export const fetchPlaceOrderInfo = createAsyncThunk(
  'place-order/info',
  async () => {
    const response = await axiosInstance.get('place-order/info/')
    return response.data
  }
)

// place customer create
export const customerCreate = createAsyncThunk('place-order/customer-create', async (data) => {
  const response = await axiosInstance.post('place-order/customer-create', data)
  return response.data
})

const placeOrderSlice = createSlice({
  name: 'placeOrder',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    placeOrderInfo: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Customer Create
      .addCase(customerCreate.pending, (state) => {
        state.isLoading = true
      })
      .addCase(customerCreate.fulfilled, (state, action) => {
        if (action.payload.error) {
          state.message = action.payload.message
          state.errors = action.payload.errors
        } else {
          state.message = action.payload.message
          state.errors = null
        }
        state.isLoading = false
      })
      .addCase(customerCreate.rejected, (state) => {
        state.isLoading = false
      })

      // Fetch Place Order info
      .addCase(fetchPlaceOrderInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        fetchPlaceOrderInfo.fulfilled,
        (state, { payload } = action) => {
          state.placeOrderInfo = payload
          state.isLoading = false
        }
      )
      .addCase(fetchPlaceOrderInfo.rejected, (state, { payload }) => {
        state.isLoading = false
      })
  },
})

export default placeOrderSlice.reducer
