// // UserList.jsx
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Icon
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import AddIcon from '@mui/icons-material/Add'
import { Button, ButtonGroup, Card, Stack } from '@mui/material'
// Components
import AdminLayout from '@/layouts/AdminLayout'

// Slice
import { setBreadcrumb } from '@/redux/Slice/breadcrumbSlice'
import { Link } from 'react-router-dom'
import CButton from '@/components/CButton'
import CDatatable from '@/components/CDatatable'
import { changeStatus, deleteRecord } from '@/redux/Slice/commonSlice'
import { showError, showSuccess } from '@/redux/Slice/alertSlice'
import { list } from '../fileCategorySlice'
import { Helmet } from 'react-helmet-async'

const FileCategoryList = () => {
  const dispatch = useDispatch()
  const { categories, isLoading } = useSelector((state) => state.fileCategory)

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        { path: '/file-category/list', label: 'File Category', isLast: false },
        { path: '', label: 'List', isLast: true },
      ])
    )
  }, [dispatch])

  const handleQuickFilterValue = (filterModel) => {
    const quickFilterValue = filterModel.quickFilterValues.join(' ')
    console.log(quickFilterValue)
  }

  const columns = [
    { field: 'name', headerName: 'name', flex: 1 },
    { field: 'type', headerName: 'Category Type', flex: 1 },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => (
        <>
          <CButton
            component={Link}
            //   to={`/leads/view-log/${params.row.id}`}
            label={params.row.status == 1 ? 'On' : 'Off'}
            sx={{
              background: params.row.status == 1 ? '#0a8154' : '#cf2323',
            }}
            onClick={() =>
              handleStatusChange(
                params.row.id,
                params.row.status == 0 ? 1 : 0,
                'FileCategory',
                'status'
              )
            }
          />
        </>
      ),
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 0.5,
      renderCell: (params) => (
        <ButtonGroup variant="contained" aria-label="Basic button group">
          <CButton
            component={Link}
            to={`/file-category/update/${params.row.id}`}
            size="small"
            label="Edit"
            sx={{ background: '#dbe1e9', color: 'black' }}
          />
          <CButton
            label="Delete"
            yesNo
            sx={{ background: '#dbe1e9', color: 'black' }}
            onClick={() => handleDelete(params.row.id, 'FileCategory')}
          />
        </ButtonGroup>
      ),
    },
  ]

  useEffect(() => {
    // page title
    document.title = 'BioAro | File Category - List'
    dispatch(list())
  }, [])

  // Handle form submission
  const handleDelete = async (fileCategoryId, model) => {
    try {
      const res = await dispatch(
        deleteRecord({
          id: fileCategoryId,
          model: model,
        })
      )

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        dispatch(list())
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Error while creating File:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred during File creation.',
        })
      )
    }
  }

  // Handle form submission
  const handleStatusChange = async (fileCategoryId, status, model, column) => {
    try {
      const res = await dispatch(
        changeStatus({
          id: fileCategoryId,
          status: status,
          model: model,
          column: column,
        })
      )

      if (res.payload && !res.payload.error) {
        dispatch(
          showSuccess({
            success: true,
            message: res.payload.message,
          })
        )
        dispatch(list())
      } else {
        dispatch(
          showError({
            error: true,
            message: res.payload.message,
          })
        )
      }
    } catch (error) {
      console.error('Error while creating File:', error)
      dispatch(
        showError({
          error: true,
          message: 'An error occurred during File creation.',
        })
      )
    }
  }

  return (
    <>
      <Helmet>
        <title>Bioaro | Tag - List</title>
      </Helmet>
      <Card sx={{ p: 2 }}>
        <Stack direction="row" justifyContent="flex-end" mb={1}>
          <CButton
            label="Create"
            icon={<AddIcon />}
            component={Link}
            to={`/file-category/create`}
          />
        </Stack>

        <CDatatable
          rows={categories}
          columns={columns}
          isLoading={isLoading}
          handleQuickFilterValue={handleQuickFilterValue}
        />
      </Card>
    </>
  )
}

export default FileCategoryList
