// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance, setAuthToken } from '../../../../services/axiosConfig'

export const fetchReport = createAsyncThunk(
  'patient/ibs-journal/report',
  async ({ patientId, filterData }) => {
    const response = await axiosInstance.get(
      `patient/ibs-journal/report/${patientId}`,
      { params: filterData }
    )

    return response.data
  }
)

const ibsJournalSlice = createSlice({
  name: 'ibsJournal',
  initialState: {
    isLoading: false,
    message: '',
    errors: '',
    data: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch  IBS Journal Report
      .addCase(fetchReport.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchReport.fulfilled, (state, { payload } = action) => {
        state.data = payload.reports
        state.isLoading = false
      })
      .addCase(fetchReport.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default ibsJournalSlice.reducer
