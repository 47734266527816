import React, { useState } from 'react'
import ReactQuill from 'react-quill'
import PropTypes from 'prop-types'
import 'react-quill/dist/quill.snow.css'
import { Box, FormLabel, Typography } from '@mui/material'

const Editor = ({ label, value, handleChange, placeholder, helperText,required }) => {
  const modules = {
    toolbar: [
      // [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ header: [1, 2, false] }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image', 'video'],
      ['clean'],
    ],
    clipboard: {
      matchVisual: false,
    },
  }

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
  ]

  return (
    <Box>
      <Typography sx={{ mb: 1 }}>
        <FormLabel>
          {label}{' '}
          {required && (
            <Typography sx={{ display: 'inline', color: 'red' }}>*</Typography>
          )}
        </FormLabel>
      </Typography>
      <Box>
        <ReactQuill
          style={{ height: '18rem' }}
          theme="snow"
          onChange={handleChange}
          value={value}
          modules={modules}
          formats={formats}
          placeholder={placeholder}
        />
      </Box>
      {/* mt={[2, 4, 8, 12]} sets the top margin to 2 on extra-small screens, 4 on
      small screens, 8 on medium screens, and 12 on large screens. */}
      <Box mt={[12, 9, 9, 9]}></Box>
      <Typography variant="subtitle2" sx={{ color: '#d32f2f' }}>
        {helperText}
      </Typography>
    </Box>
  )
}

export default Editor
