// // UserList.jsx
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Icon
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import AddIcon from '@mui/icons-material/Add'
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
// Components
import AdminLayout from '@/layouts/AdminLayout'

// Slice
import { setBreadcrumb } from '@/redux/Slice/breadcrumbSlice'
import { Link, useNavigate } from 'react-router-dom'
import CButton from '@/components/CButton'
import CDatatable from '@/components/CDatatable'
import { changeStatus, deleteRecord } from '@/redux/Slice/commonSlice'
import { showError, showSuccess } from '@/redux/Slice/alertSlice'
import CTextField from '@/components/CTextField'
import SaveIcon from '@mui/icons-material/Save'
import { Helmet } from 'react-helmet-async'
import { create, list } from '../giftCardSlice'
import GiftCardUpdate from './GiftCardUpdate'
import { renderCell } from '@/utils/ctableTool'

const GiftCardList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { giftCards, errors, isLoading } = useSelector(
    (state) => state.giftCard
  )

  useEffect(() => {
    // Set breadcrumb information when component mounts
    dispatch(
      setBreadcrumb([
        {
          path: 'website/gift-card/list',
          label: 'GiftCard',
          isLast: false,
        },
        { path: '', label: 'List', isLast: true },
      ])
    )
  }, [dispatch])

  const handleQuickFilterValue = (filterModel) => {
    const quickFilterValue = filterModel.quickFilterValues.join(' ')
    console.log(quickFilterValue)
  }

  const columns = [
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'price', headerName: 'Price', flex: 0.4 },
    { field: 'avail_month', headerName: 'Availability/Expire After (Month)', flex: 1 },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
      renderCell: (params) =>
        renderCell(
          <Box
            component="div"
            dangerouslySetInnerHTML={{
              __html: params.row.description,
            }}
          />
        ),
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 0.5,
      renderCell: (params) => (
        <ButtonGroup variant="contained" aria-label="Basic button group">
          <CButton
            component={Link}
            to={`/website/gift-card/update/${params.row.id}`}
            size="small"
            label="Edit"
          />
          <CButton
            label="Delete"
            sx={{ background: '#cf2323', color: '#fff' }}
            onClick={() => handleDelete(params.row.id, 'GiftCard')}
          />
        </ButtonGroup>
      ),
    },
  ]

      // Handle form submission
      const handleDelete = async (newsId, model) => {
        try {
          const res = await dispatch(
            deleteRecord({
              id: newsId,
              model: model,
            })
          )
    
          if (res.payload && !res.payload.error) {
            dispatch(
              showSuccess({
                success: true,
                message: res.payload.message,
              })
            )
            dispatch(list())
          } else {
            dispatch(
              showError({
                error: true,
                message: res.payload.message,
              })
            )
          }
        } catch (error) {
          console.error('Error while creating File:', error)
          dispatch(
            showError({
              error: true,
              message: 'An error occurred during File creation.',
            })
          )
        }
      }

  useEffect(() => {
    dispatch(list())
  }, [])

  return (
    <>
      <Helmet>
        <title>Bioaro | Gift Card - List</title>
      </Helmet>
      <Card sx={{ p: 2 }}>
        <Stack direction="row" justifyContent="flex-end" mb={1}>
          <CButton
            label="Create Gift-Card"
            icon={<AddIcon />}
            component={Link}
            size='small'
            to={`/website/gift-card/create`}
            sx={{marginRight:'4px'}}
          />
          <CButton
            label="Purchase Gift-Card"
            component={Link}
            size='small'
            // to={`/website/gift-card/create`}
          />
        </Stack>
        <CDatatable
          rows={giftCards}
          columns={columns}
          isLoading={isLoading}
          handleQuickFilterValue={handleQuickFilterValue}
        />
      </Card>
    </>
  )
}

export default GiftCardList
